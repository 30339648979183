import FlexRow from "../../layout/Flexbox/FlexRow";
import { useRef, useState } from "react";

import "./CheckboxInputField_style.css";

import checkboxChecked from "../../../../assets/images/common/svg/checkbox_checked.svg";
import checkboxUnchecked from "../../../../assets/images/common/svg/checkbox_unchecked.svg";

type Props = {
    checked: boolean;
    label: string;
    isLabelHidden?: boolean;
    onChange?: (val: boolean) => void;
    buttonOrientation?: "left" | "right";
};

export default function CheckboxInputField({
    checked,
    label,
    isLabelHidden = false,
    onChange,
    buttonOrientation = "left",
}: Props): JSX.Element {
    const [isChecked, setIsChecked] = useState<boolean>(checked);

    return (
        <FlexRow className="checkbox-input-container">
            {buttonOrientation === "left" && (
                <>
                    <ButtonComponent
                        checked={isChecked}
                        onClick={() => {
                            setIsChecked(() => {
                                const val = !isChecked;
                                onChange?.(val);
                                return val;
                            });
                        }}
                    />
                    {!isLabelHidden && (
                        <LabelComponent
                            label={label}
                            onClick={() => {
                                setIsChecked(() => {
                                    const val = !isChecked;
                                    onChange?.(val);
                                    return val;
                                });
                            }}
                        />
                    )}
                </>
            )}
            {buttonOrientation === "right" && (
                <>
                    {!isLabelHidden && (
                        <LabelComponent
                            label={label}
                            onClick={() => {
                                setIsChecked(() => {
                                    const val = !isChecked;
                                    onChange?.(val);
                                    return val;
                                });
                            }}
                        />
                    )}
                    <ButtonComponent
                        checked={isChecked}
                        onClick={() => {
                            setIsChecked(() => {
                                const val = !isChecked;
                                onChange?.(val);
                                return val;
                            });
                        }}
                    />
                </>
            )}
        </FlexRow>
    );
}

function ButtonComponent({
    checked,
    onClick,
}: {
    checked: boolean;
    onClick?: (e?: React.MouseEvent) => void;
}): JSX.Element {
    return (
        <img
            className="button-element"
            src={checked ? checkboxChecked : checkboxUnchecked}
            onClick={(e) => {
                onClick?.(e);
            }}
        />
    );
}

function LabelComponent({
    label,
    onClick,
}: {
    label: string;
    onClick?: (e?: React.MouseEvent) => void;
}): JSX.Element {
    return (
        <div
            className="label-element"
            onClick={(e) => {
                onClick?.(e);
            }}
        >
            {label}
        </div>
    );
}
