import FlexColumn from "../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../common/components/layout/Flexbox/FlexRow";

import "./CharacterProfileProficiencies_style.css";

type Props = {
    proficiencies: {
        strength: number;
        agility: number;
        intelligence: number;
        fortitude: number;
        charisma: number;
    };
};

export default function CharacterProfileProficiencies({
    proficiencies,
}: Props): JSX.Element {
    const highest = Math.max(
        proficiencies.strength,
        proficiencies.agility,
        proficiencies.intelligence,
        proficiencies.fortitude,
        proficiencies.charisma
    );

    return (
        <FlexRow>
            <FlexColumn className="profile-proficiency-container">
                <FlexRow style={{ fontWeight: "bold" }}>STR</FlexRow>
                <FlexRow
                    style={
                        proficiencies.strength === highest
                            ? { fontWeight: "bold" }
                            : undefined
                    }
                >
                    {proficiencies.strength}
                </FlexRow>
            </FlexColumn>
            <FlexColumn className="profile-proficiency-container">
                <FlexRow style={{ fontWeight: "bold" }}>AGI</FlexRow>
                <FlexRow
                    style={
                        proficiencies.agility === highest
                            ? { fontWeight: "bold" }
                            : undefined
                    }
                >
                    {proficiencies.agility}
                </FlexRow>
            </FlexColumn>
            <FlexColumn className="profile-proficiency-container">
                <FlexRow style={{ fontWeight: "bold" }}>INT</FlexRow>
                <FlexRow
                    style={
                        proficiencies.intelligence === highest
                            ? { fontWeight: "bold" }
                            : undefined
                    }
                >
                    {proficiencies.intelligence}
                </FlexRow>
            </FlexColumn>
            <FlexColumn className="profile-proficiency-container">
                <FlexRow style={{ fontWeight: "bold" }}>FOR</FlexRow>
                <FlexRow
                    style={
                        proficiencies.fortitude === highest
                            ? { fontWeight: "bold" }
                            : undefined
                    }
                >
                    {proficiencies.fortitude}
                </FlexRow>
            </FlexColumn>
            <FlexColumn className="profile-proficiency-container">
                <FlexRow style={{ fontWeight: "bold" }}>CHA</FlexRow>
                <FlexRow
                    style={
                        proficiencies.charisma === highest
                            ? { fontWeight: "bold" }
                            : undefined
                    }
                >
                    {proficiencies.charisma}
                </FlexRow>
            </FlexColumn>
        </FlexRow>
    );
}
