import Trait from "../Trait";
import { TraitConstructorParameters } from "../Trait";
import VCTagUtils from "../../../sys/VCTagUtils";
import { CharacterTraitJSON } from "../TraitJSON";
import { Dictionary } from "../../../sys/Dictionary";

type AdditionalParameters = {
    active?: string | boolean;
};

type CharacterTraitConstructorParameters = TraitConstructorParameters &
    AdditionalParameters;

export default class CharacterTrait extends Trait {
    private _active: boolean = true;
    public get active() {
        return this._active;
    }
    public set active(val: boolean) {
        this._active = val;
    }

    constructor(args: CharacterTraitConstructorParameters) {
        super(args);
        if (args.active != null) {
            if (typeof args.active === "string") {
                this._active =
                    args.active === "1" ||
                    args.active.charAt(1).toLowerCase() === "t";
            } else if (typeof args.active === "boolean") {
                this._active = args.active;
            }
        }
    }

    public static fromTrait(
        trait: Trait,
        isActive: boolean = true
    ): CharacterTrait {
        return new CharacterTrait({
            id: trait.id,
            title: trait.title,
            description: trait.description,
            cost: trait.cost,
            subtype: trait.subtype,
            mods: trait.mods,
            module: trait.module,
            active: isActive,
        });
    }

    public static override fromVCTagString(
        input: string,
        id?: number,
        title?: string
    ): CharacterTrait {
        return new CharacterTrait({
            ...Trait.argsFromVCTagString(input, id, title),
            active: VCTagUtils.readTag(input, "active"),
        });
    }

    public static fromJSON(json: CharacterTraitJSON) {
        const mods = new Dictionary<string, number>();
        if (json.stat_mods != null) {
            for (var key in json.stat_mods) {
                mods.set(key, Number.parseInt(json.stat_mods[key], 10));
            }
        }

        return new CharacterTrait({
            id: json.id ?? -1,
            title: json.title ?? "",
            description: json.description ?? "",
            cost: json.cost ?? 0,
            subtype: json.subtype ?? "",
            mods: mods,
            module: json.module ?? "",
            active: json.is_active === true,
        });
    }

    public override toVCTagString(): string {
        return super
            .toVCTagString()
            .concat(`[active]${this._active ? "1" : "0"}[/active]`);
    }

    public toJSON(): CharacterTraitJSON {
        const rootJSON = super.toJSON();
        return { ...rootJSON, is_active: this._active };
    }
}
