import { CharacterData } from "../../../../../utils/game/character/CharacterData";
import { KeyValuePair } from "../../../../../utils/sys/KeyValuePair";
import { useCallback, useState } from "react";

import Ability from "../../../../../utils/game/mechanics/Ability";
import CharacterSchool from "../../../../../utils/game/mechanics/character/CharacterSchool";
import CharacterSheetAbilitiesPanel from "../../CharacterSheetAbilitiesPanel/CharacterSheetAbilitiesPanel";
import CharacterSheetSchoolsPanel from "../../CharacterSheetSchoolsPanel/CharacterSheetSchoolsPanel";
import FlexColumn from "../../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../../common/components/layout/Flexbox/FlexRow";
import TabControl from "../../../../../common/components/layout/TabControl/TabControl";
import TagList from "../../../../../common/components/display/TagList/TagList";
import TextInput from "../../../../../common/components/input/TextInput/TextInput";

import "./CharacterSheetAbilitiesTab_style.css";

type Props = {
    characterData: CharacterData;
    isEditable?: boolean;
    isEditing?: boolean;
    onEditingChanged?: (editing: boolean) => void;
    pointSpendEnabled?: boolean;
    onPointSpendEnabledChanged?: (val: boolean) => void;
    pendingSchoolPointSpend?: Array<{ id: number; points: number }>;
    onPendingSchoolPointSpendChanged?: (
        vals: Array<{ id: number; points: number }>
    ) => void;
    selectedTab?: string | null;
    onSelectedTabChanged?: (tab: string | null) => void;
    onCharacterDataValueChanged?: (val: KeyValuePair[]) => void;
};

export default function CharacterSheetAbilitiesTab(props: Props): JSX.Element {
    const [selectedAbilitiesTab, setSelectedAbilitiesTab] = useState<
        string | null
    >(props.selectedTab ?? null);
    const [abilityOptions, setAbilityOptions] = useState<
        Array<Ability> | undefined
    >(undefined);
    const [vocationInputText, setVocationInputText] = useState<string>("");
    const [vocationTags, setVocationTags] = useState<Array<string>>(
        props.characterData.vocations
    );

    const addVocation = useCallback(
        (vocation: string) => {
            if (!vocationTags.includes(vocation)) {
                const newVocationTags = [...vocationTags, vocation];
                setVocationTags(newVocationTags);
                setVocationInputText("");

                props.onCharacterDataValueChanged?.([
                    {
                        key: "vocations",
                        value: newVocationTags.map(
                            (voc) => `[vocation]${voc}[/vocation]`
                        ),
                    },
                ]);
            }
        },
        [
            vocationTags,
            setVocationTags,
            setVocationInputText,
            props.onCharacterDataValueChanged,
        ]
    );

    const removeVocation = useCallback(
        (vocation: string) => {
            if (vocationTags.includes(vocation)) {
                const newVocationTags = vocationTags.filter(
                    (v) => v !== vocation
                );
                setVocationTags(newVocationTags);
                setVocationInputText("");

                props.onCharacterDataValueChanged?.([
                    {
                        key: "vocations",
                        value: newVocationTags.map(
                            (voc) => `[vocation]${voc}[/vocation]`
                        ),
                    },
                ]);
            }
        },
        [
            vocationTags,
            setVocationTags,
            setVocationInputText,
            props.onCharacterDataValueChanged,
        ]
    );

    return (
        <FlexColumn className="character-sheet-abilities-panel">
            <TabControl
                id="abilities_tab_control"
                selected={selectedAbilitiesTab}
                onSelectedTabChange={(tab) => {
                    setSelectedAbilitiesTab(() => {
                        props.onSelectedTabChanged?.(tab);
                        return tab;
                    });
                }}
                tabs={[
                    { id: "schools", label: "Schools" },
                    { id: "vocations", label: "Vocations" },
                    { id: "primary", label: "Primary" },
                    { id: "auxiliary", label: "Auxiliary" },
                    { id: "passive", label: "Passive" },
                ]}
            />
            {selectedAbilitiesTab === "schools" && (
                <CharacterSheetSchoolsPanel
                    characterData={props.characterData}
                    isEditable={props.isEditable}
                    isEditing={props.isEditing}
                    onEditingChanged={props.onEditingChanged}
                    pointSpendEnabled={props.pointSpendEnabled}
                    onPointSpendEnabledChanged={
                        props.onPointSpendEnabledChanged
                    }
                    pendingSchoolPointSpend={props.pendingSchoolPointSpend}
                    onPendingSchoolPointSpendChanged={
                        props.onPendingSchoolPointSpendChanged
                    }
                    onSchoolsAdded={(schools) => {
                        if (props.onCharacterDataValueChanged !== undefined) {
                            const newCharacterSchools = [
                                ...props.characterData.schools,
                                ...schools.map((school) =>
                                    CharacterSchool.fromSchool(school, 0)
                                ),
                            ];
                            props.onCharacterDataValueChanged([
                                {
                                    key: "schools",
                                    value: JSON.stringify(
                                        newCharacterSchools.map((school) =>
                                            school.toJSON()
                                        )
                                    ),
                                },
                            ]);
                        }
                    }}
                    onCharacterDataValueChanged={
                        props.onCharacterDataValueChanged
                    }
                />
            )}
            {selectedAbilitiesTab === "vocations" && (
                <FlexColumn>
                    {props.isEditable === true && (
                        <FlexRow gap={8}>
                            <TextInput
                                value={vocationInputText}
                                onChange={setVocationInputText}
                                onEnterKeyPress={() => {
                                    if (vocationInputText.length > 0) {
                                        addVocation(vocationInputText);
                                    }
                                }}
                            />
                            <button
                                onClick={() => {
                                    if (vocationInputText.length > 0) {
                                        addVocation(vocationInputText);
                                    }
                                }}
                            >
                                +
                            </button>
                        </FlexRow>
                    )}
                    <TagList
                        tagEntries={props.characterData.vocations.map(
                            (vocation) => {
                                return {
                                    id: vocation,
                                    value: vocation,
                                    isEditable: props.isEditable,
                                };
                            }
                        )}
                        onTagRemoved={(tag) => {
                            removeVocation(tag);
                        }}
                    />
                </FlexColumn>
            )}
            {selectedAbilitiesTab === "primary" && (
                <CharacterSheetAbilitiesPanel
                    label="Primary Abilities"
                    abilityOptions={abilityOptions}
                    onAbilityOptionsChanged={setAbilityOptions}
                    abilities={props.characterData.abilities}
                    isEditable={props.isEditable}
                    abilityCountLimit={10}
                    onAbilitiesAdded={(abilities) => {
                        if (props.onCharacterDataValueChanged !== undefined) {
                            const newAbilitiesList = [
                                ...props.characterData.abilities,
                                ...abilities,
                            ];
                            props.onCharacterDataValueChanged([
                                {
                                    key: "abilities",
                                    value: JSON.stringify(
                                        newAbilitiesList.map((ability) =>
                                            ability.toJSON()
                                        )
                                    ),
                                },
                            ]);
                        }
                    }}
                    onAbilityRemoved={(ability) => {
                        if (props.onCharacterDataValueChanged !== undefined) {
                            const newAbilitiesList = [
                                ...props.characterData.abilities.filter(
                                    (a) => a !== ability
                                ),
                            ];
                            props.onCharacterDataValueChanged([
                                {
                                    key: "abilities",
                                    value: JSON.stringify(
                                        newAbilitiesList.map((a) => a.toJSON())
                                    ),
                                },
                            ]);
                        }
                    }}
                />
            )}
            {selectedAbilitiesTab === "auxiliary" && (
                <CharacterSheetAbilitiesPanel
                    label="Auxiliary Abilities"
                    abilityOptions={abilityOptions}
                    onAbilityOptionsChanged={setAbilityOptions}
                    abilities={props.characterData.auxAbilities}
                    isEditable={props.isEditable}
                    onAbilitiesAdded={(abilities) => {
                        if (props.onCharacterDataValueChanged !== undefined) {
                            const newAbilitiesList = [
                                ...props.characterData.auxAbilities,
                                ...abilities,
                            ];
                            props.onCharacterDataValueChanged([
                                {
                                    key: "aux_abilities",
                                    value: JSON.stringify(
                                        newAbilitiesList.map((ability) =>
                                            ability.toJSON()
                                        )
                                    ),
                                },
                            ]);
                        }
                    }}
                    onAbilityRemoved={(ability) => {
                        if (props.onCharacterDataValueChanged !== undefined) {
                            const newAbilitiesList = [
                                ...props.characterData.auxAbilities.filter(
                                    (a) => a !== ability
                                ),
                            ];
                            props.onCharacterDataValueChanged([
                                {
                                    key: "aux_abilities",
                                    value: JSON.stringify(
                                        newAbilitiesList.map((a) => a.toJSON())
                                    ),
                                },
                            ]);
                        }
                    }}
                />
            )}
            {selectedAbilitiesTab === "passive" && (
                <CharacterSheetAbilitiesPanel
                    label="Passive Abilities"
                    abilityOptions={abilityOptions}
                    onAbilityOptionsChanged={setAbilityOptions}
                    abilities={props.characterData.passiveAbilities}
                    isEditable={props.isEditable}
                    abilityCountLimit={5}
                    optionFilter={(a) =>
                        a.subtype.toLowerCase().includes("passive")
                    }
                    onAbilitiesAdded={(abilities) => {
                        if (props.onCharacterDataValueChanged !== undefined) {
                            const newAbilitiesList = [
                                ...props.characterData.passiveAbilities,
                                ...abilities,
                            ];
                            props.onCharacterDataValueChanged([
                                {
                                    key: "passive_abilities",
                                    value: JSON.stringify(
                                        newAbilitiesList.map((ability) =>
                                            ability.toJSON()
                                        )
                                    ),
                                },
                            ]);
                        }
                    }}
                    onAbilityRemoved={(ability) => {
                        if (props.onCharacterDataValueChanged !== undefined) {
                            const newAbilitiesList = [
                                ...props.characterData.passiveAbilities.filter(
                                    (a) => a !== ability
                                ),
                            ];
                            props.onCharacterDataValueChanged([
                                {
                                    key: "passive_abilities",
                                    value: JSON.stringify(
                                        newAbilitiesList.map((a) => a.toJSON())
                                    ),
                                },
                            ]);
                        }
                    }}
                />
            )}
        </FlexColumn>
    );
}
