import { KeyValuePair } from "./KeyValuePair";

export class Dictionary<K = any, V = any> {
    /** Underlying array of key-value pairs. */
    private keyValuePairs: Array<KeyValuePair<K, V>>;

    constructor(
        vals?:
            | Array<{ key: K; value: V }>
            | Array<KeyValuePair<K, V>>
            | Array<{ key: K; value: V } | KeyValuePair<K, V>>
    ) {
        this.keyValuePairs =
            vals == null
                ? []
                : vals.map((v) => {
                      return { key: v.key, value: v.value };
                  });
    }

    /** Returns an array of the keys present in the dictionary. */
    public get keys(): Array<K> {
        return this.keyValuePairs.map((x) => x.key);
    }

    /** Returns an array of the values present in the dictionary. */
    public get values(): Array<V> {
        return this.keyValuePairs.map((x) => x.value);
    }

    /** Returns the number of key-value pairs in the dictionary. */
    public get length(): number {
        return this.keyValuePairs.length;
    }

    /** Inserts a new key-value pair to the dictionary. Overwrites the previous value of key if it is already present. Returns the index of the added value. */
    public set(key: K, value: V): number {
        let index = this.keyValuePairs.findIndex((x) => x.key === key);
        if (index < 0) {
            this.keyValuePairs.push({ key, value });
            index = this.keyValuePairs.length - 1;
        } else {
            this.keyValuePairs[index].value = value;
        }

        return index;
    }

    /** Inserts multiple new key-value pairs to the dictionary, overwriting any existing keys with the respective new values. Returns the indices of the newly-inserted values. */
    public setMultiple(...entries: Array<{ key: K; value: V }>): Array<number> {
        const indices: Array<number> = [];
        entries.forEach((entry) =>
            indices.push(this.set(entry.key, entry.value))
        );

        return indices;
    }

    /** Removes the specified key, and its corresponding value, from the dictionary, if present. Returns the index where the removal occurred, or -1 if no removal occurred. */
    public remove(key: K): number {
        let result = -1;
        this.keyValuePairs = this.keyValuePairs.filter((x, i) => {
            if (x.key === key) {
                result = i;
                return false;
            }
            return true;
        });
        return result;
    }

    /** Removes all key-value pairs from the dictionary. */
    public clear(): void {
        this.keyValuePairs = [];
    }

    public hasKey(key: K): boolean {
        return this.keyValuePairs.find((x) => x.key === key) ? true : false;
    }

    /** Returns the value associated with the provided key, or undefined if the key does not exist. */
    public get(key: K): V | undefined {
        return this.keyValuePairs.find((p) => p.key === key)?.value;
    }

    /** Returns the key(s) whose value is equal to the one provided, possibly an empty array. */
    public reverseGet(value: V): Array<K> {
        return this.keyValuePairs
            .filter((p) => p.value === value)
            .map((p) => p.key);
    }

    /** Returns the first key whose value is equal to the one provided, or null if no such value exists. */
    public reverseGetFirst(value: V): K | undefined {
        return this.keyValuePairs.find((p) => p.value === value)?.key;
    }

    public toArray(): Array<KeyValuePair<K, V>> {
        return this.keyValuePairs;
    }

    /** Perform a forEach operation on the underlying array. */
    public forEach(
        callbackfn: (
            value: KeyValuePair<K, V>,
            index: number,
            array: KeyValuePair<K, V>[]
        ) => void,
        thisArg?: any
    ) {
        this.keyValuePairs.forEach(callbackfn, thisArg);
    }

    /** Perform a map operation on the underlying array. */
    public map(
        callbackfn: (
            value: KeyValuePair<K, V>,
            index: number,
            array: KeyValuePair<K, V>[]
        ) => unknown,
        thisArg?: any
    ): unknown[] {
        return this.keyValuePairs.map(callbackfn, thisArg);
    }

    public find(
        predicate: (
            value: KeyValuePair<K, V>,
            index: number,
            array: KeyValuePair<K, V>[]
        ) => boolean,
        thisArg?: any
    ): KeyValuePair<K, V> | undefined {
        return this.keyValuePairs.find(predicate);
    }

    public filter(
        predicate: (
            key: K,
            value: V,
            index: number,
            array: KeyValuePair<K, V>[]
        ) => boolean,
        thisArg?: any
    ): Dictionary<K, V> {
        return new Dictionary(this.filterEntries(predicate, thisArg));
    }

    public filterEntries(
        predicate: (
            key: K,
            value: V,
            index: number,
            array: KeyValuePair<K, V>[]
        ) => boolean,
        thisArg?: any
    ): KeyValuePair<K, V>[] {
        return this.keyValuePairs.filter(
            (entry, idx, arr) => predicate(entry.key, entry.value, idx, arr),
            thisArg
        );
    }

    public filterValues(
        predicate: (value: V, index: number, array: V[]) => boolean,
        thisArg?: any
    ): V[] {
        return this.values.filter(
            (val, idx, arr) => predicate(val, idx, arr),
            thisArg
        );
    }

    public toString(): string {
        return this.keyValuePairs.toString();
    }
}
