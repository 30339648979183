import { CharacterData } from "../../../../utils/game/character/CharacterData";
import { InventoryItemTransferRequestResponse } from "../../../../utils/sys/InventoryItemTransferContext";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import InventoryItem from "../../../../utils/game/mechanics/InventoryItem";
import InventoryItemIconSlot from "../InventoryItemSlot/InventoryItemIconSlot";
import { useContext, useMemo } from "react";
import InventoryItemTransferContext from "../../../../utils/sys/InventoryItemTransferContext";
import { UIInventoryItemContainerClickParams } from "../InventoryItemSlot/InventoryItemContainer";

import "./CharacterEquipmentPanel_style.css";

type Props = {
    characterData: CharacterData;
    isEditable?: boolean;
    onItemClick?: (params?: UIInventoryItemContainerClickParams) => void;
};

export default function CharacterEquipmentPanel({
    characterData,
    isEditable,
    onItemClick,
}: Props): JSX.Element {
    const inventoryItemTransferContext = useContext(
        InventoryItemTransferContext
    );

    const storedItemEquipTypes = useMemo(() => {
        return inventoryItemTransferContext.storedItem?.equipTypes ?? [];
    }, [inventoryItemTransferContext.storedItem]);

    return (
        <div className="character-equipment-container">
            <FlexColumn>
                <FlexRow alignment="center">
                    <InventoryItemIconSlot
                        id={"ammo"}
                        className="item-slot"
                        emptyIconSrc="arrow_blank"
                        item={characterData.ammo}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return { type: "container", item, slotKey: "ammo" };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("ammunition") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.ammo
                        }
                        isValidTarget={storedItemEquipTypes.includes(
                            "ammunition"
                        )}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <div className="item-slot-spacer" />
                    <InventoryItemIconSlot
                        id={"helmet"}
                        className="item-slot"
                        emptyIconSrc="helmet_blank"
                        item={characterData.head}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return { type: "container", item, slotKey: "head" };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("head") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.head
                        }
                        isValidTarget={storedItemEquipTypes.includes("head")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <InventoryItemIconSlot
                        id={"neck"}
                        className="item-slot"
                        emptyIconSrc="amulet_blank"
                        item={characterData.neck}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return { type: "container", item, slotKey: "neck" };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("neck") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.neck
                        }
                        isValidTarget={storedItemEquipTypes.includes("neck")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <div className="item-slot-spacer" />
                </FlexRow>
                <FlexRow alignment="center">
                    <InventoryItemIconSlot
                        id={"ring1"}
                        className="item-slot"
                        emptyIconSrc="ring_blank"
                        item={characterData.ring1}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return {
                                type: "container",
                                item,
                                slotKey: "ring1",
                            };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("finger") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.ring1
                        }
                        isValidTarget={storedItemEquipTypes.includes("finger")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <InventoryItemIconSlot
                        id={"lh"}
                        className="item-slot"
                        emptyIconSrc="lh_blank"
                        item={characterData.lh}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return { type: "container", item, slotKey: "lh" };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("held") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.lh
                        }
                        isValidTarget={storedItemEquipTypes.includes("held")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <InventoryItemIconSlot
                        id={"torso"}
                        className="item-slot"
                        emptyIconSrc="shirt_blank"
                        item={characterData.torso}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return {
                                type: "container",
                                item,
                                slotKey: "torso",
                            };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("torso") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.torso
                        }
                        isValidTarget={storedItemEquipTypes.includes("torso")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <InventoryItemIconSlot
                        id={"rh"}
                        className="item-slot"
                        emptyIconSrc="rh_blank"
                        item={characterData.rh}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return { type: "container", item, slotKey: "rh" };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("held") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.rh
                        }
                        isValidTarget={storedItemEquipTypes.includes("held")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <InventoryItemIconSlot
                        id={"ring4"}
                        className="item-slot"
                        emptyIconSrc="ring_blank"
                        item={characterData.ring4}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return {
                                type: "container",
                                item,
                                slotKey: "ring4",
                            };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("finger") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.ring4
                        }
                        isValidTarget={storedItemEquipTypes.includes("finger")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                </FlexRow>
                <FlexRow alignment="center">
                    <InventoryItemIconSlot
                        id={"ring2"}
                        className="item-slot"
                        emptyIconSrc="ring_blank"
                        item={characterData.ring2}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return {
                                type: "container",
                                item,
                                slotKey: "ring2",
                            };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("finger") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.ring2
                        }
                        isValidTarget={storedItemEquipTypes.includes("finger")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <InventoryItemIconSlot
                        id={"hands"}
                        className="item-slot"
                        emptyIconSrc="gloves_blank"
                        item={characterData.hands}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return {
                                type: "container",
                                item,
                                slotKey: "hands",
                            };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("hands") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.hands
                        }
                        isValidTarget={storedItemEquipTypes.includes("hands")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <InventoryItemIconSlot
                        id={"legs"}
                        className="item-slot"
                        emptyIconSrc="pants_blank"
                        item={characterData.legs}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return { type: "container", item, slotKey: "legs" };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("legs") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.legs
                        }
                        isValidTarget={storedItemEquipTypes.includes("legs")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <InventoryItemIconSlot
                        id={"waist"}
                        className="item-slot"
                        emptyIconSrc="belt_blank"
                        item={characterData.waist}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return {
                                type: "container",
                                item,
                                slotKey: "waist",
                            };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("waist") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.waist
                        }
                        isValidTarget={storedItemEquipTypes.includes("waist")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <InventoryItemIconSlot
                        id={"ring5"}
                        className="item-slot"
                        emptyIconSrc="ring_blank"
                        item={characterData.ring5}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return {
                                type: "container",
                                item,
                                slotKey: "ring5",
                            };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("finger") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.ring5
                        }
                        isValidTarget={storedItemEquipTypes.includes("finger")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                </FlexRow>
                <FlexRow alignment="center">
                    <InventoryItemIconSlot
                        id={"ring3"}
                        className="item-slot"
                        emptyIconSrc="ring_blank"
                        item={characterData.ring3}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return {
                                type: "container",
                                item,
                                slotKey: "ring3",
                            };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("finger") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.ring3
                        }
                        isValidTarget={storedItemEquipTypes.includes("finger")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <div className="item-slot-spacer" />
                    <InventoryItemIconSlot
                        id={"feet"}
                        className="item-slot"
                        emptyIconSrc="boots_blank"
                        item={characterData.feet}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return { type: "container", item, slotKey: "feet" };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("feet") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.feet
                        }
                        isValidTarget={storedItemEquipTypes.includes("feet")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                    <div className="item-slot-spacer" />
                    <InventoryItemIconSlot
                        id={"ring6"}
                        className="item-slot"
                        emptyIconSrc="ring_blank"
                        item={characterData.ring6}
                        isEditable={isEditable}
                        setItemCallback={(item) => {
                            return {
                                type: "container",
                                item,
                                slotKey: "ring6",
                            };
                        }}
                        itemRestrictor={(item) =>
                            item?.equipTypes.includes("finger") ?? true
                        }
                        isSelected={
                            inventoryItemTransferContext.storedItem != null &&
                            inventoryItemTransferContext.storedItem ===
                                characterData.ring6
                        }
                        isValidTarget={storedItemEquipTypes.includes("finger")}
                        tooltipEnabled={true}
                        onClick={onItemClick}
                    />
                </FlexRow>
            </FlexColumn>
        </div>
    );
}
