import { KeyValuePair } from "../../../../utils/sys/KeyValuePair";
import FlexColumn from "../Flexbox/FlexColumn";
import FlexRow from "../Flexbox/FlexRow";
import TextField from "../../display/TextField/TextField";
import { useState } from "react";

import sortAsc from "../../../../assets/images/common/svg/sort_asc.svg";
import sortDesc from "../../../../assets/images/common/svg/sort_desc.svg";
import sortNone from "../../../../assets/images/common/svg/sort_none.svg";

import "./Table_style.css";

export type TableSortKey = { key: string; order?: "asc" | "desc" };

type Props<T> = {
    columns: Array<{
        columnKey: string;
        columnHeader?: string;
        sortable?: boolean;
        width?: string | number;
    }>;
    rowData: Array<T>;
    cellParser: Array<{
        columnKey: string;
        callback: (val: T) => React.ReactNode;
    }>;
    idGetter: (val: T) => string;
    /** A row footer is a full table width component sitting underneath the main cells for a row */
    rowFooter?: (val: T) => React.ReactNode;
    isRowSelected?: (val: T) => boolean;
    sortKey?: TableSortKey | null;
    onChangeSortKey?: (key: TableSortKey | null) => void;
    onRowClick?: (val: T) => void;
    onHeaderClick?: (key: string) => void;
    rowFilter?: ((val: T) => boolean) | null;
    showHeader?: boolean;
    className?: string;
};

export default function Table<T = Array<string>>(props: Props<T>): JSX.Element {
    return (
        <FlexColumn
            className={`generic-table-container${
                props.className ? ` ${props.className}` : ``
            }`}
        >
            {(props.showHeader === undefined || props.showHeader === true) && (
                <FlexRow className="generic-table-header-container">
                    {props.columns.map((col) => {
                        return (
                            <FlexRow
                                key={col.columnKey}
                                className={`generic-table-header-cell${
                                    col.sortable === true ? ` sortable` : ``
                                }`}
                                alignment="end"
                                style={
                                    col.width === undefined
                                        ? { width: "100%" }
                                        : {
                                              width:
                                                  typeof col.width === "string"
                                                      ? col.width
                                                      : `${col.width}px`,
                                              flexShrink:
                                                  typeof col.width === "string"
                                                      ? col.width.includes("px")
                                                          ? 0
                                                          : 1
                                                      : 0,
                                          }
                                }
                                onClick={() => {
                                    if (col.sortable === true) {
                                        if (
                                            props.sortKey?.key === col.columnKey
                                        ) {
                                            if (
                                                props.sortKey?.order ===
                                                    undefined ||
                                                props.sortKey?.order === "desc"
                                            ) {
                                                props.onChangeSortKey?.({
                                                    key: col.columnKey,
                                                    order: "asc",
                                                });
                                            } else {
                                                props.onChangeSortKey?.(null);
                                            }
                                        } else {
                                            props.onChangeSortKey?.({
                                                key: col.columnKey,
                                                order: "desc",
                                            });
                                        }
                                    }
                                }}
                            >
                                <TextField className="label">
                                    {col.columnHeader ?? ""}
                                </TextField>
                                {col.sortable && (
                                    <div className="sort-button">
                                        <img
                                            src={
                                                props.sortKey?.key ===
                                                col.columnKey
                                                    ? props.sortKey?.order ===
                                                          undefined ||
                                                      props.sortKey?.order ===
                                                          "desc"
                                                        ? sortDesc
                                                        : sortAsc
                                                    : sortNone
                                            }
                                            alt="V"
                                        />
                                    </div>
                                )}
                            </FlexRow>
                        );
                    })}
                </FlexRow>
            )}
            <FlexColumn className="generic-table-rows-container">
                {(props.rowFilter == null
                    ? props.rowData
                    : props.rowData.filter(
                          (row) => props.rowFilter?.(row) ?? false
                      )
                ).map((rowEntry) => {
                    const rowID = props.idGetter(rowEntry);
                    const isSelected = props.isRowSelected?.(rowEntry) ?? false;
                    const footerContents =
                        props.rowFooter === undefined
                            ? null
                            : props.rowFooter(rowEntry);
                    return (
                        <FlexColumn
                            key={rowID}
                            className={`generic-table-row-wrapper${
                                isSelected ? " selected" : ""
                            }`}
                            onClick={() => {
                                props.onRowClick?.(rowEntry);
                            }}
                        >
                            <FlexRow className={"generic-table-row"}>
                                {props.columns.map((col) => {
                                    const parser = props.cellParser.find(
                                        (c) => c.columnKey === col.columnKey
                                    )?.callback;

                                    return (
                                        <div
                                            key={`${col.columnKey}-${rowID}`}
                                            className="generic-table-cell"
                                            style={
                                                col.width === undefined
                                                    ? { width: "100%" }
                                                    : {
                                                          width:
                                                              typeof col.width ===
                                                              "string"
                                                                  ? col.width
                                                                  : `${col.width}px`,
                                                          flexShrink:
                                                              typeof col.width ===
                                                              "string"
                                                                  ? col.width.includes(
                                                                        "px"
                                                                    )
                                                                      ? 0
                                                                      : 1
                                                                  : 0,
                                                      }
                                            }
                                        >
                                            {parser ? parser(rowEntry) : <></>}
                                        </div>
                                    );
                                })}
                            </FlexRow>
                            {footerContents !== null && <br />}
                            {footerContents !== null && (
                                <FlexRow className="generic-table-row-footer-container">
                                    {footerContents}
                                </FlexRow>
                            )}
                        </FlexColumn>
                    );
                })}
            </FlexColumn>
        </FlexColumn>
    );
}
