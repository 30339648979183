import React, { useRef, useEffect } from "react";
import { MutableRefObject } from "react";

function useClickOutAlerter(ref: MutableRefObject<any>, callback?: () => void) {
    useEffect(() => {
        function handleClickOut(event: Event) {
            if (ref.current && !ref.current.contains(event.target)) {
                event.preventDefault();
                callback?.();
            }
        }

        document.addEventListener("mousedown", handleClickOut);
        return () => {
            document.removeEventListener("mousedown", handleClickOut);
        };
    }, [ref]);
}

type Props = {
    forwardRef?: MutableRefObject<any>;
    children: React.ReactNode;
    onClickOut?: () => void;
};

export default function ClickOutAlertWrapper(props: Props): JSX.Element {
    const localRef = useRef(null);
    useClickOutAlerter(props.forwardRef ?? localRef, props.onClickOut);

    return (
        <div ref={localRef} style={{ width: "100%" }}>
            {props.children}
        </div>
    );
}
