import DropdownSelector from "../../../common/components/input/DropdownSelector/DropdownSelector";
import FlexColumn from "../../../common/components/layout/Flexbox/FlexColumn";
import Modal from "../../../common/components/modals/Modal/Modal";
import { useGetCharacterList } from "../../../net/CharacterManagement";
import Cookies from "universal-cookie";

import { useEffect, useState } from "react";

type Props = {
    isVisible?: boolean;
    onClose?: () => void;
    onConfirm?: (characterID: string | null) => void;
};

export default function LoadCharacterModal(props: Props): JSX.Element {
    const [loadCharacterList, _isLoadingCharacterList] = useGetCharacterList();
    const [selectedCharacterID, setSelectedCharacterID] = useState<
        string | null
    >(null);
    const [characterOptions, setCharacterOptions] = useState<
        Array<{ id: string; name: string; creator: string }>
        // eslint-disable-next-line react-hooks/exhaustive-deps
    >([]);

    const cookies = new Cookies();

    useEffect(() => {
        loadCharacterList({
            variables: { token: cookies.get("session_token") },
            onComplete: (result) => {
                setCharacterOptions(result);
            },
            onError: (_error) => {
                setCharacterOptions([]);
            },
        });
    }, []);

    return (
        <Modal
            header="Load Character"
            isVisible={props.isVisible}
            footer="standard-submit-cancel"
            onClose={() => {
                props.onClose?.();
            }}
            onConfirm={() => {
                props.onConfirm?.(selectedCharacterID);
            }}
        >
            <FlexColumn>
                <DropdownSelector
                    placeholder="Select A Character..."
                    value={selectedCharacterID}
                    onChange={setSelectedCharacterID}
                    options={characterOptions.map((c) => {
                        return { value: c.id, label: c.name };
                    })}
                />
            </FlexColumn>
        </Modal>
    );
}
