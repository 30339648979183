import { CharacterData } from "../../../../../utils/game/character/CharacterData";
import { Black, White } from "../../../../../utils/sys/Color";
import { KeyValuePair } from "../../../../../utils/sys/KeyValuePair";
import { Whitelist, WhitelistUtils } from "../../../../../utils/sys/Whitelist";
import { useCallback, useMemo, useState } from "react";

import AlignmentLabels from "../../../../../utils/sys/AlignmentLabels";
import AlignmentMeter from "../../AlignmentMeter/AlignmentMeter";
import FlexColumn from "../../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../../common/components/layout/Flexbox/FlexRow";
import Meter from "../../../../../common/components/input/Meter/Meter";
import MultiLineTextField from "../../../../../common/components/input/MultiLineTextInputToggleField/MultiLineTextInputToggleField";
import NumberInputToggleField from "../../../../../common/components/input/NumberInputToggleField/NumberInputToggleField";
import TagList from "../../../../../common/components/display/TagList/TagList";
import TextField from "../../../../../common/components/display/TextField/TextField";
import TextInput from "../../../../../common/components/input/TextInput/TextInput";
import ReputationLabels from "../../../../../utils/sys/ReputationLabels";
import TabControl from "../../../../../common/components/layout/TabControl/TabControl";
import TextInputToggleField from "../../../../../common/components/input/TextInputToggleField/TextInputToggleField";
import Tooltip from "../../../../../common/components/wrappers/Tooltip/Tooltip";
import WhitelistEditorModal from "../../../../../common/components/modals/WhitelistEditorModal/WhitelistEditorModal";

import "./CharacterSheetInfoTab_style.css";

type Props = {
    characterData: CharacterData;
    isEditable?: boolean;
    selectedTab?: string | null;
    onSelectedTabChanged?: (tab: string | null) => void;
    onCharacterDataValueChanged?: (vals: KeyValuePair[]) => void;
};

export default function CharacterSheetInfoTab(props: Props): JSX.Element {
    const [selectedInfoTab, setSelectedInfoTab] = useState<string | null>(
        props.selectedTab ?? null
    );

    const [isWhitelistModalVisible, setWhitelistModalVisibility] =
        useState<boolean>(false);

    const [moduleInputText, setModuleInputText] = useState<string>("");
    const [moduleTags, setModuleTags] = useState<Array<string>>(
        props.characterData.modules
    );

    const conductLabel = useMemo(() => {
        return AlignmentLabels.getConductLabel(props.characterData.conduct);
    }, [props.characterData.conduct]);
    const moralityLabel = useMemo(() => {
        return AlignmentLabels.getMoralityLabel(props.characterData.morality);
    }, [props.characterData.morality]);
    const alignmentLabel = useMemo(() => {
        return AlignmentLabels.getAlignmentLabel(
            props.characterData.conduct,
            props.characterData.morality
        );
    }, [props.characterData.conduct, props.characterData.morality]);

    const fameLabel = useMemo(() => {
        return ReputationLabels.getFameLabel(props.characterData.fame);
    }, [props.characterData.fame]);
    const infamyLabel = useMemo(() => {
        return ReputationLabels.getInfamyLabel(props.characterData.infamy);
    }, [props.characterData.infamy]);
    const reputationLabel = useMemo(() => {
        return ReputationLabels.getReputationLabel(
            props.characterData.fame,
            props.characterData.infamy
        );
    }, [props.characterData.fame, props.characterData.infamy]);

    const addModule = useCallback(
        (module: string) => {
            if (!moduleTags.includes(module)) {
                const newModuleTags = [...moduleTags, module];
                setModuleTags(newModuleTags);
                setModuleInputText("");

                props.onCharacterDataValueChanged?.([
                    {
                        key: "modules",
                        value: newModuleTags.join("||"),
                    },
                ]);
            }
        },
        [
            moduleTags,
            setModuleTags,
            setModuleInputText,
            props.onCharacterDataValueChanged,
        ]
    );

    const removeModule = useCallback(
        (module: string) => {
            if (moduleTags.includes(module)) {
                const newModuleTags = moduleTags.filter((m) => m !== module);
                setModuleTags(newModuleTags);
                setModuleInputText("");

                props.onCharacterDataValueChanged?.([
                    {
                        key: "modules",
                        value: newModuleTags.join("||"),
                    },
                ]);
            }
        },
        [
            moduleTags,
            setModuleTags,
            setModuleInputText,
            props.onCharacterDataValueChanged,
        ]
    );

    return (
        <FlexColumn>
            <TabControl
                id="notes_tab_control"
                selected={selectedInfoTab}
                onSelectedTabChange={(tab) => {
                    setSelectedInfoTab(() => {
                        props.onSelectedTabChanged?.(tab);
                        return tab;
                    });
                }}
                tabs={[
                    { id: "bio", label: "Bio" },
                    { id: "appearance", label: "Appearance" },
                    { id: "alignment", label: "Alignment/Reputation" },
                    { id: "other", label: "Other" },
                ]}
            />
            {selectedInfoTab === "bio" && (
                <FlexColumn>
                    <TextInputToggleField
                        label="Name"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "name",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.name}
                    </TextInputToggleField>
                    <TextInputToggleField
                        label="Birth"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "birth",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.birth}
                    </TextInputToggleField>
                    <NumberInputToggleField
                        label="Level"
                        value={props.characterData.level}
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                { key: "level", value: val },
                            ]);
                        }}
                    />
                    <TextInputToggleField
                        label="Homeland"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "home",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.home}
                    </TextInputToggleField>
                    <MultiLineTextField
                        placeholderText="Biography"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "biography",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.biography}
                    </MultiLineTextField>
                </FlexColumn>
            )}
            {selectedInfoTab === "appearance" && (
                <FlexColumn>
                    <TextInputToggleField
                        label="Race"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "race",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.raceText}
                    </TextInputToggleField>
                    <TextInputToggleField
                        label="Sex"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "gender",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.gender}
                    </TextInputToggleField>
                    <TextInputToggleField
                        label="Age"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "age",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.age}
                    </TextInputToggleField>
                    <TextInputToggleField
                        label="Height"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "height",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.height}
                    </TextInputToggleField>
                    <TextInputToggleField
                        label="Weight"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "weight",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.weight}
                    </TextInputToggleField>
                    <TextInputToggleField
                        label="Eyes"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "eyes",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.eyes}
                    </TextInputToggleField>
                    <TextInputToggleField
                        label="Hair"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "hair",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.hair}
                    </TextInputToggleField>
                    <MultiLineTextField
                        placeholderText="Appearance"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "appearance",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.appearance}
                    </MultiLineTextField>
                </FlexColumn>
            )}
            {selectedInfoTab === "alignment" && (
                <FlexColumn gap={16}>
                    <FlexColumn gap={4}>
                        <FlexRow gap={8}>
                            <TextField type="bold" style={{ width: "auto" }}>
                                Conduct
                            </TextField>
                            <TextField style={{ width: "auto" }}>
                                {conductLabel}
                            </TextField>
                        </FlexRow>
                        <AlignmentMeter
                            currentValue={props.characterData.conduct}
                            isEditable={props.isEditable}
                            onValueChanged={(val) => {
                                props.onCharacterDataValueChanged?.([
                                    { key: "conduct", value: val },
                                ]);
                            }}
                        />

                        <FlexRow gap={8}>
                            <TextField type="bold" style={{ width: "auto" }}>
                                Morality
                            </TextField>
                            <TextField style={{ width: "auto" }}>
                                {moralityLabel}
                            </TextField>
                        </FlexRow>
                        <AlignmentMeter
                            currentValue={props.characterData.morality}
                            isEditable={props.isEditable}
                            onValueChanged={(val) => {
                                props.onCharacterDataValueChanged?.([
                                    { key: "morality", value: val },
                                ]);
                            }}
                        />
                        <FlexRow gap={8}>
                            <TextField type="bold" style={{ width: "auto" }}>
                                Alignment
                            </TextField>
                            <TextField style={{ width: "auto" }}>
                                {alignmentLabel}
                            </TextField>
                        </FlexRow>
                    </FlexColumn>
                    <FlexColumn gap={4}>
                        <FlexRow gap={8}>
                            <TextField type="bold" style={{ width: "auto" }}>
                                Fame
                            </TextField>
                            <TextField style={{ width: "auto" }}>
                                {fameLabel}
                            </TextField>
                        </FlexRow>
                        <Meter
                            fillColor={White}
                            currentValue={props.characterData.fame}
                            maxValue={1000}
                            isEditable={props.isEditable}
                            isCurrentEditable={true}
                            onValuesChanged={(vals) => {
                                if (vals.current !== props.characterData.fame) {
                                    props.onCharacterDataValueChanged?.([
                                        { key: "fame", value: vals.current },
                                    ]);
                                }
                            }}
                        />
                        <FlexRow gap={8}>
                            <TextField type="bold" style={{ width: "auto" }}>
                                Infamy
                            </TextField>
                            <TextField style={{ width: "auto" }}>
                                {infamyLabel}
                            </TextField>
                        </FlexRow>
                        <Meter
                            fillColor={Black}
                            currentValue={props.characterData.infamy}
                            maxValue={1000}
                            isEditable={props.isEditable}
                            isCurrentEditable={true}
                            onValuesChanged={(vals) => {
                                if (
                                    vals.current !== props.characterData.infamy
                                ) {
                                    props.onCharacterDataValueChanged?.([
                                        { key: "infamy", value: vals.current },
                                    ]);
                                }
                            }}
                        />
                        <FlexRow gap={8}>
                            <TextField type="bold" style={{ width: "auto" }}>
                                Reputation
                            </TextField>
                            <TextField style={{ width: "auto" }}>
                                {reputationLabel}
                            </TextField>
                        </FlexRow>
                    </FlexColumn>
                </FlexColumn>
            )}
            {selectedInfoTab === "other" && (
                <FlexColumn gap={8}>
                    {props.isEditable && (
                        <button
                            onClick={() => {
                                setWhitelistModalVisibility(
                                    !isWhitelistModalVisible
                                );
                            }}
                        >
                            Whitelist
                        </button>
                    )}

                    <TextField type="bold" style={{ width: "auto" }}>
                        Modules
                    </TextField>
                    {props.isEditable && (
                        <FlexRow gap={8}>
                            <TextInput
                                value={moduleInputText}
                                onChange={setModuleInputText}
                                onEnterKeyPress={() => {
                                    if (moduleInputText.length > 0) {
                                        addModule(moduleInputText);
                                    }
                                }}
                            />
                            <button
                                onClick={() => {
                                    if (moduleInputText.length > 0) {
                                        addModule(moduleInputText);
                                    }
                                }}
                            >
                                +
                            </button>
                        </FlexRow>
                    )}
                    <FlexRow gap={4} wrap={true}>
                        <TagList
                            tagEntries={moduleTags.map((module) => {
                                return {
                                    id: module,
                                    value: module,
                                    isEditable: props.isEditable,
                                };
                            })}
                            onTagRemoved={(tag) => {
                                removeModule(tag);
                            }}
                        />
                    </FlexRow>

                    <MultiLineTextField
                        placeholderText="Notes"
                        isEditable={props.isEditable}
                        onStopEditing={(val) => {
                            props.onCharacterDataValueChanged?.([
                                {
                                    key: "notes_0",
                                    value: val,
                                },
                            ]);
                        }}
                    >
                        {props.characterData.notes[0]}
                    </MultiLineTextField>
                </FlexColumn>
            )}
            <WhitelistEditorModal
                isVisible={isWhitelistModalVisible}
                whitelist={props.characterData.whitelist}
                whitelistActive={props.characterData.isWhitelistProtected}
                onClose={() => {
                    setWhitelistModalVisibility(false);
                }}
                onConfirm={(whitelistActive, whitelist) => {
                    props.onCharacterDataValueChanged?.([
                        {
                            key: "whitelist_protected",
                            value: whitelistActive ? 1 : 0,
                        },
                        {
                            key: "whitelist",
                            value: WhitelistUtils.toCSVString(whitelist),
                        },
                    ]);
                    setWhitelistModalVisibility(false);
                }}
            />
        </FlexColumn>
    );
}
