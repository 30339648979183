import { SchoolBase } from "./SchoolBase";
import { SchoolJSON } from "./SchoolJSON";

export type SchoolConstructorParameters = {
    id?: string | number;
    title?: string;
    description?: string;
    tag?: string;
    module?: string | null;
};

export default class School extends SchoolBase {
    constructor(args: SchoolConstructorParameters) {
        super();

        if (args.id != null) {
            if (typeof args.id === "string") {
                const val = Number.parseInt(args.id);
                this._id = Number.isNaN(val) ? -1 : val;
            } else if (typeof args.id === "number") {
                this._id = args.id;
            }
        }
        if (args.title != null) {
            this.title = args.title;
        }
        if (args.description != null) {
            this._description = args.description;
        }
        if (args.tag != null) {
            this._tag = args.tag;
        }
        if (args.module != null) {
            this._module = args.module;
        }
    }

    public static fromJSON(json: SchoolJSON) {
        return new School({
            id: json.id ?? -1,
            title: json.title ?? "",
            description: json.description ?? "",
            tag: json.tag ?? "",
            module: json.module ?? "",
        });
    }

    public toVCTagString(): string {
        return `[net-id]${this._id}[/net-id][title]${this._title}[/title][description]${this._description}[/description][tag]${this._tag}[/tag][module]${this._module}[/module]`;
    }

    public toJSON(): SchoolJSON {
        return {
            description: this._description,
            id: this._id,
            module: this._module ?? undefined,
            tag: this._tag,
            title: this._title,
        };
    }
}
