import { InventoryItemCollectionProps } from "./InventoryItemCollection";
import { InventoryItemTransferRequestResponse } from "../../../../utils/sys/InventoryItemTransferContext";
import InventoryItemTransferContext from "../../../../utils/sys/InventoryItemTransferContext";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import InventoryItemListSlotGap from "./InventoryItemListSlotGap";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import InventoryItemInfoSlot from "../InventoryItemSlot/InventoryItemInfoSlot";
import { useCallback, useContext, useMemo, useRef } from "react";

import "./style/InventoryItemList_style.css";
import InventoryItem from "../../../../utils/game/mechanics/InventoryItem";
import { UIInventoryItemContainerClickParams } from "../InventoryItemSlot/InventoryItemContainer";

type Props = InventoryItemCollectionProps & {
    selectedItem?: InventoryItem | null;
    onItemClick?: (params?: UIInventoryItemContainerClickParams) => void;
    onItemEditRequested?: (itemIndex: number) => void;
};

export default function InventoryItemList(props: Props): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);

    const inventoryItemTransferContext = useContext(
        InventoryItemTransferContext
    );

    const heldItem = useMemo(
        () => inventoryItemTransferContext.storedItem,
        [inventoryItemTransferContext.storedItem]
    );

    const storedItemEquipTypes = useMemo(() => {
        return heldItem?.equipTypes ?? [];
    }, [heldItem]);

    const showSlotGaps = useMemo(() => {
        return heldItem != null;
    }, [heldItem]);

    return (
        <FlexColumn forwardRef={ref} className="inventory-item-list-container">
            {props.items.map((item, index) => {
                return [
                    showSlotGaps && index === 0 ? (
                        <InventoryItemListSlotGap
                            key={`slot-gap-${props.id}-1`}
                            onClick={(e) => {
                                if (props.isEditable) {
                                    props.onItemClick?.({
                                        event: e?.event,
                                        containerProps: {
                                            id: `slot-gap-${props.id}-1`,
                                            item: null,
                                            itemRestrictor: null,
                                            setItemCallback: (
                                                itemToSet: InventoryItem | null
                                            ) => {
                                                if (itemToSet != null) {
                                                    return props.addItemCallback?.(
                                                        itemToSet,
                                                        -1
                                                    );
                                                }
                                                return undefined;
                                            },
                                        },
                                    });
                                }
                            }}
                        />
                    ) : (
                        []
                    ),
                    <InventoryItemInfoSlot
                        id={`${props.id}-${index}`}
                        key={`item-slot-${index}`}
                        className="item-slot"
                        item={item}
                        tooltipEnabled={true}
                        isEditable={props.isEditable}
                        onClick={(e) => {
                            if (props.isEditable) {
                                props.onItemClick?.(e);
                            }
                        }}
                        isSelected={props.selectedItem === item}
                        isValidTarget={
                            heldItem != null &&
                            item.equipTypes.find((t) =>
                                storedItemEquipTypes.includes(t)
                            ) != null
                        }
                        onItemRemoved={() => {
                            props.onItemRemoved?.(item);
                        }}
                        onEditRequested={() => {
                            props.onItemEditRequested?.(index);
                        }}
                        setItemCallback={(item) => {
                            if (item === null) {
                                return props.removeItemAtCallback?.(index);
                            } else {
                                return props.setItemAtCallback?.(index, item);
                            }
                        }}
                    />,
                    showSlotGaps ? (
                        <InventoryItemListSlotGap
                            key={`slot-gap-${props.id}-${index}`}
                            onClick={(e) => {
                                if (props.isEditable) {
                                    props.onItemClick?.({
                                        event: e?.event,
                                        containerProps: {
                                            id: `slot-gap-${props.id}-${index}`,
                                            item: null,
                                            itemRestrictor: null,
                                            setItemCallback: (
                                                itemToSet: InventoryItem | null
                                            ) => {
                                                if (itemToSet != null) {
                                                    return props.addItemCallback?.(
                                                        itemToSet,
                                                        index
                                                    );
                                                }
                                                return undefined;
                                            },
                                        },
                                    });
                                }
                            }}
                        />
                    ) : (
                        []
                    ),
                ].flat();
            })}
        </FlexColumn>
    );
}
