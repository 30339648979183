import Ability from "../../../../utils/game/mechanics/Ability";
import AbilityIcon from "../AbilityIcon/AbilityIcon";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import LabeledTextField from "../../../../common/components/display/LabeledTextField/LabeledTextField";
import SchoolIconLibrary from "../../../../utils/sys/SchoolIconLibrary";
import TextField from "../../../../common/components/display/TextField/TextField";

import "./CharacterAbilityPanel_style.css";

type Props = {
    ability: Ability;
    isEditable?: boolean;
    onAbilityRemoved?: () => void;
};

export default function CharacterAbilityPanel(props: Props): JSX.Element {
    const icon: React.ReactNode | null = (() => {
        const iconSrc = props.ability.icon.trim();
        if (iconSrc.length > 0) {
            if (iconSrc.includes(":")) {
                return (
                    <AbilityIcon
                        className="icon"
                        src={props.ability.icon.split(":")[0]}
                        index={Number.parseInt(
                            props.ability.icon.split(":")[1]
                        )}
                    />
                );
            }
        }

        const school = props.ability.school.trim().toLowerCase();
        return (
            <img
                className="icon"
                src={SchoolIconLibrary.image(`${school}_icon`)}
            />
        );
    })();

    return (
        <FlexRow className="character-ability-panel-container">
            <FlexColumn className="details-container">
                <FlexRow className="header">
                    <div className="icon-wrapper">{icon}</div>
                    <FlexColumn>
                        <FlexRow width="full">
                            <FlexRow width="full">
                                <div className="title">
                                    {props.ability.title}
                                </div>
                            </FlexRow>
                            {props.isEditable === true && (
                                <FlexRow width="auto">
                                    <button
                                        onClick={() => {
                                            props.onAbilityRemoved?.();
                                        }}
                                    >
                                        X
                                    </button>
                                </FlexRow>
                            )}
                        </FlexRow>
                        <div className="subtype">{`Level ${props.ability.level.toString()} ${
                            props.ability.school
                        } ${props.ability.subtype}`}</div>
                    </FlexColumn>
                </FlexRow>
                <FlexRow>
                    <LabeledTextField className="double-field" label="Cost">
                        {props.ability.cost}
                    </LabeledTextField>
                    <LabeledTextField
                        className="double-field"
                        label="Exhaustion"
                    >
                        {props.ability.cost}
                    </LabeledTextField>
                </FlexRow>
                <FlexRow>
                    <LabeledTextField className="double-field" label="Range">
                        {props.ability.range}
                    </LabeledTextField>
                    <LabeledTextField className="double-field" label="Duration">
                        {props.ability.duration}
                    </LabeledTextField>
                </FlexRow>
                <LabeledTextField label="Acc. Check">
                    {props.ability.accuracyCheck ? "Y" : "N"}
                </LabeledTextField>
                <br />
                <div>{props.ability.description}</div>
            </FlexColumn>
        </FlexRow>
    );
}
