import { Color } from "../../../../utils/sys/Color";
import { InventoryItemTransferRequestResponse } from "../../../../utils/sys/InventoryItemTransferContext";
import InventoryItem from "../../../../utils/game/mechanics/InventoryItem";
import InventoryItemIcon from "../InventoryItemIcon/InventoryItemIcon";
import InventoryItemDetails from "./InventoryItemDetails";
import Tooltip from "../../../../common/components/wrappers/Tooltip/Tooltip";

import { useMemo } from "react";

import { UIInventoryItemContainerProps } from "./InventoryItemContainer";

import "./style/InventoryItemIconSlot_style.css";

type Props = UIInventoryItemContainerProps & {
    className?: string;
    tooltipEnabled?: boolean;
    onEditRequested?: () => void;
};

export default function InventoryItemIconSlot(props: Props): JSX.Element {
    const emptyBGSrc =
        props.emptyBGSrc === undefined ? "back01" : props.emptyBGSrc;

    const tooltipContent = useMemo(() => {
        return <InventoryItemDetails id="tooltip" item={props.item} />;
    }, [props.item]);

    return (
        <Tooltip
            tooltipContent={tooltipContent}
            enabled={props.tooltipEnabled && props.item != null}
            direction="auto-left-right"
        >
            <InventoryItemIcon
                className={`inventory-item-icon-slot-container${
                    props.className == null ? `` : ` ${props.className}`
                }`}
                bgSrc={props.item == null ? emptyBGSrc : props.item.bgSrc}
                bgColor={props.item == null ? null : props.item.bgColor}
                iconSrc={
                    props.item == null ? props.emptyIconSrc : props.item.iconSrc
                }
                iconColor={props.item == null ? null : props.item.iconColor}
                onClick={(e) => {
                    if (props.isEditable) {
                        props.onClick?.({ event: e, containerProps: props });
                    }
                }}
                showHighlight={props.isSelected || props.isValidTarget}
                highlightColor={
                    props.isSelected
                        ? "active"
                        : props.isValidTarget
                        ? "valid"
                        : undefined
                }
            />
        </Tooltip>
    );
}
