import { Coin, CoinCount, Currency, CurrencyUtils } from "./Currency";
import VCTagUtils from "../../sys/VCTagUtils";

import generalStandard from "../../../assets/json/currency/general_standard.json";
import ayaseyeStandard from "../../../assets/json/currency/ayaseye_standard.json";
import ilevastraStandard from "../../../assets/json/currency/ilevastra_standard.json";

export class StandardCurrencies {
    public static readonly currencies: Array<Currency> = [
        CurrencyUtils.fromJSON(ayaseyeStandard),
        CurrencyUtils.fromJSON(generalStandard),
        CurrencyUtils.fromJSON(ilevastraStandard),
    ];

    public static coerceStandardCoinFromCoinID(val: string): Coin {
        for (var i = 0; i < this.currencies.length; ++i) {
            const coin = this.currencies[i].denominations.find(
                (den) => den.id === val
            );
            if (coin !== undefined) {
                return coin;
            }
        }

        return {
            id: val,
            name: val,
            standardizedValue: 1,
            unitValue: 1,
        };
    }

    public static parseCoinCountsFromVCString(val: string): Array<CoinCount> {
        const result: Array<CoinCount> = [];
        const tags = VCTagUtils.findTags(val);
        if (tags.length > 0) {
            tags.forEach((tag) => {
                const count = VCTagUtils.readTagAsNumber(val, tag, 0);
                let coin = undefined;
                for (var i = 0; i < StandardCurrencies.currencies.length; ++i) {
                    const coinMatch = StandardCurrencies.currencies[
                        i
                    ].denominations.find((d) => d.id === tag);
                    if (coinMatch !== undefined) {
                        coin = coinMatch;
                        break;
                    }
                }

                if (coin === undefined) {
                    result.push({
                        coin: {
                            id: tag,
                            name: tag,
                            unitValue: 1,
                            standardizedValue: 1,
                        },
                        count,
                    });
                } else {
                    result.push({ coin, count });
                }
            });
        }

        return result;
    }
}
