import ActiveUserContext from "../../utils/session/ActiveUserContext";
import FlexRow from "../../common/components/layout/Flexbox/FlexRow";
import PageFramework from "../../common/components/layout/PageFramework/PageFramework";
import RecentCharactersCard from "./components/RecentCharactersCard";
import RecentWikiActivityCard from "./components/RecentWikiActivityCard";
import { useContext } from "react";

export default function Home(): JSX.Element {
    const activeUser = useContext(ActiveUserContext);

    return (
        <PageFramework>
            <FlexRow>
                {activeUser.id >= 1 && <RecentCharactersCard />}
                <RecentWikiActivityCard />
            </FlexRow>
        </PageFramework>
    );
}
