import "./TextField_style.css";

type Props = {
    children?: string;
    type?: "normal" | "bold";
    className?: string | null;
    style?: React.CSSProperties;
    onClick?: () => void;
};

export default function TextField({
    children = "",
    type = "normal",
    className = null,
    style = undefined,
    onClick = undefined,
}: Props): JSX.Element {
    return (
        <div
            className={`text-field${type === "bold" ? ` bold` : ``}${
                className != null ? ` ${className}` : ``
            }`}
            style={style}
            onClick={onClick}
        >
            {children}
        </div>
    );
}
