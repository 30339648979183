import { act } from "react-dom/test-utils";
import FlexColumn from "../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../common/components/layout/Flexbox/FlexRow";
import ActiveUserContext from "../../utils/session/ActiveUserContext";
import TextField from "../../common/components/display/TextField/TextField";
import Cookies from "universal-cookie";
import { useContext } from "react";
import { useCreateCharacterData } from "../../net/CharacterManagement";

type Props = {};

export default function NoCharacterContainer(props: Props): JSX.Element {
    const activeUser = useContext(ActiveUserContext);
    const [createNewCharacter, isCreatingNewCharacter] =
        useCreateCharacterData();

    const cookies = new Cookies();

    const createCharacterClicked = () => {
        if (!isCreatingNewCharacter()) {
            createNewCharacter({
                variables: { token: cookies.get("session_token") },
                onComplete: (result) => {
                    if (result?.id != null) {
                        window.location.href = `?id=${result.id}`;
                    }
                },
                onError: (err) => {
                    console.error(err);
                },
            });
        }
    };

    return (
        <FlexColumn>
            <h1>New Character</h1>
            {activeUser.id >= 0 && (
                <FlexColumn>
                    {isCreatingNewCharacter() ? (
                        <TextField>Loading...</TextField>
                    ) : (
                        <button onClick={createCharacterClicked}>
                            Create New Character
                        </button>
                    )}
                </FlexColumn>
            )}
            {activeUser.id < 0 && (
                <TextField>
                    You must be signed in to create a new character. Please sign
                    in or register before proceeding.
                </TextField>
            )}
        </FlexColumn>
    );
}
