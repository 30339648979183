import { Color, Cyan, DarkGray } from "../../../../utils/sys/Color";
import FlexColumn from "../../layout/Flexbox/FlexColumn";
import FlexRow from "../../layout/Flexbox/FlexRow";
import { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useMousePosition } from "../../../hooks/MouseControlHooks";

import handleIcon from "../../../../assets/images/common/svg/circle_handle.svg";

import "./NumericSlider_style.css";

type Props = {
    label?: string | null;
    min?: number;
    max?: number;
    showValue?: boolean;
    /** Value will round to increments of the provided value */
    valueIncrement?: number;
    fillColor?: Color | null;
    barBackColor?: Color;
    value: number;
    onChange?: (val: number) => void;
};

export default function NumericSlider({
    label,
    min = 0,
    max = 100,
    showValue = true,
    valueIncrement = 1,
    fillColor = Cyan,
    barBackColor = DarkGray,
    value = 0,
    onChange,
}: Props): JSX.Element {
    const [isTrackingMouse, setTrackingMouse] = useState<boolean>(false);
    const [numberVal, setNumberVal] = useState<number>(value);

    const mousePos = useMousePosition();
    const barRef = useRef<HTMLDivElement>(null);

    const sliderPosition = useMemo(() => {
        let val = 0;
        if (max > min) {
            val = ((numberVal - min) / (max - min)) * 100;
        }

        val = Math.round(Math.max(Math.min(val, 100), 0));

        return val;
    }, [min, max, numberVal]);

    useEffect(() => {
        if (isTrackingMouse && mousePos != null && barRef?.current != null) {
            const minPos = barRef.current.getBoundingClientRect().x;
            const maxPos = barRef.current.getBoundingClientRect().right;

            let val = 0;
            if (maxPos > minPos) {
                val =
                    ((mousePos.x - minPos) / (maxPos - minPos)) * (max - min) +
                    min;
            }
            val = Math.round(val / valueIncrement) * valueIncrement + min;
            val = Math.max(Math.min(val, max), min);

            setNumberVal(() => {
                onChange?.(val);
                return val;
            });
        }
    }, [mousePos]);

    useEffect(() => {
        const stopTracking = (e: MouseEvent) => {
            setTrackingMouse(false);
        };

        window.addEventListener("mouseup", stopTracking);

        return () => {
            {
                window.removeEventListener("mouseup", stopTracking);
            }
        };
    }, []);

    const sliderClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ): void => {
        const clickPos = event.clientX;
        const minPos = event.currentTarget.getBoundingClientRect().x;
        const maxPos = event.currentTarget.getBoundingClientRect().right;

        let val = 0;
        if (maxPos > minPos) {
            val = ((clickPos - minPos) / (maxPos - minPos)) * (max - min) + min;
        }
        val = Math.round(val / valueIncrement) * valueIncrement + min;
        val = Math.max(Math.min(val, max), min);

        setNumberVal(() => {
            onChange?.(val);
            return val;
        });
    };

    return (
        <FlexRow className="numeric-slider-container">
            {label != null && <div className="label">{label}</div>}
            <div
                className="slider"
                onMouseDown={(event) => {
                    sliderClick(event);
                    setTrackingMouse(true);
                    event.preventDefault();
                }}
            >
                <div
                    ref={barRef}
                    className="slider-bar"
                    style={{ background: barBackColor }}
                />
                {fillColor !== null && sliderPosition > 0 && (
                    <div
                        className="filled-section"
                        style={{
                            width: `${sliderPosition}%`,
                            background: fillColor,
                        }}
                    />
                )}

                <div
                    className="slider-handle"
                    style={{
                        backgroundImage: handleIcon
                            ? `url(${handleIcon})`
                            : undefined,
                        left: `${sliderPosition}%`,
                    }}
                />
            </div>
            {showValue === true && (
                <div className="value">{numberVal.toString()}</div>
            )}
        </FlexRow>
    );
}
