import VCTagUtils from "../../sys/VCTagUtils";
import { AbilityBase } from "./AbilityBase";
import { AbilityJSON } from "./AbilityJSON";

export default class Ability extends AbilityBase {
    constructor(args: {
        id?: string | number;
        title?: string;
        description?: string;
        subtype?: string;
        school?: string;
        vocation?: string;
        level?: string | number;
        cost?: string;
        range?: string;
        duration?: string;
        accuracyCheck?: string | boolean;
        exhaustion?: string | number;
        icon?: string;
        module?: string | null;
        /** Used for compatibility: Some values may be stored in a properties string */
        properties?: string;
    }) {
        super();

        if (args.id != null) {
            if (typeof args.id === "string") {
                const val = Number.parseInt(args.id);
                this._id = Number.isNaN(val) ? -1 : val;
            } else if (typeof args.id === "number") {
                this._id = args.id;
            }
        }
        if (args.title != null) {
            this._title = args.title;
        }
        if (args.description != null) {
            this._description = args.description;
        }
        if (args.subtype != null) {
            this._subtype = args.subtype;
        }
        if (args.school != null) {
            this._school = args.school;
        }
        if (args.vocation != null) {
            this._vocation = args.vocation;
        }
        if (args.level != null) {
            if (typeof args.level === "string") {
                const val = Number.parseInt(args.level);
                this._level = Number.isNaN(val) ? 0 : val;
            } else if (typeof args.level === "number") {
                this._level = args.level;
            }
        }
        if (args.cost != null) {
            this._cost = args.cost;
        }
        if (args.range != null) {
            this._range = args.range;
        }
        if (args.duration != null) {
            this._duration = args.duration;
        }
        if (args.accuracyCheck != null) {
            if (typeof args.accuracyCheck === "string") {
                this._accuracyCheck =
                    args.accuracyCheck === "1" ||
                    args.accuracyCheck.charAt(1).toLowerCase() === "t";
            } else if (typeof args.accuracyCheck === "boolean") {
                this._accuracyCheck = args.accuracyCheck;
            }
        }
        if (args.exhaustion != null) {
            if (typeof args.exhaustion === "string") {
                const val = Number.parseInt(args.exhaustion);
                this._exhaustion = Number.isNaN(val) ? 0 : val;
            } else if (typeof args.exhaustion === "number") {
                this._exhaustion = args.exhaustion;
            }
        }
        if (args.icon != null) {
            this._icon = args.icon;
        }
        if (args.module != null) {
            this._module = args.module;
        }

        /** Handle legacy import style */
        if (args.properties != null) {
            if (args.subtype === undefined) {
                this._subtype = VCTagUtils.readTag(args.properties, "type");
            }

            if (
                args.school === undefined ||
                args.school === "0" ||
                args.school === "1"
            ) {
                this._school = VCTagUtils.readTag(args.properties, "school");
            }

            if (args.vocation === undefined) {
                this._vocation = VCTagUtils.readTag(
                    args.properties,
                    "vocation"
                );
            }

            if (args.level === undefined) {
                this._level = VCTagUtils.readTagAsNumber(
                    args.properties,
                    "level"
                );
            }

            if (args.cost === undefined) {
                this._cost = VCTagUtils.readTag(args.properties, "cost");
            }

            if (args.exhaustion === undefined) {
                this._exhaustion = VCTagUtils.readTagAsNumber(
                    args.properties,
                    "exhaust"
                );
            }

            if (args.range === undefined) {
                this._range = VCTagUtils.readTag(args.properties, "range");
            }

            if (args.duration === undefined) {
                this._duration = VCTagUtils.readTag(
                    args.properties,
                    "duration"
                );
            }

            if (args.accuracyCheck === undefined) {
                this._accuracyCheck = VCTagUtils.readTagAsBoolean(
                    args.properties,
                    "accuracy_check"
                );
            }

            if (args.icon === undefined) {
                this._icon = VCTagUtils.readTag(args.properties, "icon");
            }
        }
    }

    public static fromVCTagString(
        input: string,
        id?: number,
        title?: string
    ): Ability {
        return new Ability({
            id:
                id != null
                    ? id
                    : VCTagUtils.readTagNullable(input, "net-id") ?? "-1",
            title: title != null ? title : VCTagUtils.readTag(input, "title"),
            description: VCTagUtils.readTag(input, "description").trim(),
            subtype: VCTagUtils.readTag(input, "type"),
            school: VCTagUtils.readTag(input, "school"),
            vocation: VCTagUtils.readTag(input, "vocation"),
            level: VCTagUtils.readTag(input, "level"),
            cost: VCTagUtils.readTag(input, "cost"),
            range: VCTagUtils.readTag(input, "range"),
            duration: VCTagUtils.readTag(input, "duration"),
            accuracyCheck: VCTagUtils.readTag(input, "accuracy_check"),
            exhaustion: Number.parseInt(VCTagUtils.readTag(input, "exhaust")),
            icon: VCTagUtils.readTag(input, "icon"),
            module: VCTagUtils.readTagNullable(input, "module"),
        });
    }

    public static fromJSON(json: AbilityJSON): Ability {
        return new Ability({
            id: json.id ?? -1,
            title: json.title ?? "",
            description: json.description ?? "",
            subtype: json.subtype ?? "",
            school: json.school ?? "",
            vocation: json.vocation ?? "",
            level: json.level ?? 0,
            cost: json.cost ?? "",
            range: json.range ?? "",
            duration: json.duration ?? "",
            accuracyCheck: json.accuracy_check ?? "",
            exhaustion: json.exhaustion ?? 0,
            icon: json.icon ?? "",
            module: json.module ?? "",
        });
    }

    public toVCTagString(): string {
        return `[net-id]${this._id}[/net-id][title]${
            this._title
        }[/title][description]${this._description}[/description][type]${
            this._subtype
        }[/type][school]${this._school}[/school][vocation]${
            this._vocation
        }[/vocation][level]${this._level}[/level][cost]${
            this._cost
        }[/cost][range]${this._range}[/range][duration]${
            this._duration
        }[/duration][accuracy_check]${
            this._accuracyCheck ? "1" : "0"
        }[accuracy_check][exhaust]${this._exhaustion}[/exhaust][icon]${
            this._icon
        }[/icon][module]${this._module ?? ""}[/module]`;
    }

    public toJSON(): AbilityJSON {
        return {
            accuracy_check: this._accuracyCheck,
            cost: this._cost,
            description: this._description,
            duration: this._duration,
            exhaustion: this._exhaustion,
            icon: this._icon,
            id: this._id,
            level: this._level,
            module: this._module ?? undefined,
            range: this._range,
            school: this._school,
            subtype: this._subtype,
            title: this._title,
            vocation: this._vocation,
        };
    }
}
