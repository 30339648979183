import Card from "../../../common/components/layout/Card/Card";
import CharacterProfileProficiencies from "./CharacterProfileProficiencies";
import FlexColumn from "../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../common/components/layout/Flexbox/FlexRow";
import Icon from "../../../common/components/Icon/Icon";
import SchoolIconLibrary from "../../../utils/sys/SchoolIconLibrary";
import TextField from "../../../common/components/display/TextField/TextField";
import Tooltip from "../../../common/components/wrappers/Tooltip/Tooltip";
import { StandardSchools } from "../../../utils/game/mechanics/StandardSchools";
import { useMemo } from "react";

import { CharacterData } from "../../../utils/game/character/CharacterData";

import femaleIcon from "../../../assets/images/common/svg/female_glyph.svg";
import maleIcon from "../../../assets/images/common/svg/male_glyph.svg";

import "./CharacterProfileCard_style.css";

type Props = {
    character: CharacterData;
    disabled?: boolean;
    onClick?: () => void;
};

export default function CharacterProfileCard({
    character,
    disabled,
    onClick,
}: Props): JSX.Element {
    const characterSex = useMemo(() => {
        switch (character.gender.trim().toLowerCase()) {
            case "male":
            case "m":
                return "Male";
            case "female":
            case "f":
                return "Female";
            default:
                return "";
        }
    }, [character.gender]);

    const highestSchoolLevel = Math.max(
        ...character.schools.map((s) => s.level)
    );
    const characterMainSchoolTitle =
        character.schools.find((s) => s.level === highestSchoolLevel)?.title ??
        null;

    const characterMainSchool = StandardSchools.find(
        (s) =>
            s.value.title.toLowerCase() ===
            characterMainSchoolTitle?.toLowerCase()
    );

    return (
        <Card
            className="character-profile-body"
            disabled={disabled}
            onClick={onClick}
        >
            <FlexColumn gap={4}>
                <FlexRow alignment="start">
                    <TextField type="bold">{character.name}</TextField>
                </FlexRow>
                <FlexRow alignment="start">
                    <TextField style={{ width: "auto" }}>{`Level ${
                        character.level
                    }${
                        character.race?.name ? ` ${character.race.name}` : ``
                    }`}</TextField>
                    {characterSex === "Female" && (
                        <Icon src={femaleIcon} size={16} />
                    )}
                    {characterSex === "Male" && (
                        <Icon src={maleIcon} size={16} />
                    )}
                </FlexRow>
                <FlexRow>
                    <CharacterProfileProficiencies
                        proficiencies={{
                            strength: character.strength,
                            agility: character.agility,
                            intelligence: character.intelligence,
                            fortitude: character.fortitude,
                            charisma: character.charisma,
                        }}
                    />
                    <div className="school-icon">
                        {characterMainSchool != null && (
                            <Tooltip tooltipContent={characterMainSchoolTitle}>
                                <Icon
                                    src={SchoolIconLibrary.image(
                                        characterMainSchool?.value.iconSrc ??
                                            "No Main School"
                                    )}
                                    size={44}
                                    alt={characterMainSchoolTitle ?? undefined}
                                    style={{ borderRadius: "50%" }}
                                />
                            </Tooltip>
                        )}
                    </div>
                </FlexRow>
            </FlexColumn>
        </Card>
    );
}
