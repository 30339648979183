import { UIInventoryItemContainerClickParams } from "../InventoryItemSlot/InventoryItemContainer";

import "./style/InventoryItemListSlotGap_style.css";

type Props = {
    onClick?: (params?: { event: React.MouseEvent }) => void;
};

export default function InventoryItemListSlotGap(props: Props): JSX.Element {
    return (
        <div className="slot-gap-wrapper">
            <div
                className="slot-gap"
                onClick={(e) => {
                    props.onClick?.({ event: e });
                }}
            />
        </div>
    );
}
