import { useEffect, useState } from "react";

type Props = Readonly<{
    value: string;
    onChange?: (val: string) => void;
    style?: React.CSSProperties;
    password?: boolean;
    onEnterKeyPress?: () => void;
}>;

export default function TextInput(props: Props): JSX.Element {
    const [value, setValue] = useState<string>(props.value);
    const [isPasswordField, _] = useState<boolean>(
        props.password != undefined ? props.password : false
    );

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            props.onEnterKeyPress?.();
        }
    };

    return (
        <input
            type={isPasswordField ? "password" : "text"}
            value={value}
            onKeyDown={(event) => {
                handleKeyDown(event);
            }}
            onChange={(event) => {
                setValue(() => {
                    const newValue = event.target.value;
                    props.onChange?.(newValue);
                    return newValue;
                });
            }}
            style={props.style}
        />
    );
}
