import { CharacterData } from "../../../../../utils/game/character/CharacterData";

import FlexColumn from "../../../../../common/components/layout/Flexbox/FlexColumn";
import { KeyValuePair } from "../../../../../utils/sys/KeyValuePair";
import CharacterSheetTraitsPanel from "../../CharacterSheetTraitsPanel/CharacterSheetTraitsPanel";
import StatBoard from "../../../../../utils/game/mechanics/StatBoard";

type Props = {
    characterData: CharacterData;
    statBoard: StatBoard;
    isEditable?: boolean;
    onCharacterDataValueChanged?: (val: KeyValuePair[]) => void;
};

export default function CharacterSheetTraitsTab(props: Props): JSX.Element {
    return (
        <FlexColumn>
            <CharacterSheetTraitsPanel
                characterData={props.characterData}
                statBoard={props.statBoard}
                isEditable={props.isEditable}
                onCharacterDataValueChanged={props.onCharacterDataValueChanged}
            />
        </FlexColumn>
    );
}
