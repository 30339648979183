import { Color } from "../../sys/Color";
import { Dictionary } from "../../sys/Dictionary";
import InventoryItemBase from "./InventoryItemBase";
import {
    InventoryItemTypeUtils,
    InventoryItemSubTypeUtils,
} from "../../sys/InventoryItemType";
import KeyValueStringParser from "../../sys/KeyValueStringParser";
import Proficiency from "./Proficiency";
import { ProficiencyStatKey } from "./Proficiency";
import VCTagUtils from "../../sys/VCTagUtils";
import { Whitelist, WhitelistUtils } from "../../sys/Whitelist";
import { WieldStyle, WieldStyleUtils } from "./WieldStyle";
import DamageTypeUtils, { DamageType } from "./DamageType";
import { InventoryItemType } from "../../sys/InventoryItemType";
import { InventoryItemJSON } from "./InventoryItemJSON";

export default class InventoryItem extends InventoryItemBase {
    constructor(args: {
        id?: string | number;
        title?: string;
        undefinedTitle?: string;
        type?: InventoryItemType | string;
        subtype?: string;
        modifyingProficiencies?:
            | string
            | ProficiencyStatKey
            | Array<ProficiencyStatKey>;
        weight?: string;
        value?: string;
        dmg?: string;
        mdmg?: string;
        pow?: string;
        mpow?: string;
        rng?: string;
        mrng?: string;
        def?: string;
        mdef?: string;
        block?: string;
        components?: string;
        unitSingularTitle?: string;
        unitPluralTitle?: string;
        description?: string;
        unidentifiedDescription?: string;
        mods?: string | Dictionary<string, number>;
        wieldStyles?: string | Array<string> | Array<WieldStyle>;
        damageTypes?: string | Array<string> | Array<DamageType>;
        stackable?: string | boolean;
        tier?: string | number;
        bgSrc?: string;
        bgColor?: string | number | Color;
        iconSrc?: string;
        iconColor?: string | number | Color;
        equipped?: string | boolean;
        starred?: string | boolean;
        hidden?: string | boolean;
        whitelistProtected?: string | boolean;
        whitelist?: string | Whitelist;
    }) {
        super();

        if (args.id != null) {
            if (typeof args.id === "string") {
                const val = Number.parseInt(args.id);
                this._id = Number.isNaN(val) ? -1 : val;
            } else if (typeof args.id === "number") {
                this._id = args.id;
            }
        }
        if (args.title != null) {
            this._title = args.title;
        }
        if (args.undefinedTitle != null) {
            this._unidentifiedTitle = args.undefinedTitle;
        }
        if (args.type != null) {
            this._type = InventoryItemTypeUtils.coerce(args.type);
        }
        if (args.subtype != null) {
            this._subtype = InventoryItemSubTypeUtils.coerce(args.subtype).key;
        }
        if (args.modifyingProficiencies != null) {
            if (Array.isArray(args.modifyingProficiencies)) {
                this._modifyingProficiencies = args.modifyingProficiencies;
            } else if (typeof args.modifyingProficiencies === "string") {
                (args.modifyingProficiencies.includes(":")
                    ? args.modifyingProficiencies.split(":")
                    : [args.modifyingProficiencies]
                ).map((mod) => Proficiency.coerceProficiencyStatKey(mod));
            }
        }
        if (args.weight != null) {
            this._weight = args.weight;
        }
        if (args.value != null) {
            this._value = args.value;
        }
        if (args.dmg != null) {
            this._dmg = args.dmg;
        }
        if (args.mdmg != null) {
            this._mdmg = args.mdmg;
        }
        if (args.pow != null) {
            this._pow = args.pow;
        }
        if (args.mpow != null) {
            this._mpow = args.mpow;
        }
        if (args.rng != null) {
            this._rng = args.rng;
        }
        if (args.mrng != null) {
            this._mrng = args.mrng;
        }
        if (args.def != null) {
            this._def = args.def;
        }
        if (args.mdef != null) {
            this._mdef = args.mdef;
        }
        if (args.block != null) {
            if (typeof args.block === "string") {
                const val = Number.parseInt(args.block);
                this._block = Number.isNaN(val) ? -1 : val;
            } else if (typeof args.block === "number") {
                this._block = args.block;
            }
        }
        if (args.components != null) {
            this._components = args.components;
        }
        if (args.unitSingularTitle != null) {
            this._unitSingularTitle = args.unitSingularTitle;
        }
        if (args.unitPluralTitle != null) {
            this._unitPluralTitle = args.unitPluralTitle;
        }
        if (args.description != null) {
            this._description = args.description;
        }
        if (args.unidentifiedDescription != null) {
            this._unidentifiedDescription = args.unidentifiedDescription;
        }
        if (args.mods != null) {
            if (typeof args.mods === "string") {
                this._mods = new Dictionary<string, number>(
                    KeyValueStringParser.parseKeyValueListString(
                        args.mods
                    ).flatMap((entry) => {
                        const num = Number.parseFloat(entry.value);
                        if (Number.isNaN(num)) {
                            return [];
                        } else {
                            return { key: entry.key, value: num };
                        }
                    })
                );
            } else if (typeof args.mods === "object") {
                this._mods = args.mods;
            }
        }
        if (args.wieldStyles != null) {
            if (typeof args.wieldStyles === "string") {
                this._permittedWieldStyles = args.wieldStyles
                    .split(",")
                    .map((s) => WieldStyleUtils.coerce(s));
            } else {
                this._permittedWieldStyles = args.wieldStyles.map((s) =>
                    WieldStyleUtils.coerce(s)
                );
            }
        }
        if (args.damageTypes != null) {
            if (typeof args.damageTypes === "string") {
                this._damageTypes = args.damageTypes
                    .split(",")
                    .map((s) => DamageTypeUtils.coerce(s));
            } else {
                this._damageTypes = args.damageTypes.map((s) =>
                    DamageTypeUtils.coerce(s)
                );
            }
        }
        if (args.stackable != null) {
            if (typeof args.stackable === "string") {
                this._stackable =
                    args.stackable === "1" ||
                    args.stackable.charAt(1).toLowerCase() === "t";
            } else if (typeof args.stackable === "boolean") {
                this._stackable = args.stackable;
            }
        }

        if (args.tier != null) {
            if (typeof args.tier === "string") {
                const val = Number.parseInt(args.tier);
                this._tier = Number.isNaN(val) ? 0 : val;
            } else if (typeof args.tier === "number") {
                this._tier = args.tier;
            }
        }
        if (args.bgSrc != null) {
            this._bgSrc = args.bgSrc;
        }
        if (args.bgColor != null) {
            if (typeof args.bgColor === "string") {
                if (args.bgColor.length === 7 && args.bgColor[0] === "#") {
                    this._bgColor = `#${args.bgColor.substring(1)}`;
                } else {
                    const val = Number.parseInt(args.bgColor);
                    this._bgColor = Number.isNaN(val)
                        ? null
                        : `#${Math.abs(val).toString(16)}`;
                }
            } else if (typeof args.bgColor === "number") {
                this._iconColor = `#${args.bgColor.toString(16)}`;
            }
        }
        if (args.iconSrc != null) {
            this._iconSrc = args.iconSrc;
        }
        if (args.iconColor != null) {
            if (typeof args.iconColor === "string") {
                if (args.iconColor.length === 7 && args.iconColor[0] === "#") {
                    this._iconColor = `#${args.iconColor.substring(1)}`;
                } else {
                    const val = Number.parseInt(args.iconColor);
                    this._iconColor = Number.isNaN(val)
                        ? null
                        : `#${Math.abs(val).toString(16)}`;
                }
            } else if (typeof args.iconColor === "number") {
                this._iconColor = `#${args.iconColor.toString(16)}`;
            }
        }
        if (args.equipped != null) {
            if (typeof args.equipped === "string") {
                this._equipped =
                    args.equipped === "1" ||
                    args.equipped.charAt(1).toLowerCase() === "t";
            } else if (typeof args.equipped === "boolean") {
                this._equipped = args.equipped;
            }
        }
        if (args.starred != null) {
            if (typeof args.starred === "string") {
                this._starred =
                    args.starred === "1" ||
                    args.starred.charAt(1).toLowerCase() === "t";
            } else if (typeof args.starred === "boolean") {
                this._starred = args.starred;
            }
        }
        if (args.hidden != null) {
            if (typeof args.hidden === "string") {
                this._hidden =
                    args.hidden === "1" ||
                    args.hidden.charAt(1).toLowerCase() === "t";
            } else if (typeof args.hidden === "boolean") {
                this._hidden = args.hidden;
            }
        }
        if (args.whitelistProtected != null) {
            if (typeof args.whitelistProtected === "string") {
                this._whitelistProtected =
                    args.whitelistProtected === "1" ||
                    args.whitelistProtected.charAt(1).toLowerCase() === "t";
            } else if (typeof args.whitelistProtected === "boolean") {
                this._whitelistProtected = args.whitelistProtected;
            }
        }
        if (args.whitelist != null) {
            if (typeof args.whitelist === "string") {
                this._whitelist = WhitelistUtils.fromCSVString(args.whitelist);
            } else {
                this._whitelist = args.whitelist;
            }
        }
    }

    public static fromVCTagString(input: string): InventoryItem {
        return new InventoryItem({
            id: VCTagUtils.readTagNullable(input, "net-id") ?? "-1",
            title: VCTagUtils.readTag(input, "title"),
            undefinedTitle: VCTagUtils.readTag(input, "btitle"),
            type: VCTagUtils.readTag(input, "type"),
            subtype: VCTagUtils.readTag(input, "long-type"),
            modifyingProficiencies: VCTagUtils.readTag(input, "mod-prof"),
            weight: VCTagUtils.readTag(input, "weight"),
            value: VCTagUtils.readTag(input, "value"),
            dmg: VCTagUtils.readTag(input, "dmg"),
            mdmg: VCTagUtils.readTag(input, "mdmg"),
            damageTypes: VCTagUtils.readTag(input, "dmg-types"),
            pow: VCTagUtils.readTag(input, "pow"),
            mpow: VCTagUtils.readTag(input, "mpow"),
            rng: VCTagUtils.readTag(input, "rng"),
            mrng: VCTagUtils.readTag(input, "mrng"),
            def: VCTagUtils.readTag(input, "def"),
            mdef: VCTagUtils.readTag(input, "mdef"),
            block: VCTagUtils.readTag(input, "block"),
            unitSingularTitle: VCTagUtils.readTag(input, "unit-s"),
            unitPluralTitle: VCTagUtils.readTag(input, "unit-p"),
            description: VCTagUtils.readTag(input, "description"),
            unidentifiedDescription: VCTagUtils.readTag(input, "bdescription"),
            mods: VCTagUtils.readTag(input, "mods"),
            stackable: VCTagUtils.readTag(input, "stackable"),
            components: VCTagUtils.readTag(input, "components"),
            tier: VCTagUtils.readTag(input, "tier"),
            bgSrc: VCTagUtils.readTag(input, "bg-src"),
            bgColor: VCTagUtils.readTag(input, "bg-color"),
            iconSrc: VCTagUtils.readTag(input, "icon-src"),
            iconColor: VCTagUtils.readTag(input, "icon-color"),
            equipped: VCTagUtils.readTag(input, "equipped"),
            starred: VCTagUtils.readTag(input, "starred"),
            hidden: VCTagUtils.readTag(input, "hidden"),
            whitelistProtected: VCTagUtils.readTag(
                input,
                "whitelist-protected"
            ),
            whitelist: VCTagUtils.readTag(input, "whitelist"),
        });
    }

    public static fromJSON(json: InventoryItemJSON): InventoryItem {
        const mods = new Dictionary<string, number>();
        if (json.modifier_effects != null) {
            for (var key in json.modifier_effects) {
                mods.set(key, Number.parseInt(json.modifier_effects[key], 10));
            }
        }
        return new InventoryItem({
            id: json.id ?? -1,
            title: json.title ?? "",
            undefinedTitle: json.unidentified_title ?? "",
            type: json.item_class ?? "",
            subtype: json.item_subclass ?? "",
            modifyingProficiencies:
                json.key_proficiencies
                    ?.map((key) => Proficiency.coerceProficiencyStatKey(key))
                    .filter((key) => key !== "Unknown") ?? [],
            weight: json.weight ?? "",
            value: json.cost ?? "",
            dmg: json.damage ?? "",
            mdmg: json.magic_damage ?? "",
            damageTypes: json.damage_types
                ? json.damage_types.map((dt) => DamageTypeUtils.coerce(dt))
                : [],
            pow: json.power ?? "",
            mpow: json.magic_power ?? "",
            rng: json.range ?? "",
            mrng: json.magic_range ?? "",
            def: json.defense ?? "",
            mdef: json.magic_defense ?? "",
            block: json.block ? json.block.toString() : "",
            unitSingularTitle: json.unit_singular_name ?? "",
            unitPluralTitle: json.unit_plural_name ?? "",
            description: json.description ?? "",
            unidentifiedDescription: json.unidentified_description ?? "",
            mods: mods,
            stackable: json.stackable === true,
            components: json.components == null ? "" : "",
            tier: json.tier ?? 0,
            bgSrc: json.bg_src ?? "",
            bgColor: json.bg_color ?? "",
            iconSrc: json.icon_src ?? "",
            iconColor: json.icon_color ?? "",
            equipped: json.equipped === true,
            starred: json.starred === true,
            hidden: json.unidentified === true,
            whitelistProtected: json.whitelist_protected === true,
            whitelist: {
                viewers: json.whitelist?.readers ?? [],
                editors: json.whitelist?.writers ?? [],
            },
        });
    }

    public toVCTagString(): string {
        return `[net-id]${this._id}[/net-id][title]${
            this._title
        }[/title][btitle]${this._unidentifiedTitle}[/btitle][type]${
            this._type
        }[/type][long-type]${
            this._subtype
        }[/long-type][mod-prof]${this._modifyingProficiencies
            .map((prof) => Proficiency.getProficiencyStatKeyLabel(prof))
            .join(":")}[/mod-prof][weight]${this._weight}[/weight][value]${
            this._value
        }[/value][dmg]${this._dmg}[/dmg][mdmg]${this._mdmg}[/mdmg][pow]${
            this._pow
        }[/pow][mpow]${this._mpow}[/mpow][rng]${this._rng}[/rng][mrng]${
            this._mrng
        }[/mrng][def]${this._def}[/def][block]${
            this._block
        }[/block][wield-styles]${this._permittedWieldStyles.join(
            ","
        )}[/wield-styles][dmg-types]${this._damageTypes.join(
            ","
        )}[/dmg-types][mdef]${this._mdef}[/mdef][unit-s]${
            this._unitSingularTitle
        }[/unit-s][unit-p]${this._unitPluralTitle}[/unit-p][components]${
            this._components
        }[/components][description]${
            this._description
        }[/description][bdescription]${
            this._unidentifiedDescription
        }[/bdescription][mods]${this._mods
            .map((entry) => `${entry.key}:${entry.value}`)
            .join(";")}[/mods][stackable]${
            this._stackable ? "1" : "0"
        }[/stackable][tier]${this._tier}[/tier][bg-src]${
            this._bgSrc
        }[/bg-src][icon-src]${this._iconSrc}[/icon-src][bg-color]${
            this._bgColor
        }[/bg-color][icon-color]${this._iconColor}[/icon-color][equipped]${
            this._equipped ? "1" : "0"
        }[/equipped][starred]${this._starred ? "1" : "0"}[/starred][hidden]${
            this._hidden
        }[/hidden][whitelist-protected]${
            this._whitelistProtected ? "1" : "0"
        }[/whitelist-protected][whitelist]${WhitelistUtils.toCSVString(
            this._whitelist
        )}[/whitelist]`;
    }

    public toJSON(): InventoryItemJSON {
        return {
            bg_color: this._bgColor ?? undefined,
            bg_src: this._bgSrc,
            block: this._block,
            components: [],
            cost: this._value,
            damage: this._dmg,
            damage_types: this._damageTypes,
            defense: this._def,
            description: this._description,
            equipped: this._equipped,
            icon_color: this._iconColor ?? undefined,
            icon_src: this._iconSrc,
            id: this._id,
            item_class: this._type,
            item_subclass: this._subtype,
            key_proficiencies: this._modifyingProficiencies,
            magic_damage: this._mdmg,
            magic_defense: this._mdef,
            magic_power: this._mpow,
            magic_range: this.mrng,
            modifier_effects: this._mods
                .toArray()
                .map((v) => v.value.toString()),
            power: this._pow,
            range: this._rng,
            stackable: this._stackable,
            starred: this._starred,
            tier: this._tier,
            title: this._title,
            unidentified: this._hidden,
            unidentified_description: this._unidentifiedDescription,
            unidentified_title: this._unidentifiedTitle,
            unit_plural_name: this._unitPluralTitle,
            unit_singular_name: this._unitSingularTitle,
            weight: this._weight,
            whitelist: {
                readers: this._whitelist.viewers,
                writers: this._whitelist.editors,
            },
            whitelist_protected: this._whitelistProtected,
            wield_styles: this._permittedWieldStyles.map((s) => s.toString()),
        };
    }
}
