import FlexRow from "../../layout/Flexbox/FlexRow";
import { useState } from "react";

import "./RadioButton_style.css";

import radioChecked from "../../../../assets/images/common/svg/radio_checked.svg";
import radioUnchecked from "../../../../assets/images/common/svg/radio_unchecked.svg";

type Props = {
    activeValue: string | null;
    value: string;
    label: string;
    isLabelHidden?: boolean;
    onChange?: (val: string | null) => void;
    buttonOrientation?: "left" | "right";
};

export default function RadioButton({
    activeValue,
    value,
    label,
    isLabelHidden = false,
    onChange,
    buttonOrientation = "left",
}: Props): JSX.Element {
    return (
        <FlexRow className="radio-button-container">
            {buttonOrientation === "left" && (
                <>
                    <ButtonComponent
                        selected={value === activeValue}
                        onClick={() => {
                            onChange?.(activeValue === value ? null : value);
                        }}
                    />
                    {!isLabelHidden && (
                        <LabelComponent
                            label={label}
                            onClick={() => {
                                onChange?.(
                                    activeValue === value ? null : value
                                );
                            }}
                        />
                    )}
                </>
            )}
            {buttonOrientation === "right" && (
                <>
                    {!isLabelHidden && (
                        <LabelComponent
                            label={label}
                            onClick={() => {
                                onChange?.(
                                    activeValue === value ? null : value
                                );
                            }}
                        />
                    )}
                    <ButtonComponent
                        selected={value === activeValue}
                        onClick={() => {
                            onChange?.(activeValue === value ? null : value);
                        }}
                    />
                </>
            )}
        </FlexRow>
    );
}

function ButtonComponent({
    selected,
    onClick,
}: {
    selected: boolean;
    onClick?: (e?: React.MouseEvent) => void;
}): JSX.Element {
    return (
        <img
            className="button-element"
            src={selected ? radioChecked : radioUnchecked}
            onClick={(e) => {
                onClick?.(e);
            }}
        />
    );
}

function LabelComponent({
    label,
    onClick,
}: {
    label: string;
    onClick?: (e?: React.MouseEvent) => void;
}): JSX.Element {
    return (
        <div
            className="label-element"
            onClick={(e) => {
                onClick?.(e);
            }}
        >
            {label}
        </div>
    );
}
