import ResistancePanel from "../ResistancePanel/ResistancePanel";
import { CharacterData } from "../../../../utils/game/character/CharacterData";
import StatBoard from "../../../../utils/game/mechanics/StatBoard";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";

type Props = { characterData: CharacterData; statBoard: StatBoard };

export default function ResistancesContainer({
    characterData,
    statBoard,
}: Props): JSX.Element {
    return (
        <FlexColumn>
            <FlexRow>
                <ResistancePanel statBoard={statBoard} statKey="BLURES" />
                <ResistancePanel statBoard={statBoard} statKey="COLRES" />
                <ResistancePanel statBoard={statBoard} statKey="DARRES" />
                <ResistancePanel statBoard={statBoard} statKey="FORRES" />
                <ResistancePanel statBoard={statBoard} statKey="HEARES" />
            </FlexRow>
            <FlexRow>
                <ResistancePanel statBoard={statBoard} statKey="LIGRES" />
                <ResistancePanel statBoard={statBoard} statKey="PIERES" />
                <ResistancePanel statBoard={statBoard} statKey="PSIRES" />
                <ResistancePanel statBoard={statBoard} statKey="SLARES" />
                <ResistancePanel statBoard={statBoard} statKey="SHORES" />
            </FlexRow>
        </FlexColumn>
    );
}
