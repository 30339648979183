import "./LabeledTextField_style.css";

type Props = {
    label: string;
    children?: string;
    className?: string;
    labelClassName?: string | null;
    valueClassName?: string | null;
    labelStyle?: React.CSSProperties;
    valueStyle?: React.CSSProperties;
};

export default function LabeledTextField({
    label,
    children = "",
    className,
    labelClassName,
    valueClassName,
    labelStyle,
    valueStyle,
}: Props): JSX.Element {
    return (
        <div
            className={`labeled-text-field${
                className == null ? `` : ` ${className}`
            }`}
        >
            <div
                className={`ltf-label-field${
                    labelClassName == null ? `` : ` ${labelClassName}`
                }`}
                style={labelStyle}
            >
                {label}
            </div>
            <div
                className={`ltf-value-field${
                    valueClassName == null ? `` : ` ${valueClassName}`
                }`}
                style={valueStyle}
            >
                {children}
            </div>
        </div>
    );
}
