import Attribute from "../mechanics/Attribute";
import StatBoard from "../mechanics/StatBoard";
import { CharacterData } from "./CharacterData";
import type { CharacterDataJSON } from "./CharacterDataJSON";
import { Vitality } from "../mechanics/Vitality";
import * as Attributes from "../../game/mechanics/Attributes";
import VitalityRatingUtils from "../mechanics/VitalityRating";

const PointSpendValue = { key: "PointSpendValue", tip: "Base Score" };
const ProfAdjustValue = { key: "ProfAdjustValue", tip: "Proficiency Bonus" };
const StrAdjustValue = { key: "StrAdjustValue", tip: "STR Bonus" };
const AgiAdjustValue = { key: "AgiAdjustValue", tip: "AGI Bonus" };
const IntAdjustValue = { key: "IntAdjustValue", tip: "INT Bonus" };
const ForAdjustValue = { key: "ForAdjustValue", tip: "FOR Bonus" };
const ChaAdjustValue = { key: "ChaAdjustValue", tip: "CHA Bonus" };
const ForChaAdjustValue = { key: "ForChaAdjustValue", tip: "FOR/CHA Bonus" };
const IntChaAdjustValue = { key: "IntChaAdjustValue", tip: "INT/CHA Bonus" };
const AgiIntAdjustValue = { key: "AgiIntAdjustValue", tip: "AGI/INT Bonus" };
const StrForAdjustValue = { key: "StrForAdjustValue", tip: "STR/FOR Bonus" };
const StrAgiAdjustValue = { key: "StrAgiAdjustValue", tip: "STR/AGI Bonus" };
const IntAgiAdjustValue = { key: "IntAgiAdjustValue", tip: "INT/AGI Bonus" };

export class CharacterDataParser {
    static parseRawCharacterDataToStatBoard(
        charaData: CharacterDataJSON,
        statBoard: StatBoard
    ): void {
        const addPointSpendValue = (statKey: string, value: number) => {
            statBoard.addFeature(
                statKey,
                PointSpendValue.key,
                null,
                value,
                PointSpendValue.tip
            );
        };

        const addModifierValue = (modifierStatKey: string, statKey: string) => {
            statBoard.addFeature(
                modifierStatKey,
                PointSpendValue.key,
                null,
                {
                    type: "addend",
                    value: (): number => {
                        return Math.floor(
                            Number.parseInt(
                                statBoard.getValueByStatKey(statKey, null, [
                                    "addend",
                                    "modifier",
                                ])
                            ) / 2
                        );
                    },
                },
                PointSpendValue.tip
            );
        };

        const addAttributeValue = (
            modifierStatKey: string,
            statKeys: Array<string>,
            attribute: Attribute,
            source?: string,
            tip?: string
        ) => {
            statBoard.addFeature(
                modifierStatKey,
                source ?? ProfAdjustValue.key,
                null,
                {
                    type: "addend",
                    value: (): number => {
                        const statValues = statKeys.map((key) =>
                            Number.parseInt(
                                statBoard.getValueByStatKey(key, null, [
                                    "addend",
                                    "modifier",
                                ])
                            )
                        );
                        const score =
                            statValues.length > 1
                                ? Math.floor(
                                      statValues.reduce(
                                          (acc, curr) => acc + curr,
                                          0
                                      ) / statValues.length
                                  )
                                : statValues.length === 1
                                ? statValues[0]
                                : 0;

                        return attribute.getValueForScore(score);
                    },
                },
                tip ?? ProfAdjustValue.tip
            );
        };

        // Proficiencies
        addPointSpendValue("STR", charaData.strength ?? 0);
        addPointSpendValue("AGI", charaData.agility ?? 0);
        addPointSpendValue("INT", charaData.intelligence ?? 0);
        addPointSpendValue("FOR", charaData.fortitude ?? 0);
        addPointSpendValue("CHA", charaData.charisma ?? 0);

        // Modifiers
        addModifierValue("STRMOD", "STR");
        addModifierValue("AGIMOD", "AGI");
        addModifierValue("INTMOD", "INT");
        addModifierValue("FORMOD", "FOR");
        addModifierValue("CHAMOD", "CHA");

        // Attributes
        addAttributeValue(
            "AWA",
            ["INT"],
            Attributes.Awareness,
            IntAdjustValue.key,
            IntAdjustValue.tip
        );
        addAttributeValue(
            "WGTALL",
            ["STR"],
            Attributes.WeightAllowance,
            StrAdjustValue.key,
            StrAdjustValue.tip
        );
        addAttributeValue(
            "REG",
            ["FOR", "CHA"],
            Attributes.Regen,
            ForChaAdjustValue.key,
            ForChaAdjustValue.tip
        );
        addAttributeValue(
            "SUR",
            ["FOR"],
            Attributes.Survival,
            ForAdjustValue.key,
            ForAdjustValue.tip
        );
        addAttributeValue(
            "WIL",
            ["INT", "CHA"],
            Attributes.Willpower,
            IntChaAdjustValue.key,
            IntChaAdjustValue.tip
        );
        addAttributeValue(
            "STLTH",
            ["AGI", "INT"],
            Attributes.Stealth,
            AgiIntAdjustValue.key,
            AgiIntAdjustValue.tip
        );

        // Vitality
    }

    static parseCharacterDataToStatBoard(
        charaData: CharacterData,
        statBoard: StatBoard
    ): void {
        statBoard.removeFeaturesBySource(["Race"]);
        const addPointSpendValue = (statKey: string, value: number) => {
            statBoard.addFeature(
                statKey,
                PointSpendValue.key,
                null,
                value,
                PointSpendValue.tip
            );
        };

        const addModifierValue = (modifierStatKey: string, statKey: string) => {
            statBoard.addFeature(
                modifierStatKey,
                PointSpendValue.key,
                null,
                {
                    type: "addend",
                    value: (): number => {
                        return Math.floor(
                            Number.parseInt(
                                statBoard.getValueByStatKey(statKey, null, [
                                    "addend",
                                    "modifier",
                                ])
                            ) / 2
                        );
                    },
                },
                PointSpendValue.tip
            );
        };

        const addAttributeValue = (
            attribute: Attribute,
            statKeys: Array<string>,
            source?: string,
            tip?: string
        ) => {
            statBoard.addFeature(
                attribute.statKey,
                source ?? ProfAdjustValue.key,
                null,
                {
                    type: "addend",
                    value: (): number => {
                        const statValues = statKeys.map((key) =>
                            Number.parseInt(
                                statBoard.getValueByStatKey(key, null, [
                                    "addend",
                                    "modifier",
                                ])
                            )
                        );
                        const score =
                            statValues.length > 1
                                ? Math.floor(
                                      statValues.reduce(
                                          (acc, curr) => acc + curr,
                                          0
                                      ) / statValues.length
                                  )
                                : statValues.length === 1
                                ? statValues[0]
                                : 0;

                        return attribute.getValueForScore(score);
                    },
                },
                tip ?? ProfAdjustValue.tip
            );
        };

        // Proficiencies
        addPointSpendValue("STR", charaData.strength);
        addPointSpendValue("AGI", charaData.agility);
        addPointSpendValue("INT", charaData.intelligence);
        addPointSpendValue("FOR", charaData.fortitude);
        addPointSpendValue("CHA", charaData.charisma);

        // Modifiers
        addModifierValue("STRMOD", "STR");
        addModifierValue("AGIMOD", "AGI");
        addModifierValue("INTMOD", "INT");
        addModifierValue("FORMOD", "FOR");
        addModifierValue("CHAMOD", "CHA");

        // Attributes
        addAttributeValue(
            Attributes.Awareness,
            ["INT"],
            IntAdjustValue.key,
            IntAdjustValue.tip
        );
        addAttributeValue(
            Attributes.WeightAllowance,
            ["STR"],
            StrAdjustValue.key,
            StrAdjustValue.tip
        );
        addAttributeValue(
            Attributes.Regen,
            ["FOR", "CHA"],
            ForChaAdjustValue.key,
            ForChaAdjustValue.tip
        );
        addAttributeValue(
            Attributes.Survival,
            ["FOR"],
            ForAdjustValue.key,
            ForAdjustValue.tip
        );
        addAttributeValue(
            Attributes.Willpower,
            ["INT", "CHA"],
            IntChaAdjustValue.key,
            IntChaAdjustValue.tip
        );
        addAttributeValue(
            Attributes.Stealth,
            ["AGI", "INT"],
            AgiIntAdjustValue.key,
            AgiIntAdjustValue.tip
        );

        // Combat Stats
        statBoard.addFeature(
            "DEF",
            StrForAdjustValue.key,
            null,
            {
                type: "addend",
                value: () => {
                    return Attributes.Dodge.getValueForScore(
                        Math.floor(
                            (charaData.strength + charaData.fortitude) / 2
                        )
                    );
                },
            },
            StrForAdjustValue.tip
        );

        statBoard.addFeature(
            "MDEF",
            IntChaAdjustValue.key,
            null,
            {
                type: "addend",
                value: () => {
                    return Attributes.BaseMagicDefense.getValueForScore(
                        Math.floor(
                            (charaData.intelligence + charaData.charisma) / 2
                        )
                    );
                },
            },
            IntChaAdjustValue.tip
        );

        statBoard.addFeature(
            "DDG",
            AgiIntAdjustValue.key,
            null,
            {
                type: "addend",
                value: () => {
                    return Attributes.Dodge.getValueForScore(charaData.agility);
                },
            },
            AgiAdjustValue.tip
        );

        statBoard.addFeature(
            "POW",
            StrAgiAdjustValue.key,
            null,
            {
                type: "addend",
                value: () => {
                    return Attributes.Power.getValueForScore(
                        Math.floor((charaData.strength + charaData.agility) / 2)
                    );
                },
            },
            StrAgiAdjustValue.tip
        );

        statBoard.addFeature(
            "MPOW",
            IntAgiAdjustValue.key,
            null,
            {
                type: "addend",
                value: () => {
                    return Attributes.MagicPower.getValueForScore(
                        Math.floor(
                            (charaData.intelligence + charaData.agility) / 2
                        )
                    );
                },
            },
            IntAgiAdjustValue.tip
        );

        statBoard.addFeature(
            "SPD",
            AgiIntAdjustValue.key,
            null,
            {
                type: "addend",
                value: () => {
                    return Attributes.Speed.getValueForScore(charaData.agility);
                },
            },
            AgiAdjustValue.tip
        );

        statBoard.addFeature(
            "ACC",
            AgiIntAdjustValue.key,
            null,
            {
                type: "addend",
                value: () => {
                    return Attributes.Accuracy.getValueForScore(
                        charaData.agility
                    );
                },
            },
            AgiAdjustValue.tip
        );

        // Vitality
        statBoard.addFeature(
            "MPMAX",
            `Race`,
            null,
            {
                type: "addend",
                value: () => {
                    if (charaData.race !== null) {
                        return Vitality.getMPValue(
                            VitalityRatingUtils.coerce(charaData.race.mpBase),
                            charaData.level
                        );
                    }
                    return 0;
                },
            },
            `Race${charaData.race ? `: ${charaData.race.name}` : ``}`
        );

        statBoard.addFeature(
            "MPMAX",
            IntChaAdjustValue.key,
            null,
            {
                type: "addend",
                value: () => {
                    return Attributes.MPAdjust.getValueForScore(
                        Math.floor(
                            (charaData.intelligence + charaData.charisma) / 2
                        )
                    );
                },
            },
            IntChaAdjustValue.tip
        );

        statBoard.addFeature(
            "MP",
            `Base`,
            null,
            {
                type: "addend",
                value: () => {
                    return Number.parseInt(
                        statBoard.getValueByStatKey("MPMAX")
                    );
                },
            },
            `Base Value`
        );

        statBoard.addFeature(
            "MP",
            `Exhaustion`,
            null,
            {
                type: "addend",
                value: () => {
                    return -charaData.exhaustion;
                },
            },
            `Exhaustion`
        );

        statBoard.addFeature(
            "MP",
            `MPAdjust`,
            null,
            {
                type: "addend",
                value: () => {
                    return charaData.mpAdjust;
                },
            },
            `MP Adjust`
        );

        statBoard.addFeature(
            "EPMAX",
            `Race`,
            null,
            {
                type: "addend",
                value: () => {
                    if (charaData.race !== null) {
                        return Vitality.getEPValue(
                            VitalityRatingUtils.coerce(charaData.race.epBase),
                            charaData.level
                        );
                    }
                    return 0;
                },
            },
            `Race${charaData.race ? `: ${charaData.race.name}` : ``}`
        );

        statBoard.addFeature(
            "EPMAX",
            StrForAdjustValue.key,
            null,
            {
                type: "addend",
                value: () => {
                    return Attributes.EPAdjust.getValueForScore(
                        Math.floor(
                            (charaData.strength + charaData.fortitude) / 2
                        )
                    );
                },
            },
            StrForAdjustValue.tip
        );

        statBoard.addFeature(
            "EP",
            `Base`,
            null,
            {
                type: "addend",
                value: () => {
                    return Number.parseInt(
                        statBoard.getValueByStatKey("EPMAX")
                    );
                },
            },
            `Base Value`
        );

        statBoard.addFeature(
            "EP",
            `Exhaustion`,
            null,
            {
                type: "addend",
                value: () => {
                    return -charaData.exhaustion;
                },
            },
            `Exhaustion`
        );

        statBoard.addFeature(
            "EP",
            `EPAdjust`,
            null,
            {
                type: "addend",
                value: () => {
                    return charaData.epAdjust;
                },
            },
            `EP Adjust`
        );

        // Equipment
        statBoard.addFeature(
            "DEF",
            "Torso",
            null,
            {
                type: "addend",
                value: () => {
                    return Number.parseInt(charaData.torso?.def ?? "0");
                },
            },
            charaData.torso?.title
        );

        statBoard.addFeature(
            "MDEF",
            "Torso",
            null,
            {
                type: "addend",
                value: () => {
                    return Number.parseInt(charaData.torso?.mdef ?? "0");
                },
            },
            charaData.torso?.title
        );

        // Racial Adjustments
        if (charaData.race !== null) {
            charaData.race.raceModifiers.forEach((mod) => {
                statBoard.addFeature(
                    mod.key,
                    "Race",
                    null,
                    { type: "addend", value: mod.value },
                    `Race${charaData.race ? `: ${charaData.race.name}` : ``}`
                );
            });
        }

        // Traits
        if (charaData.traits.length > 0) {
            charaData.traits.forEach((trait) => {
                if (trait.active && trait.mods.length > 0) {
                    trait.mods.forEach((mod) => {
                        statBoard.addFeature(
                            mod.key,
                            `trait_${trait.id}`,
                            null,
                            {
                                type: "addend",
                                value: () => {
                                    return trait.active ? mod.value : 0;
                                },
                            },
                            `Trait: ${trait.title}`
                        );
                    });
                }
            });
        }
    }
}
