import fameLabels from "../../assets/json/alignment/fame_labels.json";
import infamyLabels from "../../assets/json/alignment/infamy_labels.json";
import reputationLabels from "../../assets/json/alignment/reputation_labels.json";

export default class ReputationLabels {
    public static getFameLabel(val: number) {
        return (
            fameLabels.find((c) => {
                const max = c.max ?? Number.MAX_SAFE_INTEGER;
                const min = c.min ?? Number.MIN_SAFE_INTEGER;

                return val >= min && val <= max;
            })?.label ?? ""
        );
    }

    public static getInfamyLabel(val: number) {
        return (
            infamyLabels.find((c) => {
                const max = c.max ?? Number.MAX_SAFE_INTEGER;
                const min = c.min ?? Number.MIN_SAFE_INTEGER;

                return val >= min && val <= max;
            })?.label ?? ""
        );
    }

    public static getReputationLabel(fame: number, infamy: number) {
        return (
            reputationLabels.find((c) => {
                const max = c.max ?? Number.MAX_SAFE_INTEGER;
                const min = c.min ?? Number.MIN_SAFE_INTEGER;
                const ratioMax = c.ratio_max ?? 1;
                const ratioMin = c.ratio_min ?? 0;

                const total = fame + infamy;
                const ratio = total === 0 ? 0 : (fame - infamy) / total;

                return (
                    total >= min &&
                    total <= max &&
                    ratio >= ratioMin &&
                    ratio <= ratioMax
                );
            })?.label ?? ""
        );
    }
}
