import Icon from "../../../../common/components/Icon/Icon";
import InventoryItem from "../../../../utils/game/mechanics/InventoryItem";
import { InventoryItemContainerProps } from "./InventoryItemContainer";
import InventoryItemIcon from "../InventoryItemIcon/InventoryItemIcon";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import TextField from "../../../../common/components/display/TextField/TextField";
import {
    InventoryItemType,
    InventoryItemTypeUtils,
} from "../../../../utils/sys/InventoryItemType";
import LabeledTextField from "../../../../common/components/display/LabeledTextField/LabeledTextField";

import currencyIcon from "../../../../assets/images/common/svg/currency.svg";
import weightIcon from "../../../../assets/images/common/svg/weight.svg";

import "./style/InventoryItemDetails_style.css";

type Props = InventoryItemContainerProps & {
    unidentified?: boolean;
    className?: string;
};

export default function InventoryItemDetails(props: Props): JSX.Element {
    const unidentified =
        props.unidentified === undefined ? false : props.unidentified;

    return (
        <FlexColumn
            className={`inventory-item-details-container${
                props.className == null ? `` : ` ${props.className}`
            }`}
        >
            {props.item != null && (
                <FlexColumn gap={4}>
                    <FlexRow>
                        <InventoryItemIcon
                            bgSrc={props.item.bgSrc}
                            bgColor={props.item.bgColor}
                            iconSrc={props.item.iconSrc}
                            iconColor={props.item.iconColor}
                            className="inventory-item-details-item-icon"
                        />
                        <FlexColumn>
                            <TextField type="bold">
                                {unidentified
                                    ? props.item.unidentifiedTitle
                                    : props.item.title}
                            </TextField>
                            <TextField>{`${props.item.typeString}${
                                props.item.type === InventoryItemType.GENERIC
                                    ? ``
                                    : ` - ${props.item.subTypeString}`
                            }`}</TextField>
                            <FlexRow>
                                <Icon src={currencyIcon} size={16} />
                                <TextField>{props.item.value}</TextField>
                                <Icon src={weightIcon} size={16} />
                                <TextField>{props.item.weight}</TextField>
                            </FlexRow>
                        </FlexColumn>
                    </FlexRow>
                    {props.item.type === InventoryItemType.ACCESSORY && (
                        <FlexColumn></FlexColumn>
                    )}
                    {props.item.type === InventoryItemType.AMMUNITION && (
                        <FlexColumn>
                            <FlexRow>
                                <LabeledTextField label="Dmg">
                                    {props.item.dmg}
                                </LabeledTextField>
                                <LabeledTextField label="M.Dmg">
                                    {props.item.mdmg}
                                </LabeledTextField>
                            </FlexRow>
                        </FlexColumn>
                    )}
                    {props.item.type === InventoryItemType.ARMOR && (
                        <FlexColumn>
                            <FlexRow>
                                <LabeledTextField label="Def">
                                    {props.item.def}
                                </LabeledTextField>
                                <LabeledTextField label="M.Def">
                                    {props.item.mdef}
                                </LabeledTextField>
                            </FlexRow>
                        </FlexColumn>
                    )}
                    {props.item.type === InventoryItemType.COMPONENT && (
                        <FlexColumn></FlexColumn>
                    )}
                    {props.item.type === InventoryItemType.CONSUMABLE && (
                        <FlexColumn></FlexColumn>
                    )}
                    {props.item.type === InventoryItemType.WEAPON && (
                        <FlexColumn>
                            <FlexRow>
                                <LabeledTextField label="Dmg">
                                    {props.item.dmg}
                                </LabeledTextField>
                                <LabeledTextField label="M.Dmg">
                                    {props.item.mdmg}
                                </LabeledTextField>
                            </FlexRow>
                            <FlexRow>
                                <LabeledTextField label="Pow">
                                    {props.item.pow}
                                </LabeledTextField>
                                <LabeledTextField label="M.Pow">
                                    {props.item.mpow}
                                </LabeledTextField>
                            </FlexRow>
                            <FlexRow>
                                <LabeledTextField label="Rng">
                                    {props.item.rng}
                                </LabeledTextField>
                                <LabeledTextField label="M.Rng">
                                    {props.item.mrng}
                                </LabeledTextField>
                            </FlexRow>
                        </FlexColumn>
                    )}
                    <TextField>
                        {unidentified
                            ? props.item.unidentifiedDescription
                            : props.item.description}
                    </TextField>
                </FlexColumn>
            )}
        </FlexColumn>
    );
}
