import { useRef, useEffect } from "react";

const useCanvas = (draw: (context: CanvasRenderingContext2D) => void) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d");
        let animationFrameID: number = 0;

        const render = () => {
            if (context != null) {
                draw(context);
            }
            animationFrameID = window.requestAnimationFrame(render);
        };
        render();

        return () => {
            window.cancelAnimationFrame(animationFrameID);
        };
    }, [draw]);

    return canvasRef;
};

export default useCanvas;
