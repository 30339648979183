import { useEffect, useMemo, useState } from "react";
import { useGetTalentList } from "../../../../net/CharacterFeatureManagement";
import { TableSortKey } from "../../../../common/components/layout/Table/Table";
import Cookies from "universal-cookie";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import Modal from "../../../../common/components/modals/Modal/Modal";
import Talent from "../../../../utils/game/mechanics/Talent";
import Table from "../../../../common/components/layout/Table/Table";
import TextField from "../../../../common/components/display/TextField/TextField";
import TextInput from "../../../../common/components/input/TextInput/TextInput";

import "../style/CommonModalStyle_style.css";

type Props = {
    isVisible?: boolean;
    talentOptions?: Array<Talent>;
    /** Filters talent options based on external properties */
    optionFilter?: ((t: Talent) => boolean) | null;
    onTalentOptionsChanged?: (talents: Array<Talent>) => void;
    onClose?: () => void;
    onConfirm?: (talents: Array<Talent>) => void;
};

export default function LoadTalentModal(props: Props): JSX.Element {
    const [loadTalentList, isLoadingTalentList] = useGetTalentList();
    const [selectedTalentIDs, setSelectedTalentIDs] = useState<Array<number>>(
        []
    );
    const [talentList, setTalentList] = useState<Array<Talent>>(
        props.talentOptions ?? []
    );
    const [talentListSortKey, setTalentListSortKey] =
        useState<TableSortKey | null>(null);
    const [searchStr, setSearchStr] = useState<string>("");

    const cookies = new Cookies();

    useEffect(() => {
        if (props.talentOptions === undefined) {
            loadTalentList({
                variables: { token: cookies.get("session_token") },
                onComplete: (result) => {
                    setTalentList(result);
                    props.onTalentOptionsChanged?.(result);
                },
                onError: (error) => {},
            });
        }
    }, []);

    const handleChangeSort = (sortKey: TableSortKey | null) => {
        if (sortKey !== null) {
            setTalentList(
                talentList.sort((a, b) => {
                    switch (sortKey.key) {
                        case "title":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.title.localeCompare(b.title)
                                : b.title.localeCompare(a.title);
                        case "subtype":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.subtype.localeCompare(b.subtype)
                                : b.subtype.localeCompare(a.subtype);
                        case "proficiency":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.proficiency
                                      .join()
                                      .localeCompare(b.proficiency.join())
                                : b.proficiency
                                      .join()
                                      .localeCompare(a.proficiency.join());
                        case "description":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.description.localeCompare(b.description)
                                : b.description.localeCompare(a.description);
                        default:
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.id - b.id
                                : b.id - a.id;
                    }
                })
            );
        }

        setTalentListSortKey(sortKey);
    };

    const tableFilter = useMemo(() => {
        const searchFilter =
            searchStr.length > 0
                ? (talent: Talent) => {
                      if (props.optionFilter != null) {
                          if (!props.optionFilter(talent)) {
                              return false;
                          }
                      }
                      const match = new RegExp(searchStr, "i");
                      const result =
                          talent.title.match(match) ??
                          talent.subtype.match(match) ??
                          talent.proficiency.join().match(match) ??
                          talent.description.match(match);

                      return result !== null;
                  }
                : null;

        const filters = [props.optionFilter, searchFilter].flatMap(
            (f) => f ?? []
        );
        return filters.length > 0
            ? (talent: Talent) => filters.every((filter) => filter(talent))
            : null;
    }, [props.optionFilter, searchStr]);

    return (
        <Modal
            contentClassName="character-common-modal"
            header="Add Talent"
            isVisible={props.isVisible}
            width={750}
            footer="standard-submit-cancel"
            onClose={props.onClose}
            onConfirm={() => {
                props.onConfirm?.(
                    talentList.filter((talent) =>
                        selectedTalentIDs.includes(talent.id)
                    )
                );
            }}
        >
            <FlexRow>
                <TextField type="bold" style={{ width: "auto", margin: "8px" }}>
                    Search
                </TextField>
                <TextInput
                    value={searchStr}
                    style={{ width: "240px" }}
                    onChange={setSearchStr}
                />
                <FlexRow alignment="end">
                    <TextField
                        type="bold"
                        style={{ width: "auto", margin: "8px" }}
                    >{`${selectedTalentIDs.length} Selected`}</TextField>
                    <button
                        onClick={() => {
                            setSelectedTalentIDs([]);
                        }}
                    >
                        Clear
                    </button>
                </FlexRow>
            </FlexRow>
            <hr />
            <Table
                className="options-list"
                columns={[
                    {
                        columnKey: "title",
                        columnHeader: "Title",
                        sortable: true,
                        width: 180,
                    },
                    {
                        columnKey: "subtype",
                        columnHeader: "Subtype",
                        sortable: true,
                        width: 120,
                    },
                    {
                        columnKey: "proficiency",
                        columnHeader: "Proficiency",
                        sortable: true,
                        width: 120,
                    },
                    {
                        columnKey: "description",
                        columnHeader: "Description",
                        sortable: true,
                    },
                ]}
                rowData={talentList}
                cellParser={[
                    {
                        columnKey: "title",
                        callback: (talent) => {
                            return (
                                <TextField className="option-item">
                                    {talent.title}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "subtype",
                        callback: (talent) => {
                            return (
                                <TextField className="option-item">
                                    {talent.subtype}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "proficiency",
                        callback: (talent) => {
                            return (
                                <TextField className="option-item">
                                    {talent.proficiency.join(", ")}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "description",
                        callback: (talent) => {
                            return (
                                <TextField className="option-item">
                                    {talent.description}
                                </TextField>
                            );
                        },
                    },
                ]}
                idGetter={(talent) => talent.id.toString()}
                sortKey={talentListSortKey}
                onChangeSortKey={handleChangeSort}
                isRowSelected={(talent) =>
                    selectedTalentIDs.includes(talent.id)
                }
                onRowClick={(talent) => {
                    if (selectedTalentIDs.includes(talent.id)) {
                        setSelectedTalentIDs([
                            ...selectedTalentIDs.filter(
                                (entry) => entry !== talent.id
                            ),
                        ]);
                    } else {
                        setSelectedTalentIDs([...selectedTalentIDs, talent.id]);
                    }
                }}
                rowFilter={tableFilter}
                showHeader={true}
            />
        </Modal>
    );
}
