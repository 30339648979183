import React, { useRef, useEffect } from "react";
import { MutableRefObject } from "react";

function useMouseOverAlerter(
    ref: MutableRefObject<HTMLElement>,
    mouseEnterCallback?: (event?: MouseEvent) => void,
    mouseLeaveCallback?: (event?: MouseEvent) => void
) {
    useEffect(() => {
        function handleMouseEnter(event: MouseEvent) {
            if (ref.current) {
                mouseEnterCallback?.(event);
            }
        }

        function handleMouseLeave(event: MouseEvent) {
            if (ref.current) {
                mouseLeaveCallback?.(event);
            }
        }

        ref.current?.addEventListener("mouseenter", handleMouseEnter);
        ref.current?.addEventListener("mouseleave", handleMouseLeave);
        return () => {
            ref.current?.removeEventListener("mouseenter", handleMouseEnter);
            ref.current?.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, [ref]);
}

type Props = {
    forwardRef?: MutableRefObject<any>;
    children: React.ReactNode;
    onMouseEnter?: (event?: MouseEvent) => void;
    onMouseLeave?: (event?: MouseEvent) => void;
};

export default function MouseOverAlertWrapper(props: Props): JSX.Element {
    const localRef = useRef(null);
    useMouseOverAlerter(
        props.forwardRef ?? localRef,
        props.onMouseEnter,
        props.onMouseLeave
    );

    return <div ref={localRef}>{props.children}</div>;
}
