import { Rect2d } from "../types/Geometry";

export default class ImageSlicer {
    private _src: string;
    public get src() {
        return this._src;
    }
    public set src(val: string) {
        this._src = val;

        this.loadSrcImage();
    }

    private _rows: number;
    public get rows() {
        return this._rows;
    }
    public set rows(val: number) {
        this._rows = val;
    }

    private _columns: number;
    public get columns() {
        return this._columns;
    }
    public set columns(val: number) {
        this._columns = val;
    }

    private _isLoaded: boolean = false;
    public get isLoaded() {
        return this._isLoaded;
    }

    private _image: ImageBitmap | null = null;
    public get image() {
        return this._image;
    }

    constructor(src: string, rows: number = 1, columns = 1) {
        this._src = src;
        this._rows = rows;
        this._columns = columns;

        this.loadSrcImage();
    }

    private loadSrcImage() {
        this._isLoaded = false;
        this._image = null;

        const img = new Image();
        img.onload = () => {
            createImageBitmap(img).then((result) => {
                console.log(`Loaded ${this._src}`);
                this._image = result;
            });
            this._isLoaded = true;
        };
        img.src = this._src;
    }

    public getPixelCoordsFromIndex(index: number): Rect2d | null {
        if (this.image == null) {
            return null;
        } else {
            const result = {
                x: Math.floor(
                    (this.image.width / this._columns) * (index % this._rows)
                ),
                y: Math.floor(
                    (this.image.height / this._columns) *
                        Math.floor(index / this._rows)
                ),
                w: Math.floor(this.image.width / this._columns),
                h: Math.floor(this.image.height / this._rows),
            };
            return {
                x: Math.floor(
                    (this.image.width / this._columns) * (index % this._rows)
                ),
                y: Math.floor(
                    (this.image.height / this._columns) *
                        Math.floor(index / this._rows)
                ),
                w: Math.floor(this.image.width / this._columns),
                h: Math.floor(this.image.height / this._rows),
            };
        }
    }
}
