import back01 from "../../assets/images/character_sheet/png/items/back01.png";
import back02 from "../../assets/images/character_sheet/png/items/back02.png";
import back03 from "../../assets/images/character_sheet/png/items/back03.png";
import back04 from "../../assets/images/character_sheet/png/items/back04.png";

import amulet_blank from "../../assets/images/character_sheet/png/items/amulet_blank.png";
import amulet01 from "../../assets/images/character_sheet/png/items/amulet01.png";
import arrow_blank from "../../assets/images/character_sheet/png/items/arrow_blank.png";
import arrow01 from "../../assets/images/character_sheet/png/items/arrow01.png";
import arrow02 from "../../assets/images/character_sheet/png/items/arrow02.png";
import arrow03 from "../../assets/images/character_sheet/png/items/arrow03.png";
import axe01 from "../../assets/images/character_sheet/png/items/axe01.png";
import axe02 from "../../assets/images/character_sheet/png/items/axe02.png";
import bag01 from "../../assets/images/character_sheet/png/items/bag01.png";
import belt_blank from "../../assets/images/character_sheet/png/items/belt_blank.png";
import belt01 from "../../assets/images/character_sheet/png/items/belt01.png";
import book01 from "../../assets/images/character_sheet/png/items/book01.png";
import book02 from "../../assets/images/character_sheet/png/items/book02.png";
import boots_blank from "../../assets/images/character_sheet/png/items/boots_blank.png";
import boots01 from "../../assets/images/character_sheet/png/items/boots01.png";
import bow01 from "../../assets/images/character_sheet/png/items/bow01.png";
import brigandine01 from "../../assets/images/character_sheet/png/items/brigandine01.png";
import bullet01 from "../../assets/images/character_sheet/png/items/bullet01.png";
import cards01 from "../../assets/images/character_sheet/png/items/cards01.png";
import chainmail01 from "../../assets/images/character_sheet/png/items/chainmail01.png";
import chest01 from "../../assets/images/character_sheet/png/items/chest01.png";
import club01 from "../../assets/images/character_sheet/png/items/club01.png";
import coin01 from "../../assets/images/character_sheet/png/items/coin01.png";
import component01 from "../../assets/images/character_sheet/png/items/component01.png";
import component02 from "../../assets/images/character_sheet/png/items/component02.png";
import component03 from "../../assets/images/character_sheet/png/items/component03.png";
import component04 from "../../assets/images/character_sheet/png/items/component04.png";
import crossbow01 from "../../assets/images/character_sheet/png/items/crossbow01.png";
import cuirass01 from "../../assets/images/character_sheet/png/items/cuirass01.png";
import dice01 from "../../assets/images/character_sheet/png/items/dice01.png";
import food01 from "../../assets/images/character_sheet/png/items/food01.png";
import food02 from "../../assets/images/character_sheet/png/items/food02.png";
import gem01 from "../../assets/images/character_sheet/png/items/gem01.png";
import gloves_blank from "../../assets/images/character_sheet/png/items/gloves_blank.png";
import glove01 from "../../assets/images/character_sheet/png/items/glove01.png";
import gloves01 from "../../assets/images/character_sheet/png/items/gloves01.png";
import hammer01 from "../../assets/images/character_sheet/png/items/hammer01.png";
import hammer02 from "../../assets/images/character_sheet/png/items/hammer02.png";
import helmet_blank from "../../assets/images/character_sheet/png/items/helmet_blank.png";
import helmet01 from "../../assets/images/character_sheet/png/items/helmet01.png";
import knuckles01 from "../../assets/images/character_sheet/png/items/knuckles01.png";
import lh_blank from "../../assets/images/character_sheet/png/items/lh_blank.png";
import note01 from "../../assets/images/character_sheet/png/items/note01.png";
import orb01 from "../../assets/images/character_sheet/png/items/orb01.png";
import pants_blank from "../../assets/images/character_sheet/png/items/pants_blank.png";
import pants01 from "../../assets/images/character_sheet/png/items/pants01.png";
import pistol01 from "../../assets/images/character_sheet/png/items/pistol01.png";
import potion01 from "../../assets/images/character_sheet/png/items/potion01.png";
import potion02 from "../../assets/images/character_sheet/png/items/potion02.png";
import potion03 from "../../assets/images/character_sheet/png/items/potion03.png";
import quarterstaff01 from "../../assets/images/character_sheet/png/items/quarterstaff01.png";
import rh_blank from "../../assets/images/character_sheet/png/items/rh_blank.png";
import rifle01 from "../../assets/images/character_sheet/png/items/rifle01.png";
import ring_blank from "../../assets/images/character_sheet/png/items/ring_blank.png";
import ring01 from "../../assets/images/character_sheet/png/items/ring01.png";
import ring02 from "../../assets/images/character_sheet/png/items/ring02.png";
import robe01 from "../../assets/images/character_sheet/png/items/robe01.png";
import rope01 from "../../assets/images/character_sheet/png/items/rope01.png";
import scroll01 from "../../assets/images/character_sheet/png/items/scroll01.png";
import shirt_blank from "../../assets/images/character_sheet/png/items/shirt_blank.png";
import shirt01 from "../../assets/images/character_sheet/png/items/shirt01.png";
import spear01 from "../../assets/images/character_sheet/png/items/spear01.png";
import staff01 from "../../assets/images/character_sheet/png/items/staff01.png";
import sword01 from "../../assets/images/character_sheet/png/items/sword01.png";
import sword02 from "../../assets/images/character_sheet/png/items/sword02.png";
import sword03 from "../../assets/images/character_sheet/png/items/sword03.png";
import wand01 from "../../assets/images/character_sheet/png/items/wand01.png";

export default class InventoryItemIconLibrary {
    public static image(src: string | null | undefined) {
        switch (src) {
            case "back01":
                return back01;
            case "back02":
                return back02;
            case "back03":
                return back03;
            case "back04":
                return back04;

            case "amulet_blank":
                return amulet_blank;
            case "amulet01":
                return amulet01;
            case "arrow_blank":
                return arrow_blank;
            case "arrow01":
                return arrow01;
            case "arrow02":
                return arrow02;
            case "arrow03":
                return arrow03;
            case "axe01":
                return axe01;
            case "axe02":
                return axe02;
            case "bag01":
                return bag01;
            case "belt_blank":
                return belt_blank;
            case "belt01":
                return belt01;
            case "book01":
                return book01;
            case "book02":
                return book02;
            case "boots_blank":
                return boots_blank;
            case "boots01":
                return boots01;
            case "bow01":
                return bow01;
            case "brigandine01":
                return brigandine01;
            case "bullet01":
                return bullet01;
            case "cards01":
                return cards01;
            case "chainmail01":
                return chainmail01;
            case "chest01":
                return chest01;
            case "club01":
                return club01;
            case "coin01":
                return coin01;
            case "component01":
                return component01;
            case "component02":
                return component02;
            case "component03":
                return component03;
            case "component04":
                return component04;
            case "crossbow01":
                return crossbow01;
            case "cuirass01":
                return cuirass01;
            case "dice01":
                return dice01;
            case "food01":
                return food01;
            case "food02":
                return food02;
            case "gem01":
                return gem01;
            case "gloves_blank":
                return gloves_blank;
            case "glove01":
                return glove01;
            case "gloves01":
                return gloves01;
            case "hammer01":
                return hammer01;
            case "hammer02":
                return hammer02;
            case "helmet_blank":
                return helmet_blank;
            case "helmet01":
                return helmet01;
            case "knuckles01":
                return knuckles01;
            case "lh_blank":
                return lh_blank;
            case "note01":
                return note01;
            case "orb01":
                return orb01;
            case "pants_blank":
                return pants_blank;
            case "pants01":
                return pants01;
            case "pistol01":
                return pistol01;
            case "potion01":
                return potion01;
            case "potion02":
                return potion02;
            case "potion03":
                return potion03;
            case "quarterstaff01":
                return quarterstaff01;
            case "rh_blank":
                return rh_blank;
            case "rifle01":
                return rifle01;
            case "ring_blank":
                return ring_blank;
            case "ring01":
                return ring01;
            case "ring02":
                return ring02;
            case "robe01":
                return robe01;
            case "rope01":
                return rope01;
            case "scroll01":
                return scroll01;
            case "shirt_blank":
                return shirt_blank;
            case "shirt01":
                return shirt01;
            case "spear01":
                return spear01;
            case "staff01":
                return staff01;
            case "sword01":
                return sword01;
            case "sword02":
                return sword02;
            case "sword03":
                return sword03;
            case "wand01":
                return wand01;

            default:
                return null;
        }
    }
}
