import { ProficiencyStatKey } from "./Proficiency";
import Proficiency from "./Proficiency";
import VCTagUtils from "../../sys/VCTagUtils";
import { TalentBase, TalentSubType } from "./TalentBase";
import { TalentJSON } from "./TalentJSON";

export type TalentConstructorParameters = {
    id?: string | number;
    title?: string;
    description?: string;
    subtype?: string | TalentSubType;
    proficiency?:
        | string
        | ProficiencyStatKey
        | Array<ProficiencyStatKey>
        | null;
    module?: string | null;
    /** Used for compatibility: Some values may be stored in a properties string */
    properties?: string;
};

export default class Talent extends TalentBase {
    constructor(args: TalentConstructorParameters) {
        super();

        if (args.id != null) {
            if (typeof args.id === "string") {
                const val = Number.parseInt(args.id);
                this._id = Number.isNaN(val) ? -1 : val;
            } else if (typeof args.id === "number") {
                this._id = args.id;
            }
        }
        if (args.title != null) {
            this._title = args.title;
        }
        if (args.description != null) {
            this._description = args.description;
        }
        if (args.subtype != null) {
            if (typeof args.subtype === "string") {
                this._subtype = Talent.coerceTalentSubType(args.subtype);
            } else {
                this._subtype = args.subtype;
            }
        }
        if (args.proficiency != null) {
            if (typeof args.proficiency === "string") {
                const vals = args.proficiency.includes("|")
                    ? args.proficiency.split("|")
                    : [args.proficiency];
                this._proficiency = vals.map((p) =>
                    Proficiency.coerceProficiencyStatKey(p)
                );
            } else {
                if (Array.isArray(args.proficiency)) {
                    this._proficiency = args.proficiency;
                } else {
                    this._proficiency = [args.proficiency];
                }
            }
        }

        if (args.module != null) {
            this._module = args.module;
        }

        /** Handle legacy import style */
        if (args.properties != null) {
            if (args.subtype === undefined) {
                const propSubtype = VCTagUtils.readTag(args.properties, "type");
                if (propSubtype.length > 0) {
                    this._subtype = Talent.coerceTalentSubType(propSubtype);
                }
            }

            if (args.proficiency === undefined) {
                const propProficiency = VCTagUtils.readTagNullable(
                    args.properties,
                    "proficiency"
                );

                if (propProficiency != null) {
                    const vals = propProficiency.includes("|")
                        ? propProficiency.split("|")
                        : [propProficiency];
                    this._proficiency = vals.map((p) =>
                        Proficiency.coerceProficiencyStatKey(p)
                    );
                }
            }

            if (args.module === undefined) {
                const propModule = VCTagUtils.readTag(
                    args.properties,
                    "module"
                );
                this._module = propModule.length > 0 ? propModule : null;
            }
        }
    }

    protected static argsFromVCTagString(
        input: string,
        id?: number,
        title?: string
    ): TalentConstructorParameters {
        return {
            id:
                id != null
                    ? id
                    : VCTagUtils.readTagNullable(input, "net-id") ?? "-1",
            title: title != null ? title : VCTagUtils.readTag(input, "title"),
            description: VCTagUtils.readTag(input, "description").trim(),
            subtype: VCTagUtils.readTag(input, "type"),
            proficiency: VCTagUtils.readTagNullable(input, "proficiency"),
            module: VCTagUtils.readTagNullable(input, "module"),
        };
    }

    public static fromVCTagString(
        input: string,
        id?: number,
        title?: string
    ): Talent {
        return new Talent(this.argsFromVCTagString(input, id, title));
    }

    public static fromJSON(json: TalentJSON) {
        return new Talent({
            id: json.id ?? -1,
            title: json.title ?? "",
            description: json.description ?? "",
            subtype: json.subtype ?? "",
            proficiency:
                json.proficiencies
                    ?.map((key) => Proficiency.coerceProficiencyStatKey(key))
                    .filter((key) => key !== "Unknown") ?? [],
            module: json.module ?? "",
        });
    }

    public static coerceTalentSubType(input: string): TalentSubType {
        switch (input.toLowerCase().trim()) {
            case "beneficiary":
                return "Beneficiary";
            case "knowledge":
                return "Knowledge";
            case "operative":
                return "Operative";
            case "weapon":
                return "Weapon";
            case "unknown":
            default:
                return "Unknown";
        }
    }

    public toVCTagString(): string {
        return `[net-id]${this._id}[/net-id][title]${
            this._title
        }[/title][type]${this._subtype}[/type][description]${
            this._description
        }[/description][proficiency]${
            this._proficiency
                ? this._proficiency
                      .map((p) => Proficiency.getProficiencyStatKeyLabel(p))
                      .join("|")
                : ""
        }[/proficiency][module]${this._module ?? ""}[/module]`;
    }

    public toJSON(): TalentJSON {
        return {
            description: this._description,
            id: this._id,
            module: this._module ?? undefined,
            proficiencies: this._proficiency,
            subtype: this._subtype,
            title: this._title,
        };
    }
}
