import Ability from "../utils/game/mechanics/Ability";
import School from "../utils/game/mechanics/School";
import Talent from "../utils/game/mechanics/Talent";
import Trait from "../utils/game/mechanics/Trait";
import CreateTrackableAsyncFunction from "../utils/CreateTrackableAsyncFunction";
import VCTagUtils from "../utils/sys/VCTagUtils";

const getAbilitiesURL = "https://ayaseye.com/api/mechanics/get_abilities.php";
const getSchoolsURL = "https://ayaseye.com/api/mechanics/get_schools.php";
const getTalentsURL = "https://ayaseye.com/api/mechanics/get_talents.php";
const getTraitsURL = "https://ayaseye.com/api/mechanics/get_traits.php";

async function getAbilityList(args: {
    token?: string;
}): Promise<Array<Ability>> {
    const payload = { token: args.token };

    const response = await fetch(`${getAbilitiesURL}`, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then(async (res) => {
        const resultData = await res.json();
        if (Array.isArray(resultData)) {
            return resultData.map((entry) => new Ability(entry));
        }
        return [];
    });

    return response;
}

export function useGetAbilityList() {
    return CreateTrackableAsyncFunction(getAbilityList);
}

async function getSchoolList(args: { token?: string }): Promise<Array<School>> {
    const payload = { token: args.token };

    const response = await fetch(`${getSchoolsURL}`, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then(async (res) => {
        const resultData = await res.json();
        if (Array.isArray(resultData)) {
            return resultData.map((entry) => School.fromJSON(entry));
        }
        return [];
    });

    return response;
}

export function useGetSchoolList() {
    return CreateTrackableAsyncFunction(getSchoolList);
}

async function getTalentList(args: { token?: string }): Promise<Array<Talent>> {
    const payload = { token: args.token };

    const response = await fetch(`${getTalentsURL}`, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then(async (res) => {
        const resultData = await res.json();
        if (Array.isArray(resultData)) {
            return resultData.map((entry) => Talent.fromJSON(entry));
        }
        return [];
    });

    return response;
}

export function useGetTalentList() {
    return CreateTrackableAsyncFunction(getTalentList);
}

async function getTraitList(args: { token?: string }): Promise<Array<Trait>> {
    const payload = { token: args.token };

    const response = await fetch(`${getTraitsURL}`, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then(async (res) => {
        const resultData = await res.json();
        if (Array.isArray(resultData)) {
            return resultData.map((entry) => {
                return Trait.fromJSON(entry);
            });
        }
        return [];
    });

    return response;
}

export function useGetTraitList() {
    return CreateTrackableAsyncFunction(getTraitList);
}
