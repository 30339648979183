import {
    InventoryItemType,
    InventoryItemTypeUtils,
    InventoryItemSubType,
    InventoryItemSubTypeKey,
    InventoryItemSubTypeUtils,
    InventoryItemEquipType,
    InventoryItemEquipTypeKey,
    InventoryItemEquipTypeUtils,
} from "../../sys/InventoryItemType";
import { Color } from "../../sys/Color";
import { Dictionary } from "../../sys/Dictionary";
import { ProficiencyStatKey } from "./Proficiency";
import { Whitelist } from "../../sys/Whitelist";
import { v4 as uuidv4 } from "uuid";
import { WieldStyle } from "./WieldStyle";
import { DamageType } from "./DamageType";

export default abstract class InventoryItemBase {
    protected _id: number = -1;
    public get id() {
        return this._id;
    }

    protected _localID: string = uuidv4();

    protected _title: string = "";
    public get title() {
        return this._title;
    }

    protected _unidentifiedTitle: string = "";
    public get unidentifiedTitle() {
        return this._unidentifiedTitle;
    }

    protected _type: InventoryItemType = InventoryItemType.GENERIC;
    public get type() {
        return this._type;
    }
    public get typeString(): string {
        return InventoryItemTypeUtils.getLabel(this._type);
    }

    protected _subtype: InventoryItemSubTypeKey = "generic";
    public get subtype() {
        return this._subtype;
    }
    public get subTypeString(): string {
        return InventoryItemSubTypeUtils.getLabel(this._subtype);
    }

    public get equipTypes(): InventoryItemEquipTypeKey[] {
        return InventoryItemEquipTypeUtils.fromItemSubType(this.subtype).map(
            (e) => e.key
        );
    }

    protected _permittedWieldStyles: Array<WieldStyle> = [];
    public get permittedWieldStyles() {
        return this._permittedWieldStyles;
    }
    public permitsWieldStyle = (style: WieldStyle) => {
        return this._permittedWieldStyles.includes(style);
    };

    protected _damageTypes: Array<DamageType> = [];
    public get damageTypes() {
        return this._damageTypes;
    }
    public isDamageType = (dmgType: DamageType) => {
        return this._damageTypes.includes(dmgType);
    };

    protected _modifyingProficiencies: Array<ProficiencyStatKey> = [];
    public get modifyingProficiencies() {
        return this._modifyingProficiencies;
    }

    protected _weight: string = "";
    public get weight() {
        return this._weight;
    }

    protected _value: string = "";
    public get value() {
        return this._value;
    }

    protected _dmg: string = "";
    public get dmg() {
        return this._dmg;
    }

    protected _mdmg: string = "";
    public get mdmg() {
        return this._mdmg;
    }

    protected _pow: string = "";
    public get pow() {
        return this._pow;
    }

    protected _mpow: string = "";
    public get mpow() {
        return this._mpow;
    }

    protected _rng: string = "";
    public get rng() {
        return this._rng;
    }

    protected _mrng: string = "";
    public get mrng() {
        return this._mrng;
    }

    protected _def: string = "";
    public get def() {
        return this._def;
    }

    protected _mdef: string = "";
    public get mdef() {
        return this._mdef;
    }

    protected _block: number = 0;
    public get block() {
        return this._block;
    }

    protected _unitSingularTitle: string = "";
    public get unitSingularTitle() {
        return this._unitSingularTitle;
    }

    protected _unitPluralTitle: string = "";
    public get unitPluralTitle() {
        return this._unitPluralTitle;
    }

    protected _description: string = "";
    public get description() {
        return this._description;
    }

    protected _unidentifiedDescription: string = "";
    public get unidentifiedDescription() {
        return this._unidentifiedDescription;
    }

    protected _mods: Dictionary<string, number> = new Dictionary<
        string,
        number
    >();
    public get mods(): Dictionary<string, number> {
        return this._mods;
    }

    protected _components: string = "";
    public get components() {
        return this._components;
    }

    protected _stackable: boolean = false;
    public get stackable() {
        return this._stackable;
    }

    protected _tier: number = 0;
    public get tier() {
        return this._tier;
    }

    protected _bgSrc: string = "";
    public get bgSrc() {
        return this._bgSrc;
    }

    protected _bgColor: Color | null = null;
    public get bgColor() {
        return this._bgColor;
    }

    protected _iconSrc: string = "";
    public get iconSrc() {
        return this._iconSrc;
    }

    protected _iconColor: Color | null = null;
    public get iconColor() {
        return this._iconColor;
    }

    protected _equipped: boolean = false;
    public get equipped() {
        return this._equipped;
    }

    protected _starred: boolean = false;
    public get starred() {
        return this._starred;
    }

    protected _hidden: boolean = false;
    public get hidden() {
        return this._hidden;
    }

    protected _whitelistProtected: boolean = false;
    public get whitelistProtected() {
        return this._whitelistProtected;
    }

    protected _whitelist: Whitelist = { viewers: [], editors: [] };
    public get whitelist() {
        return this._whitelist;
    }
}
