import { Dictionary } from "../../../sys/Dictionary";
import VCTagUtils from "../../../sys/VCTagUtils";
import Proficiency from "../Proficiency";
import Talent from "../Talent";
import { TalentConstructorParameters } from "../Talent";
import { CharacterTalentJSON } from "../TalentJSON";

type CharacterTalentConstructorParameters = TalentConstructorParameters & {
    score?: string | number;
};

export default class CharacterTalent extends Talent {
    private _score: number = 0;
    public get score() {
        return this._score;
    }
    public set score(val: number) {
        this._score = val;
    }

    constructor(args: CharacterTalentConstructorParameters) {
        super(args);

        if (args.score != null) {
            if (typeof args.score === "string") {
                const val = Number.parseInt(args.score);
                this._score = Number.isNaN(val) ? -1 : val;
            } else if (typeof args.score === "number") {
                this._score = args.score;
            }
        }
    }

    public static scoreToRankAndProgress(val: number): {
        rank: number;
        progress: number;
    } {
        if (val < 0) {
            return { rank: 0, progress: 0 };
        }
        const result = { rank: 0, progress: val };
        while (result.progress >= Math.min(result.rank + 1, 10)) {
            result.progress = Math.max(
                result.progress - Math.min(result.rank + 1, 10),
                0
            );
            ++result.rank;
        }

        return result;
    }

    public static rankAndProgressToScore(
        rank: number,
        progress: number = 0
    ): number {
        let result: number = 0;
        if (rank > 9) {
            result += 10 * (rank - 9);
            rank = 9;
        }
        while (rank > 0) {
            result += rank;
            --rank;
        }

        return result + progress;
    }

    public static getNextRankCost(currentRank: number): number {
        return Math.max(Math.min(currentRank + 1, 10), 1);
    }

    public get rank() {
        return CharacterTalent.scoreToRankAndProgress(this._score).rank;
    }

    public get progress() {
        return CharacterTalent.scoreToRankAndProgress(this._score).progress;
    }

    public static fromTalent(talent: Talent, score: number = 0) {
        return new CharacterTalent({
            id: talent.id,
            title: talent.title,
            description: talent.description,
            subtype: talent.subtype,
            proficiency: talent.proficiency,
            module: talent.module,
            score: score,
        });
    }

    public static override fromVCTagString(
        input: string,
        id?: number,
        title?: string
    ): CharacterTalent {
        return new CharacterTalent({
            ...Talent.argsFromVCTagString(input, id, title),
            score: this.rankAndProgressToScore(
                Number.parseInt(VCTagUtils.readTag(input, "rank")),
                Number.parseInt(VCTagUtils.readTag(input, "progress"))
            ),
        });
    }

    public static fromJSON(json: CharacterTalentJSON) {
        return new CharacterTalent({
            id: json.id ?? -1,
            title: json.title ?? "",
            description: json.description ?? "",
            subtype: json.subtype ?? "",
            proficiency:
                json.proficiencies
                    ?.map((key) => Proficiency.coerceProficiencyStatKey(key))
                    .filter((key) => key !== "Unknown") ?? [],
            module: json.module ?? "",
            score: json.score ?? 0,
        });
    }

    public override toVCTagString(): string {
        return super
            .toVCTagString()
            .concat(
                `[rank]${this.rank}[/rank][progress]${this.progress}[/progress]`
            );
    }

    public toJSON(): CharacterTalentJSON {
        let rootJSON = super.toJSON();
        return { ...rootJSON, score: this._score };
    }
}
