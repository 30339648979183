import FlexColumn from "../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../common/components/layout/Flexbox/FlexRow";

import { useState } from "react";

type ArticleProperty = {
    key: string;
    value: string;
};

type Props = {
    properties: ArticleProperty[];
    onUpdatePropertyValue?: (key: string, value: string) => void;
    onRemoveProperty?: (key: string) => void;
    onAddProperty?: (key: string) => void;
    onPropertyDataChanged?: (value: ArticleProperty[]) => void;
};

export default function ArticlePropertiesEditControl({
    properties,
    onPropertyDataChanged,
}: Props): JSX.Element {
    const [newPropertyText, setNewPropertyText] = useState<string>("");

    return (
        <FlexColumn gap={8}>
            {properties.map((property, index) => (
                <FlexRow key={`prop-row-${property.key}`} gap={8}>
                    <FlexRow width="quarter" alignment="end">
                        {property.key}
                    </FlexRow>
                    <input
                        id={`prop-value-${index}`}
                        type="text"
                        value={property.value}
                        onChange={(event) => {
                            onPropertyDataChanged?.(
                                properties.map((p) =>
                                    p.key === property.key
                                        ? {
                                              key: property.key,
                                              value: event.target.value,
                                          }
                                        : p
                                )
                            );
                        }}
                    />
                    <input
                        type="button"
                        value="X"
                        onClick={() => {
                            onPropertyDataChanged?.(
                                properties.filter(
                                    (prop) => prop.key !== property.key
                                )
                            );
                        }}
                    />
                </FlexRow>
            ))}
            <FlexRow alignment="end" width="full">
                <FlexRow alignment="center" gap={8} width="quarter">
                    <input
                        type="text"
                        value={newPropertyText}
                        onChange={(event) => {
                            setNewPropertyText(event.target.value);
                        }}
                    />
                    <input
                        type="button"
                        value="+"
                        onClick={() => {
                            onPropertyDataChanged?.([
                                ...properties,
                                { key: newPropertyText, value: "" },
                            ]);
                        }}
                        disabled={
                            newPropertyText.trim().length <= 0 ||
                            Object.keys(properties).includes(newPropertyText)
                        }
                    />
                </FlexRow>
            </FlexRow>
        </FlexColumn>
    );
}
