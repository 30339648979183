import FlexColumn from "../Flexbox/FlexColumn";
import FlexRow from "../Flexbox/FlexRow";
import ListItem from "./ListItem";

import { useCallback, useState } from "react";

import "./List_style.css";

type Props = {
    selectMode?: "none" | "single" | "multi";
    className?: string | null;
    items: Array<{ id: string; label?: string; node?: React.ReactNode }>;
    selectedIDs?: Array<string>;
    onSelectedIDsChanged?: (selection: Array<string>) => void;
};

export default function List({
    selectMode = "single",
    className,
    items,
    selectedIDs = [],
    onSelectedIDsChanged,
}: Props): JSX.Element {
    const onItemClick = useCallback(
        (id: string) => {
            switch (selectMode) {
                case "none":
                    onSelectedIDsChanged?.([]);
                    break;
                case "single":
                    if (selectedIDs.includes(id)) {
                        onSelectedIDsChanged?.([]);
                    } else {
                        onSelectedIDsChanged?.([id]);
                    }
                    break;
                case "multi":
                    if (selectedIDs.includes(id)) {
                        onSelectedIDsChanged?.(
                            selectedIDs.filter((n) => n !== id)
                        );
                    } else {
                        onSelectedIDsChanged?.([...selectedIDs, id]);
                    }
                    break;
            }
        },
        [selectMode, selectedIDs, onSelectedIDsChanged]
    );

    return (
        <div className={`list-container${className ? ` ${className}` : ``}`}>
            {items.map((entry) => {
                return entry.node === undefined ? (
                    <ListItem
                        id={entry.id}
                        key={entry.id}
                        label={entry.label}
                        selected={selectedIDs.includes(entry.id)}
                        onClick={() => {
                            onItemClick(entry.id);
                        }}
                    />
                ) : (
                    <ListItem
                        id={entry.id}
                        key={entry.id}
                        selected={selectedIDs.includes(entry.id)}
                        onClick={() => {
                            onItemClick(entry.id);
                        }}
                    >
                        {entry.node}
                    </ListItem>
                );
            })}
        </div>
    );
}
