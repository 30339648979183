export type Whitelist = Readonly<{
    viewers: Array<number>;
    editors: Array<number>;
}>;

export class WhitelistUtils {
    public static fromCSVString(input: string): Whitelist {
        const groups: Array<Array<number>> = (
            input.includes("~") ? input.split("~") : [input]
        ).map((group) =>
            (group.includes(",") ? group.split(",") : [group]).flatMap(
                (entry) => {
                    const num = Number.parseInt(entry);
                    return Number.isNaN(num) ? [] : num;
                }
            )
        );

        return {
            viewers: groups.length > 0 ? groups[0] : [],
            editors: groups.length > 1 ? groups[1] : [],
        };
    }

    public static canView(id: number, whitelist: Whitelist) {
        return whitelist.viewers.includes(id) || whitelist.editors.includes(id);
    }

    public static canEdit(id: number, whitelist: Whitelist) {
        return whitelist.editors.includes(id);
    }

    public static toCSVString(whitelist: Whitelist): string {
        return `${whitelist.viewers.join(",")}${
            whitelist.editors.length > 0
                ? `~${whitelist.editors.join(",")}`
                : ""
        }`;
    }
}
