import type { AbilityJSON } from "../mechanics/AbilityJSON";
import type { CharacterDataJSON } from "./CharacterDataJSON";
import type { InventoryItemJSON } from "../mechanics/InventoryItemJSON";
import type { SchoolJSON, CharacterSchoolJSON } from "../mechanics/SchoolJSON";
import type { TalentJSON, CharacterTalentJSON } from "../mechanics/TalentJSON";
import type { TraitJSON, CharacterTraitJSON } from "../mechanics/TraitJSON";
import type { UserTagJSON } from "../../sys/UserTagJSON";

import { Coin, CoinCount, Currency } from "../mechanics/Currency";
import { KeyValuePair } from "../../sys/KeyValuePair";
import { InferRaceFromName, Races } from "../mechanics/Races";
import { StandardCurrencies } from "../mechanics/StandardCurrencies";
import { StandardSchools } from "../mechanics/StandardSchools";
import { Whitelist, WhitelistUtils } from "../../sys/Whitelist";

import Ability from "../mechanics/Ability";
import CharacterSchool from "../mechanics/character/CharacterSchool";
import CharacterTrait from "../mechanics/character/CharacterTrait";
import CharacterTalent from "../mechanics/character/CharacterTalent";
import InventoryItem from "../mechanics/InventoryItem";
import Race from "../mechanics/Race";
import VCTagUtils from "../../sys/VCTagUtils";

import fieldStorageTypeMap from "../../../assets/json/character_data/v2/field_storage_type_map.json";

export type CharacterData = {
    id: number;
    name: string;
    raceText: string;
    race: Race | null;
    gender: string;
    height: string;
    weight: string;
    age: string;
    birth: string;
    hair: string;
    eyes: string;
    appearance: string;
    biography: string;
    languages: string;
    modules: Array<string>;
    notes: Array<string>;
    home: string;
    level: number;
    strength: number;
    agility: number;
    intelligence: number;
    fortitude: number;
    charisma: number;
    currentHP: number;
    maxHP: number;
    currentXP: number;
    maxXP: number;
    conduct: number;
    morality: number;
    fame: number;
    infamy: number;
    mpAdjust: number;
    epAdjust: number;
    exhaustion: number;
    traits: Array<CharacterTrait>;
    talentPoints: number;
    talents: Array<CharacterTalent>;
    schoolPoints: number;
    schools: Array<CharacterSchool>;
    vocations: Array<string>;
    abilities: Array<Ability>;
    auxAbilities: Array<Ability>;
    passiveAbilities: Array<Ability>;
    inventory: Array<InventoryItem>;
    ammo: InventoryItem | null;
    head: InventoryItem | null;
    neck: InventoryItem | null;
    torso: InventoryItem | null;
    legs: InventoryItem | null;
    feet: InventoryItem | null;
    waist: InventoryItem | null;
    hands: InventoryItem | null;
    lh: InventoryItem | null;
    rh: InventoryItem | null;
    wieldMode: string;
    ring1: InventoryItem | null;
    ring2: InventoryItem | null;
    ring3: InventoryItem | null;
    ring4: InventoryItem | null;
    ring5: InventoryItem | null;
    ring6: InventoryItem | null;
    wallet: Array<CoinCount>;
    creator: number;
    isWhitelistProtected: boolean;
    whitelist: Whitelist;
};

export class CharacterDataUtils {
    public static processRawCharacterData(
        data: CharacterDataJSON
    ): CharacterData {
        return {
            id: data.id ?? -1,
            name: data.name ?? "",
            raceText: data.race ?? "",
            race: data.race ? InferRaceFromName(data.race) ?? null : null,
            gender: data.gender ?? "",
            height: data.height ?? "",
            weight: data.weight ?? "",
            age: data.age ?? "",
            birth: data.birth ?? "",
            hair: data.hair ?? "",
            eyes: data.eyes ?? "",
            appearance: data.appearance ?? "",
            biography: data.biography ?? "",
            languages: data.languages ?? "",
            modules: data.modules ?? [],
            notes: [
                data.notes_0 ?? "",
                data.notes_1 ?? "",
                data.notes_2 ?? "",
                data.notes_3 ?? "",
            ],
            home: data.home ?? "",
            level: data.level ?? 0,
            strength: data.strength ?? 0,
            agility: data.agility ?? 0,
            intelligence: data.intelligence ?? 0,
            fortitude: data.fortitude ?? 0,
            charisma: data.charisma ?? 0,
            currentHP: data.hp_current ?? 0,
            maxHP: data.hp_max ?? 0,
            currentXP: data.xp_current ?? 0,
            maxXP: data.xp_next ?? 0,
            conduct: data.conduct ?? 0,
            morality: data.morality ?? 0,
            fame: data.fame ?? 0,
            infamy: data.infamy ?? 0,
            mpAdjust: data.channel_adj ?? 0,
            epAdjust: data.stamina_adj ?? 0,
            exhaustion: data.exhaust ?? 0,
            traits: data.traits?.map((t) => CharacterTrait.fromJSON(t)) ?? [],
            talentPoints: data.available_talent_points ?? 0,
            talents:
                data.talents?.map((t) => CharacterTalent.fromJSON(t)) ?? [],
            schoolPoints: data.available_school_points ?? 0,
            schools:
                data.schools?.map((s) => CharacterSchool.fromJSON(s)) ?? [],
            vocations: data.vocations ?? [],
            abilities: data.abilities?.map((a) => Ability.fromJSON(a)) ?? [],
            auxAbilities:
                data.aux_abilities?.map((a) => Ability.fromJSON(a)) ?? [],
            passiveAbilities:
                data.passive_abilities?.map((a) => Ability.fromJSON(a)) ?? [],
            inventory:
                data.inventory?.map((item) => InventoryItem.fromJSON(item)) ??
                [],
            ammo: data.ammo ? InventoryItem.fromJSON(data.ammo) : null,
            head: data.head ? InventoryItem.fromJSON(data.head) : null,
            neck: data.neck ? InventoryItem.fromJSON(data.neck) : null,
            torso: data.torso ? InventoryItem.fromJSON(data.torso) : null,
            legs: data.legs ? InventoryItem.fromJSON(data.legs) : null,
            feet: data.feet ? InventoryItem.fromJSON(data.feet) : null,
            waist: data.waist ? InventoryItem.fromJSON(data.waist) : null,
            hands: data.hands ? InventoryItem.fromJSON(data.hands) : null,
            lh: data.lh ? InventoryItem.fromJSON(data.lh) : null,
            rh: data.rh ? InventoryItem.fromJSON(data.rh) : null,
            wieldMode: data.wield_mode ? data.wield_mode.toString() : "",
            ring1: data.ring1 ? InventoryItem.fromJSON(data.ring1) : null,
            ring2: data.ring2 ? InventoryItem.fromJSON(data.ring2) : null,
            ring3: data.ring3 ? InventoryItem.fromJSON(data.ring3) : null,
            ring4: data.ring4 ? InventoryItem.fromJSON(data.ring4) : null,
            ring5: data.ring5 ? InventoryItem.fromJSON(data.ring5) : null,
            ring6: data.ring6 ? InventoryItem.fromJSON(data.ring6) : null,
            wallet:
                data.wallet == null
                    ? []
                    : data.wallet.map((entry) => {
                          const coin =
                              StandardCurrencies.coerceStandardCoinFromCoinID(
                                  entry.coin
                              );
                          return { coin, count: entry.count };
                      }),
            creator: data.creator?.id == null ? -1 : data.creator.id,
            isWhitelistProtected: data.whitelist_protected === true,
            whitelist: {
                viewers: data.whitelist?.readers ?? [],
                editors: data.whitelist?.writers ?? [],
            },
        };
    }

    public static valueArrayToRawCharacterData(
        input: Array<KeyValuePair>
    ): CharacterDataJSON {
        const output: [string, string | number][] = [];

        input.forEach((kv) => {
            const match = fieldStorageTypeMap.find((t) => t.key === kv.key);
            if (match) {
                switch (match.type) {
                    case "string":
                        output.push([kv.key, this.valueAsString(kv.value)]);
                        break;
                    case "int":
                        output.push([kv.key, this.valueAsInt(kv.value)]);
                        break;
                    case "float":
                        output.push([kv.key, this.valueAsFloat(kv.value)]);
                        break;
                    case "boolean":
                        output.push([
                            kv.key,
                            this.valueAsNumericBoolean(kv.value),
                        ]);
                        break;
                }
            }
        });

        return Object.fromEntries(output) as CharacterDataJSON;
    }

    private static valueAsString(input: any): string {
        if (input == null) {
            return "";
        } else if (typeof input === "string") {
            return input;
        } else if (typeof input === "number") {
            return input.toString();
        } else {
            return input.toString();
        }
    }

    private static valueAsInt(input: any): number {
        if (input == null) {
            return 0;
        } else if (typeof input === "string") {
            const numVal = Number.parseInt(input);
            return Number.isNaN(numVal) ? 0 : numVal;
        } else if (typeof input === "number") {
            return Math.floor(input);
        } else {
            const numVal = Number.parseInt(input.toString());
            return Number.isNaN(numVal) ? 0 : numVal;
        }
    }

    private static valueAsFloat(input: any): number {
        if (input == null) {
            return 0;
        } else if (typeof input === "string") {
            const numVal = Number.parseInt(input);
            return Number.isNaN(numVal) ? 0 : numVal;
        } else if (typeof input === "number") {
            return input;
        } else {
            const numVal = Number.parseInt(input.toString());
            return Number.isNaN(numVal) ? 0 : numVal;
        }
    }

    private static valueAsNumericBoolean(input: any): number {
        if (input == null) {
            return 0;
        } else if (typeof input === "string") {
            const numVal = Number.parseInt(input);
            return Number.isNaN(numVal) ? 0 : Math.max(Math.min(numVal, 1), 0);
        } else if (typeof input === "number") {
            return Math.max(Math.min(input, 1), 0);
        } else {
            const numVal = Number.parseInt(input.toString());
            return Number.isNaN(numVal) ? 0 : Math.max(Math.min(numVal, 1), 0);
        }
    }

    /** Returns RawCharacter data with originalData's values updated to any non-null values of newData.
     * Or, returns false if newData has no non-null values, or if its values already match the original data. */
    public static mergeIn(
        originalData: CharacterDataJSON,
        inboundData: CharacterDataJSON
    ): CharacterDataJSON | false {
        console.log("Original: ", originalData, "Inbound: ", inboundData);
        const originalEntries = Object.entries(originalData);
        const resultEntries = Object.entries(originalData);
        const inboundEntries = Object.entries(inboundData);
        let updatedNeeded: boolean = false;

        inboundEntries.forEach((kv) => {
            const key = kv[0];
            const originIndex = originalEntries.findIndex((e) => e[0] === key);

            if (originIndex != null && originIndex >= 0) {
                if (kv[1] !== originalEntries[originIndex][1]) {
                    updatedNeeded = true;
                    resultEntries[originIndex][1] = kv[1];
                }
            }
        });

        return updatedNeeded ? Object.fromEntries(resultEntries) : false;
    }

    public static getSpecializationLevels(characterData: CharacterData): Array<{
        schoolTag: string;
        schoolName: string;
        specializationLevel: number;
    }> {
        const result: Array<{
            schoolTag: string;
            schoolName: string;
            specializationLevel: number;
        }> = [];

        characterData.abilities.forEach((ability) => {
            const school = StandardSchools.find((entry) => {
                return (
                    entry.key.toLowerCase().trim() ===
                        ability.school.toLowerCase().trim() ||
                    entry.value.title.toLowerCase().trim() ===
                        ability.school.toLowerCase().trim()
                );
            });

            if (school !== undefined) {
                let index = result.findIndex((r) => r.schoolTag === school.key);
                if (index < 0) {
                    result.push({
                        schoolTag: school.key,
                        schoolName: school.value.title,
                        specializationLevel: 1,
                    });
                } else {
                    result[index].specializationLevel += 1;
                }
            }
        });

        characterData.passiveAbilities.forEach((ability) => {
            const school = StandardSchools.find((entry) => {
                return (
                    entry.key.toLowerCase().trim() ===
                        ability.school.toLowerCase().trim() ||
                    entry.value.title.toLowerCase().trim() ===
                        ability.school.toLowerCase().trim()
                );
            });

            if (school !== undefined) {
                let index = result.findIndex((r) => r.schoolTag === school.key);
                if (index < 0) {
                    result.push({
                        schoolTag: school.key,
                        schoolName: school.value.title,
                        specializationLevel: 1,
                    });
                } else {
                    result[index].specializationLevel += 1;
                }
            }
        });

        return result;
    }
}
