import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import CharacterSchool from "../../../../utils/game/mechanics/character/CharacterSchool";
import Icon from "../../../../common/components/Icon/Icon";
import SchoolIconLibrary from "../../../../utils/sys/SchoolIconLibrary";
import { useState } from "react";

import circle from "../../../../assets/images/common/svg/circle.svg";
import double_circle from "../../../../assets/images/common/svg/double_circle.svg";
import double_circle_flare_1 from "../../../../assets/images/common/svg/double_circle_flare_1.svg";
import double_circle_flare_2 from "../../../../assets/images/common/svg/double_circle_flare_2.svg";
import double_circle_flare_3 from "../../../../assets/images/common/svg/double_circle_flare_3.svg";

import "./CharacterSchoolPanel_style.css";
import TextField from "../../../../common/components/display/TextField/TextField";

type Props = {
    school: CharacterSchool;
    specializationLevel?: number;
    isEditable?: boolean;
    isEditing?: boolean;
    pendingPointSpend?: number;
    onPendingPointSpendChanged?: (prevValue: number, newValue: number) => void;
    onSchoolRemoved?: () => void;
};

export default function CharacterSchoolPanel({
    school,
    specializationLevel = 0,
    isEditable = false,
    isEditing = false,
    pendingPointSpend,
    onPendingPointSpendChanged,
    onSchoolRemoved,
}: Props): JSX.Element {
    const pendingLevelAndProgress = CharacterSchool.scoreToLevelAndProgress(
        school.score + (pendingPointSpend ?? 0)
    );

    return (
        <FlexRow className="character-school-panel-container">
            <FlexColumn width="auto" gap={8}>
                <div className="icon-container">
                    <Icon
                        className="icon"
                        src={SchoolIconLibrary.image(school.iconSrc)}
                        size={64}
                    />
                </div>
                <div className="level-container">
                    <img
                        className="level-circle"
                        src={
                            school.level >= 10
                                ? double_circle_flare_3
                                : school.level >= 8
                                ? double_circle_flare_2
                                : school.level >= 6
                                ? double_circle_flare_2
                                : school.level >= 4
                                ? double_circle_flare_1
                                : school.level >= 2
                                ? double_circle
                                : circle
                        }
                    />
                    <div className="level-value">
                        <div className="level-text">{school.level}</div>
                    </div>
                </div>
            </FlexColumn>

            <FlexColumn className="details-container" gap={4}>
                <FlexRow width="full">
                    <FlexRow>
                        <div className="title">{school.title}</div>
                    </FlexRow>
                    {isEditable && (
                        <FlexRow width="auto">
                            <button
                                onClick={(e) => {
                                    onSchoolRemoved?.();
                                    e.stopPropagation();
                                }}
                            >
                                X
                            </button>
                        </FlexRow>
                    )}
                </FlexRow>
                <FlexRow gap={8}>
                    <FlexRow gap={8} width="half">
                        <TextField type="bold" style={{ width: "auto" }}>
                            Level
                        </TextField>
                        <TextField style={{ width: "auto" }}>
                            {isEditing
                                ? pendingLevelAndProgress.level.toString()
                                : school.level.toString()}
                        </TextField>
                    </FlexRow>
                    <FlexRow gap={8} width="half">
                        <TextField type="bold" style={{ width: "auto" }}>
                            Progress
                        </TextField>
                        {isEditing && (
                            <div
                                className="school-subtract-button"
                                onClick={() => {
                                    if (pendingPointSpend === undefined) {
                                        if (school.score > 0) {
                                            onPendingPointSpendChanged?.(0, -1);
                                        }
                                    } else if (
                                        school.score + pendingPointSpend >
                                        0
                                    ) {
                                        onPendingPointSpendChanged?.(
                                            pendingPointSpend ?? 0,
                                            (pendingPointSpend ?? 0) - 1
                                        );
                                    }
                                }}
                            >
                                -
                            </div>
                        )}
                        <TextField style={{ width: "auto" }}>{`${
                            pendingLevelAndProgress.progress
                        } / ${CharacterSchool.getNextLevelCost(
                            pendingLevelAndProgress.level
                        )}`}</TextField>
                        {isEditing && (
                            <div
                                className="school-add-button"
                                onClick={() => {
                                    if (pendingPointSpend === undefined) {
                                        if (school.score < 355) {
                                            onPendingPointSpendChanged?.(0, 1);
                                        }
                                    } else if (
                                        school.score + pendingPointSpend <
                                        355
                                    ) {
                                        onPendingPointSpendChanged?.(
                                            pendingPointSpend ?? 0,
                                            (pendingPointSpend ?? 0) + 1
                                        );
                                    }
                                }}
                            >
                                +
                            </div>
                        )}
                    </FlexRow>
                </FlexRow>
                <FlexRow gap={8}>
                    <TextField type="bold" style={{ width: "auto" }}>
                        Specialization
                    </TextField>
                    <TextField style={{ width: "auto" }}>
                        {specializationLevel.toString()}
                    </TextField>
                </FlexRow>
                <div>{school.description}</div>
            </FlexColumn>
        </FlexRow>
    );
}
