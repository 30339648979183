import { KeyValuePair } from "../../../../../utils/sys/KeyValuePair";
import { CharacterData } from "../../../../../utils/game/character/CharacterData";

import FlexColumn from "../../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../../common/components/layout/Flexbox/FlexRow";
import MultiLineTextField from "../../../../../common/components/input/MultiLineTextInputToggleField/MultiLineTextInputToggleField";
import TabControl from "../../../../../common/components/layout/TabControl/TabControl";

import { useState } from "react";

type Props = {
    characterData: CharacterData;
    isEditable?: boolean;
    selectedTab?: string | null;
    onSelectedTabChanged?: (tab: string | null) => void;
    onCharacterDataValueChanged?: (val: KeyValuePair[]) => void;
};

export default function CharacterSheetNotesTab(props: Props): JSX.Element {
    const [selectedNotesTab, setSelectedNotesTab] = useState<string | null>(
        props.selectedTab ?? null
    );

    return (
        <FlexColumn>
            <TabControl
                id="notes_tab_control"
                selected={selectedNotesTab}
                onSelectedTabChange={(tab) => {
                    setSelectedNotesTab(() => {
                        props.onSelectedTabChanged?.(tab);
                        return tab;
                    });
                }}
                tabs={[
                    { id: "notes1", label: "Notes 1" },
                    { id: "notes2", label: "Notes 2" },
                    { id: "notes3", label: "Notes 3" },
                ]}
            />
            {selectedNotesTab === "notes1" && (
                <MultiLineTextField
                    placeholderText="Notes"
                    isEditable={props.isEditable}
                    onStopEditing={(val) => {
                        props.onCharacterDataValueChanged?.([
                            { key: "notes_1", value: val },
                        ]);
                    }}
                >
                    {props.characterData.notes[1]}
                </MultiLineTextField>
            )}
            {selectedNotesTab === "notes2" && (
                <MultiLineTextField
                    placeholderText="Notes"
                    isEditable={props.isEditable}
                    onStopEditing={(val) => {
                        props.onCharacterDataValueChanged?.([
                            { key: "notes_2", value: val },
                        ]);
                    }}
                >
                    {props.characterData.notes[2]}
                </MultiLineTextField>
            )}
            {selectedNotesTab === "notes3" && (
                <MultiLineTextField
                    placeholderText="Notes"
                    isEditable={props.isEditable}
                    onStopEditing={(val) => {
                        props.onCharacterDataValueChanged?.([
                            { key: "notes_3", value: val },
                        ]);
                    }}
                >
                    {props.characterData.notes[3]}
                </MultiLineTextField>
            )}
        </FlexColumn>
    );
}
