import ClickOutAlertWrapper from "../../wrappers/ClickOutAlertWrapper/ClickOutAlertWrapper";
import { useRef, useState } from "react";

import "./TextInputToggleField_style.css";

type Props = {
    label?: string | null | undefined;
    children: string;
    displayFieldStyle?: React.CSSProperties | undefined;
    editorFieldStyle?: React.CSSProperties | undefined;
    isEditable?: boolean;
    onStopEditing?: (val: string) => void;
    onEnterKeyPress?: () => void;
};

export default function TextInputToggleField(props: Props): JSX.Element {
    const isEditable = props.isEditable != null ? props.isEditable : true;
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [textValue, setTextValue] = useState<string>(props.children);

    const ref = useRef(null);

    const textRef = useRef<string>();
    textRef.current = textValue;

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            props.onEnterKeyPress?.();
        }
    };

    return (
        <div ref={ref} className="text-input-toggle-field-container">
            {props.label != null && (
                <div className="label-field">{props.label}</div>
            )}
            {isEditing ? (
                <ClickOutAlertWrapper
                    forwardRef={ref}
                    onClickOut={() => {
                        setIsEditing(false);
                        if (textRef.current && textRef.current !== textValue) {
                            props.onStopEditing?.(textRef.current);
                        }
                    }}
                >
                    <input
                        type="text"
                        className={`editor-field`}
                        style={props.editorFieldStyle}
                        value={textValue}
                        autoFocus={true}
                        onKeyDown={(event) => {
                            handleKeyDown(event);
                        }}
                        onChange={(e) => {
                            setTextValue(e.target.value);
                        }}
                    />
                </ClickOutAlertWrapper>
            ) : (
                <div
                    className={`display-field${isEditable ? " editable" : ""}${
                        textValue.length === 0 ? " empty" : ""
                    }`}
                    style={props.displayFieldStyle}
                    onClick={() => {
                        if (isEditable) {
                            setIsEditing(true);
                        }
                    }}
                >
                    {textValue}
                </div>
            )}
        </div>
    );
}
