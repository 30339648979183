import { StatKeyMap } from "../../../../utils/game/mechanics/StatKeyMap";

import CharacterTrait from "../../../../utils/game/mechanics/character/CharacterTrait";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import TagList from "../../../../common/components/display/TagList/TagList";

import "./CharacterTraitPanel_style.css";

type Props = {
    trait: CharacterTrait;
    isEditable?: boolean;
    onTraitRemoved?: () => void;
    onTraitActivationChanged?: (active: boolean) => void;
};

export default function CharacterTraitPanel(props: Props): JSX.Element {
    return (
        <FlexRow className="character-trait-panel-container">
            <div
                className={`active-indicator${
                    props.trait.active ? " active" : " inactive"
                }${props.isEditable ? " editable" : ""}`}
                onClick={() => {
                    if (props.isEditable === true) {
                        props.onTraitActivationChanged?.(!props.trait.active);
                    }
                }}
            />
            <FlexColumn className="details-container" gap={4}>
                <FlexRow width="full">
                    <FlexRow width="full">
                        <div className="title">{props.trait.title}</div>
                        <div className="type">{`${props.trait.subtype} Trait`}</div>
                    </FlexRow>
                    {props.isEditable === true && (
                        <FlexRow width="auto">
                            <button
                                onClick={(e) => {
                                    props.onTraitRemoved?.();
                                    e.stopPropagation();
                                }}
                            >
                                X
                            </button>
                        </FlexRow>
                    )}
                </FlexRow>
                <div>{props.trait.description}</div>
                {props.trait.mods.length > 0 && (
                    <TagList
                        tagEntries={props.trait.mods.toArray().map((mod) => {
                            const modName = StatKeyMap.hasKey(mod.key)
                                ? StatKeyMap.get(mod.key)
                                : mod.key;
                            const modValueText =
                                mod.value > 0
                                    ? `+${mod.value}`
                                    : `${mod.value}`;

                            return {
                                id: mod.key,
                                value: `${modName}: ${modValueText}`,
                            };
                        })}
                    />
                )}
            </FlexColumn>
        </FlexRow>
    );
}
