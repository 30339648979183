import { Rect2d } from "../../../types/Geometry";
import ImageSlicer from "../../../utilities/ImageSlicer";
import useCanvas from "../../../hooks/RenderingHooks";
import { useContext, useEffect, useMemo, useRef, useState } from "react";

type Props = {
    image: ImageBitmap | null;
    imageSubCoords?: Rect2d | null;
    className?: string;
    style?: React.CSSProperties;
};

export default function ImageRenderer(props: Props): JSX.Element {
    const clampedSubCoords = useMemo(() => {
        if (props.imageSubCoords == undefined) {
            return undefined;
        }
        if (props.image == null) {
            return undefined;
        }

        const clampedX = Math.min(
            Math.max(props.imageSubCoords.x, 0),
            props.image.width - 1
        );
        const clampedY = Math.min(
            Math.max(props.imageSubCoords.y, 0),
            props.image.height - 1
        );
        const clampedW = Math.min(
            props.imageSubCoords.w,
            props.image.width - 1 - clampedX
        );
        const clampedH = Math.min(
            props.imageSubCoords.h,
            props.image.height - 1 - clampedY
        );

        return { x: clampedX, y: clampedY, w: clampedW, h: clampedH };
    }, [props.image, props.imageSubCoords]);

    const canvasRef = useCanvas((context) => {
        if (props.image != null) {
            const cW = context.canvas.width;
            const cH = context.canvas.height;

            if (clampedSubCoords == null) {
                context.drawImage(props.image, 0, 0);
            } else {
                context.drawImage(
                    props.image,
                    clampedSubCoords.x,
                    clampedSubCoords.y,
                    clampedSubCoords.w,
                    clampedSubCoords.h,
                    0,
                    0,
                    cW,
                    cH
                );
            }
        }
    });

    return (
        <canvas
            ref={canvasRef}
            className={props.className}
            style={props.style}
        />
    );
}
