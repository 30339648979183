import { CompoundDiceType, DiceType } from "./Dice";
import { DiceUtils } from "./Dice";

export const DataInputKey = "DataInput";

export type StatAddend = {
    type: "addend";
    value: number | (() => number);
};

export type StatModifier = {
    type: "modifier";
    value: (input: number) => number;
};

export type StatDice = {
    type: "dice";
    value: CompoundDiceType;
};

export type StatFeature = StatAddend | StatModifier | StatDice;

export class StatFeatureUtils {
    public static stringify(feature: StatFeature): string {
        switch (feature.type) {
            case "addend":
                if (typeof feature.value === "number") {
                    return feature.value.toString();
                } else {
                    return feature.value().toString();
                }
            case "modifier":
                return `x => ${feature.value(1)}x`;
            case "dice":
                return DiceUtils.stringify(...feature.value);
        }
    }
}
