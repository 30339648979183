import { Dictionary } from "../../sys/Dictionary";
import KeyValueStringParser from "../../sys/KeyValueStringParser";
import VCTagUtils from "../../sys/VCTagUtils";
import { TraitBase, TraitSubType } from "./TraitBase";
import { TraitJSON } from "./TraitJSON";

export type TraitConstructorParameters = {
    id?: string | number;
    title?: string;
    description?: string;
    cost?: string | number;
    subtype?: string | TraitSubType;
    mods?: Dictionary<string, number> | string;
    module?: string | null;
    /** Used for compatibility: Some values may be stored in a properties string */
    properties?: string;
};

export default class Trait extends TraitBase {
    constructor(args: TraitConstructorParameters) {
        super();

        if (args.id != null) {
            if (typeof args.id === "string") {
                const val = Number.parseInt(args.id);
                this._id = Number.isNaN(val) ? -1 : val;
            } else if (typeof args.id === "number") {
                this._id = args.id;
            }
        }
        if (args.title != null) {
            this._title = args.title;
        }
        if (args.description != null) {
            this._description = args.description;
        }
        if (args.cost != null) {
            if (typeof args.cost === "string") {
                const val = Number.parseInt(args.cost);
                this._cost = Number.isNaN(val) ? -1 : val;
            } else if (typeof args.cost === "number") {
                this._cost = args.cost;
            }
        }
        if (args.subtype != null) {
            if (typeof args.subtype === "string") {
                this._subtype = Trait.coerceTraitSubType(args.subtype);
            } else if (typeof args.subtype === "object") {
                this._subtype = args.subtype;
            }
        }

        if (args.mods != null) {
            if (typeof args.mods === "string") {
                this._mods = args.mods
                    ? new Dictionary<string, number>(
                          KeyValueStringParser.parseKeyValueListString(
                              args.mods
                          ).flatMap((entry) => {
                              const num = Number.parseFloat(entry.value);
                              if (Number.isNaN(num)) {
                                  return [];
                              } else {
                                  return { key: entry.key, value: num };
                              }
                          })
                      )
                    : new Dictionary<string, number>();
            } else {
                this._mods = new Dictionary<string, number>(
                    args.mods.toArray()
                );
            }
        }

        if (args.module != null) {
            this._module = args.module;
        }

        /** Handle legacy import style */
        if (args.properties != null) {
            if (args.cost === undefined) {
                const propCost = VCTagUtils.readTag(args.properties, "cost");
                if (propCost.length > 0) {
                    const numVal = Number.parseInt(propCost);
                    if (!Number.isNaN(numVal)) {
                        this._cost = numVal;
                    }
                }
            }

            if (args.subtype === undefined) {
                const propSubtype = VCTagUtils.readTag(args.properties, "type");
                if (propSubtype.length > 0) {
                    this._subtype = Trait.coerceTraitSubType(propSubtype);
                }
            }

            if (args.mods === undefined) {
                const propMods = VCTagUtils.readTag(args.properties, "mods");
                if (propMods.length > 0) {
                    this._mods = new Dictionary<string, number>(
                        KeyValueStringParser.parseKeyValueListString(
                            propMods
                        ).flatMap((entry) => {
                            const num = Number.parseFloat(entry.value);
                            if (Number.isNaN(num)) {
                                return [];
                            } else {
                                return { key: entry.key, value: num };
                            }
                        })
                    );
                }
            }

            if (args.module === undefined) {
                const propModule = VCTagUtils.readTag(
                    args.properties,
                    "module"
                );
                this._module = propModule.length > 0 ? propModule : null;
            }
        }
    }

    protected static argsFromVCTagString(
        input: string,
        id?: number,
        title?: string
    ) {
        return {
            id:
                id != null
                    ? id
                    : Number.parseInt(
                          VCTagUtils.readTagNullable(input, "net-id") ?? "-1"
                      ),
            title: title != null ? title : VCTagUtils.readTag(input, "title"),
            description: VCTagUtils.readTag(input, "description").trim(),
            cost: Number.parseInt(
                VCTagUtils.readTagNullable(input, "cost") ?? "0"
            ),
            subtype: this.coerceTraitSubType(VCTagUtils.readTag(input, "type")),
            mods: VCTagUtils.readTag(input, "mods"),
            module: VCTagUtils.readTagNullable(input, "module"),
        };
    }

    public static fromVCTagString(
        input: string,
        id?: number,
        title?: string
    ): Trait {
        return new Trait(this.argsFromVCTagString(input, id, title));
    }

    public static fromJSON(json: TraitJSON) {
        const mods = new Dictionary<string, number>();
        if (json.stat_mods != null) {
            for (var key in json.stat_mods) {
                mods.set(key, Number.parseInt(json.stat_mods[key], 10));
            }
        }

        return new Trait({
            id: json.id ?? -1,
            title: json.title ?? "",
            description: json.description ?? "",
            cost: json.cost ?? 0,
            subtype: json.subtype ?? "",
            mods: mods,
            module: json.module ?? "",
        });
    }

    public static coerceTraitSubType(input: string): TraitSubType {
        switch (input.toLowerCase().trim()) {
            case "biological":
                return "Biological";
            case "learned":
                return "Learned";
            case "passive":
                return "Passive";
            case "origin":
                return "Origin";
            case "unknown":
            default:
                return "Unknown";
        }
    }

    public toVCTagString(): string {
        return `[net-id]${this._id}[/net-id][title]${
            this._title
        }[/title][type]${this._subtype}[/type][description]${
            this._description
        }[/description][cost]${this._cost}[/cost][module]${
            this._module ?? ""
        }[/module][mods]${this._mods.map(
            (m) => `${m.key}:${m.value};`
        )}[/mods]`;
    }

    public toJSON(): TraitJSON {
        return {
            cost: this._cost ?? undefined,
            description: this._description,
            id: this._id,
            module: this._module ?? undefined,
            stat_mods: this._mods.toArray().map((v) => v.value.toString()),
            subtype: this._subtype,
            title: this._title,
        };
    }
}
