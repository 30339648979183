import ActiveUserContext from "../../../utils/session/ActiveUserContext";
import Card from "../../../common/components/layout/Card/Card";
import { TimestampedArticleTagJSON } from "../../../utils/sys/TimestampedArticleTagJSON";
import Cookies from "universal-cookie";

import { useGetRecentArticleTags } from "../../../net/ArticleManagement";

import { useContext, useEffect, useState } from "react";

export default function RecentWikiActivityCard(): JSX.Element {
    const activeUser = useContext(ActiveUserContext);
    const cookies = new Cookies();

    const [loadArticle, isLoadingArticle] = useGetRecentArticleTags();
    const [articleTagList, setArticleTagList] = useState<
        Array<TimestampedArticleTagJSON>
    >([]);

    useEffect(() => {
        loadArticle({
            variables: {
                count: 35,
                token: cookies.get("session_token"),
            },
            onComplete: (data) => {
                setArticleTagList(data);
            },
            onError: (err) => {
                console.error("Failed to load recent characters.");
            },
        });
    }, [activeUser.id]);

    return (
        <Card heading="Recent Wiki Activity">
            {isLoadingArticle() ? (
                <div>Loading</div>
            ) : (
                <ul>
                    {articleTagList.map((tag) => (
                        <li>
                            <a href={`./wiki/?id=${tag.id ?? -1}`}>
                                {`${tag.title ?? "Article"}`}
                            </a>
                            {` by ${
                                tag.editor == null
                                    ? "Unknown"
                                    : tag.editor.user_name
                            } on ${tag.edit_time}`}
                        </li>
                    ))}
                </ul>
            )}
        </Card>
    );
}
