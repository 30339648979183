export enum WieldStyle {
    NONE = 0,
    ONE_HAND = 1,
    TWO_HAND = 2,
}

export class WieldStyleUtils {
    public static getValueArray(): Array<WieldStyle> {
        return [WieldStyle.NONE, WieldStyle.ONE_HAND, WieldStyle.TWO_HAND];
    }

    public static coerce(val: string | number | WieldStyle): WieldStyle {
        if (typeof val === "string") {
            switch (val.trim().toUpperCase()) {
                case "NONE":
                case "0":
                    return WieldStyle.NONE;
                case "ONE":
                case "ONE HANDED":
                case "1":
                    return WieldStyle.ONE_HAND;
                case "TWO":
                case "TWO HANDED":
                case "2":
                    return WieldStyle.TWO_HAND;
                default:
                    return WieldStyle.NONE;
            }
        } else if (typeof val === "number") {
            return Object.values(WieldStyle).includes(val)
                ? val
                : WieldStyle.NONE;
        } else {
            return val;
        }
    }

    public static getLabel(val: string | number | WieldStyle): string {
        const coercedValue =
            typeof val === "string" || typeof val === "number"
                ? this.coerce(val)
                : val;
        switch (coercedValue) {
            case WieldStyle.NONE:
                return "None";
            case WieldStyle.ONE_HAND:
                return "One Handed";
            case WieldStyle.TWO_HAND:
                return "Two Handed";
            default:
                return "Unknown";
        }
    }
}
