import { Currency, Coin } from "../../../../utils/game/mechanics/Currency";
import { StandardCurrencies } from "../../../../utils/game/mechanics/StandardCurrencies";
import { useMemo, useState } from "react";

import DropdownSelector from "../../../../common/components/input/DropdownSelector/DropdownSelector";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import NumberInputToggleField from "../../../../common/components/input/NumberInputToggleField/NumberInputToggleField";
import TextField from "../../../../common/components/display/TextField/TextField";
import TextInputToggleField from "../../../../common/components/input/TextInputToggleField/TextInputToggleField";

import plusIcon from "../../../../assets/images/common/svg/plus.svg";

import "./CharacterSheetWalletPanel_style.css";

const otherCurrenciesTag = "other";
const otherCurrenciesName = "Other Currencies";

type CoinCount = {
    coin: Coin;
    count: number;
};

type Props = Readonly<{
    coinCounts: CoinCount[];
    isEditable?: boolean;
    onCoinCountChanged?: (coinCounts: CoinCount[]) => void;
    expanded?: boolean;
    onExpandedChanged?: (val: boolean) => void;
}>;

export default function CharacterSheetWalletPanel(props: Props): JSX.Element {
    const [collapsedCurrencyIDs, setCollapsedCurrencyIDs] = useState<
        Array<string>
    >([]);

    const currencies: Array<Currency> = useMemo(() => {
        const result: Array<Currency> = [];

        props.coinCounts.forEach((coin) => {
            const currencyMatch: Currency | undefined =
                StandardCurrencies.currencies.find((c) =>
                    c.denominations.find((d) => d.id === coin.coin.id)
                );

            if (currencyMatch === undefined) {
                let otherIndex = result.findIndex((o) => o.id === "other");
                if (otherIndex < 0) {
                    result.push({
                        id: otherCurrenciesTag,
                        name: otherCurrenciesName,
                        modules: [],
                        denominations: [],
                    });
                    otherIndex = result.length - 1;
                }

                const denomination = result[otherIndex].denominations.find(
                    (d) => d.id === coin.coin.id
                );
                if (denomination === undefined) {
                    result[otherIndex].denominations.push(coin.coin);
                }
            } else {
                if (!result.includes(currencyMatch)) {
                    result.push(currencyMatch);
                }
            }
        });

        result.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

        return result;
    }, [props.coinCounts]);

    const coinValueChange = (coin: Coin, count: number) => {
        props.onCoinCountChanged?.(
            props.coinCounts.map((cc) => {
                if (cc.coin.id === coin.id) {
                    return { coin: cc.coin, count };
                } else {
                    return cc;
                }
            })
        );
    };

    const addCoinTypes = (coins: Coin[]) => {
        const updatedCoinCounts = [
            ...props.coinCounts,
            ...coins
                .filter(
                    (c) =>
                        props.coinCounts.find((cc) => cc.coin.id === c.id) ===
                        undefined
                )
                .map((c) => {
                    return { coin: c, count: 0 };
                }),
        ];
        props.onCoinCountChanged?.(updatedCoinCounts);
    };

    const removeCoinTypes = (coins: Coin[]) => {
        const updatedCoinCounts = [
            ...props.coinCounts.filter(
                (cc) => coins.find((c) => c.id === cc.coin.id) === undefined
            ),
        ];
        props.onCoinCountChanged?.(updatedCoinCounts);
    };

    const replaceCoinType = (coinType: Coin, replacementCoinType: Coin) => {
        const updatedCoinCounts = [
            ...props.coinCounts.map((cc) => {
                if (cc.coin.id === coinType.id) {
                    return { coin: replacementCoinType, count: cc.count };
                } else {
                    return cc;
                }
            }),
        ];
        props.onCoinCountChanged?.(updatedCoinCounts);
    };

    return (
        <FlexColumn width="full" className="wallet-container">
            <div
                className="wallet-header"
                onClick={(e) => {
                    props.onExpandedChanged?.(!props.expanded);
                    e.stopPropagation();
                }}
            >
                <FlexRow>
                    <TextField className="wallet-header-label" type="bold">
                        Wallet
                    </TextField>
                    {props.isEditable === true && (
                        <FlexRow alignment="end" width="auto">
                            <DropdownSelector
                                placeholder=""
                                alternateButton={
                                    <button className="add-button">
                                        <img src={plusIcon} alt="+" />
                                    </button>
                                }
                                direction="left"
                                buttonStyle={{ width: "28px" }}
                                showChevron={false}
                                options={[
                                    ...StandardCurrencies.currencies
                                        .filter((c) => !currencies.includes(c))
                                        .map((c) => {
                                            return {
                                                value: c.id,
                                                label: c.name,
                                            };
                                        }),
                                    { value: "other", label: "Other Currency" },
                                ]}
                                value={null}
                                onChange={(val) => {
                                    if (val === "other") {
                                        var i = 1;
                                        while (
                                            props.coinCounts.find(
                                                (cc) =>
                                                    cc.coin.id ===
                                                    `Other Currency ${i}`
                                            ) !== undefined
                                        ) {
                                            ++i;
                                        }
                                        addCoinTypes([
                                            {
                                                id: `Other Currency ${i}`,
                                                name: `Other Currency ${i}`,
                                                unitValue: 1,
                                                standardizedValue: 1,
                                            },
                                        ]);
                                    } else {
                                        const currencies =
                                            StandardCurrencies.currencies.find(
                                                (cc) => cc.id === val
                                            )?.denominations ?? [];
                                        if (currencies.length > 0) {
                                            addCoinTypes(currencies);
                                        }
                                    }
                                }}
                                buttonClassName=""
                            />
                        </FlexRow>
                    )}
                </FlexRow>
            </div>
            <div
                className={`currencies-wrapper${
                    props.expanded === true ? `` : ` hidden`
                }`}
            >
                {currencies.map((currency) => {
                    return (
                        <div key={currency.id} className="currency-container">
                            <FlexRow
                                className={`currency-header${
                                    collapsedCurrencyIDs.includes(currency.id)
                                        ? ` collapsed`
                                        : ``
                                }`}
                                onClick={(e) => {
                                    if (
                                        collapsedCurrencyIDs.includes(
                                            currency.id
                                        )
                                    ) {
                                        setCollapsedCurrencyIDs(
                                            collapsedCurrencyIDs.filter(
                                                (c) => c !== currency.id
                                            )
                                        );
                                    } else {
                                        setCollapsedCurrencyIDs([
                                            ...collapsedCurrencyIDs,
                                            currency.id,
                                        ]);
                                    }

                                    e?.stopPropagation();
                                }}
                            >
                                <TextField type="bold">
                                    {currency.name}
                                </TextField>
                                {props.isEditable &&
                                    currency.id !== otherCurrenciesTag && (
                                        <FlexRow alignment="end">
                                            <button
                                                onClick={(e) => {
                                                    removeCoinTypes(
                                                        currency.denominations
                                                    );
                                                    e.stopPropagation();
                                                }}
                                            >
                                                X
                                            </button>
                                        </FlexRow>
                                    )}
                            </FlexRow>
                            {!collapsedCurrencyIDs.includes(currency.id) && (
                                <div className="denominations-container">
                                    {currency.denominations.map((denom) => {
                                        return (
                                            <FlexRow
                                                key={`${currency.id}-${denom.id}`}
                                                className="denomination-row"
                                                gap={8}
                                            >
                                                {props.isEditable === true &&
                                                    currency.id ===
                                                        otherCurrenciesTag && (
                                                        <button
                                                            onClick={(e) => {
                                                                removeCoinTypes(
                                                                    [denom]
                                                                );
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            X
                                                        </button>
                                                    )}
                                                <FlexRow>
                                                    {props.isEditable ===
                                                        true &&
                                                        currency.id ===
                                                            otherCurrenciesTag && (
                                                            <TextInputToggleField
                                                                displayFieldStyle={{
                                                                    textAlign:
                                                                        "right",
                                                                }}
                                                                onStopEditing={(
                                                                    val
                                                                ) => {
                                                                    replaceCoinType(
                                                                        denom,
                                                                        {
                                                                            id: val,
                                                                            name: val,
                                                                            unitValue:
                                                                                props.coinCounts.find(
                                                                                    (
                                                                                        cc
                                                                                    ) =>
                                                                                        cc
                                                                                            .coin
                                                                                            .id ===
                                                                                        denom.id
                                                                                )
                                                                                    ?.coin
                                                                                    .unitValue ??
                                                                                1,
                                                                            standardizedValue:
                                                                                props.coinCounts.find(
                                                                                    (
                                                                                        cc
                                                                                    ) =>
                                                                                        cc
                                                                                            .coin
                                                                                            .id ===
                                                                                        denom.id
                                                                                )
                                                                                    ?.coin
                                                                                    .standardizedValue ??
                                                                                1,
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                {denom.name}
                                                            </TextInputToggleField>
                                                        )}
                                                    {props.isEditable !==
                                                        true ||
                                                        (currency.id !==
                                                            otherCurrenciesTag && (
                                                            <TextField
                                                                className={
                                                                    "denomination-name"
                                                                }
                                                                style={{
                                                                    textAlign:
                                                                        "right",
                                                                }}
                                                            >
                                                                {denom.name}
                                                            </TextField>
                                                        ))}
                                                </FlexRow>
                                                <FlexRow
                                                    alignment="end"
                                                    width="half"
                                                >
                                                    {props.isEditable ===
                                                    true ? (
                                                        <NumberInputToggleField
                                                            value={
                                                                props.coinCounts.find(
                                                                    (c) =>
                                                                        c.coin
                                                                            .id ===
                                                                        denom.id
                                                                )?.count ?? 0
                                                            }
                                                            onStopEditing={(
                                                                val
                                                            ) => {
                                                                coinValueChange(
                                                                    denom,
                                                                    val
                                                                );
                                                            }}
                                                            displayFieldStyle={{
                                                                textAlign:
                                                                    "right",
                                                            }}
                                                        />
                                                    ) : (
                                                        <TextField
                                                            className={
                                                                "denomination-plain-value"
                                                            }
                                                            style={{
                                                                width: "auto",
                                                            }}
                                                        >
                                                            {(
                                                                props.coinCounts.find(
                                                                    (c) =>
                                                                        c.coin
                                                                            .id ===
                                                                        denom.id
                                                                )?.count ?? 0
                                                            ).toString()}
                                                        </TextField>
                                                    )}
                                                </FlexRow>
                                            </FlexRow>
                                        );
                                    })}
                                    <FlexRow
                                        className="total-row"
                                        gap={16}
                                        alignment="end"
                                    >
                                        <TextField
                                            type="bold"
                                            style={{ width: "auto" }}
                                        >
                                            Total
                                        </TextField>
                                        <TextField style={{ width: "auto" }}>
                                            {currency.denominations
                                                .reduce((acc, coin) => {
                                                    const count =
                                                        props.coinCounts.find(
                                                            (c) =>
                                                                c.coin.id ===
                                                                coin.id
                                                        )?.count ?? 0;
                                                    const coinValue =
                                                        coin.unitValue * count;
                                                    return acc + coinValue;
                                                }, 0)
                                                .toString()}
                                        </TextField>
                                    </FlexRow>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </FlexColumn>
    );
}
