import Icon from "../../../common/components/Icon/Icon";
import FlexRow from "../../../common/components/layout/Flexbox/FlexRow";
import TextField from "../../../common/components/display/TextField/TextField";
import TextInput from "../../../common/components/input/TextInput/TextInput";

import { useRef, useState } from "react";

import magnifier from "../../../assets/images/common/svg/magnifier.svg";

import "./WikiSearchBar_style.css";

type Props = Readonly<{
    srcString?: string;
    onSearchStringChanged?: (val: string) => void;
    onSearchSubmitted?: (val: string) => void;
}>;

export default function WikiSearchBar({
    srcString,
    onSearchStringChanged,
    onSearchSubmitted,
}: Props): JSX.Element {
    const searchString = useRef<string>(srcString ?? "");

    return (
        <FlexRow gap={4}>
            <TextInput
                value={searchString.current}
                onChange={(val) => {
                    searchString.current = val;
                    onSearchStringChanged?.(val);
                }}
                onEnterKeyPress={() => {
                    onSearchSubmitted?.(searchString.current);
                }}
            />
            <button
                onClick={() => {
                    onSearchSubmitted?.(searchString.current);
                }}
            >
                <Icon src={magnifier} size={16} />
            </button>
        </FlexRow>
    );
}
