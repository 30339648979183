import { Dictionary } from "../../sys/Dictionary";
import {
    StatAddend,
    StatDice,
    StatFeature,
    StatModifier,
    StatFeatureUtils,
} from "./StatTracking";
import type { CharacterDataJSON } from "../character/CharacterDataJSON";
import { CompoundDiceType, DiceUtils } from "./Dice";
import { StatKeyMap } from "./StatKeyMap";

type MappedStatFeature = {
    source: string;
    qualifiers: Array<string> | null;
    feature: StatFeature;
    sourceTip?: string;
};

export default class StatBoard {
    private featureMap: Dictionary<string, Array<MappedStatFeature>>;

    constructor() {
        this.featureMap = new Dictionary();
    }

    // Features
    public addFeature(
        statKey: string,
        source: string,
        qualifiers: Array<string> | null,
        feature: StatFeature | number,
        sourceTip?: string
    ) {
        if (!this.featureMap.hasKey(statKey)) {
            this.featureMap.set(statKey, []);
        }

        const val =
            this.featureMap
                .get(statKey)
                ?.filter((entry) => entry.source !== source) ?? [];
        val.push({
            source,
            qualifiers,
            feature:
                typeof feature === "number"
                    ? { type: "addend", value: feature }
                    : feature,
            sourceTip,
        });

        this.featureMap.set(statKey, val);
    }

    public addFeatures(
        ...args: Array<{
            statKey: string;
            source: string;
            qualifiers: Array<string> | null;
            feature: StatFeature | number;
            sourceTip: string;
        }>
    ) {
        args.forEach((arg) => {
            this.addFeature(
                arg.statKey,
                arg.source,
                arg.qualifiers,
                arg.feature,
                arg.sourceTip
            );
        });
    }

    /** Removes features coming from the specified source(s). If statKeys are provided, removal will only occur on stats specified.
     * If qualifiers are provided, only features matching ALL of the specified qualifiers will be removed. */
    public removeFeaturesBySource(
        sources: Array<string>,
        statKeys?: Array<string>,
        qualifiers?: Array<string>
    ): void {
        if (sources.length < 1) {
            return;
        }

        const keys =
            statKeys == null
                ? this.featureMap.keys
                : this.featureMap.keys.filter((key) => statKeys.includes(key));

        keys.forEach((key) => {
            this.featureMap.set(
                key,
                this.featureMap.get(key)?.filter((entry) => {
                    if (sources.includes(entry.source)) {
                        if (qualifiers == null) {
                            return false;
                        } else {
                            return !qualifiers.every((q) =>
                                entry.qualifiers?.includes(q)
                            );
                        }
                    } else {
                        return true;
                    }
                }) ?? []
            );
        });
    }

    public removeFeaturesByStatKey(...statKeys: Array<string>) {
        const keys = this.featureMap.keys.filter((key) =>
            statKeys.includes(key)
        );

        keys.forEach((key) => {
            this.featureMap.set(key, []);
        });
    }

    /** Returns all the stat features for the specified statKey. All results will be returned if qualifiers and types are not provided.
     * If qualifiers is supplied, then the provided qualifiers will give access to results with matching qualifiers.
     * If types is supplied, only the specified feature types will be returned. */
    public getFeaturesByStatKey(
        statKey: string,
        qualifiers?: Array<string> | null,
        types?: Array<"addend" | "modifier" | "dice"> | null
    ): Array<StatFeature> {
        return (
            this.featureMap
                .get(statKey)
                ?.filter((entry) => {
                    return (
                        (qualifiers == null ||
                            entry.qualifiers == null ||
                            entry.qualifiers.every((q) =>
                                qualifiers.includes(q)
                            )) &&
                        (types == null || types.includes(entry.feature.type))
                    );
                })
                .map((entry) => entry.feature) ?? []
        );
    }

    public getFeaturesByStatKeyAndSource(
        statKey: string,
        source: string
    ): Array<StatFeature> {
        return (
            this.featureMap
                .get(statKey)
                ?.filter((f) => f.source === source)
                .map((f) => f.feature) ?? []
        );
    }

    /** Returns the result value for the specified statKey. All results will be resolved if qualifiers and types are not provided.
     * If qualifiers is supplied, then the provided qualifiers will give access to results with matching qualifiers.
     * If types is supplied, only the specified feature types will be resolved. */
    public getValueByStatKey(
        statKey: string,
        qualifiers?: Array<string> | null,
        types?: Array<"addend" | "modifier" | "dice"> | null
    ): string {
        const features = this.getFeaturesByStatKey(statKey, qualifiers, types);

        let numericValue = 0;
        if (types == null || types.includes("addend")) {
            numericValue = features
                .flatMap((feature) =>
                    feature.type === "addend" ? feature : []
                )
                .reduce((acc, curr) => {
                    return (
                        acc +
                        (typeof curr.value === "number"
                            ? curr.value
                            : curr.value())
                    );
                }, numericValue);
        }
        if (types == null || types.includes("modifier")) {
            numericValue = features
                .flatMap((feature) =>
                    feature.type === "modifier" ? feature : []
                )
                .reduce((acc, curr) => curr.value(acc), numericValue);
        }
        if (types == null || types.includes("dice")) {
            const dice: CompoundDiceType = features
                .flatMap((feature) => (feature.type === "dice" ? feature : []))
                .reduce((acc: CompoundDiceType, curr: StatDice) => {
                    return [...acc, ...curr.value];
                }, []);
            return DiceUtils.stringify(...dice, numericValue);
        }

        return numericValue.toString();
    }

    public getTipsByStatKey(
        statKey: string,
        qualifiers?: Array<string> | null,
        types?: Array<"addend" | "modifier" | "dice"> | null,
        appendValues?: boolean
    ): Array<string> {
        return (
            this.featureMap
                .get(statKey)
                ?.filter((entry) => {
                    return (
                        (qualifiers == null ||
                            entry.qualifiers == null ||
                            entry.qualifiers.every((q) =>
                                qualifiers.includes(q)
                            )) &&
                        (types == null || types.includes(entry.feature.type))
                    );
                })
                .flatMap((entry) =>
                    entry.sourceTip != null
                        ? `${entry.sourceTip}${
                              appendValues === true
                                  ? `: ${StatFeatureUtils.stringify(
                                        entry.feature
                                    )}`
                                  : ""
                          }`
                        : []
                ) ?? []
        );
    }

    public clear(): void {
        this.featureMap.clear();
    }
}
