import { Dictionary } from "../../sys/Dictionary";

export type TraitSubType =
    | "Biological"
    | "Learned"
    | "Passive"
    | "Origin"
    | "Unknown";

export abstract class TraitBase {
    protected _id: number = -1;
    public get id() {
        return this._id;
    }

    protected _title: string = "";
    public get title(): string {
        return this._title;
    }

    protected _description: string = "";
    public get description(): string {
        return this._description;
    }

    protected _cost: number | null = 0;
    public get cost(): number {
        return this._cost ?? 0;
    }

    protected _subtype: TraitSubType = "Unknown";
    public get subtype(): string {
        return this._subtype;
    }

    protected _mods: Dictionary<string, number> = new Dictionary<
        string,
        number
    >();
    public get mods(): Dictionary<string, number> {
        return this._mods;
    }

    protected _module: string | null = null;
    public get module(): string | null {
        return this._module;
    }
}
