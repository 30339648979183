import Attribute from "./Attribute";

import accuracy from "../../../assets/json/attributes/v2/accuracy.json";
import awareness from "../../../assets/json/attributes/v2/awareness.json";
import baseDamage from "../../../assets/json/attributes/v2/base_damage.json";
import baseDefense from "../../../assets/json/attributes/v2/base_defense.json";
import baseMagicDamage from "../../../assets/json/attributes/v2/base_magic_damage.json";
import baseMagicDefense from "../../../assets/json/attributes/v2/base_magic_defense.json";
import dodge from "../../../assets/json/attributes/v2/dodge.json";
import epAdjust from "../../../assets/json/attributes/v2/ep_adjust.json";
import magicPower from "../../../assets/json/attributes/v2/magic_power.json";
import mpAdjust from "../../../assets/json/attributes/v2/mp_adjust.json";
import power from "../../../assets/json/attributes/v2/power.json";
import regen from "../../../assets/json/attributes/v2/regen.json";
import speed from "../../../assets/json/attributes/v2/speed.json";
import stealth from "../../../assets/json/attributes/v2/stealth.json";
import survival from "../../../assets/json/attributes/v2/survival.json";
import weightAllowance from "../../../assets/json/attributes/v2/weight_allowance.json";
import willpower from "../../../assets/json/attributes/v2/willpower.json";

// export const Accuracy: Attribute
export const Accuracy: Attribute = Attribute.fromJSON(accuracy);
export const Awareness: Attribute = Attribute.fromJSON(awareness);
export const BaseDamage: Attribute = Attribute.fromJSON(baseDamage);
export const BaseDefense: Attribute = Attribute.fromJSON(baseDefense);
export const BaseMagicDamage: Attribute = Attribute.fromJSON(baseMagicDamage);
export const BaseMagicDefense: Attribute = Attribute.fromJSON(baseMagicDefense);
export const Dodge: Attribute = Attribute.fromJSON(dodge);
export const EPAdjust: Attribute = Attribute.fromJSON(epAdjust);
export const MagicPower: Attribute = Attribute.fromJSON(magicPower);
export const MPAdjust: Attribute = Attribute.fromJSON(mpAdjust);
export const Power: Attribute = Attribute.fromJSON(power);
export const Regen: Attribute = Attribute.fromJSON(regen);
export const Speed: Attribute = Attribute.fromJSON(speed);
export const Stealth: Attribute = Attribute.fromJSON(stealth);
export const Survival: Attribute = Attribute.fromJSON(survival);
export const WeightAllowance: Attribute = Attribute.fromJSON(weightAllowance);
export const Willpower: Attribute = Attribute.fromJSON(willpower);
