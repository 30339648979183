import ActiveUserContext from "../../../utils/session/ActiveUserContext";
import Card from "../../../common/components/layout/Card/Card";
import Cookies from "universal-cookie";
import {
    CharacterData,
    CharacterDataUtils,
} from "../../../utils/game/character/CharacterData";
import { useNavigate } from "react-router-dom";
import { CharacterDataJSON } from "../../../utils/game/character/CharacterDataJSON";
import FlexColumn from "../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../common/components/layout/Flexbox/FlexRow";
import CharacterProfileCard from "./CharacterProfileCard";

import { useGetRecentCharacterList } from "../../../net/CharacterManagement";

import { useContext, useEffect, useMemo, useState } from "react";

export default function RecentCharactersCard(): JSX.Element {
    const activeUser = useContext(ActiveUserContext);
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [loadRecentCharacters, isLoadingRecentCharacters] =
        useGetRecentCharacterList();
    const [recentCharacterData, setRecentCharacterData] = useState<
        Array<CharacterData>
    >([]);

    useEffect(() => {
        loadRecentCharacters({
            variables: {
                token: cookies.get("session_token"),
            },
            onComplete: (data) => {
                setRecentCharacterData(
                    data.map((entry) =>
                        CharacterDataUtils.processRawCharacterData(entry)
                    )
                );
            },
            onError: (err) => {
                console.error("Failed to load recent characters.");
            },
        });
    }, [activeUser.id]);

    return (
        <Card heading="My Recent Characters">
            {isLoadingRecentCharacters() ? (
                <div>Loading</div>
            ) : (
                recentCharacterData.map((character) => {
                    return (
                        <CharacterProfileCard
                            key={character.id}
                            character={character}
                            onClick={() => {
                                navigate(`/character/?id=${character.id}`);
                            }}
                        />
                    );
                })
            )}
        </Card>
    );
}
