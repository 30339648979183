import { Dictionary } from "../../sys/Dictionary";
import { VitalityRating } from "./VitalityRating";
import VitalityRatingUtils from "./VitalityRating";

export default class Race {
    private _key: string;
    public get key() {
        return this._key;
    }

    private _name: string;
    public get name() {
        return this._name;
    }

    private _modules: Array<string> = [];
    public get modules() {
        return this._modules;
    }

    private _hpBase: VitalityRating = VitalityRating.MEDIUM;
    public get hpBase() {
        return this._hpBase;
    }

    private _epBase: VitalityRating = VitalityRating.MEDIUM;
    public get epBase() {
        return this._epBase;
    }

    private _mpBase: VitalityRating = VitalityRating.MEDIUM;
    public get mpBase() {
        return this._mpBase;
    }

    private _raceModifiers: Dictionary<string, number> = new Dictionary<
        string,
        number
    >();
    public get raceModifiers() {
        return this._raceModifiers;
    }

    constructor(key: string, name: string) {
        this._key = key;
        this._name = name;
    }

    public static fromJSON(json: {
        key: string;
        name: string;
        modules?: string[];
        vitality?: { hp: string; ep: string; mp: string };
        features?: { key: string; value: number }[];
    }): Race {
        const race = new Race(json.key, json.name);

        race._modules = json.modules ?? [];

        race._hpBase =
            json.vitality === undefined
                ? VitalityRating.MEDIUM
                : VitalityRatingUtils.coerce(json.vitality.hp);
        race._epBase =
            json.vitality === undefined
                ? VitalityRating.MEDIUM
                : VitalityRatingUtils.coerce(json.vitality.ep);
        race._mpBase =
            json.vitality === undefined
                ? VitalityRating.MEDIUM
                : VitalityRatingUtils.coerce(json.vitality.mp);

        if (json.features !== undefined && json.features.length > 0) {
            json.features.forEach((f) =>
                race._raceModifiers.set(f.key, f.value)
            );
        }

        return race;
    }
}
