import { useState } from "react";

export default function CreateTrackableAsyncFunction<T1 = any[], T2 = any>(
    func: (() => Promise<T2>) | ((args: T1) => Promise<T2>)
): [
    (subargs: {
        variables: T1;
        onComplete?: (result: T2) => void;
        onError?: (error: any) => void;
    }) => void,
    () => boolean
] {
    const [isRunning, setIsRunning] = useState<boolean>(false);

    return [
        (subargs: {
            variables: T1;
            onComplete?: (result: T2) => void;
            onError?: (error: any) => void;
        }) => {
            setIsRunning(true);
            func(subargs.variables).then(
                (val) => {
                    subargs.onComplete?.(val);
                    setIsRunning(false);
                },
                (error) => {
                    subargs.onError?.(error);
                    setIsRunning(false);
                }
            );
        },
        () => isRunning,
    ];
}
