import damageTypesJSON from "../../../assets/json/damage_types.json";

export enum DamageType {
    BLUNT = "blunt",
    COLD = "cold",
    DARK = "dark",
    FORCE = "force",
    HEAT = "heat",
    LIGHT = "light",
    MAGICAL = "magical",
    PHYSICAL = "physical",
    PIERCING = "piercing",
    PSIONIC = "psionic",
    SLASHING = "slashing",
    SHOCK = "shock",
    UNKNOWN = "unknown",
}

export default class DamageTypeUtils {
    private static damageTypes = damageTypesJSON.map((entry) => {
        return { key: entry.value, value: entry.label };
    });

    public static getValueArray(): Array<DamageType> {
        return [
            DamageType.BLUNT,
            DamageType.COLD,
            DamageType.DARK,
            DamageType.FORCE,
            DamageType.HEAT,
            DamageType.LIGHT,
            DamageType.MAGICAL,
            DamageType.PHYSICAL,
            DamageType.PIERCING,
            DamageType.PSIONIC,
            DamageType.SLASHING,
            DamageType.SHOCK,
            DamageType.UNKNOWN,
        ];
    }

    public static coerce(val: string | DamageType): DamageType {
        if (typeof val === "string") {
            switch (val.trim().toLowerCase()) {
                case "blunt":
                    return DamageType.BLUNT;
                case "cold":
                    return DamageType.COLD;
                case "dark":
                    return DamageType.DARK;
                case "force":
                    return DamageType.FORCE;
                case "heat":
                    return DamageType.HEAT;
                case "light":
                    return DamageType.LIGHT;
                case "magical":
                    return DamageType.MAGICAL;
                case "physical":
                    return DamageType.PHYSICAL;
                case "piercing":
                    return DamageType.PIERCING;
                case "psionic":
                    return DamageType.PSIONIC;
                case "slashing":
                    return DamageType.SLASHING;
                case "shock":
                    return DamageType.SHOCK;
                case "unknown":
                default:
                    return DamageType.UNKNOWN;
            }
        } else {
            return val;
        }
    }

    public static getLabel(val: string | DamageType): string {
        const coercedValue = typeof val === "string" ? this.coerce(val) : val;

        return (
            DamageTypeUtils.damageTypes.find((t) => t.key === coercedValue)
                ?.value ?? "Unknown"
        );
    }
}
