import {
    Color,
    Gray,
    Purple,
    Yellow,
    White,
} from "../../../../utils/sys/Color";
import PositiveNegativeMeter from "../PositiveNegativeMeter/PositiveNegativeMeter";
import { useMemo } from "react";

import posNegBarNeutral from "../../../../assets/images/common/svg/pos_neg_bar_neutral.svg";

import "./AlignmentMeter_style.css";

type Props = {
    currentValue: number;
    isEditable?: boolean;
    onValueChanged?: (val: number) => void;
};

export default function AlignmentMeter(props: Props): JSX.Element {
    const fillColor = useMemo(() => {
        return props.currentValue < -40
            ? Purple
            : props.currentValue < 40
            ? White
            : Yellow;
    }, [props.currentValue]);

    return (
        <div className="alignment-meter">
            <img className="bar-frame" src={posNegBarNeutral} />
            <PositiveNegativeMeter
                minValue={-100}
                maxValue={100}
                currentValue={props.currentValue}
                fillColor={fillColor}
                isEditable={props.isEditable}
                className="fill-wrapper"
                onValueChanged={props.onValueChanged}
            />
        </div>
    );
}
