import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { Dictionary } from "./utils/sys/Dictionary";
import MainHeader from "./common/components/layout/MainHeader/MainHeader";
import Layout from "./common/Layout";
import Home from "./pages/home/Home";
import Wiki from "./pages/wiki/Wiki";
import WikiEdit from "./pages/wiki/WikiEdit";
import Character from "./pages/character/Character";
import { UserTag } from "./utils/sys/UserTag";
import NoPage from "./pages/NoPage";
import { useCallback, useEffect, useState } from "react";
import ActiveUserContext from "./utils/session/ActiveUserContext";
import IdentifiedUsersContext from "./utils/session/IdentifiedUsersContext";
import { DefaultUser, UserDetails } from "./utils/session/ActiveUserContext";
import { useResumeSession } from "./net/UserManagement";
import Cookies from "universal-cookie";

export default function App() {
    const [resumeSession, isResumingSession] = useResumeSession();
    const [activeUser, setActiveUser] = useState<UserDetails>(DefaultUser);
    const [identifiedUsers, setIdentifiedUsers] = useState<
        Dictionary<number, UserTag>
    >(new Dictionary());
    const addIdentifiedUsers = useCallback(
        (users: Array<UserTag>) => {
            const dict = identifiedUsers;
            users.forEach((user) => {
                dict.set(user.id, user);
            });
            setIdentifiedUsers(dict);
        },
        [identifiedUsers, setIdentifiedUsers]
    );

    const cookies = new Cookies();

    useEffect(() => {
        const sessionToken = cookies.get("session_token");
        if (
            sessionToken != undefined &&
            sessionToken != "null" &&
            activeUser.id <= 0
        ) {
            console.log(
                "No user data, VC Token exists, attempting to resume session..."
            );
            resumeSession({
                variables: { token: sessionToken ?? null },
                onComplete: (response) => {
                    if (response?.result === true) {
                        if ((response.user?.id ?? -1) >= 0) {
                            console.log(
                                `Resumed session as ${response.user.display_name} : ${response.user.id}.`
                            );
                            setActiveUser({
                                id: response.user.id ?? -1,
                                username: response.user.user_name ?? "",
                                displayName: response.user.display_name ?? "",
                                rank: response.user.rank ?? 0,
                            });
                        }
                    }
                },
                onError: (error) => {
                    console.error(error);
                },
            });
        } else {
            if (activeUser.id <= 0) {
                console.log("No session token found.");
            }
        }
    }, []);

    return (
        <div className="App">
            <ActiveUserContext.Provider value={activeUser}>
                <IdentifiedUsersContext.Provider
                    value={{
                        users: identifiedUsers,
                        setUsers: setIdentifiedUsers,
                        addUsers: addIdentifiedUsers,
                    }}
                >
                    <header className="App-header">
                        <MainHeader />
                    </header>
                    <BrowserRouter>
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <Layout
                                        activeUser={activeUser}
                                        onUserChange={(user) => {
                                            setActiveUser(user ?? DefaultUser);
                                        }}
                                    />
                                }
                            >
                                <Route index element={<Home />} />
                                <Route path="/wiki" element={<Wiki />} />
                                <Route
                                    path="/wiki/edit"
                                    element={<WikiEdit />}
                                />
                                <Route
                                    path="/character"
                                    element={<Character />}
                                />
                                <Route path="*" element={<NoPage />} />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                    <Outlet />
                </IdentifiedUsersContext.Provider>
            </ActiveUserContext.Provider>
        </div>
    );
}
