import TagEntry from "./TagEntry";
import { TagEntryProps } from "./TagEntry";

import "./TagList_style.css";

type Props = Readonly<{
    tagEntries: Array<TagEntryProps>;
    onTagRemoved?: (tagID: string) => void;
}>;

export default function TagList(props: Props): JSX.Element {
    return (
        <div className="tag-list-container">
            {props.tagEntries.map((entry) => {
                return (
                    <TagEntry
                        key={entry.id}
                        {...entry}
                        onRemove={() => {
                            props.onTagRemoved?.(entry.id);
                        }}
                    ></TagEntry>
                );
            })}
        </div>
    );
}
