import { useMemo, useState } from "react";
import CreateTrackableAsyncFunction from "../utils/CreateTrackableAsyncFunction";
import { UserTag } from "../utils/sys/UserTag";
import { UserTagJSON } from "../utils/sys/UserTagJSON";

export type LoginResponse = {
    result: boolean;
    message: string;
    user: UserTagJSON;
    token: {
        id: number;
        key: string;
    } | null;
};

export type ResumeResponse = {
    result: boolean;
    message: string;
    user: UserTagJSON;
    token: {
        id: number;
        key: string;
    } | null;
};

export type UserRegisterResponse = {
    result: boolean;
    message: string;
    user: {
        id: number;
        username: string;
        displayName: string;
    } | null;
};

const loginURL = "https://ayaseye.com/api/user/login.php";
const logoutURL = "https://ayaseye.com/api/user/logout.php";
const registerURL = "https://ayaseye.com/api/user/register_new_user.php";
const resumeSessionURL = "https://ayaseye.com/api/user/resume_user_session.php";
const getFriendsURL = "https://ayaseye.com/api/user/get_friends.php";
const getUserTagsURL = "https://ayaseye.com/api/user/get_users.php";

async function requestLogin(args: {
    username: string;
    pwd: string;
}): Promise<LoginResponse> {
    const payload = { username: args.username, pwd: args.pwd };

    const response = await fetch(loginURL, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then(async (res) => {
        const parsed = await res.json();
        const data = parsed["data"] ?? null;
        const user = data ? data["user"] ?? null : null;
        const token = data ? data["token"] ?? null : null;

        return {
            result: parsed["result"] ?? false,
            message: parsed["message"] ?? "",
            user: {
                id: user["id"] ?? -1,
                username: user["user_name"] ?? "",
                displayName: user["display_name"] ?? "",
                rank: user["rank"] ?? 0,
            },
            token: token
                ? {
                      id: token["id"] ?? "",
                      key: token["key"] ?? "",
                  }
                : null,
        };
    });

    return response;
}

export function useRequestLogin() {
    return CreateTrackableAsyncFunction(requestLogin);
}

async function logout(args: { token: string }) {
    const payload = { token: args.token };

    const response = await fetch(logoutURL, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then(async (res) => {
        const parsed = await res.json();
        return parsed;
    });

    return response;
}

export function useLogout() {
    return CreateTrackableAsyncFunction(logout);
}

async function requestUserRegistration(args: {
    username: string;
    pwd: string;
}): Promise<UserRegisterResponse> {
    const payload = { username: args.username, pwd: args.pwd };

    const response = await fetch(registerURL, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then(async (res) => {
        const parsed = await res.json();
        const data = parsed["data"] ?? null;
        const responseCode = parsed["response_code"] ?? null;
        const user = data ? data["user"] ?? null : null;

        return {
            result: parsed["result"] ?? false,
            message:
                responseCode === "USER_EXISTS"
                    ? "User already exists"
                    : responseCode === "INVALID_USERNAME"
                    ? "Invalid Username"
                    : responseCode === "INVALID_PASSWORD"
                    ? "Invalid Password"
                    : "Registration Failed",
            user: user
                ? {
                      id: user["id"] ?? -1,
                      username: user["user_name"] ?? "",
                      displayName: user["display_name"] ?? "",
                  }
                : null,
        };
    });

    return response;
}

export function useRequestUserRegistration() {
    return CreateTrackableAsyncFunction(requestUserRegistration);
}

async function resumeSession(args: { token: string }): Promise<ResumeResponse> {
    const payload = { token: args.token };

    const response = await fetch(resumeSessionURL, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then(async (res) => {
        const parsed = await res.json();
        const data = parsed["data"] ?? null;
        const user = data ? data["user"] ?? null : null;
        const token = data ? data["token"] ?? null : null;

        return {
            result: parsed["result"] ?? "failure",
            message: parsed["message"] ?? "",
            user: user,
            token: token
                ? {
                      id: token["id"] ?? "",
                      key: token["key"] ?? "",
                  }
                : null,
        };
    });

    return response;
}

export function useResumeSession() {
    return CreateTrackableAsyncFunction(resumeSession);
}

async function getUserFriends(args: {
    userID: number;
    token?: string;
}): Promise<Array<UserTag>> {
    const payload = { target_user_id: args.userID, token: args.token };

    const response = await fetch(getFriendsURL, {
        method: "POST",
        body: JSON.stringify(payload),
    })
        .then<UserTagJSON[]>(async (res) => {
            try {
                const parsed: Array<UserTagJSON> = await res.json();
                return parsed;
            } catch (x) {
                console.error("Invalid response");
            }
            return [];
        })
        .then<UserTag[]>(async (tags) => {
            return tags.map<UserTag>((entry) => ({
                id: entry.id ?? -1,
                username: entry.user_name ?? "",
                displayName: entry.display_name ?? "",
                rank: entry.rank ?? undefined,
            }));
        });

    return response;
}

export function useGetUserFriends() {
    return CreateTrackableAsyncFunction(getUserFriends);
}

async function getUserTags(args: {
    userIDs: number[];
}): Promise<Array<UserTag>> {
    const payload = { ids: args.userIDs };

    const response = await fetch(getUserTagsURL, {
        method: "POST",
        body: JSON.stringify(payload),
    })
        .then<UserTagJSON[]>(async (res) => {
            try {
                const parsed: Array<UserTagJSON> = await res.json();
                return parsed;
            } catch (x) {
                console.error("Invalid response");
            }
            return [];
        })
        .then<UserTag[]>(async (tags) => {
            return tags.map<UserTag>((entry) => ({
                id: entry.id ?? -1,
                username: entry.user_name ?? "",
                displayName: entry.display_name ?? "",
                rank: entry.rank ?? undefined,
            }));
        });

    return response;
}

export function useGetUserTags() {
    return CreateTrackableAsyncFunction(getUserTags);
}
