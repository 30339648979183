import { CharacterDataJSON } from "../utils/game/character/CharacterDataJSON";
import CreateTrackableAsyncFunction from "../utils/CreateTrackableAsyncFunction";

const getCharacterDataURL =
    "https://ayaseye.com/api/character/get_character.php";
const getCharacterListURL =
    "https://ayaseye.com/api/character/get_all_character_tags.php";
const getRecentCharacterListURL =
    "https://ayaseye.com/api/character/get_recent_characters.php";
const updateCharacterDataURL =
    "https://ayaseye.com/api/character/update_character.php";
const createCharacterDataURL =
    "https://ayaseye.com/api/character/create_character.php";

async function getCharacterData(args: {
    id: number;
    token?: string;
}): Promise<CharacterDataJSON | null> {
    const payload = { id: args.id, token: args.token };

    const response = await fetch(`${getCharacterDataURL}?id=${args.id}`, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then<CharacterDataJSON>(async (res) => {
        const resultData = await res.json();

        if (
            resultData["id"] !== undefined &&
            resultData["id"].toString() === args.id.toString()
        ) {
            return resultData;
        } else {
            return null;
        }
    });

    return response;
}

export function useGetCharacterData() {
    return CreateTrackableAsyncFunction(getCharacterData);
}

async function getCharacterList(args: {
    token?: string;
}): Promise<Array<{ id: string; name: string; creator: string }>> {
    const payload = { token: args.token };
    const response = await fetch(getCharacterListURL, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then(async (res) => {
        let result = await res.json();

        if (Array.isArray(result)) {
            result = result.flatMap((r) =>
                r["id"] === undefined
                    ? []
                    : {
                          id: r.id,
                          name: r["name"] ?? "",
                          creator: r["creator"] ?? "",
                          whitelistProtected: r["whitelist_protected"] ?? false,
                          whitelist: r["whitelist"] ?? "",
                      }
            );
            return result;
        }
        return [];
    });

    return response;
}

export function useGetCharacterList() {
    return CreateTrackableAsyncFunction(getCharacterList);
}

async function getRecentCharacterList(args: {
    token?: string;
}): Promise<Array<CharacterDataJSON>> {
    const payload = { token: args.token };
    const response = await fetch(getRecentCharacterListURL, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then<Array<CharacterDataJSON>>(async (res) => {
        let result = await res.json();
        return result;
    });

    return response;
}

export function useGetRecentCharacterList() {
    return CreateTrackableAsyncFunction(getRecentCharacterList);
}

async function updateCharacterData(args: {
    token?: string;
    id: number;
    data: CharacterDataJSON;
}): Promise<CharacterDataJSON | null> {
    const payload = { token: args.token, variables: args.data };

    const response = await fetch(`${updateCharacterDataURL}?id=${args.id}`, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then(async (res) => {
        const resultData = await res.json();

        if (
            resultData["id"] !== undefined &&
            resultData["id"].toString() === args.id.toString()
        ) {
            // Strip technical data from results
            const { id: _id, message: _message, ...cleanData } = resultData;
            return cleanData;
        } else {
            return null;
        }
    });

    return response;
}

export function useUpdateCharacterData() {
    return CreateTrackableAsyncFunction(updateCharacterData);
}

async function createCharacterData(args: {
    token?: string;
}): Promise<CharacterDataJSON | null> {
    const payload = { token: args.token };

    const response = await fetch(`${createCharacterDataURL}`, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then(async (res) => {
        const resultData = await res.json();

        if (resultData["id"] !== undefined) {
            const { ...cleanData } = resultData;
            return cleanData;
        } else {
            return null;
        }
    });

    return response;
}

export function useCreateCharacterData() {
    return CreateTrackableAsyncFunction(createCharacterData);
}
