import * as React from "react";
import { useMemo, useState } from "react";

import "../../../styles/main_style.css";
import "./TabControl_style.css";

type Props = Readonly<{
    id: string;
    tabs: Array<{ id: string; label: string }>;
    selected?: string | null;
    allowNoSelection?: boolean;
    onSelectedTabChange?: (newTab: string | null) => void;
}>;

export default function TabControl({
    id,
    tabs,
    selected,
    allowNoSelection,
    onSelectedTabChange,
}: Props): JSX.Element {
    const [selectedTab, setSelectedTab] = useState<string | null>(
        selected ?? (allowNoSelection && tabs.length > 0 ? tabs[0].id : null)
    );

    const tabComponents = useMemo(() => {
        return (
            <>
                {tabs.map((tab) => {
                    return (
                        <TabComponent
                            id={tab.id}
                            key={tab.id}
                            label={tab.label}
                            parentID={id}
                            isSelected={selectedTab === tab.id}
                            onClick={() => {
                                if (selectedTab === tab.id) {
                                    if (allowNoSelection) {
                                        setSelectedTab(() => {
                                            onSelectedTabChange?.(null);
                                            return null;
                                        });
                                    }
                                } else {
                                    setSelectedTab(() => {
                                        const value = tab.id;
                                        onSelectedTabChange?.(value);
                                        return value;
                                    });
                                }
                            }}
                        />
                    );
                })}
            </>
        );
    }, [id, tabs, selectedTab, allowNoSelection, onSelectedTabChange]);

    return <div className="tab-control-container">{tabComponents}</div>;
}

type TabComponentProps = Readonly<{
    id: string;
    label: string;
    parentID: string;
    isSelected: boolean;
    onClick: () => void;
}>;

function TabComponent({
    id,
    label,
    isSelected,
    parentID,
    onClick,
}: TabComponentProps): JSX.Element {
    const classes = useMemo<string>(() => {
        return "tab-component".concat(isSelected ? " selected" : "");
    }, [isSelected]);

    return (
        <div
            className={classes}
            id={"tab-component-".concat(parentID).concat("-").concat(id)}
            onClick={() => onClick()}
        >
            {label}
        </div>
    );
}
