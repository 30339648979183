import * as React from "react";
import { MutableRefObject } from "react";

import "./FlexColumn_style.css";

type Props = Readonly<{
    children?: React.ReactNode;
    width?: "full" | "half" | "quarter" | "auto" | string | number;
    style?: React.CSSProperties | undefined;
    className?: string;
    onClick?: (event?: React.MouseEvent) => void;
    onBlur?: () => void;
    gap?: 4 | 8 | 12 | 16;
    forwardRef?: MutableRefObject<any>;
}>;

export default function FlexColumn(props: Props): JSX.Element {
    const widthClass =
        props.width === "full"
            ? "full-width"
            : props.width === "half"
            ? "half-width"
            : props.width === "quarter"
            ? "quarter-width"
            : props.width === "auto"
            ? "auto-width"
            : null;

    return (
        <div
            ref={props.forwardRef}
            className={`flex-column${
                props.className != null ? ` ${props.className}` : ""
            }${props.gap != undefined ? ` gap-${props.gap}` : ``}${
                widthClass === null ? `` : ` ${widthClass}`
            }`}
            style={props.style ?? undefined}
            onClick={props?.onClick}
            onBlur={props?.onBlur}
        >
            {props.children}
        </div>
    );
}
