import School from "../School";
import { SchoolConstructorParameters } from "../School";
import VCTagUtils from "../../../sys/VCTagUtils";
import { CharacterSchoolJSON } from "../SchoolJSON";

type CharacterSchoolConstructorParameters = SchoolConstructorParameters & {
    score?: string | number;
    special?: string | number;
};

export default class CharacterSchool extends School {
    private _score: number = 0;
    public get score() {
        return this._score;
    }
    public set score(val: number) {
        this._score = val;
    }

    constructor(args: CharacterSchoolConstructorParameters) {
        super(args);

        if (args.score != null) {
            if (typeof args.score === "string") {
                const val = Number.parseInt(args.score);
                this._score = Number.isNaN(val) ? -1 : val;
            } else if (typeof args.score === "number") {
                this._score = args.score;
            }
        }
    }

    public static scoreToLevelAndProgress(val: number): {
        level: number;
        progress: number;
    } {
        if (val < 0) {
            return { level: 0, progress: 0 };
        }
        const result = { level: 0, progress: val };
        while (result.progress >= Math.min(result.level + 1, 10)) {
            result.progress = Math.max(
                result.progress - Math.min(result.level + 1, 10),
                0
            );
            ++result.level;
        }

        return result;
    }

    public static levelAndProgressToScore(
        level: number,
        progress: number = 0
    ): number {
        let result: number = 0;
        if (level > 9) {
            result += 10 * (level - 9);
            level = 9;
        }
        while (level > 0) {
            result += level;
            --level;
        }

        return result + progress;
    }

    public static getNextLevelCost(currentLevel: number): number {
        return Math.max(Math.min(currentLevel + 1, 10), 1);
    }

    public get level() {
        return CharacterSchool.scoreToLevelAndProgress(this._score).level;
    }

    public get progress() {
        return CharacterSchool.scoreToLevelAndProgress(this._score).progress;
    }

    public static fromSchool(
        school: School,
        score: number = 0,
        special: number = 0
    ) {
        return new CharacterSchool({
            id: school.id,
            title: school.title,
            description: school.description,
            tag: school.tag,
            module: school.module,
            score: score,
            special: special,
        });
    }

    public static fromVCTagString(
        input: string,
        id?: number,
        title?: string
    ): CharacterSchool {
        return new CharacterSchool({
            id:
                id != null
                    ? id
                    : Number.parseInt(
                          VCTagUtils.readTagNullable(input, "net-id") ?? "-1"
                      ),
            title: title != null ? title : VCTagUtils.readTag(input, "title"),
            description: VCTagUtils.readTag(input, "description"),
            score: this.levelAndProgressToScore(
                Number.parseInt(VCTagUtils.readTag(input, "level")),
                Number.parseInt(VCTagUtils.readTag(input, "progress"))
            ),
        });
    }

    public static fromJSON(json: CharacterSchoolJSON) {
        return new CharacterSchool({
            id: json.id ?? -1,
            title: json.title ?? "",
            description: json.description ?? "",
            tag: json.tag ?? "",
            module: json.module ?? "",
            score: json.score ?? 0,
            special: json.special ?? 0,
        });
    }

    public override toVCTagString(): string {
        const levelAndProgress = CharacterSchool.scoreToLevelAndProgress(
            this._score
        );
        return super
            .toVCTagString()
            .concat(
                `[level]${levelAndProgress.level}[/level][progress]${levelAndProgress.progress}[/progress]`
            );
    }

    public toJSON(): CharacterSchoolJSON {
        const rootJSON = super.toJSON();
        return { ...rootJSON, score: this._score, special: undefined };
    }
}
