import blankIcon from "../../assets/images/character_sheet/png/schools/blank_icon.png";

import archeryIcon from "../../assets/images/character_sheet/png/schools/archery_icon.png";
import barbarismIcon from "../../assets/images/character_sheet/png/schools/barbarism_icon.png";
import chronomancyIcon from "../../assets/images/character_sheet/png/schools/chronomancy_icon.png";
import druidismIcon from "../../assets/images/character_sheet/png/schools/druidism_icon.png";
import elementalIcon from "../../assets/images/character_sheet/png/schools/elemental_icon.png";
import illusionIcon from "../../assets/images/character_sheet/png/schools/illusion_icon.png";
import marksmanshipIcon from "../../assets/images/character_sheet/png/schools/marksmanship_icon.png";
import martialismIcon from "../../assets/images/character_sheet/png/schools/martialism_icon.png";
import pugilismIcon from "../../assets/images/character_sheet/png/schools/pugilism_icon.png";
import thaumaturgyIcon from "../../assets/images/character_sheet/png/schools/thaumaturgy_icon.png";
import thieveryIcon from "../../assets/images/character_sheet/png/schools/thievery_icon.png";
import transmutationIcon from "../../assets/images/character_sheet/png/schools/transmutation_icon.png";
import umbramancyIcon from "../../assets/images/character_sheet/png/schools/umbramancy_icon.png";
import unimancyIcon from "../../assets/images/character_sheet/png/schools/unimancy_icon.png";

export default class SchoolIconLibrary {
    public static image(src: string | null | undefined) {
        switch ((src ?? "").toLowerCase()) {
            case "archery":
            case "archery_icon":
                return archeryIcon;
            case "barbarism":
            case "barbarism_icon":
                return barbarismIcon;
            case "chronomancy":
            case "chronomancy_icon":
                return chronomancyIcon;
            case "druidism":
            case "druidism_icon":
                return druidismIcon;
            case "elemental":
            case "elementalism":
            case "elemental_icon":
                return elementalIcon;
            case "illusion":
            case "illusion_icon":
                return illusionIcon;
            case "marksmanship":
            case "marksmanship_icon":
                return marksmanshipIcon;
            case "martialism":
            case "martialism_icon":
                return martialismIcon;
            case "pugilism":
            case "pugilism_icon":
                return pugilismIcon;
            case "thaumaturgy":
            case "thaumaturgy_icon":
                return thaumaturgyIcon;
            case "thievery":
            case "thievery_icon":
                return thieveryIcon;
            case "transmutation":
            case "transmutation_icon":
                return transmutationIcon;
            case "umbramancy":
            case "umbramancy_icon":
                return umbramancyIcon;
            case "unimancy":
            case "unimancy_icon":
                return unimancyIcon;

            default:
                return blankIcon;
        }
    }
}
