import * as React from "react";
import StatBoard from "../../../../utils/game/mechanics/StatBoard";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import { StatKeyMap } from "../../../../utils/game/mechanics/StatKeyMap";
import Tooltip from "../../../../common/components/wrappers/Tooltip/Tooltip";
import { useMemo } from "react";

import "./ResistancePanel_style.css";

type Props = Readonly<{
    statBoard: StatBoard;
    statKey: string;
}>;

export default function ResistancePanel({
    statBoard,
    statKey,
}: Props): JSX.Element {
    const score: number = useMemo(() => {
        return Number.parseInt(statBoard.getValueByStatKey(statKey));
    }, [statBoard, statKey]);

    const scoreTips = statBoard.getTipsByStatKey(statKey, null, null, true);

    const stringifyScore = (val: number): string => {
        if (val <= -2) {
            return "x2";
        } else if (val === -1) {
            return "x1.5";
        } else if (val === 0) {
            return "-";
        } else if (val === 1) {
            return "1/2";
        } else {
            return "X";
        }
    };

    return (
        <FlexColumn className="resistance-panel-container">
            <div className="title">{StatKeyMap.get(statKey) ?? statKey}</div>
            <Tooltip tooltipContent={scoreTips} enabled={scoreTips.length > 0}>
                <div className="score-container">
                    <div className="score">{stringifyScore(score)}</div>
                </div>
            </Tooltip>
        </FlexColumn>
    );
}
