import { Dictionary } from "../sys/Dictionary";
import { UserTag } from "../sys/UserTag";
import { createContext } from "react";

type IdentifiedUsers = {
    users: Dictionary<number, UserTag>;
    setUsers: (vals: Dictionary<number, UserTag>) => void;
    addUsers: (this: IdentifiedUsers, users: Array<UserTag>) => void;
};

export const DefaultIdentifiedUsers = {
    users: new Dictionary<number, UserTag>(),
    setUsers: () => {},
    addUsers: function (this: IdentifiedUsers, users: Array<UserTag>) {
        users.forEach((user) => {
            this.users.set(user.id, user);
        });
    },
};

const IdentifiedUsersContext = createContext<IdentifiedUsers>(
    DefaultIdentifiedUsers
);

export default IdentifiedUsersContext;
