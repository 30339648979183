import { useEffect, useMemo, useState } from "react";
import { useGetAbilityList } from "../../../../net/CharacterFeatureManagement";
import { TableSortKey } from "../../../../common/components/layout/Table/Table";
import Cookies from "universal-cookie";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import Modal from "../../../../common/components/modals/Modal/Modal";
import Table from "../../../../common/components/layout/Table/Table";
import TextField from "../../../../common/components/display/TextField/TextField";
import TextInput from "../../../../common/components/input/TextInput/TextInput";
import Ability from "../../../../utils/game/mechanics/Ability";

import "../style/CommonModalStyle_style.css";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";

type Props = {
    isVisible?: boolean;
    abilityOptions?: Array<Ability>;
    /** Filters ability options based on external properties */
    optionFilter?: ((a: Ability) => boolean) | null;
    onAbilityOptionsChanged?: (abilities: Array<Ability>) => void;
    onClose?: () => void;
    onConfirm?: (abilities: Array<Ability>) => void;
};

export default function LoadAbilityModal(props: Props): JSX.Element {
    const [loadAbilityList, isLoadingAbilityList] = useGetAbilityList();
    const [selectedAbilityIDs, setSelectedAbilityIDs] = useState<Array<number>>(
        []
    );
    const [abilityList, setAbilityList] = useState<Array<Ability>>(
        props.abilityOptions ?? []
    );
    const [abilityListSortKey, setAbilityListSortKey] =
        useState<TableSortKey | null>(null);
    const [searchStr, setSearchStr] = useState<string>("");

    const cookies = new Cookies();

    useEffect(() => {
        if (props.abilityOptions === undefined) {
            loadAbilityList({
                variables: { token: cookies.get("session_token") },
                onComplete: (result) => {
                    setAbilityList(result);
                    props.onAbilityOptionsChanged?.(result);
                },
                onError: (error) => {},
            });
        }
    }, []);

    const handleChangeSort = (sortKey: TableSortKey | null) => {
        if (sortKey !== null) {
            setAbilityList(
                abilityList.sort((a, b) => {
                    switch (sortKey.key) {
                        case "title":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.title.localeCompare(b.title)
                                : b.title.localeCompare(a.title);
                        case "level":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.level - b.level
                                : b.level - a.level;
                        case "school":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.school.localeCompare(b.school)
                                : b.school.localeCompare(a.school);
                        case "cost":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.cost.localeCompare(b.cost)
                                : b.cost.localeCompare(a.cost);
                        case "exhaust":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.exhaustion - b.exhaustion
                                : b.exhaustion - a.exhaustion;
                        case "range":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.range.localeCompare(b.range)
                                : b.range.localeCompare(a.range);
                        case "duration":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.duration.localeCompare(b.duration)
                                : b.duration.localeCompare(a.duration);
                        case "accCheck":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? (a.accuracyCheck ? 1 : 0) -
                                      (b.accuracyCheck ? 1 : 0)
                                : (b.accuracyCheck ? 1 : 0) -
                                      (a.accuracyCheck ? 1 : 0);

                        case "description":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.description.localeCompare(b.description)
                                : b.description.localeCompare(a.description);
                        default:
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.id - b.id
                                : b.id - a.id;
                    }
                })
            );
        }

        setAbilityListSortKey(sortKey);
    };

    const tableFilter = useMemo(() => {
        const searchFilter =
            searchStr.length > 0
                ? (ability: Ability) => {
                      const match = new RegExp(searchStr, "i");
                      const result =
                          ability.title.match(match) ||
                          ability.description.match(match);

                      return (
                          (props.optionFilter == null ||
                              props.optionFilter(ability)) &&
                          result !== null
                      );
                  }
                : null;

        const filters = [props.optionFilter, searchFilter].flatMap(
            (f) => f ?? []
        );
        return filters.length > 0
            ? (ability: Ability) => filters.every((filter) => filter(ability))
            : null;
    }, [props.optionFilter, searchStr]);

    return (
        <Modal
            contentClassName="character-common-modal"
            header="Add Ability"
            isVisible={props.isVisible}
            width={1000}
            footer="standard-submit-cancel"
            onClose={props.onClose}
            onConfirm={() => {
                props.onConfirm?.(
                    abilityList.filter((ability) =>
                        selectedAbilityIDs.includes(ability.id)
                    )
                );
            }}
        >
            <FlexRow>
                <TextField type="bold" style={{ width: "auto", margin: "8px" }}>
                    Search
                </TextField>
                <TextInput
                    value={searchStr}
                    style={{ width: "240px" }}
                    onChange={setSearchStr}
                />
                <FlexRow alignment="end">
                    <TextField
                        type="bold"
                        style={{ width: "auto", margin: "8px" }}
                    >{`${selectedAbilityIDs.length} Selected`}</TextField>
                    <button
                        onClick={() => {
                            setSelectedAbilityIDs([]);
                        }}
                    >
                        Clear
                    </button>
                </FlexRow>
            </FlexRow>
            <hr />
            <Table
                className="options-list"
                columns={[
                    {
                        columnKey: "title",
                        columnHeader: "Title",
                        sortable: true,
                        width: 180,
                    },
                    {
                        columnKey: "level",
                        columnHeader: "Lvl",
                        sortable: true,
                        width: "60px",
                    },
                    {
                        columnKey: "type",
                        columnHeader: "Type",
                        sortable: true,
                        width: "90px",
                    },
                    {
                        columnKey: "school",
                        columnHeader: "School",
                        sortable: true,
                        width: "120px",
                    },
                    {
                        columnKey: "cost",
                        columnHeader: "Cost",
                        sortable: true,
                    },
                    {
                        columnKey: "exhaustion",
                        columnHeader: "Exhaust",
                        sortable: true,
                    },
                    {
                        columnKey: "range",
                        columnHeader: "Range",
                        sortable: true,
                    },
                    {
                        columnKey: "duration",
                        columnHeader: "Duration",
                        sortable: true,
                    },
                    {
                        columnKey: "accCheck",
                        columnHeader: "Acc?",
                        sortable: true,
                    },
                ]}
                rowData={abilityList}
                rowFooter={(ability) => {
                    return <TextField>{ability.description}</TextField>;
                }}
                cellParser={[
                    {
                        columnKey: "title",
                        callback: (ability) => {
                            return (
                                <TextField className="option-item">
                                    {ability.title}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "level",
                        callback: (ability) => {
                            return (
                                <TextField className="option-item">
                                    {ability.level.toString()}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "type",
                        callback: (ability) => {
                            return (
                                <TextField className="option-item">
                                    {ability.subtype}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "school",
                        callback: (ability) => {
                            return (
                                <TextField className="option-item">
                                    {ability.school}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "cost",
                        callback: (ability) => {
                            return (
                                <TextField className="option-item">
                                    {ability.cost}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "exhaustion",
                        callback: (ability) => {
                            return (
                                <TextField className="option-item">
                                    {ability.exhaustion.toString()}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "range",
                        callback: (ability) => {
                            return (
                                <TextField className="option-item">
                                    {ability.range}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "duration",
                        callback: (ability) => {
                            return (
                                <TextField className="option-item">
                                    {ability.duration}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "accCheck",
                        callback: (ability) => {
                            return (
                                <TextField className="option-item">
                                    {ability.accuracyCheck ? "Y" : "N"}
                                </TextField>
                            );
                        },
                    },
                ]}
                idGetter={(ability) => ability.id.toString()}
                sortKey={abilityListSortKey}
                onChangeSortKey={handleChangeSort}
                isRowSelected={(ability) =>
                    selectedAbilityIDs.includes(ability.id)
                }
                onRowClick={(ability) => {
                    if (selectedAbilityIDs.includes(ability.id)) {
                        setSelectedAbilityIDs([
                            ...selectedAbilityIDs.filter(
                                (entry) => entry !== ability.id
                            ),
                        ]);
                    } else {
                        setSelectedAbilityIDs([
                            ...selectedAbilityIDs,
                            ability.id,
                        ]);
                    }
                }}
                rowFilter={tableFilter}
                showHeader={true}
            />
        </Modal>
    );
}
