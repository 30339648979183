import { Dictionary } from "./Dictionary";

import accessorySubTypes from "../../assets/json/item_types/accessory_types.json";
import ammunitionSubTypes from "../../assets/json/item_types/ammunition_types.json";
import armorSubTypes from "../../assets/json/item_types/armor_types.json";
import componentSubTypes from "../../assets/json/item_types/component_types.json";
import consumableSubTypes from "../../assets/json/item_types/consumable_types.json";
import genericSubTypes from "../../assets/json/item_types/generic_types.json";
import weaponSubTypes from "../../assets/json/item_types/weapon_types.json";

import equipTypes from "../../assets/json/item_types/equip_types.json";

export enum InventoryItemType {
    GENERIC = 0,
    WEAPON = 1,
    ARMOR = 2,
    ACCESSORY = 3,
    CONSUMABLE = 4,
    AMMUNITION = 5,
    COMPONENT = 6,
}

export class InventoryItemTypeUtils {
    public static coerce(
        val: string | number | InventoryItemType
    ): InventoryItemType {
        if (typeof val === "string") {
            switch (val.trim().toUpperCase()) {
                case "GENERIC":
                case "0":
                    return InventoryItemType.GENERIC;
                case "WEAPON":
                case "1":
                    return InventoryItemType.WEAPON;
                case "ARMOR":
                case "2":
                    return InventoryItemType.ARMOR;
                case "ACCESSORY":
                case "3":
                    return InventoryItemType.ACCESSORY;
                case "CONSUMABLE":
                case "4":
                    return InventoryItemType.CONSUMABLE;
                case "AMMUNITION":
                case "5":
                    return InventoryItemType.AMMUNITION;
                case "COMPONENT":
                case "6":
                    return InventoryItemType.COMPONENT;
                default:
                    return InventoryItemType.GENERIC;
            }
        } else if (typeof val === "number") {
            return Object.values(InventoryItemType).includes(val)
                ? val
                : InventoryItemType.GENERIC;
        } else {
            return val;
        }
    }

    public static getLabel(val: string | number | InventoryItemType): string {
        const coercedValue =
            typeof val === "string" || typeof val === "number"
                ? this.coerce(val)
                : val;
        switch (coercedValue) {
            case InventoryItemType.GENERIC:
                return "Generic";
            case InventoryItemType.WEAPON:
                return "Weapon";
            case InventoryItemType.ARMOR:
                return "Armor";
            case InventoryItemType.ACCESSORY:
                return "Accessory";
            case InventoryItemType.CONSUMABLE:
                return "Consumable";
            case InventoryItemType.AMMUNITION:
                return "Ammunition";
            case InventoryItemType.COMPONENT:
                return "Component";
            default:
                return "Unknown";
        }
    }
}

export type InventoryItemSubTypeKey = string;

export type InventoryItemSubType = Readonly<{
    key: string;
    label: string;
}>;

const itemSubTypeMap: Dictionary<string, InventoryItemSubType> = new Dictionary(
    [
        ...accessorySubTypes.map((v) => {
            return { key: v.value, value: { key: v.value, label: v.label } };
        }),
        ...ammunitionSubTypes.map((v) => {
            return { key: v.value, value: { key: v.value, label: v.label } };
        }),
        ...armorSubTypes.map((v) => {
            return { key: v.value, value: { key: v.value, label: v.label } };
        }),
        ...componentSubTypes.map((v) => {
            return { key: v.value, value: { key: v.value, label: v.label } };
        }),
        ...consumableSubTypes.map((v) => {
            return { key: v.value, value: { key: v.value, label: v.label } };
        }),
        ...genericSubTypes.map((v) => {
            return { key: v.value, value: { key: v.value, label: v.label } };
        }),
        ...weaponSubTypes.map((v) => {
            return { key: v.value, value: { key: v.value, label: v.label } };
        }),
    ]
);

export class InventoryItemSubTypeUtils {
    public static coerce(
        val: string | InventoryItemSubTypeKey | InventoryItemSubType
    ): InventoryItemSubType {
        if (typeof val === "string") {
            const key = val.trim().toLowerCase();
            return (
                itemSubTypeMap.get(key) ?? { key: "unknown", label: "Unknown" }
            );
        } else {
            return val;
        }
    }

    public static getLabel(
        val: string | InventoryItemSubTypeKey | InventoryItemSubType
    ): string {
        const coercedValue = typeof val === "string" ? this.coerce(val) : val;

        return coercedValue.label;
    }
}

export type InventoryItemEquipTypeKey = string;

export type InventoryItemEquipType = Readonly<{
    key: string;
    label: string;
    itemSubTypes: Array<string>;
}>;

const itemEquipTypeMap: Dictionary<string, InventoryItemEquipType> =
    new Dictionary([
        ...equipTypes.map((v) => {
            return {
                key: v.type,
                value: {
                    key: v.type,
                    label: v.label,
                    itemSubTypes: v.valid_subtypes,
                },
            };
        }),
    ]);

export class InventoryItemEquipTypeUtils {
    public static coerce(
        val: string | InventoryItemEquipTypeKey | InventoryItemEquipType
    ): InventoryItemEquipType {
        if (typeof val === "string") {
            const key = val.trim().toLowerCase();
            return (
                itemEquipTypeMap.get(key) ?? {
                    key: "unknown",
                    label: "Unknown",
                    itemSubTypes: [],
                }
            );
        } else {
            return val;
        }
    }

    public static fromItemSubType(
        subType: string | InventoryItemSubType
    ): InventoryItemEquipType[] {
        return (
            typeof subType === "string"
                ? itemEquipTypeMap.filterEntries((_, value) =>
                      value.itemSubTypes.includes(subType)
                  )
                : itemEquipTypeMap.filterEntries((_, value) =>
                      value.itemSubTypes.includes(subType.key)
                  )
        ).map((e) => e.value);
    }

    public static getLabel(
        val: string | InventoryItemEquipTypeKey | InventoryItemEquipType
    ): string {
        const coercedValue = typeof val === "string" ? this.coerce(val) : val;
        return coercedValue.label;
    }
}
