import * as React from "react";
import { useMemo, useState } from "react";
import useWindowDimensions from "../../../utilities/WindowDimensions";

import "../../../styles/main_style.css";
import "./Modal_style.css";

export type ModalProps = Readonly<{
    isVisible?: boolean;
    useDefaultStyle?: boolean;
    width?: number | null;
    height?: number | null;
    showCloseButton?: boolean;
    header?: string | React.ReactNode;
    footer?: string | React.ReactNode;
    onClose?: () => void;
    onConfirm?: (response?: any) => void;
    contentClassName?: string;
}>;

type Props = Readonly<ModalProps & { children: React.ReactNode }>;

export default function Modal(props: Props): JSX.Element {
    const [modalWidth, setModalWidth] = useState<number | null>(
        props.width ?? null
    );
    const [modalHeight, setModalHeight] = useState<number | null>(
        props.height ?? null
    );
    const [useDefaultStyle, setUseDefaultStyle] = useState<boolean>(
        props.useDefaultStyle == null ? true : props.useDefaultStyle
    );
    const [showCloseButton, setShowCloseButton] = useState<boolean>(
        props.showCloseButton == null ? true : props.showCloseButton
    );

    const modalStyle = useMemo<React.CSSProperties>(() => {
        return { width: modalWidth ?? "auto", height: modalHeight ?? "auto" };
    }, [modalWidth, modalHeight]);

    const footer: React.ReactNode = (() => {
        if (props.footer === null || props.footer === undefined) {
            return null;
        } else if (typeof props.footer === "string") {
            switch (props.footer) {
                case "standard-ok":
                    return <ModalOKFooter onConfirm={props.onConfirm} />;
                case "standard-ok-cancel":
                    return (
                        <ModalOKCancelFooter
                            onClose={props.onClose}
                            onConfirm={props.onConfirm}
                        />
                    );
                case "standard-submit-cancel":
                    return (
                        <ModalSubmitCancelFooter
                            onClose={props.onClose}
                            onConfirm={props.onConfirm}
                        />
                    );
                default:
                    return <div>{props.footer}</div>;
            }
        } else {
            return props.footer;
        }
    })();

    return (
        <>
            {props.isVisible === true && (
                <div
                    className={"modal-top-container".concat(
                        useDefaultStyle ? " default-style" : ""
                    )}
                    style={modalStyle}
                >
                    <div className="flex-row">
                        {props.header != null &&
                            (typeof props.header === "string" ? (
                                <h1>{props.header}</h1>
                            ) : (
                                props.header
                            ))}
                        {showCloseButton && (
                            <div
                                className="flex-row flex-end"
                                style={{ flex: 0 }}
                            >
                                <div
                                    className="x-button"
                                    onClick={() => {
                                        props.onClose?.();
                                    }}
                                >
                                    X
                                </div>
                            </div>
                        )}
                    </div>
                    <hr />
                    <div className={props.contentClassName}>
                        {props.children}
                    </div>
                    {footer !== null && <hr />}
                    {footer}
                </div>
            )}
        </>
    );
}

function ModalOKFooter({ onConfirm }: { onConfirm?: () => void }): JSX.Element {
    return (
        <div className="flex-row flex-end">
            <input
                type="button"
                className="confirm-button"
                value="OK"
                onClick={() => {
                    onConfirm?.();
                }}
            />
        </div>
    );
}

function ModalOKCancelFooter({
    onClose,
    onConfirm,
}: {
    onClose?: () => void;
    onConfirm?: () => void;
}): JSX.Element {
    return (
        <div className="flex-row flex-end">
            <input
                type="button"
                className="cancel-button"
                value="Cancel"
                onClick={() => {
                    onClose?.();
                }}
                style={{ marginRight: "8px" }}
            />
            <input
                type="button"
                className="confirm-button"
                value="OK"
                onClick={() => {
                    onConfirm?.();
                }}
            />
        </div>
    );
}

function ModalSubmitCancelFooter({
    onClose,
    onConfirm,
}: {
    onClose?: () => void;
    onConfirm?: () => void;
}): JSX.Element {
    return (
        <div className="flex-row flex-end">
            <input
                type="button"
                className="cancel-button"
                value="Cancel"
                onClick={() => {
                    onClose?.();
                }}
                style={{ marginRight: "8px" }}
            />
            <input
                type="button"
                className="confirm-button"
                value="Submit"
                onClick={() => {
                    onConfirm?.();
                }}
            />
        </div>
    );
}
