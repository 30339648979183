import "./ListMenu_style.css";

type Props = {
    label: string;
    onClick?: () => void;
};

export default function ListMenuItem(props: Props): JSX.Element {
    return (
        <div
            className="list-menu-item"
            onClick={() => {
                props.onClick?.();
            }}
        >
            {props.label}
        </div>
    );
}
