import * as React from "react";

import "../../../styles/main_style.css";

type Props = Readonly<{
    children: React.ReactNode;
    noPadding?: boolean;
}>;

export default function PageFramework({
    children,
    noPadding,
}: Props): JSX.Element {
    return (
        <div id="page-container">
            <div id="left-margin" />
            <div
                id="content-container"
                className={`${noPadding !== true ? "use-padding" : ""}`}
            >
                {children}
            </div>
            <div id="right-margin" />
        </div>
    );
}
