export default class VCTagUtils {
    public static readTagNullable(input: string, tag: string): string | null {
        const patt: RegExp = new RegExp(
            "(\\[" + tag + "\\]|\\[/" + tag + "\\])",
            "g"
        );
        var count: number = 0;
        var dex: number = 0;
        var result: string | null = null;
        var test: RegExpExecArray | null;
        while ((test = patt.exec(input)) !== null) {
            if (test[0] === "[" + tag + "]") {
                if (count === 0) {
                    dex = patt.lastIndex;
                }
                ++count;
            } else if (test[0] === "[/" + tag + "]") {
                --count;
                if (count === 0) {
                    result = input.substring(dex, test.index);
                    break;
                }
            }
        }
        return result;
    }

    public static readTag(
        input: string,
        tag: string,
        fallback: string = ""
    ): string {
        return this.readTagNullable(input, tag) ?? fallback;
    }

    public static readTagAsNumber(
        input: string,
        tag: string,
        fallback: number = 0
    ): number {
        const strVal = this.readTagNullable(input, tag);
        if (strVal === null) {
            return fallback;
        } else {
            const parsed = Number.parseFloat(strVal);
            return Number.isNaN(parsed) ? fallback : parsed;
        }
    }

    public static readTagAsBoolean(
        input: string,
        tag: string,
        fallback: boolean = false
    ) {
        const strVal = this.readTagNullable(input, tag)?.toLowerCase().trim();
        if (strVal === null) {
            return fallback;
        } else {
            return (
                strVal === "1" ||
                strVal === "t" ||
                strVal === "true" ||
                strVal === "yes" ||
                strVal === "y"
            );
        }
    }

    public static setTag(input: string, tag: string, val: string): string {
        const patt = new RegExp("(\\[" + tag + "\\]|\\[/" + tag + "\\])", "g");
        var count: number = 0;
        var dex: number = 0;
        var result: string = input;
        var test: RegExpExecArray | null;
        while ((test = patt.exec(input)) !== null) {
            if (test[0] === "[" + tag + "]") {
                if (count === 0) {
                    dex = patt.lastIndex;
                }
                ++count;
            } else if (test[0] === "[/" + tag + "]") {
                --count;
                if (count === 0) {
                    result =
                        input.substring(0, dex) +
                        val +
                        input.substring(test.index);
                    break;
                }
            }
        }
        return result;
    }

    public static removeTag(input: string, tag: string): string {
        const patt = new RegExp("(\\[" + tag + "\\]|\\[/" + tag + "\\])", "g");
        var count: number = 0;
        var dex: number = 0;
        var result: string = input;
        var test: RegExpExecArray | null;
        while ((test = patt.exec(input)) !== null) {
            if (test[0] === "[" + tag + "]") {
                if (count === 0) {
                    dex = patt.lastIndex;
                }
                ++count;
            } else if (test[0] === "[/" + tag + "]") {
                --count;
                if (count === 0) {
                    result =
                        input.substring(0, dex - tag.length - 2) +
                        input.substring(test.index + tag.length + 3);
                    break;
                }
            }
        }
        return result;
    }

    public static readTagMulti(input: string, tag: string): Array<string> {
        const patt = new RegExp("(\\[" + tag + "\\]|\\[/" + tag + "\\])", "g");
        var count: number = 0;
        var dex: number = 0;
        var result: Array<string> = [];
        var test: RegExpExecArray | null;
        while ((test = patt.exec(input)) !== null) {
            if (test[0] === "[" + tag + "]") {
                if (count === 0) {
                    dex = patt.lastIndex;
                }
                ++count;
            } else if (test[0] === "[/" + tag + "]") {
                --count;
                if (count === 0) {
                    result.push(input.substring(dex, test.index));
                }
            }
        }
        return result;
    }

    public static findTags(input: string): Array<string> {
        var matcher: RegExp = /(\[)([^/]+)(\])/g;
        var match: RegExpExecArray | null;
        var tags: Array<string> = [];
        while ((match = matcher.exec(input))) tags.push(match[2]);

        return tags;
    }
}
