import type { UserBase } from "../sys/UserTag";
import { createContext } from "react";

/**
 * User type for containing extensive user data.
 */
export type UserDetails = UserBase &
    Readonly<{
        rank: number;
    }>;

export const DefaultUser = {
    id: -1,
    username: "Guest",
    displayName: "Guest",
    rank: 0,
};

const ActiveUserContext = createContext<UserDetails>(DefaultUser);

export default ActiveUserContext;
