import { CharacterData } from "../../../../../utils/game/character/CharacterData";

import FlexColumn from "../../../../../common/components/layout/Flexbox/FlexColumn";
import { KeyValuePair } from "../../../../../utils/sys/KeyValuePair";
import CharacterSheetTalentsPanel from "../../CharacterSheetTalentsPanel/CharacterSheetTalentsPanel";
import StatBoard from "../../../../../utils/game/mechanics/StatBoard";

type Props = {
    characterData: CharacterData;
    statBoard: StatBoard;
    isEditable?: boolean;
    isEditing?: boolean;
    onEditingChanged?: (editing: boolean) => void;
    pointSpendEnabled?: boolean;
    onPointSpendEnabledChanged?: (val: boolean) => void;
    pendingTalentPointSpend?: Array<{ id: number; points: number }>;
    onPendingTalentPointSpendChanged?: (
        vals: Array<{ id: number; points: number }>
    ) => void;
    onCharacterDataValueChanged?: (val: KeyValuePair[]) => void;
};

export default function CharacterSheetTalentsTab(props: Props): JSX.Element {
    return (
        <FlexColumn>
            <CharacterSheetTalentsPanel
                characterData={props.characterData}
                statBoard={props.statBoard}
                isEditable={props.isEditable}
                isEditing={props.isEditing}
                onEditingChanged={props.onEditingChanged}
                pendingTalentPointSpend={props.pendingTalentPointSpend}
                onPendingTalentPointSpendChanged={
                    props.onPendingTalentPointSpendChanged
                }
                onCharacterDataValueChanged={props.onCharacterDataValueChanged}
                pointSpendEnabled={props.pointSpendEnabled}
                onPointSpendEnabledChanged={props.onPointSpendEnabledChanged}
            />
        </FlexColumn>
    );
}
