import { useCallback, useRef, useState } from "react";
import MouseMoveAlertWrapper from "../MouseMoveAlertWrapper/MouseMoveAlertWrapper";
import MouseOverAlertWrapper from "../MouseOverAlertWrapper/MouseOverAlertWrapper";

import "./Tooltip_style.css";

type Props = {
    /** Components for which the tooltip will appear */
    children: React.ReactNode;
    /** Either a string or number that will be pre-formatted into a tooltip, or the component that will be rendered as the tooltip */
    tooltipContent: number | string | Array<number | string> | React.ReactNode;
    /** Time, in milliseconds, to wait before showing the tooltip, default 500. */
    delay?: number;
    /** Direction from the wrapped element the tooltip should appear */
    direction?:
        | "above"
        | "above-right"
        | "right"
        | "below-right"
        | "below"
        | "below-left"
        | "left"
        | "above-left"
        | "auto-above-below"
        | "auto-left-right";
    /** Offset, in pixels, from which to offset the tooltip from the mouse position on the x-axis, default 5. */
    offsetX?: number;
    /** Offset, in pixels, from which to offset the tooltip from the mouse position on the y-axis, default 5. */
    offsetY?: number;
    /** Determines if the default background should be rendered */
    showBackground?: boolean;
    /** Determines if the tooltip should be drawn with a drop shadow or not. */
    showShadow?: boolean;
    /** If false, tooltip will never appear */
    enabled?: boolean;
};

export default function Tooltip(props: Props): JSX.Element {
    const enabled = props.enabled === undefined ? true : props.enabled;
    const [isVisible, setVisibility] = useState<boolean>(false);
    const [tooltipPos, setTooltipPos] = useState<{
        x: number;
        y: number;
    } | null>(null);
    const [tooltipTranslation, setTooltipTranslation] = useState<{
        x: string;
        y: string;
    } | null>(null);

    const ref = useRef<HTMLDivElement>(null);

    const offset = { x: props.offsetX ?? 5, y: props.offsetY ?? 5 };
    const showBackground = props.showBackground ?? true;
    const showShadow = props.showShadow ?? true;

    const node =
        typeof props.tooltipContent === "string" ||
        typeof props.tooltipContent === "number" ? (
            <div>{props.tooltipContent}</div>
        ) : Array.isArray(props.tooltipContent) ? (
            <div>
                {props.tooltipContent.map((entry, index) => (
                    <div key={index}>{entry}</div>
                ))}
            </div>
        ) : (
            props.tooltipContent
        );

    const positionTooltip = useCallback(() => {
        const windowSize = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
        var rect =
            ref.current?.getBoundingClientRect() ?? new DOMRect(0, 0, 0, 0);
        if (ref.current != null) {
            var offsetParent = ref.current.offsetParent;
            if (offsetParent !== null) {
                const parentRect = offsetParent.getBoundingClientRect();
                rect = new DOMRect(
                    rect.x - parentRect.x + offsetParent.scrollLeft,
                    rect.y - parentRect.y + offsetParent.scrollTop,
                    rect.width,
                    rect.height
                );
            }
        }

        const direction = (() => {
            switch (props.direction) {
                case "auto-above-below":
                    if (rect.bottom > windowSize.height / 2) {
                        return "above";
                    } else {
                        return "below";
                    }
                case "auto-left-right":
                    if (rect.right > windowSize.width / 2) {
                        return "left";
                    } else {
                        return "right";
                    }
                default:
                    return props.direction;
            }
        })();

        switch (direction) {
            case "above":
                setTooltipPos({
                    x: rect.left,
                    y: rect.top,
                });
                setTooltipTranslation({
                    x: "0",
                    y: "-100%",
                });
                break;
            case "above-right":
                setTooltipPos({
                    x: rect.right,
                    y: rect.top,
                });
                setTooltipTranslation({
                    x: "0",
                    y: "-100%",
                });
                break;
            case "right":
                setTooltipPos({
                    x: rect.right,
                    y:
                        rect.bottom > windowSize.height / 2
                            ? rect.bottom
                            : rect.top,
                });
                setTooltipTranslation({
                    x: "0",
                    y: rect.bottom > windowSize.height / 2 ? "-100%" : "0",
                });
                break;
            case "below-right":
                setTooltipPos({
                    x: rect.right,
                    y: rect.bottom,
                });
                setTooltipTranslation({
                    x: "0",
                    y: "0",
                });
                break;
            case "below-left":
                setTooltipPos({
                    x: rect.left,
                    y: rect.bottom,
                });
                setTooltipTranslation({
                    x: "-100%",
                    y: "0",
                });
                break;
            case "left":
                setTooltipPos({
                    x: rect.left,
                    y:
                        rect.bottom > windowSize.height / 2
                            ? rect.bottom
                            : rect.top,
                });
                setTooltipTranslation({
                    x: "-100%",
                    y: rect.bottom > windowSize.height / 2 ? "-100%" : "0",
                });
                break;
            case "above-left":
                setTooltipPos({
                    x: rect.left,
                    y: rect.top,
                });
                setTooltipTranslation({
                    x: "-100%",
                    y: "-100%",
                });
                break;
            case "below":
            default:
                setTooltipPos({
                    x: rect.left,
                    y: rect.bottom,
                });
                setTooltipTranslation({
                    x: "0",
                    y: "0",
                });
        }
    }, [props.direction, props.offsetX, props.offsetY]);

    return (
        <MouseOverAlertWrapper
            forwardRef={ref}
            onMouseEnter={() => {
                if (enabled) {
                    positionTooltip();
                    setVisibility(true);
                }
            }}
            onMouseLeave={() => {
                if (enabled || isVisible) {
                    setVisibility(false);
                }
            }}
        >
            <div ref={ref}>{props.children}</div>
            {isVisible && enabled === true && (
                <div
                    className={`tooltip${
                        typeof props.tooltipContent === "string" ||
                        typeof props.tooltipContent === "number" ||
                        Array.isArray(props.tooltipContent)
                            ? " text"
                            : ""
                    }${showBackground ? " with-background" : ""}${
                        showShadow ? " shadow" : ""
                    }`}
                    style={{
                        left: (tooltipPos?.x ?? 0) + (offset?.x ?? 0),
                        top: (tooltipPos?.y ?? 0) + (offset?.y ?? 0),
                        transform: `translateX(${
                            tooltipTranslation?.x ?? "0"
                        }) translateY(${tooltipTranslation?.y ?? "0"})`,
                    }}
                >
                    {node}
                </div>
            )}
        </MouseOverAlertWrapper>
    );
}
