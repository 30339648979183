import * as React from "react";
import {
    LoginResponse,
    UserRegisterResponse,
} from "../../../../net/UserManagement";
import { useState } from "react";
import FlexColumn from "../../layout/Flexbox/FlexColumn";
import FlexRow from "../../layout/Flexbox/FlexRow";
import Modal from "../Modal/Modal";
import TabControl from "../../layout/TabControl/TabControl";
import TextInput from "../../input/TextInput/TextInput";
import {
    DefaultUser,
    UserDetails,
} from "../../../../utils/session/ActiveUserContext";

import {
    useRequestLogin,
    useRequestUserRegistration,
} from "../../../../net/UserManagement";
import Cookies from "universal-cookie";

import "../../../styles/main_style.css";

type Props = Readonly<{
    isVisible?: boolean;
    onClose?: () => void;
    onConfirm?: (newUser: UserDetails | null) => void;
}>;

export default function LoginModal(props: Props): JSX.Element {
    const [selectedTab, setSelectedTab] = useState<string>("login");
    const [usernameEntry, setUsernameEntry] = useState<string>("");
    const [passwordEntry, setPasswordEntry] = useState<string>("");
    const [repeatPasswordEntry, setRepeatPasswordEntry] = useState<string>("");
    const [requestLogin, _isRequestingLogin] = useRequestLogin();
    const [requestUserRegistration, _isRequestingUserRegistration] =
        useRequestUserRegistration();

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [errorType, setErrorType] = useState<"error" | "success">("error");

    const cookies = new Cookies();

    const loginSubmit = () => {
        requestLogin({
            variables: { username: usernameEntry, pwd: passwordEntry },
            onComplete: (result: LoginResponse) => {
                if (result["result"] === true) {
                    props.onConfirm?.(
                        (result.user?.id ?? -1) >= 0
                            ? {
                                  id: result.user.id ?? -1,
                                  username: result.user.user_name ?? "",
                                  displayName: result.user.display_name ?? "",
                                  rank: result.user.rank ?? 0,
                              }
                            : DefaultUser
                    );
                    if ((result.token?.id ?? -1) >= 0) {
                        cookies.set(
                            "session_token",
                            JSON.stringify(result.token)
                        );
                    }
                } else if (result["result"] === false) {
                    setErrorType("error");
                    setErrorMessage("Invalid username/password.");
                } else {
                    setErrorType("error");
                    setErrorMessage("Unable to communicate with server.");
                }
            },
            onError: (error) => {
                console.log(error);
            },
        });
    };

    const registerSubmit = () => {
        if (passwordEntry !== repeatPasswordEntry) {
            setErrorType("error");
            setErrorMessage("Passwords do not match");

            return;
        }

        requestUserRegistration({
            variables: { username: usernameEntry, pwd: passwordEntry },
            onComplete: (result: UserRegisterResponse) => {
                if (result["result"] && result["user"] != null) {
                    setErrorType("success");
                    setErrorMessage(
                        `User ${result.user.displayName} registered successfully. You may now log in.`
                    );
                    setPasswordEntry("");
                    setRepeatPasswordEntry("");
                } else {
                    setErrorType("error");
                    setErrorMessage(result.message);
                }
            },
            onError: (error) => {
                console.log(error);
            },
        });
    };

    return (
        <Modal
            header="Login / Register"
            isVisible={props.isVisible}
            footer="standard-submit-cancel"
            onClose={() => {
                props.onClose?.();
            }}
            onConfirm={() => {
                if (selectedTab === "login") {
                    loginSubmit();
                } else if (selectedTab === "register") {
                    registerSubmit();
                }
            }}
        >
            <TabControl
                id="login-mode"
                tabs={[
                    { id: "login", label: "Log In" },
                    { id: "register", label: "Register" },
                ]}
                selected={selectedTab}
                onSelectedTabChange={(tabID) => {
                    setSelectedTab(tabID ?? selectedTab);
                }}
                allowNoSelection={false}
            />
            {selectedTab === "login" && (
                <FlexColumn>
                    <FlexRow style={{ padding: 8 }}>
                        <div
                            style={{
                                width: 150,
                                marginTop: "auto",
                                marginBottom: "auto",
                            }}
                        >
                            Username:
                        </div>
                        <TextInput
                            value={usernameEntry}
                            onChange={setUsernameEntry}
                            style={{ width: 200 }}
                            onEnterKeyPress={loginSubmit}
                        />
                    </FlexRow>
                    <FlexRow style={{ padding: 8 }}>
                        <div
                            style={{
                                width: 150,
                                marginTop: "auto",
                                marginBottom: "auto",
                            }}
                        >
                            Password:
                        </div>
                        <TextInput
                            value={passwordEntry}
                            onChange={setPasswordEntry}
                            style={{ width: 200 }}
                            password={true}
                            onEnterKeyPress={loginSubmit}
                        />
                    </FlexRow>
                    {errorMessage !== null && (
                        <div style={{ color: "red" }}>{errorMessage}</div>
                    )}
                </FlexColumn>
            )}
            {selectedTab === "register" && (
                <FlexColumn>
                    <FlexRow style={{ padding: 8 }}>
                        <div
                            style={{
                                width: 150,
                                marginTop: "auto",
                                marginBottom: "auto",
                            }}
                        >
                            Username:
                        </div>
                        <TextInput
                            value={usernameEntry}
                            onChange={setUsernameEntry}
                            style={{ width: 200 }}
                            onEnterKeyPress={registerSubmit}
                        />
                    </FlexRow>
                    <FlexRow style={{ padding: 8 }}>
                        <div
                            style={{
                                width: 150,
                                marginTop: "auto",
                                marginBottom: "auto",
                            }}
                        >
                            Password:
                        </div>
                        <TextInput
                            value={passwordEntry}
                            onChange={setPasswordEntry}
                            style={{ width: 200 }}
                            password={true}
                            onEnterKeyPress={registerSubmit}
                        />
                    </FlexRow>
                    <FlexRow style={{ padding: 8 }}>
                        <div
                            style={{
                                width: 150,
                                marginTop: "auto",
                                marginBottom: "auto",
                            }}
                        >
                            Repeat Password:
                        </div>
                        <TextInput
                            value={repeatPasswordEntry}
                            onChange={setRepeatPasswordEntry}
                            style={{ width: 200 }}
                            password={true}
                            onEnterKeyPress={registerSubmit}
                        />
                    </FlexRow>
                    {errorMessage !== null && (
                        <div
                            style={
                                errorType === "error"
                                    ? { color: "red" }
                                    : { color: "green" }
                            }
                        >
                            {errorMessage}
                        </div>
                    )}
                </FlexColumn>
            )}
        </Modal>
    );
}
