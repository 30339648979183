import CheckboxInputField from "../../input/CheckboxInputField/CheckboxInputField";
import DropdownSelector from "../../input/DropdownSelector/DropdownSelector";
import DropdownMultiSelector from "../../input/DropdownSelector/DropdownMultiSelector";
import InventoryItemIconEditorModal from "../InventoryItemIconEditorModal/InventoryItemIconEditorModal";
import WhitelistEditorModal from "../WhitelistEditorModal/WhitelistEditorModal";
import FlexColumn from "../../layout/Flexbox/FlexColumn";
import FlexRow from "../../layout/Flexbox/FlexRow";
import Modal from "../Modal/Modal";
import MultiLineTextField from "../../input/MultiLineTextInputToggleField/MultiLineTextInputToggleField";
import NumberInputToggleField from "../../input/NumberInputToggleField/NumberInputToggleField";
import RadioButton from "../../input/RadioButton/RadioButton";
import TextInputToggleField from "../../input/TextInputToggleField/TextInputToggleField";
import Tooltip from "../../wrappers/Tooltip/Tooltip";

import accessoryTypes from "../../../../assets/json/item_types/accessory_types.json";
import ammunitionTypes from "../../../../assets/json/item_types/ammunition_types.json";
import armorTypes from "../../../../assets/json/item_types/armor_types.json";
import componentTypes from "../../../../assets/json/item_types/component_types.json";
import consumableTypes from "../../../../assets/json/item_types/consumable_types.json";
import genericTypes from "../../../../assets/json/item_types/generic_types.json";
import weaponTypes from "../../../../assets/json/item_types/weapon_types.json";
import {
    WieldStyle,
    WieldStyleUtils,
} from "../../../../utils/game/mechanics/WieldStyle";
import DamageTypeUtils, {
    DamageType,
} from "../../../../utils/game/mechanics/DamageType";

import { useCallback, useContext, useState } from "react";
import InventoryItemIcon from "../../../../pages/character/components/InventoryItemIcon/InventoryItemIcon";

import "./InventoryItemEditorModal_style.css";
import TextField from "../../display/TextField/TextField";
import InventoryItem from "../../../../utils/game/mechanics/InventoryItem";
import { InventoryItemTypeUtils } from "../../../../utils/sys/InventoryItemType";
import Proficiency from "../../../../utils/game/mechanics/Proficiency";
import { Color } from "../../../../utils/sys/Color";
import { Whitelist } from "../../../../utils/sys/Whitelist";
import ActiveUserContext from "../../../../utils/session/ActiveUserContext";

type Props = {
    isVisible?: boolean;
    initialItemData?: InventoryItem;
    onClose?: () => void;
    onConfirm?: (itemData: InventoryItem) => void;
};

const wieldStyles = WieldStyleUtils.getValueArray()
    .filter((style) => style !== WieldStyle.NONE)
    .map((style) => {
        return {
            value: style.toString(),
            label: WieldStyleUtils.getLabel(style),
        };
    });
const damageTypes = DamageTypeUtils.getValueArray()
    .filter((t) => t !== DamageType.UNKNOWN)
    .map((t) => {
        return { value: t, label: DamageTypeUtils.getLabel(t) };
    });

export default function InventoryItemEditorModal(props: Props): JSX.Element {
    const activeUser = useContext(ActiveUserContext);
    const initialItemData = props.initialItemData;

    const [name, setName] = useState<string>(
        initialItemData ? initialItemData.title : ""
    );
    const [weight, setWeight] = useState<number>(
        initialItemData ? Number.parseFloat(initialItemData.weight) : 0
    );
    const [costValue, setCostValue] = useState<number>(
        initialItemData ? Number.parseFloat(initialItemData.value) : 0
    );
    const [components, setComponents] = useState<string>(
        initialItemData ? initialItemData.components : ""
    );
    const [subtypeOptions, setSubtypeOptions] = useState<
        Array<{ value: string; label: string }>
    >([]);
    const [selectedType, setSelectedType] = useState<string | null>(
        initialItemData
            ? InventoryItemTypeUtils.getLabel(
                  initialItemData.type
              ).toLowerCase()
            : null
    );
    const [selectedSubtype, setSelectedSubtype] = useState<string | null>(
        initialItemData ? initialItemData.subTypeString : null
    );
    const [activeProfs, setActiveProfs] = useState<Array<string>>(
        initialItemData ? initialItemData.modifyingProficiencies : []
    );
    const [isStackable, setStackable] = useState<boolean>(
        initialItemData ? initialItemData.stackable : false
    );
    const [unitSingular, setUnitSingular] = useState<string>(
        initialItemData ? initialItemData.unitSingularTitle : ""
    );
    const [unitPlural, setUnitPlural] = useState<string>(
        initialItemData ? initialItemData.unitPluralTitle : ""
    );
    const [dmgValues, setDmgValues] = useState<{
        dmg: string;
        pow: number;
        rng: number;
        mdmg: string;
        mpow: number;
        mrng: number;
    }>({
        dmg: initialItemData ? initialItemData.dmg : "",
        pow: initialItemData ? Number.parseInt(initialItemData.pow) : 0,
        rng: initialItemData ? Number.parseInt(initialItemData.rng) : 0,
        mdmg: initialItemData ? initialItemData.mdmg : "",
        mpow: initialItemData ? Number.parseInt(initialItemData.mpow) : 0,
        mrng: initialItemData ? Number.parseInt(initialItemData.mrng) : 0,
    });
    const [selectedWieldStyles, setSelectedWieldStyles] = useState<
        Array<WieldStyle>
    >(initialItemData ? initialItemData.permittedWieldStyles : []);
    const [selectedDamageTypes, setSelectedDamageTypes] = useState<
        Array<DamageType>
    >(initialItemData ? initialItemData.damageTypes : []);
    const [defValues, setDefValues] = useState<{
        def: number;
        mdef: number;
        block: number;
    }>({
        def: initialItemData ? Number.parseInt(initialItemData.def) : 0,
        mdef: initialItemData ? Number.parseInt(initialItemData.mdef) : 0,
        block: initialItemData?.block ?? 0,
    });
    const [mods, setMods] = useState<string>(
        initialItemData
            ? initialItemData.mods.map((m) => `${m.key}:${m.value}`).join(";")
            : ""
    );
    const [description, setDescription] = useState<string>(
        initialItemData ? initialItemData.description : ""
    );
    const [imgSrc, setImgSrc] = useState<{
        bgSrc: string | null;
        iconSrc: string | null;
        bgColor: Color | null;
        iconColor: Color | null;
    }>({
        bgSrc: initialItemData ? initialItemData.bgSrc : null,
        iconSrc: initialItemData ? initialItemData.iconSrc : null,
        bgColor: initialItemData ? initialItemData.bgColor : null,
        iconColor: initialItemData ? initialItemData.iconColor : null,
    });
    const [onUseEffect, setOnUseEffect] = useState<string | null>(null);
    const [equipEffect, setEquipEffect] = useState<string | null>(null);

    const [whitelist, setWhitelist] = useState<Whitelist>(
        initialItemData
            ? initialItemData.whitelist
            : { viewers: [], editors: [activeUser.id] }
    );

    const [isWhitelistActive, setWhitelistActive] = useState<boolean>(
        initialItemData ? initialItemData.whitelistProtected : false
    );

    const [isIconModalVisible, setIconModalVisibility] =
        useState<boolean>(false);

    const [isWhitelistModalVisible, setWhitelistModalVisibility] =
        useState<boolean>(false);

    const generateItemData = useCallback((): InventoryItem => {
        return new InventoryItem({
            title: name,
            type: selectedType ?? "",
            subtype: selectedSubtype ?? "",
            modifyingProficiencies: activeProfs.map((p) =>
                Proficiency.coerceProficiencyStatKey(p)
            ),
            weight: weight.toString(),
            value: costValue.toString(),
            def: defValues.def.toString(),
            mdef: defValues.mdef.toString(),
            block: defValues.block.toString(),
            dmg: dmgValues.dmg,
            mdmg: dmgValues.mdmg,
            pow: dmgValues.pow.toString(),
            mpow: dmgValues.mpow.toString(),
            rng: dmgValues.rng.toString(),
            mrng: dmgValues.mrng.toString(),
            stackable: isStackable,
            components: components,
            unitSingularTitle: unitSingular,
            unitPluralTitle: unitPlural,
            description: description,
            mods: mods,
            wieldStyles: selectedWieldStyles,
            damageTypes: selectedDamageTypes,
            bgSrc: imgSrc.bgSrc ?? undefined,
            bgColor: imgSrc.bgColor ?? undefined,
            iconSrc: imgSrc.iconSrc ?? undefined,
            iconColor: imgSrc.iconColor ?? undefined,
            whitelistProtected: isWhitelistActive,
            whitelist: whitelist,
        });
    }, [
        name,
        selectedType,
        selectedSubtype,
        activeProfs,
        weight,
        costValue,
        defValues,
        dmgValues,
        isStackable,
        components,
        unitSingular,
        unitPlural,
        description,
        mods,
        wieldStyles,
        damageTypes,
        imgSrc,
        isWhitelistActive,
        whitelist,
    ]);

    return (
        <Modal
            header="Edit Item"
            isVisible={props.isVisible}
            footer="standard-submit-cancel"
            onClose={() => {
                props.onClose?.();
            }}
            onConfirm={() => {
                props.onConfirm?.(generateItemData());
            }}
        >
            <FlexColumn className="item-editor-container" gap={8}>
                <FlexRow>
                    <FlexColumn gap={8} style={{ width: "100%" }}>
                        <FlexRow gap={8}>
                            <TextInputToggleField
                                label="Name"
                                onStopEditing={setName}
                            >
                                {name}
                            </TextInputToggleField>
                            <button
                                onClick={() => {
                                    setWhitelistModalVisibility(
                                        !isWhitelistModalVisible
                                    );
                                }}
                            >
                                WL
                            </button>
                        </FlexRow>
                        <FlexRow gap={8}>
                            <NumberInputToggleField
                                label="Weight"
                                value={weight}
                                onStopEditing={setWeight}
                            />
                            <NumberInputToggleField
                                label="Value"
                                value={costValue}
                                onStopEditing={setCostValue}
                            />
                        </FlexRow>

                        <TextInputToggleField
                            label="Components"
                            onStopEditing={setComponents}
                        >
                            {components}
                        </TextInputToggleField>
                    </FlexColumn>
                    <FlexColumn className="icon-toolbar-container">
                        <div className="icon-container">
                            <InventoryItemIcon
                                className={`icon${
                                    imgSrc.bgSrc == null &&
                                    imgSrc.iconSrc == null
                                        ? ` no-icon`
                                        : ``
                                }`}
                                bgSrc={imgSrc.bgSrc}
                                iconSrc={imgSrc.iconSrc}
                                bgColor={imgSrc.bgColor}
                                iconColor={imgSrc.iconColor}
                            />
                        </div>
                        <button
                            onClick={() => {
                                setIconModalVisibility(true);
                            }}
                        >
                            Icon...
                        </button>
                    </FlexColumn>
                </FlexRow>

                <InventoryItemIconEditorModal
                    isVisible={isIconModalVisible}
                    initialValues={
                        initialItemData
                            ? {
                                  bgSrc: initialItemData.bgSrc,
                                  iconSrc: initialItemData.iconSrc,
                                  bgColor: initialItemData.bgColor ?? undefined,
                                  iconColor:
                                      initialItemData.iconColor ?? undefined,
                              }
                            : undefined
                    }
                    onClose={() => {
                        setIconModalVisibility(false);
                    }}
                    onConfirm={(val) => {
                        setImgSrc(val);
                        setIconModalVisibility(false);
                    }}
                />

                <WhitelistEditorModal
                    isVisible={isWhitelistModalVisible}
                    whitelist={whitelist}
                    whitelistActive={isWhitelistActive}
                    onClose={() => {
                        setWhitelistModalVisibility(false);
                    }}
                    onConfirm={(whitelistActive, whitelist) => {
                        setWhitelistActive(whitelistActive);
                        setWhitelist(whitelist);
                    }}
                />

                <FlexRow gap={8}>
                    <FlexColumn gap={8}>
                        <RadioButton
                            label="Generic"
                            value="generic"
                            onChange={(val) => {
                                setSelectedType(val);
                                setSubtypeOptions(genericTypes);
                                setSelectedSubtype(null);
                            }}
                            activeValue={selectedType}
                        />
                        <RadioButton
                            label="Weapon"
                            value="weapon"
                            onChange={(val) => {
                                setSelectedType(val);
                                setSubtypeOptions(weaponTypes);
                                setSelectedSubtype(null);
                            }}
                            activeValue={selectedType}
                        />
                    </FlexColumn>
                    <FlexColumn gap={8}>
                        <RadioButton
                            label="Ammunition"
                            value="ammo"
                            onChange={(val) => {
                                setSelectedType(val);
                                setSubtypeOptions(ammunitionTypes);
                                setSelectedSubtype(null);
                            }}
                            activeValue={selectedType}
                        />
                        <RadioButton
                            label="Armor"
                            value="armor"
                            onChange={(val) => {
                                setSelectedType(val);
                                setSubtypeOptions(armorTypes);
                                setSelectedSubtype(null);
                            }}
                            activeValue={selectedType}
                        />
                    </FlexColumn>
                    <FlexColumn gap={8}>
                        <RadioButton
                            label="Component"
                            value="component"
                            onChange={(val) => {
                                setSelectedType(val);
                                setSubtypeOptions(componentTypes);
                                setSelectedSubtype(null);
                            }}
                            activeValue={selectedType}
                        />
                        <RadioButton
                            label="Accessory"
                            value="accessory"
                            onChange={(val) => {
                                setSelectedType(val);
                                setSubtypeOptions(accessoryTypes);
                                setSelectedSubtype(null);
                            }}
                            activeValue={selectedType}
                        />
                    </FlexColumn>
                    <FlexColumn>
                        <RadioButton
                            label="Consumable"
                            value="consumable"
                            onChange={(val) => {
                                setSelectedType(val);
                                setSubtypeOptions(consumableTypes);
                                setSelectedSubtype(null);
                            }}
                            activeValue={selectedType}
                        />
                    </FlexColumn>
                </FlexRow>
                <FlexRow gap={8}>
                    <TextField
                        type="bold"
                        style={{ width: "auto", marginTop: 4 }}
                    >
                        Subtype
                    </TextField>
                    <DropdownSelector
                        placeholder="Select Subtype..."
                        value={selectedSubtype}
                        options={subtypeOptions}
                        onChange={(subtype) => {
                            setSelectedSubtype(subtype);

                            if (selectedType === "weapon") {
                                const weaponType = weaponTypes.find(
                                    (t) => t.value === subtype
                                );
                                if (weaponType != null) {
                                    setSelectedWieldStyles(
                                        weaponType.default_wield_styles.map(
                                            (w) => WieldStyleUtils.coerce(w)
                                        )
                                    );
                                    setSelectedDamageTypes(
                                        weaponType.default_damage_types.map(
                                            (d) => DamageTypeUtils.coerce(d)
                                        )
                                    );
                                }
                            }
                        }}
                        isDisabled={selectedType == null}
                    />
                </FlexRow>

                {selectedType === "weapon" && (
                    <WeaponStyleFields
                        selectedWieldStyleKeys={selectedWieldStyles}
                        selectedDamageTypeKeys={selectedDamageTypes}
                        onValuesChanged={(vals) => {
                            if (
                                vals.selectedWieldStyleKeys !==
                                selectedWieldStyles
                            ) {
                                setSelectedWieldStyles(
                                    vals.selectedWieldStyleKeys
                                );
                            }
                            if (
                                vals.selectedDamageTypeKeys !==
                                selectedDamageTypes
                            ) {
                                setSelectedDamageTypes(
                                    vals.selectedDamageTypeKeys
                                );
                            }
                        }}
                    />
                )}
                {(selectedType === "ammo" ||
                    selectedType === "component" ||
                    selectedType === "weapon") && (
                    <DamageFields
                        dmg={dmgValues.dmg}
                        pow={dmgValues.pow}
                        rng={dmgValues.rng}
                        mdmg={dmgValues.mdmg}
                        mpow={dmgValues.mpow}
                        mrng={dmgValues.mrng}
                        onValuesChanged={(vals) => {
                            setDmgValues({
                                dmg: vals.dmg ?? dmgValues.dmg,
                                pow: vals.pow ?? dmgValues.pow,
                                rng: vals.rng ?? dmgValues.rng,
                                mdmg: vals.mdmg ?? dmgValues.mdmg,
                                mpow: vals.mpow ?? dmgValues.mpow,
                                mrng: vals.mrng ?? dmgValues.mrng,
                            });
                        }}
                    />
                )}
                {selectedType === "weapon" && (
                    <ProficiencyFields
                        profs={activeProfs}
                        onAddProf={(prof) => {
                            setActiveProfs([
                                ...activeProfs.filter((p) => p !== prof),
                                prof,
                            ]);
                        }}
                        onRemoveProf={(prof) => {
                            setActiveProfs([
                                ...activeProfs.filter((p) => p !== prof),
                            ]);
                        }}
                    />
                )}
                {(selectedType === "component" || selectedType === "armor") && (
                    <DefenseFields
                        def={defValues.def}
                        mdef={defValues.mdef}
                        block={defValues.block}
                        onValuesChanged={(vals) => {
                            setDefValues({
                                def: vals.def ?? defValues.def,
                                mdef: vals.mdef ?? defValues.mdef,
                                block: vals.block ?? defValues.block,
                            });
                        }}
                    />
                )}
                {(selectedType === "ammo" ||
                    selectedType === "component" ||
                    selectedType === "weapon" ||
                    selectedType === "armor" ||
                    selectedType === "accessory") && (
                    <ModsFields value={mods} onChange={setMods} />
                )}
                {(selectedType === "generic" ||
                    selectedType === "ammunition" ||
                    selectedType === "component" ||
                    selectedType === "consumable") && (
                    <FlexColumn>
                        <CheckboxInputField
                            label="Stackable"
                            checked={isStackable}
                            onChange={setStackable}
                        />
                        <FlexRow>
                            <TextInputToggleField
                                label="Singular Unit"
                                onStopEditing={setUnitSingular}
                            >
                                {unitSingular}
                            </TextInputToggleField>
                            <TextInputToggleField
                                label="Plural Unit"
                                onStopEditing={setUnitPlural}
                            >
                                {unitPlural}
                            </TextInputToggleField>
                        </FlexRow>
                    </FlexColumn>
                )}
                <FlexRow gap={8}>
                    <TextInputToggleField label="On Use">
                        {onUseEffect ?? ""}
                    </TextInputToggleField>
                    <TextInputToggleField label="Equipped">
                        {equipEffect ?? ""}
                    </TextInputToggleField>
                </FlexRow>
                <MultiLineTextField
                    placeholderText="Description"
                    label="Description"
                    isEditable={true}
                    onStopEditing={setDescription}
                >
                    {description}
                </MultiLineTextField>
            </FlexColumn>
        </Modal>
    );
}

function WeaponStyleFields({
    selectedWieldStyleKeys = [],
    selectedDamageTypeKeys = [],
    onValuesChanged,
}: {
    selectedWieldStyleKeys: Array<WieldStyle>;
    selectedDamageTypeKeys: Array<DamageType>;
    onValuesChanged?: (vals: {
        selectedWieldStyleKeys: Array<WieldStyle>;
        selectedDamageTypeKeys: Array<DamageType>;
    }) => void;
}): JSX.Element {
    return (
        <FlexColumn gap={8}>
            <FlexRow gap={8}>
                <TextField type="bold" style={{ width: "auto", marginTop: 4 }}>
                    Wield Style
                </TextField>
                <DropdownMultiSelector
                    placeholder="Select Wield Style(s)..."
                    options={wieldStyles}
                    values={selectedWieldStyleKeys.map((k) => k.toString())}
                    onChange={(vals) => {
                        onValuesChanged?.({
                            selectedWieldStyleKeys: vals.map((v) =>
                                WieldStyleUtils.coerce(v)
                            ),
                            selectedDamageTypeKeys,
                        });
                    }}
                />
            </FlexRow>
            <FlexRow gap={8}>
                <TextField type="bold" style={{ width: "auto", marginTop: 4 }}>
                    Damage Type
                </TextField>
                <DropdownMultiSelector
                    placeholder="Select Damage Type(s)..."
                    options={damageTypes}
                    values={selectedDamageTypeKeys}
                    onChange={(vals) => {
                        onValuesChanged?.({
                            selectedWieldStyleKeys,
                            selectedDamageTypeKeys: vals.map((v) =>
                                DamageTypeUtils.coerce(v)
                            ),
                        });
                    }}
                />
            </FlexRow>
        </FlexColumn>
    );
}

function DamageFields({
    dmg = "",
    pow = 0,
    rng = 0,
    mdmg = "",
    mpow = 0,
    mrng = 0,
    onValuesChanged,
}: {
    dmg?: string;
    pow?: number;
    rng?: number;
    mdmg?: string;
    mpow?: number;
    mrng?: number;
    onValuesChanged?: (vals: {
        dmg?: string;
        pow?: number;
        rng?: number;
        mdmg?: string;
        mpow?: number;
        mrng?: number;
    }) => void;
}): JSX.Element {
    return (
        <FlexColumn gap={8}>
            <FlexRow>
                <TextInputToggleField
                    label="DMG"
                    onStopEditing={(val) => {
                        onValuesChanged?.({ dmg: val });
                    }}
                >
                    {dmg}
                </TextInputToggleField>
                <NumberInputToggleField
                    label="POW"
                    value={pow}
                    onStopEditing={(val) => {
                        onValuesChanged?.({ pow: val });
                    }}
                />
                <NumberInputToggleField
                    label="Rng"
                    value={rng}
                    onStopEditing={(val) => {
                        onValuesChanged?.({ rng: val });
                    }}
                />
            </FlexRow>
            <FlexRow>
                <TextInputToggleField
                    label="MDMG"
                    onStopEditing={(val) => {
                        onValuesChanged?.({ mdmg: val });
                    }}
                >
                    {mdmg}
                </TextInputToggleField>
                <NumberInputToggleField
                    label="MPOW"
                    value={mpow}
                    onStopEditing={(val) => {
                        onValuesChanged?.({ mpow: val });
                    }}
                />
                <NumberInputToggleField
                    label="MRng"
                    value={mrng}
                    onStopEditing={(val) => {
                        onValuesChanged?.({ mrng: val });
                    }}
                />
            </FlexRow>
        </FlexColumn>
    );
}

function ProficiencyFields({
    profs = [],
    onRemoveProf,
    onAddProf,
}: {
    profs?: Array<string>;
    onRemoveProf?: (prof: string) => void;
    onAddProf?: (prof: string) => void;
}): JSX.Element {
    return (
        <FlexRow>
            <CheckboxInputField
                label="Strength"
                checked={profs.includes("STR")}
                onChange={(val) => {
                    if (val) {
                        onAddProf?.("STR");
                    } else {
                        onRemoveProf?.("STR");
                    }
                }}
            />
            <CheckboxInputField
                label="Agility"
                checked={profs.includes("AGI")}
                onChange={(val) => {
                    if (val) {
                        onAddProf?.("AGI");
                    } else {
                        onRemoveProf?.("AGI");
                    }
                }}
            />
            <CheckboxInputField
                label="Intelligence"
                checked={profs.includes("INT")}
                onChange={(val) => {
                    if (val) {
                        onAddProf?.("INT");
                    } else {
                        onRemoveProf?.("INT");
                    }
                }}
            />
            <CheckboxInputField
                label="Fortitude"
                checked={profs.includes("FOR")}
                onChange={(val) => {
                    if (val) {
                        onAddProf?.("FOR");
                    } else {
                        onRemoveProf?.("FOR");
                    }
                }}
            />
            <CheckboxInputField
                label="Charisma"
                checked={profs.includes("CHA")}
                onChange={(val) => {
                    if (val) {
                        onAddProf?.("CHA");
                    } else {
                        onRemoveProf?.("CHA");
                    }
                }}
            />
        </FlexRow>
    );
}

function DefenseFields({
    def = 0,
    mdef = 0,
    block = 0,
    onValuesChanged,
}: {
    def?: number;
    mdef?: number;
    block?: number;
    onValuesChanged?: (values: {
        def?: number;
        mdef?: number;
        block?: number;
    }) => void;
}): JSX.Element {
    return (
        <FlexRow>
            <NumberInputToggleField
                label="DEF"
                value={def}
                onStopEditing={(val) => {
                    onValuesChanged?.({ def: val });
                }}
            />
            <NumberInputToggleField
                label="MDEF"
                value={mdef}
                onStopEditing={(val) => {
                    onValuesChanged?.({ mdef: val });
                }}
            />
            <NumberInputToggleField
                label="Block"
                value={block}
                onStopEditing={(val) => {
                    onValuesChanged?.({ block: val });
                }}
            />
        </FlexRow>
    );
}

function ModsFields({
    value = "",
    onChange,
}: {
    value?: string;
    onChange?: (val: string) => void;
}): JSX.Element {
    return (
        <TextInputToggleField
            label="Mods"
            onStopEditing={(val) => {
                onChange?.(val);
            }}
        >
            {value}
        </TextInputToggleField>
    );
}
