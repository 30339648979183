import { useState } from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import FlexRow from "./components/layout/Flexbox/FlexRow";
import LoadCharacterModal from "../pages/character/modals/LoadCharacterModal";
import LoginModal from "./components/modals/LoginModal/LoginModal";
import { DefaultUser, UserDetails } from "../utils/session/ActiveUserContext";
import { useLogout } from "../net/UserManagement";
import ListMenuClick from "./components/input/ListMenu/ListMenuClick";
import Cookies from "universal-cookie";

type NavBarProps = Readonly<{
    activeUser: UserDetails;
    onUserChange?: (user: UserDetails | null) => void;
}>;

type Props = NavBarProps;

export default function Layout(props: Props): JSX.Element {
    const navBarProps: NavBarProps = props as NavBarProps;

    return (
        <>
            <NavBar {...navBarProps} />
            <Outlet />
        </>
    );
}

function NavBar(props: NavBarProps): JSX.Element {
    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
    const [showLoadCharacterModal, setShowLoadCharacterModal] =
        useState<boolean>(false);
    const [logout, isLoggingOut] = useLogout();
    const navigate = useNavigate();
    const cookies = new Cookies();

    return (
        <div id="nav-container">
            <div id="nav-left-margin" />
            <div id="nav-bar-container">
                <FlexRow>
                    <nav>
                        <Link to="/" className="link-container">
                            <p>Home</p>
                        </Link>
                        <Link to="/wiki" className="link-container">
                            <p>Wiki</p>
                        </Link>
                        <ListMenuClick
                            buttonClassName="link-container"
                            labelClassName="link"
                            label="Character"
                        >
                            {[
                                {
                                    value: "createCharacter",
                                    label: "Create New Character",
                                    onClick: () => {
                                        navigate("/character");
                                    },
                                },
                                {
                                    value: "loadCharacter",
                                    label: "Load Character",
                                    onClick: () => {
                                        setShowLoadCharacterModal(true);
                                    },
                                },
                            ]}
                        </ListMenuClick>
                    </nav>
                    <FlexRow alignment="end">
                        {props.activeUser.id < 0 && (
                            <div className="link-container">
                                <div
                                    className="link"
                                    onClick={() => setShowLoginModal(true)}
                                >
                                    Login
                                </div>
                            </div>
                        )}
                        {props.activeUser.id >= 0 && (
                            <>
                                <div className="link-container">
                                    <p>{props.activeUser.displayName}</p>
                                </div>
                                <div className="link-container">
                                    <div className="link-decoration">|</div>
                                </div>
                                <div className="link-container">
                                    <p
                                        onClick={() => {
                                            const sessionToken =
                                                cookies.get("session_token");

                                            logout({
                                                variables: {
                                                    token: sessionToken,
                                                },
                                                onComplete: (response) => {
                                                    cookies.remove(
                                                        "session_token"
                                                    );
                                                    props.onUserChange?.(
                                                        DefaultUser
                                                    );
                                                },
                                                onError: (error) => {
                                                    console.error(error);
                                                },
                                            });
                                        }}
                                    >
                                        Logout
                                    </p>
                                </div>
                            </>
                        )}
                        <div className="link-container">
                            <p onClick={() => {}}>≡</p>
                        </div>
                    </FlexRow>
                </FlexRow>
            </div>
            <div id="nav-right-margin" />
            {showLoginModal && (
                <LoginModal
                    isVisible={showLoginModal}
                    onClose={() => {
                        setShowLoginModal(false);
                    }}
                    onConfirm={(newUser) => {
                        props.onUserChange?.(newUser);
                        setShowLoginModal(false);
                    }}
                />
            )}
            {showLoadCharacterModal && (
                <LoadCharacterModal
                    isVisible={showLoadCharacterModal}
                    onClose={() => {
                        setShowLoadCharacterModal(false);
                    }}
                    onConfirm={(characterID) => {
                        setShowLoadCharacterModal(false);
                        if (characterID != null) {
                            navigate(`/character?id=${characterID}`);
                        }
                    }}
                />
            )}
        </div>
    );
}
