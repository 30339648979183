import { Dictionary } from "../../sys/Dictionary";

import schoolMap from "../../../assets/json/school_map.json";

export const StandardSchools = new Dictionary(
    schoolMap.map((school) => {
        return {
            key: school.tag,
            value: { title: school.title, iconSrc: school.icon_src },
        };
    })
);
