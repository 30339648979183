import ActiveUserContext from "../../utils/session/ActiveUserContext";
import ArticlePropertiesEditControl from "./components/ArticlePropertiesEditControl";
import PageFramework from "../../common/components/layout/PageFramework/PageFramework";
import WhitelistEditorModal from "../../common/components/modals/WhitelistEditorModal/WhitelistEditorModal";

import {
    useGetArticle,
    useCreateArticle,
    useUpdateArticle,
} from "../../net/ArticleManagement";
import { Whitelist, WhitelistUtils } from "../../utils/sys/Whitelist";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ArticleJSON } from "../../utils/sys/ArticleJSON";
import { UserTagJSON } from "../../utils/sys/UserTagJSON";

import Cookies from "universal-cookie";
import "../../common/styles/main_style.css";
import "./WikiEdit_style.css";

type ArticleParams = Readonly<{
    action: string | null;
    id: number | null;
}>;

const validCatalogs: ReadonlyArray<string> = [
    "guidebook",
    "guidebook2",
    "lorebook",
    "lorebook2",
];
const validTypes: ReadonlyArray<string> = [
    "atlas",
    "timeline",
    "trait",
    "talent",
    "school",
    "ability",
    "system",
    "redirect",
];

export default function WikiEdit(): JSX.Element {
    const activeUser = useContext(ActiveUserContext);
    const cookies = new Cookies();

    const navigate = useNavigate();

    const [searchParams, _] = useSearchParams();

    const articleParams: ArticleParams = useMemo(() => {
        if (
            searchParams.get("id") === null &&
            searchParams.get("action") === null
        ) {
            return {
                id: null,
                action: null,
            };
        }

        return {
            id: (() => {
                const param = searchParams.get("id");
                const val = param == null ? null : parseInt(param);
                return val == null ? null : isNaN(val) ? null : val;
            })(),
            action: (() => {
                const param = searchParams.get("action");
                return param;
            })(),
        };
    }, [searchParams]);

    const [loadArticle, isLoadingArticle] = useGetArticle();
    const [createArticle, isCreatingArticle] = useCreateArticle();
    const [updateArticle, isUpdatingArticle] = useUpdateArticle();

    const [articleID, setArticleID] = useState<number | null>(null);
    const [articleTitle, setArticleTitle] = useState<string>("");
    const [articleText, setArticleText] = useState<string>("");
    const [articleProperties, setArticleProperties] = useState<
        { key: string; value: string }[]
    >([]);
    const [articleCatalogs, setArticleCatalogs] = useState<Array<string>>([]);
    const [articleTypes, setArticleTypes] = useState<Array<string>>([]);
    const [articleCreator, setArticleCreator] = useState<UserTagJSON | null>(
        null
    );
    const [isArticleUsingWhitelist, setIsArticleUsingWhitelist] =
        useState<boolean>(false);
    const [loadedWhitelist, setLoadedWhitelist] = useState<Whitelist>({
        viewers: [],
        editors: [],
    });
    const [whitelist, setWhitelist] = useState<Whitelist>({
        viewers: [],
        editors: [],
    });
    const [isWhitelistModalVisible, setWhitelistModalVisibility] =
        useState<boolean>(false);
    const [isArticleValid, setIsArticleValid] = useState<boolean>(false);

    const canEditArticle = useMemo(() => {
        return (
            activeUser.rank >= 3 &&
            ((articleParams.id == null && articleParams.action === "create") ||
                !isArticleUsingWhitelist ||
                WhitelistUtils.canEdit(activeUser.id, loadedWhitelist) ||
                (articleCreator?.id != null &&
                    activeUser.id === articleCreator.id))
        );
    }, [
        activeUser.id,
        articleID,
        articleCreator,
        loadedWhitelist,
        articleParams,
    ]);

    const setCatalogStatus = (catalog: string, status: boolean): void => {
        if (articleCatalogs.includes(catalog)) {
            if (!status) {
                setArticleCatalogs(
                    articleCatalogs.filter((cat) => cat !== catalog)
                );
            }
        } else {
            if (status) {
                setArticleCatalogs(articleCatalogs.concat(catalog));
            }
        }
    };

    const setTypeStatus = (type: string, status: boolean): void => {
        if (articleTypes.includes(type)) {
            if (!status) {
                setArticleTypes(articleTypes.filter((cat) => cat !== type));
            }
        } else {
            if (status) {
                setArticleTypes(articleTypes.concat(type));
            }
        }
    };

    const submitArticle = () => {
        const parsedProperties: any = {};
        articleProperties.forEach((prop) => {
            parsedProperties[prop.key.toString()] = prop.value;
        });

        const articleData: ArticleJSON = {
            id: articleID ?? undefined,
            title: articleTitle,
            description: articleText,
            type_flags: [...articleCatalogs, ...articleTypes],
            properties: parsedProperties,
            editor: {
                id: activeUser.id,
                user_name: activeUser.username,
                display_name: activeUser.displayName,
                rank: activeUser.rank,
            },
            whitelist_protected: isArticleUsingWhitelist,
            whitelist: {
                readers: whitelist.viewers ?? [],
                writers: whitelist.editors ?? [],
            },
        };

        if (articleID == null) {
            if (articleParams.action === "create") {
                createArticle({
                    variables: {
                        token: cookies.get("session_token"),
                        data: articleData,
                    },
                    onComplete: (response) => {
                        navigate(`/wiki/?id=${response?.id ?? articleID}`);
                    },
                    onError: (err) => {
                        console.error("Failed to update article.");
                    },
                });
            }
        } else {
            updateArticle({
                variables: {
                    token: cookies.get("session_token"),
                    id: articleID,
                    data: articleData,
                },
                onComplete: (response) => {
                    navigate(`/wiki/?id=${response?.id ?? articleID}`);
                },
                onError: (err) => {
                    console.error("Failed to update article.");
                },
            });
        }
    };

    useEffect(() => {
        console.log(articleParams);
        if (articleParams.id == null) {
            setIsArticleValid(true);
            setArticleID(null);
            setArticleTitle("");
            setArticleText("");
            setArticleProperties([]);
            setArticleCatalogs([]);
            setArticleTypes([]);
            setArticleCreator(null);
            setIsArticleUsingWhitelist(false);
            setLoadedWhitelist({ viewers: [], editors: [] });
            setWhitelist({ viewers: [], editors: [] });
        } else {
            loadArticle({
                variables: {
                    identifier: {
                        id: articleParams.id ?? undefined,
                    },
                    token: cookies.get("session_token") ?? undefined,
                    format: 0,
                },
                onComplete: (result) => {
                    if (result != null) {
                        const articleProperties = result.properties ?? {};

                        setIsArticleValid(true);
                        setArticleID(result.id ?? null);
                        setArticleTitle(result.title ?? "");
                        setArticleText(result.description ?? "");
                        setArticleProperties(
                            result.properties == null
                                ? []
                                : Object.keys(articleProperties).map((key) => {
                                      let k =
                                          key as keyof typeof articleProperties;

                                      return {
                                          key: key,
                                          value: articleProperties[k],
                                      };
                                  })
                        );
                        setArticleTypes(
                            validTypes.filter((t) =>
                                result.type_flags?.includes(t)
                            )
                        );
                        setArticleCatalogs(
                            validCatalogs.filter((c) =>
                                result.type_flags?.includes(c)
                            )
                        );
                        setArticleCreator(result.creator ?? null);
                        setIsArticleUsingWhitelist(
                            result.whitelist_protected == true
                        );
                        setLoadedWhitelist({
                            viewers: result.whitelist?.readers ?? [],
                            editors: result.whitelist?.writers ?? [],
                        });
                        setWhitelist({
                            viewers: result.whitelist?.readers ?? [],
                            editors: result.whitelist?.writers ?? [],
                        });
                    } else {
                        setIsArticleValid(false);
                        setArticleID(null);
                        setArticleTitle("Invalid Article");
                        setArticleText(
                            "Could not find an article with the given parameters."
                        );
                        setArticleProperties([]);
                        setArticleCatalogs([]);
                        setArticleTypes([]);
                        setArticleCreator(null);
                        setIsArticleUsingWhitelist(false);
                        setLoadedWhitelist({ viewers: [], editors: [] });
                        setWhitelist({ viewers: [], editors: [] });
                    }
                },
                onError: (err) => {
                    setIsArticleValid(false);
                    setArticleID(null);
                    setArticleTitle("Invalid Article");
                    setArticleText(
                        "Could not find an article with the given parameters."
                    );
                    setArticleProperties([]);
                    setArticleCatalogs([]);
                    setArticleTypes([]);
                    setArticleCreator(null);
                    setIsArticleUsingWhitelist(false);
                    setLoadedWhitelist({ viewers: [], editors: [] });
                    setWhitelist({ viewers: [], editors: [] });
                },
            });
        }
    }, [articleParams]);

    return (
        <PageFramework>
            {isLoadingArticle() ? (
                <div>Loading...</div>
            ) : isArticleValid ? (
                canEditArticle ? (
                    <form>
                        <h3>Title</h3>
                        <input
                            id="art-title"
                            type="text"
                            value={articleTitle}
                            onChange={(event) => {
                                setArticleTitle(event.target.value);
                            }}
                        />
                        <br />
                        <h3>Content</h3>
                        <textarea
                            id="art-text"
                            rows={20}
                            cols={120}
                            value={articleText}
                            onChange={(event) =>
                                setArticleText(event.target.value)
                            }
                        />
                        <div className="flex-row flex-end">
                            <input
                                type="button"
                                value="Whitelist"
                                style={{ padding: 5, width: "20%" }}
                                onClick={() => {
                                    setWhitelistModalVisibility(
                                        !isWhitelistModalVisible
                                    );
                                }}
                            />
                        </div>
                        <h3>Properties</h3>
                        <ArticlePropertiesEditControl
                            properties={articleProperties}
                            onPropertyDataChanged={(value) => {
                                setArticleProperties(value);
                            }}
                        />
                        <h3>Catalogs</h3>
                        <div
                            className="flex-row flex-space-around center-align"
                            style={{ width: "70%" }}
                        >
                            <div>
                                <input
                                    type="checkbox"
                                    id="guidebook1Check"
                                    checked={articleCatalogs.includes(
                                        "guidebook"
                                    )}
                                    onChange={(event) =>
                                        setCatalogStatus(
                                            "guidebook",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="guidebook1Check">
                                    Rulebook v. 1
                                </label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="guidebook2Check"
                                    checked={articleCatalogs.includes(
                                        "guidebook2"
                                    )}
                                    onChange={(event) =>
                                        setCatalogStatus(
                                            "guidebook2",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="guidebook2Check">
                                    Rulebook v. 2
                                </label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="ayaLoreCheck"
                                    checked={articleCatalogs.includes(
                                        "lorebook"
                                    )}
                                    onChange={(event) =>
                                        setCatalogStatus(
                                            "lorebook",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="ayaLoreCheck">
                                    Lore: Ayaseye
                                </label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="eigLoreCheck"
                                    checked={articleCatalogs.includes(
                                        "lorebook2"
                                    )}
                                    onChange={(event) =>
                                        setCatalogStatus(
                                            "lorebook2",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="eigLoreCheck">
                                    Lore: Eigolyn
                                </label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="kalLoreCheck"
                                    checked={articleCatalogs.includes(
                                        "lorebook3"
                                    )}
                                    onChange={(event) =>
                                        setCatalogStatus(
                                            "lorebook3",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="kalLoreCheck">
                                    Lore: Kalshaea
                                </label>
                            </div>
                        </div>
                        <h3>Types</h3>
                        <div
                            className="flex-row flex-space-around center-align"
                            style={{ width: "70%" }}
                        >
                            <div>
                                <input
                                    type="checkbox"
                                    id="atlasCheck"
                                    checked={articleTypes.includes("atlas")}
                                    onChange={(event) =>
                                        setTypeStatus(
                                            "atlas",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="atlasCheck">Atlas</label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="timelineCheck"
                                    checked={articleTypes.includes("timeline")}
                                    onChange={(event) =>
                                        setTypeStatus(
                                            "timeline",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="timelineCheck">Timeline</label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="traitCheck"
                                    checked={articleTypes.includes("trait")}
                                    onChange={(event) =>
                                        setTypeStatus(
                                            "trait",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="traitCheck">Trait</label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="talentCheck"
                                    checked={articleTypes.includes("talent")}
                                    onChange={(event) =>
                                        setTypeStatus(
                                            "talent",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="talentCheck">Talent</label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="schoolCheck"
                                    checked={articleTypes.includes("school")}
                                    onChange={(event) =>
                                        setTypeStatus(
                                            "school",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="schoolCheck">School</label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="abilityCheck"
                                    checked={articleTypes.includes("ability")}
                                    onChange={(event) =>
                                        setTypeStatus(
                                            "ability",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="abilityCheck">Ability</label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="redirectCheck"
                                    checked={articleTypes.includes("redirect")}
                                    onChange={(event) =>
                                        setTypeStatus(
                                            "redirect",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="redirectCheck">Redirect</label>
                            </div>
                            <div>
                                <input
                                    type="checkbox"
                                    id="systemCheck"
                                    checked={articleTypes.includes("system")}
                                    onChange={(event) =>
                                        setTypeStatus(
                                            "system",
                                            event.target.checked
                                        )
                                    }
                                />
                                <label htmlFor="systemCheck">System</label>
                            </div>
                        </div>
                        <br />
                        <div className="flex-row flex-end">
                            <input
                                type="button"
                                value="Submit"
                                style={{ padding: 5, width: "20%" }}
                                disabled={
                                    isCreatingArticle() || isUpdatingArticle()
                                }
                                onClick={() => {
                                    submitArticle();
                                }}
                            />
                        </div>
                        <br />
                    </form>
                ) : (
                    <div>Unauthorized to edit the specified article.</div>
                )
            ) : (
                <div>Could not find an article with the given parameters.</div>
            )}
            <WhitelistEditorModal
                isVisible={isWhitelistModalVisible}
                whitelist={whitelist}
                whitelistActive={isWhitelistModalVisible}
                onClose={() => {
                    setWhitelistModalVisibility(false);
                }}
                onConfirm={(whitelistActive, updatedWhitelist) => {
                    setIsArticleUsingWhitelist(whitelistActive);
                    setWhitelist(updatedWhitelist);

                    setWhitelistModalVisibility(false);
                }}
            />
        </PageFramework>
    );
}
