import ActiveUserContext from "../../../../utils/session/ActiveUserContext";
import IdentifiedUsersContext from "../../../../utils/session/IdentifiedUsersContext";
import FlexColumn from "../../layout/Flexbox/FlexColumn";
import FlexRow from "../../layout/Flexbox/FlexRow";
import List from "../../layout/List/List";
import CheckboxInputField from "../../input/CheckboxInputField/CheckboxInputField";
import Modal from "../Modal/Modal";
import { Whitelist, WhitelistUtils } from "../../../../utils/sys/Whitelist";
import { UserTag } from "../../../../utils/sys/UserTag";
import {
    useGetUserFriends,
    useGetUserTags,
} from "../../../../net/UserManagement";
import TextField from "../../display/TextField/TextField";

import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import Cookies from "universal-cookie";

import "./WhitelistEditorModal_style.css";

type Props = {
    isVisible: boolean;
    whitelist?: Whitelist;
    whitelistActive?: boolean;
    onClose?: () => void;
    onConfirm?: (isWhitelistActive: boolean, whitelist: Whitelist) => void;
};

export default function WhitelistEditorModal({
    isVisible,
    whitelist,
    whitelistActive = false,
    onClose,
    onConfirm,
}: Props): JSX.Element {
    const activeUser = useContext(ActiveUserContext);
    const identifiedUsers = useContext(IdentifiedUsersContext);
    const cookies = new Cookies();
    const [userFetchCompleted, setUserFetchCompleted] =
        useState<boolean>(false);

    const users = useCallback(() => {
        return identifiedUsers.users;
    }, [identifiedUsers.users]);

    const [getUserFriends, _isGettingUserFriends] = useGetUserFriends();
    const [getUserTags, _isGettingUserTags] = useGetUserTags();

    const [viewerUserIDs, setViewerUserIDs] = useState<Array<number>>(
        whitelist ? whitelist.viewers : []
    );
    const [editorUserIDs, setEditorUserIDs] = useState<Array<number>>(
        whitelist ? whitelist.editors : []
    );
    const [selectedUnassigned, setSelectedUnassigned] = useState<Array<string>>(
        []
    );
    const [selectedViewers, setSelectedViewers] = useState<Array<string>>([]);
    const [selectedEditors, setSelectedEditors] = useState<Array<string>>([]);
    const [isWhitelistActive, setWhitelistActive] =
        useState<boolean>(whitelistActive);

    const unassignedUserIDs = useMemo((): Array<number> => {
        return users()
            .filterValues(
                (user) =>
                    !viewerUserIDs.includes(user.id) &&
                    !editorUserIDs.includes(user.id)
            )
            .map((user) => user.id);
    }, [users, viewerUserIDs, editorUserIDs, userFetchCompleted]);

    const unassignedUserRows = useMemo(() => {
        return users()
            .filterValues(
                (user) =>
                    !viewerUserIDs.includes(user.id) &&
                    !editorUserIDs.includes(user.id)
            )
            .map((user) => {
                return {
                    id: user.id.toString(),
                    label: user.displayName,
                };
            });
    }, [users, viewerUserIDs, editorUserIDs, userFetchCompleted]);

    const viewerUserRows = useMemo(() => {
        return viewerUserIDs.map((id) => {
            const match = users().get(id);
            return match === undefined
                ? { id: id.toString(), label: id.toString() }
                : { id: match.id.toString(), label: match.displayName };
        });
    }, [
        users,
        unassignedUserIDs,
        viewerUserIDs,
        editorUserIDs,
        userFetchCompleted,
    ]);

    const editorUserRows = useMemo(() => {
        return editorUserIDs.map((id) => {
            const match = users().get(id);
            return match === undefined
                ? {
                      id: id.toString(),
                      label: id.toString(),
                  }
                : { id: match.id.toString(), label: match.displayName };
        });
    }, [
        users,
        unassignedUserIDs,
        viewerUserIDs,
        editorUserIDs,
        userFetchCompleted,
    ]);

    useEffect(() => {
        if (activeUser?.id > 0) {
            let newUsers: Array<UserTag> = [
                {
                    id: activeUser.id,
                    username: activeUser.username,
                    displayName: activeUser.displayName,
                },
            ];

            getUserFriends({
                variables: {
                    userID: activeUser.id,
                    token: cookies.get("session_token"),
                },
                onComplete: (result) => {
                    newUsers = newUsers.concat(result);

                    if (whitelist != null) {
                        const otherUserIDs = whitelist.viewers
                            .concat(whitelist.editors)
                            .filter(
                                (uid) => !identifiedUsers.users.hasKey(uid)
                            );
                        if (otherUserIDs.length > 0) {
                            getUserTags({
                                variables: { userIDs: otherUserIDs },
                                onComplete: (result) => {
                                    newUsers = newUsers.concat(result);
                                    identifiedUsers.addUsers(newUsers);
                                    setUserFetchCompleted(true);
                                },
                            });
                        } else {
                            identifiedUsers.addUsers(newUsers);
                            setUserFetchCompleted(true);
                        }
                    }
                },
            });
        }
    }, [activeUser.id]);

    const generateWhitelist = useCallback(() => {
        return { viewers: viewerUserIDs, editors: editorUserIDs };
    }, [viewerUserIDs, editorUserIDs]);

    return (
        <Modal
            header="Whitelist"
            isVisible={isVisible}
            footer="standard-submit-cancel"
            onClose={() => {
                onClose?.();
            }}
            onConfirm={() => {
                onConfirm?.(isWhitelistActive, generateWhitelist());
            }}
        >
            <FlexColumn className="whitelist-editor-container" gap={8}>
                <CheckboxInputField
                    label="Use Whitelist"
                    checked={isWhitelistActive}
                    onChange={setWhitelistActive}
                />
                <FlexRow>
                    <FlexColumn gap={8}>
                        <TextField type="bold">Users</TextField>
                        <List
                            className="unassigned-list"
                            items={unassignedUserRows}
                            selectMode="multi"
                            selectedIDs={selectedUnassigned.map((n) =>
                                n.toString()
                            )}
                            onSelectedIDsChanged={setSelectedUnassigned}
                        />
                    </FlexColumn>
                    <FlexColumn gap={8}>
                        <FlexRow>
                            <FlexColumn className="viewers-toolbar" gap={16}>
                                <button
                                    onClick={() => {
                                        const transfers =
                                            unassignedUserIDs.filter((id) =>
                                                selectedUnassigned.includes(
                                                    id.toString()
                                                )
                                            );
                                        setViewerUserIDs([
                                            ...viewerUserIDs,
                                            ...transfers,
                                        ]);
                                        setSelectedUnassigned([]);
                                        setSelectedViewers([]);
                                    }}
                                >{`►`}</button>
                                <button
                                    onClick={() => {
                                        const transfers = viewerUserIDs.filter(
                                            (id) =>
                                                selectedViewers.includes(
                                                    id.toString()
                                                )
                                        );
                                        setViewerUserIDs(
                                            viewerUserIDs.filter(
                                                (id) => !transfers.includes(id)
                                            )
                                        );
                                        setSelectedUnassigned([]);
                                        setSelectedViewers([]);
                                    }}
                                >{`◄`}</button>
                            </FlexColumn>
                            <FlexColumn gap={8}>
                                <TextField type="bold">Viewers</TextField>
                                <List
                                    className="viewers-list"
                                    items={viewerUserRows}
                                    selectMode="multi"
                                    selectedIDs={selectedViewers}
                                    onSelectedIDsChanged={setSelectedViewers}
                                />
                            </FlexColumn>
                        </FlexRow>
                        <FlexRow className="between-toolbar" gap={16}>
                            <button
                                onClick={() => {
                                    const transfers = viewerUserIDs.filter(
                                        (id) =>
                                            selectedViewers.includes(
                                                id.toString()
                                            )
                                    );
                                    setEditorUserIDs([
                                        ...editorUserIDs,
                                        ...transfers,
                                    ]);
                                    setViewerUserIDs(
                                        viewerUserIDs.filter(
                                            (id) => !transfers.includes(id)
                                        )
                                    );
                                    setSelectedViewers([]);
                                    setSelectedEditors([]);
                                }}
                            >{`▼`}</button>
                            <button
                                onClick={() => {
                                    const transfers = editorUserIDs.filter(
                                        (id) =>
                                            selectedEditors.includes(
                                                id.toString()
                                            )
                                    );
                                    setViewerUserIDs([
                                        ...viewerUserIDs,
                                        ...transfers,
                                    ]);
                                    setEditorUserIDs(
                                        editorUserIDs.filter(
                                            (id) => !transfers.includes(id)
                                        )
                                    );
                                    setSelectedViewers([]);
                                    setSelectedEditors([]);
                                }}
                            >{`▲`}</button>
                        </FlexRow>
                        <FlexRow>
                            <FlexColumn className="editors-toolbar" gap={16}>
                                <button
                                    onClick={() => {
                                        const transfers =
                                            unassignedUserIDs.filter((id) =>
                                                selectedUnassigned.includes(
                                                    id.toString()
                                                )
                                            );
                                        setEditorUserIDs([
                                            ...editorUserIDs,
                                            ...transfers,
                                        ]);
                                        setSelectedUnassigned([]);
                                        setSelectedEditors([]);
                                    }}
                                >{`►`}</button>
                                <button
                                    onClick={() => {
                                        const transfers = editorUserIDs.filter(
                                            (id) =>
                                                selectedEditors.includes(
                                                    id.toString()
                                                )
                                        );
                                        setEditorUserIDs(
                                            editorUserIDs.filter(
                                                (id) => !transfers.includes(id)
                                            )
                                        );
                                        setSelectedUnassigned([]);
                                        setSelectedEditors([]);
                                    }}
                                >{`◄`}</button>
                            </FlexColumn>
                            <FlexColumn gap={8}>
                                <TextField type="bold">Editors</TextField>
                                <List
                                    className="editors-list"
                                    items={editorUserRows}
                                    selectMode="multi"
                                    selectedIDs={selectedEditors}
                                    onSelectedIDsChanged={(ids) => {
                                        setSelectedEditors(ids);
                                    }}
                                />
                            </FlexColumn>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
            </FlexColumn>
        </Modal>
    );
}
