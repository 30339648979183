import { Color } from "../../../../utils/sys/Color";
import ClickOutAlertWrapper from "../../../../common/components/wrappers/ClickOutAlertWrapper/ClickOutAlertWrapper";
import {
    MutableRefObject,
    useCallback,
    useMemo,
    useRef,
    useState,
} from "react";

import "./PositiveNegativeMeter_style.css";

type Props = {
    /** Baseline color with which to fill the meter */
    fillColor: Color;
    currentValue?: number;
    minValue: number;
    maxValue: number;
    className?: string;
    isEditable?: boolean;
    showNumberLabel?: boolean;
    onValueChanged?: (val: number) => void;
};

export default function PositiveNegativeMeter(props: Props): JSX.Element {
    const [isEditing, setEditing] = useState<boolean>(false);
    const [editorValue, setEditorValue] = useState<number>(
        props.currentValue ?? 0
    );

    const meterElementRef = useRef(null);
    const valueRef = useRef<number>();
    valueRef.current = editorValue;

    const fillAmount = useMemo(() => {
        const curr = props.currentValue ?? 0;

        if (curr <= props.minValue) {
            return -1;
        } else if (curr < 0) {
            return -(curr / props.minValue);
        } else if (curr === 0) {
            return 0;
        } else if (curr < props.maxValue) {
            return curr / props.maxValue;
        } else {
            return 1;
        }
    }, [props.currentValue, props.maxValue]);

    return (
        <div
            ref={meterElementRef}
            className={`positive-negative-meter${
                props.className != null ? ` ${props.className}` : ``
            }`}
            onClick={() => {
                if (props.isEditable) {
                    setEditing(true);
                }
            }}
        >
            <div className={`fill-space${props.isEditable ? ` editable` : ``}`}>
                <div className="fill-space-left">
                    {fillAmount < 0 && (
                        <div
                            className="fill"
                            style={{
                                width: `${
                                    Math.min(Math.abs(fillAmount), 1.0) * 100
                                }%`,
                                backgroundColor: props.fillColor,
                            }}
                        />
                    )}
                </div>
                <div className="fill-space-right">
                    {fillAmount > 0 && (
                        <div
                            className="fill"
                            style={{
                                width: `${Math.min(fillAmount, 1.0) * 100}%`,
                                backgroundColor: props.fillColor,
                            }}
                        />
                    )}
                </div>
            </div>

            {isEditing && (
                <ClickOutAlertWrapper
                    forwardRef={meterElementRef}
                    onClickOut={() => {
                        setEditing(false);

                        props.onValueChanged?.(valueRef.current ?? 0);
                    }}
                >
                    <div className="editor-overlay">
                        <input
                            type="number"
                            value={editorValue}
                            min={props.minValue}
                            max={props.maxValue}
                            onChange={(e) => {
                                setEditorValue(Number.parseInt(e.target.value));
                            }}
                        />
                    </div>
                </ClickOutAlertWrapper>
            )}
        </div>
    );
}
