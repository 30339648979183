type Props = {
    src: string;
    size?: number;
    style?: React.CSSProperties;
    className?: string;
    alt?: string | null;
};

export default function Icon(props: Props): JSX.Element {
    return (
        <img
            alt={props.alt ?? undefined}
            className={`icon-img${
                props.className === undefined ? `` : ` ${props.className}`
            }`}
            src={props.src}
            style={{
                ...props.style,
                ...(props.size
                    ? { width: props.size, height: props.size }
                    : undefined),
            }}
        />
    );
}
