import ProficiencyPanel from "../ProficiencyPanel/ProficiencyPanel";
import { CharacterData } from "../../../../utils/game/character/CharacterData";
import StatBoard from "../../../../utils/game/mechanics/StatBoard";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import { isEditable } from "@testing-library/user-event/dist/utils";
import { KeyValuePair } from "../../../../utils/sys/KeyValuePair";
import { useMemo } from "react";

type Props = {
    characterData: CharacterData;
    statBoard: StatBoard;
    isEditable?: boolean;
    onCharacterDataValueChanged?: (vals: KeyValuePair[]) => void;
};

export default function ProficienciesContainer({
    characterData,
    statBoard,
    isEditable = false,
    onCharacterDataValueChanged,
}: Props): JSX.Element {
    return (
        <FlexRow alignment="center">
            <ProficiencyPanel
                statBoard={statBoard}
                statKey="STR"
                modifierStatKey="STRMOD"
                isEditable={isEditable}
                onValueSave={(val) => {
                    onCharacterDataValueChanged?.([
                        { key: "strength", value: val },
                    ]);
                }}
            />
            <ProficiencyPanel
                statBoard={statBoard}
                statKey="AGI"
                modifierStatKey="AGIMOD"
                isEditable={isEditable}
                onValueSave={(val) => {
                    onCharacterDataValueChanged?.([
                        { key: "agility", value: val },
                    ]);
                }}
            />
            <ProficiencyPanel
                statBoard={statBoard}
                statKey="INT"
                modifierStatKey="INTMOD"
                isEditable={isEditable}
                onValueSave={(val) => {
                    onCharacterDataValueChanged?.([
                        { key: "intelligence", value: val },
                    ]);
                }}
            />
            <ProficiencyPanel
                statBoard={statBoard}
                statKey="FOR"
                modifierStatKey="FORMOD"
                isEditable={isEditable}
                onValueSave={(val) => {
                    onCharacterDataValueChanged?.([
                        { key: "fortitude", value: val },
                    ]);
                }}
            />
            <ProficiencyPanel
                statBoard={statBoard}
                statKey="CHA"
                modifierStatKey="CHAMOD"
                isEditable={isEditable}
                onValueSave={(val) => {
                    onCharacterDataValueChanged?.([
                        { key: "charisma", value: val },
                    ]);
                }}
            />
        </FlexRow>
    );
}
