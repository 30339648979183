export type ProficiencyStatKey =
    | "STR"
    | "AGI"
    | "INT"
    | "FOR"
    | "CHA"
    | "Unknown";

export default class Proficiency {
    private _statKey: string;
    public get statKey() {
        return this._statKey;
    }

    private _name: string;
    public get name() {
        return this._name;
    }

    constructor(statKey: string, name: string) {
        this._statKey = statKey;
        this._name = name;
    }

    public static coerceProficiencyStatKey(
        input: string
    ): "STR" | "AGI" | "INT" | "FOR" | "CHA" | "Unknown" {
        switch (input.toLowerCase().trim()) {
            case "strength":
            case "str":
                return "STR";
            case "agility":
            case "agi":
                return "AGI";
            case "intelligence":
            case "int":
                return "INT";
            case "fortitude":
            case "for":
                return "FOR";
            case "charisma":
            case "cha":
                return "CHA";
            default:
                return "Unknown";
        }
    }

    public static getProficiencyStatKeyLabel(val: ProficiencyStatKey) {
        switch (val) {
            case "STR":
                return "Strength";
            case "AGI":
                return "Agility";
            case "INT":
                return "Intelligence";
            case "FOR":
                return "Fortitude";
            case "CHA":
                return "Charisma";
            case "Unknown":
                return "Unknown";
        }
    }
}
