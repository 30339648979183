import ClickOutAlertWrapper from "../../wrappers/ClickOutAlertWrapper/ClickOutAlertWrapper";
import { useRef, useState } from "react";

import "./NumberInputToggleField_style.css";
import FlexRow from "../../layout/Flexbox/FlexRow";

type Props = {
    className?: string;
    label?: string | null | undefined;
    displayFieldStyle?: React.CSSProperties | undefined;
    editorFieldStyle?: React.CSSProperties | undefined;
    isEditable?: boolean;
    value: number;
    formatValueTextField?: (val: number) => string;
    onStopEditing?: (val: number) => void;
    onEnterKeyPress?: () => void;
};

export default function NumberInputToggleField(props: Props): JSX.Element {
    const isEditable = props.isEditable != null ? props.isEditable : true;
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [pendingValue, setPendingValue] = useState<number>(props.value);

    const ref = useRef(null);
    const valueRef = useRef<number>(props.value);

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            props.onEnterKeyPress?.();
        }
    };

    return (
        <div
            ref={ref}
            className={`number-input-field-container${
                props.className ? ` ${props.className}` : ``
            }`}
        >
            <FlexRow>
                {props.label != null && (
                    <div className="label-field">{props.label}</div>
                )}
                {isEditing ? (
                    <ClickOutAlertWrapper
                        forwardRef={ref}
                        onClickOut={() => {
                            setIsEditing(false);
                            props.onStopEditing?.(valueRef.current);
                        }}
                    >
                        <input
                            type="number"
                            className={`editor-field`}
                            style={props.editorFieldStyle}
                            value={pendingValue}
                            autoFocus={true}
                            onKeyDown={(event) => {
                                handleKeyDown(event);
                            }}
                            onChange={(e) => {
                                setPendingValue(() => {
                                    const val = Number.parseInt(e.target.value);
                                    valueRef.current = val;
                                    return val;
                                });
                            }}
                            onBlur={() => {
                                setIsEditing(false);
                                props.onStopEditing?.(valueRef.current);
                            }}
                        />
                    </ClickOutAlertWrapper>
                ) : (
                    <div
                        className={`display-field${
                            isEditable ? " editable" : ""
                        }`}
                        style={props.displayFieldStyle}
                        onClick={() => {
                            if (isEditable) {
                                setIsEditing(true);
                            }
                        }}
                    >
                        {props.formatValueTextField == null
                            ? props.value.toString()
                            : props.formatValueTextField(props.value)}
                    </div>
                )}
            </FlexRow>
        </div>
    );
}
