import schoolMap from "../../../assets/json/school_map.json";

export abstract class SchoolBase {
    protected _id: number = -1;
    public get id() {
        return this._id;
    }

    protected _title: string = "";
    public get title(): string {
        return this._title;
    }
    protected set title(val: string) {
        this._title = val;

        const mapping = schoolMap.find(
            (s) => s.title.toLowerCase() === val.toLowerCase()
        );
        this._iconSrc = mapping ? mapping.icon_src : null;
        this._tag = mapping ? mapping.tag : "";
    }

    protected _description: string = "";
    public get description(): string {
        return this._description;
    }

    protected _iconSrc: string | null = null;
    public get iconSrc(): string | null {
        return this._iconSrc;
    }

    protected _tag: string = "";
    public get tag(): string {
        return this._tag;
    }

    protected _module: string | null = null;
    public get module(): string | null {
        return this._module;
    }
}
