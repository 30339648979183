import { MutableRefObject, forwardRef } from "react";
import React from "react";
import FlexColumn from "../../layout/Flexbox/FlexColumn";
import ListMenuItem from "./ListMenuItem";

import "./ListMenu_style.css";

type Props = {
    children: Array<{ value: string; label: string }>;
    className?: string;
    forwardRef?: MutableRefObject<any>;
    onItemClick?: (value: string) => void;
    openMode?: "click" | "hover";
};

export default function ListMenu(props: Props): JSX.Element {
    return (
        <FlexColumn
            forwardRef={props.forwardRef}
            className={`list-menu-container${
                props.openMode === "click"
                    ? " click-type"
                    : props.openMode === "hover"
                    ? " hover-type"
                    : ""
            }${props.className != null ? ` ${props.className}` : ``}`}
        >
            {props.children.map((child) => {
                return (
                    <ListMenuItem
                        key={child.value}
                        label={child.label}
                        onClick={() => {
                            props.onItemClick?.(child.value);
                        }}
                    />
                );
            })}
        </FlexColumn>
    );
}
