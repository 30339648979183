import ClickOutAlertWrapper from "../../wrappers/ClickOutAlertWrapper/ClickOutAlertWrapper";
import DropdownSelectorItem from "./DropdownSelectorItem";
import FlexColumn from "../../layout/Flexbox/FlexColumn";
import FlexRow from "../../layout/Flexbox/FlexRow";
import Icon from "../../Icon/Icon";
import React, { ReactNode, useCallback, useRef, useState } from "react";

import DownArrow from "../../../../assets/images/common/svg/arrow_down.svg";
import DownArrowGray from "../../../../assets/images/common/svg/arrow_down_gray.svg";
import radioChecked from "../../../../assets/images/common/svg/radio_checked.svg";
import radioUnchecked from "../../../../assets/images/common/svg/radio_unchecked.svg";

import "../../../styles/main_style.css";
import "./DropdownSelector_style.css";

const defaultWidth = 250;

type OptionProps = {
    key?: string;
    label: string;
    value: string;
    isDisabled?: boolean;
};

type Props = {
    placeholder: ReactNode;
    values: Array<string> | null;
    onChange?: (vals: Array<string>) => void;
    options: Array<OptionProps>;
    isDisabled?: boolean;
    buttonClassName?: string;
    buttonStyle?: React.CSSProperties | undefined;
    showChevron?: boolean;
    direction?:
        | "above"
        | "above-right"
        | "right"
        | "below-right"
        | "below"
        | "below-left"
        | "left"
        | "above-left"
        | "auto-above-below"
        | "auto-left-right";
};

export default function DropdownMultiSelector(props: Props): JSX.Element {
    const showChevron =
        props.showChevron === undefined ? true : props.showChevron;
    const ref = useRef<HTMLDivElement>(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const isDisabled =
        props.isDisabled !== undefined ? props.isDisabled : false;
    const buttonClassString = `${
        props.buttonClassName !== undefined
            ? props.buttonClassName
            : `button-style`
    } ${isDisabled ? ` disabled` : ` enabled`}`;
    const [menuPos, setMenuPos] = useState<{
        x: number;
        y: number;
    } | null>(null);
    const [menuTranslation, setMenuTranslation] = useState<{
        x: string;
        y: string;
    } | null>(null);

    const positionMenu = useCallback(() => {
        const windowSize = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
        var rect =
            ref.current?.getBoundingClientRect() ?? new DOMRect(0, 0, 0, 0);
        if (ref.current != null) {
            var offsetParent = ref.current.offsetParent;
            if (offsetParent !== null) {
                const parentRect = offsetParent.getBoundingClientRect();
                rect = new DOMRect(
                    rect.x - parentRect.x + offsetParent.scrollLeft,
                    rect.y - parentRect.y + offsetParent.scrollTop,
                    rect.width,
                    rect.height
                );
            }
        }

        const direction = (() => {
            switch (props.direction) {
                case "auto-above-below":
                    if (rect.bottom > windowSize.height / 2) {
                        return "above";
                    } else {
                        return "below";
                    }
                case "auto-left-right":
                    if (rect.right > windowSize.width / 2) {
                        return "left";
                    } else {
                        return "right";
                    }
                default:
                    return props.direction;
            }
        })();

        switch (direction) {
            case "above":
                setMenuPos({
                    x: rect.left,
                    y: rect.top,
                });
                setMenuTranslation({
                    x: "0",
                    y: "-100%",
                });
                break;
            case "above-right":
                setMenuPos({
                    x: rect.right,
                    y: rect.top,
                });
                setMenuTranslation({
                    x: "0",
                    y: "-100%",
                });
                break;
            case "right":
                setMenuPos({
                    x: rect.right,
                    y:
                        rect.bottom > windowSize.height / 2
                            ? rect.bottom
                            : rect.top,
                });
                setMenuTranslation({
                    x: "0",
                    y: rect.bottom > windowSize.height / 2 ? "-100%" : "0",
                });
                break;
            case "below-right":
                setMenuPos({
                    x: rect.right,
                    y: rect.bottom,
                });
                setMenuTranslation({
                    x: "0",
                    y: "0",
                });
                break;
            case "below-left":
                setMenuPos({
                    x: rect.left,
                    y: rect.bottom,
                });
                setMenuTranslation({
                    x: "-100%",
                    y: "0",
                });
                break;
            case "left":
                setMenuPos({
                    x: rect.left ?? 0,
                    y:
                        rect.bottom > windowSize.height / 2
                            ? rect.bottom ?? 0
                            : rect.top ?? 0,
                });
                setMenuTranslation({
                    x: "-100%",
                    y: rect.bottom > windowSize.height / 2 ? "-100%" : "0",
                });
                break;
            case "above-left":
                setMenuPos({
                    x: rect.left ?? 0,
                    y: rect.top ?? 0,
                });
                setMenuTranslation({
                    x: "-100%",
                    y: "-100%",
                });
                break;
            case "below":
            default:
                setMenuPos({
                    x: rect.left ?? 0,
                    y: rect.bottom ?? 0,
                });
                setMenuTranslation({
                    x: "0",
                    y: "0",
                });
        }
    }, [props.direction]);

    return (
        <div
            ref={ref}
            style={
                {
                    ...props.buttonStyle,
                    width: props.buttonStyle?.width ?? defaultWidth,
                } ?? {
                    width: defaultWidth,
                }
            }
        >
            <FlexRow
                className={`button-style${
                    isDisabled ? " disabled" : " enabled"
                }`}
                onClick={(e) => {
                    if (!isDisabled) {
                        positionMenu();
                        setIsExpanded(!isExpanded);
                    }
                    e?.stopPropagation();
                }}
            >
                <div style={{ width: "100%" }}>
                    {props.values == null || props.values.length === 0
                        ? props.placeholder
                        : `${props.values.length} Selected`}
                </div>
                <FlexRow alignment="end" width={20}>
                    <Icon
                        src={isDisabled ? DownArrowGray : DownArrow}
                        size={16}
                    />
                </FlexRow>
            </FlexRow>
            {isExpanded && (
                <ClickOutAlertWrapper
                    forwardRef={ref}
                    onClickOut={() => {
                        setIsExpanded(false);
                    }}
                >
                    <div
                        className="options-style"
                        style={{
                            minWidth:
                                props.buttonStyle?.width ?? defaultWidth - 20,
                            left: menuPos?.x ?? 0,
                            top: menuPos?.y ?? 0,
                            transform: `translateX(${
                                menuTranslation?.x ?? "0"
                            }) translateY(${menuTranslation?.y ?? "0"})`,
                        }}
                    >
                        <FlexColumn>
                            {props.options.map((option, index) => (
                                <DropdownSelectorItem
                                    key={
                                        option.key ??
                                        `dd-selector-item-${index}`
                                    }
                                    label={option.label}
                                    value={option.value}
                                    iconSrc={
                                        props.values?.includes(option.value)
                                            ? radioChecked
                                            : radioUnchecked
                                    }
                                    onSelect={() => {
                                        if (
                                            props.values?.includes(option.value)
                                        ) {
                                            props.onChange?.(
                                                props.values.filter(
                                                    (o) => o !== option.value
                                                )
                                            );
                                        } else {
                                            if (props.values == null) {
                                                props.onChange?.([
                                                    option.value,
                                                ]);
                                            } else {
                                                props.onChange?.([
                                                    ...props.values,
                                                    option.value,
                                                ]);
                                            }
                                        }
                                    }}
                                />
                            ))}
                        </FlexColumn>
                    </div>
                </ClickOutAlertWrapper>
            )}
        </div>
    );
}
