import * as React from "react";

import AyaLogo from "../../../../assets/images/common/png/aya_logo.png";

type Props = Readonly<{}>;

function MainHeader(props: Props): JSX.Element {
    return (
        <div id="banner-container">
            <div id="banner-left-margin" />
            <div id="banner-contents">
                <img src={AyaLogo} alt="Ayaseye" />
            </div>
            <div id="banner-right-margin" />
        </div>
    );
}

export default MainHeader;
