export enum VitalityRating {
    LOWEST = "lowest",
    LOW = "low",
    MEDIUM = "medium",
    HIGH = "high",
    HIGHEST = "highest",
}

export default class VitalityRatingUtils {
    public static coerce(val: string | VitalityRating): VitalityRating {
        if (typeof val === "string") {
            switch (val.trim().toLowerCase()) {
                case "lowest":
                    return VitalityRating.LOWEST;
                case "low":
                    return VitalityRating.LOW;
                case "medium":
                    return VitalityRating.MEDIUM;
                case "high":
                    return VitalityRating.HIGH;
                case "highest":
                    return VitalityRating.HIGHEST;
                default:
                    return VitalityRating.MEDIUM;
            }
        } else {
            return val;
        }
    }

    public static getLabel(val: string | VitalityRating): string {
        const coercedValue = typeof val === "string" ? this.coerce(val) : val;

        switch (coercedValue) {
            case VitalityRating.LOWEST:
                return "Lowest";
            case VitalityRating.LOW:
                return "Low";
            case VitalityRating.MEDIUM:
                return "Medium";
            case VitalityRating.HIGH:
                return "High";
            case VitalityRating.HIGHEST:
                return "Highest";
        }
    }
}
