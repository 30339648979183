import {
    Color,
    ColorUtils,
    Blue,
    Green,
    Red,
} from "../../../../utils/sys/Color";
import FlexColumn from "../../layout/Flexbox/FlexColumn";
import FlexRow from "../../layout/Flexbox/FlexRow";
import InventoryItemIcon from "../../../../pages/character/components/InventoryItemIcon/InventoryItemIcon";
import InventoryItemIconLibrary from "../../../../utils/sys/InventoryItemIconLibrary";
import Modal from "../Modal/Modal";
import NumericSlider from "../../input/NumericSlider/NumericSlider";
import { useState } from "react";

import bgOptions from "../../../../assets/json/item_icons/bg_options.json";
import iconOptions from "../../../../assets/json/item_icons/icon_options.json";

import "./InventoryItemIconEditorModal_style.css";

type Props = {
    isVisible?: boolean;
    initialValues?: {
        bgSrc?: string;
        iconSrc?: string;
        bgColor?: Color;
        iconColor?: Color;
    };
    onClose?: () => void;
    onConfirm?: (src: {
        bgSrc: string | null;
        iconSrc: string | null;
        bgColor: Color | null;
        iconColor: Color | null;
    }) => void;
};

export default function InventoryItemIconEditorModal(
    props: Props
): JSX.Element {
    const [selectedBG, setSelectedBG] = useState<string | null>(
        props.initialValues?.bgSrc ?? null
    );
    const [selectedIcon, setSelectedIcon] = useState<string | null>(
        props.initialValues?.iconSrc ?? null
    );
    const [bgRGB, setBGRGB] = useState<{ r: number; g: number; b: number }>(
        props.initialValues?.bgColor === undefined
            ? { r: 0, g: 0, b: 0 }
            : {
                  r: ColorUtils.split(props.initialValues.bgColor).r,
                  g: ColorUtils.split(props.initialValues.bgColor).g,
                  b: ColorUtils.split(props.initialValues.bgColor).b,
              }
    );
    const [iconRGB, setIconRGB] = useState<{ r: number; g: number; b: number }>(
        props.initialValues?.iconColor === undefined
            ? { r: 0, g: 0, b: 0 }
            : {
                  r: ColorUtils.split(props.initialValues.iconColor).r,
                  g: ColorUtils.split(props.initialValues.iconColor).g,
                  b: ColorUtils.split(props.initialValues.iconColor).b,
              }
    );

    const bgs = (): React.ReactNode => {
        const rows: Array<React.ReactNode> = [];
        for (let v = 0; v < bgOptions.length; v += 5) {
            const bgs: Array<React.ReactNode> = [];
            for (let i = v; i < v + 5 && i < bgOptions.length; ++i) {
                bgs.push(
                    <InventoryItemIcon
                        key={bgOptions[i]}
                        className={`icon${
                            selectedBG === bgOptions[i] ? ` selected` : ``
                        }`}
                        bgSrc={bgOptions[i]}
                        onClick={() => {
                            setSelectedBG(bgOptions[i]);
                        }}
                    />
                );
            }
            rows.push(<FlexRow key={`bg-row-${v / 5}`}>{bgs}</FlexRow>);
        }
        return <FlexColumn className="icon-list-container">{rows}</FlexColumn>;
    };

    const icons = (): React.ReactNode => {
        const rows: Array<React.ReactNode> = [];
        for (let v = 0; v < iconOptions.length; v += 5) {
            const icons: Array<React.ReactNode> = [];
            for (let i = v; i < v + 5 && i < iconOptions.length; ++i) {
                icons.push(
                    <InventoryItemIcon
                        key={iconOptions[i]}
                        className={`icon${
                            selectedIcon === iconOptions[i] ? ` selected` : ``
                        }`}
                        iconSrc={iconOptions[i]}
                        onClick={() => {
                            setSelectedIcon(iconOptions[i]);
                        }}
                    />
                );
            }
            rows.push(<FlexRow key={`icon-row-${v / 5}`}>{icons}</FlexRow>);
        }
        return <FlexColumn className="icon-list-container">{rows}</FlexColumn>;
    };

    return (
        <Modal
            header="Edit Item Icon"
            isVisible={props.isVisible}
            footer="standard-ok-cancel"
            onClose={() => {
                props.onClose?.();
            }}
            onConfirm={() => {
                props.onConfirm?.({
                    bgSrc: selectedBG,
                    iconSrc: selectedIcon,
                    bgColor: `#${bgRGB.r.toString(16).padStart(2, "0")}${bgRGB.g
                        .toString(16)
                        .padStart(2, "0")}${bgRGB.b
                        .toString(16)
                        .padStart(2, "0")}`,
                    iconColor: `#${iconRGB.r
                        .toString(16)
                        .padStart(2, "0")}${iconRGB.g
                        .toString(16)
                        .padStart(2, "0")}${iconRGB.b
                        .toString(16)
                        .padStart(2, "0")}`,
                });
            }}
        >
            <FlexColumn className="item-icon-editor-container">
                <FlexRow>
                    {bgs()}
                    {icons()}
                </FlexRow>
                <FlexRow>
                    <FlexColumn>
                        <NumericSlider
                            label="R"
                            min={0}
                            max={255}
                            fillColor={Red}
                            value={bgRGB.r}
                            onChange={(val) => {
                                setBGRGB({ r: val, g: bgRGB.g, b: bgRGB.b });
                            }}
                        />
                        <NumericSlider
                            label="G"
                            min={0}
                            max={255}
                            fillColor={Green}
                            value={bgRGB.g}
                            onChange={(val) => {
                                setBGRGB({ r: bgRGB.r, g: val, b: bgRGB.b });
                            }}
                        />
                        <NumericSlider
                            label="B"
                            min={0}
                            max={255}
                            fillColor={Blue}
                            value={bgRGB.b}
                            onChange={(val) => {
                                setBGRGB({ r: bgRGB.r, g: bgRGB.g, b: val });
                            }}
                        />
                    </FlexColumn>
                    <FlexColumn>
                        <NumericSlider
                            label="R"
                            min={0}
                            max={255}
                            fillColor={Red}
                            value={iconRGB.r}
                            onChange={(val) => {
                                setIconRGB({
                                    r: val,
                                    g: iconRGB.g,
                                    b: iconRGB.b,
                                });
                            }}
                        />
                        <NumericSlider
                            label="G"
                            min={0}
                            max={255}
                            fillColor={Green}
                            value={iconRGB.g}
                            onChange={(val) => {
                                setIconRGB({
                                    r: iconRGB.r,
                                    g: val,
                                    b: iconRGB.b,
                                });
                            }}
                        />
                        <NumericSlider
                            label="B"
                            min={0}
                            max={255}
                            fillColor={Blue}
                            value={iconRGB.b}
                            onChange={(val) => {
                                setIconRGB({
                                    r: iconRGB.r,
                                    g: iconRGB.g,
                                    b: val,
                                });
                            }}
                        />
                    </FlexColumn>
                </FlexRow>
                <FlexRow className="result-container">
                    <InventoryItemIcon
                        className="result-icon"
                        bgSrc={selectedBG}
                        iconSrc={selectedIcon}
                        bgColor={`rgb(${bgRGB.r}, ${bgRGB.g}, ${bgRGB.b})`}
                        iconColor={`rgb(${iconRGB.r}, ${iconRGB.g}, ${iconRGB.b})`}
                        style={{ border: "1px solid black" }}
                    />
                </FlexRow>
            </FlexColumn>
        </Modal>
    );
}
