import CreateTrackableAsyncFunction from "../utils/CreateTrackableAsyncFunction";
import { ArticleJSON } from "../utils/sys/ArticleJSON";

const getArticleURL = "https://ayaseye.com/api/article/get_article.php";
const searchArticleURL = "https://ayaseye.com/api/article/search_articles.php";
const recentArticleTagsURL =
    "https://ayaseye.com/api/article/get_recent_article_tags.php";
const createArticleURL = "https://ayaseye.com/api/article/create_article.php";
const updateArticleURL = "https://ayaseye.com/api/article/update_article.php";

async function getArticle(args: {
    identifier: {
        // Only one identifier parameter should be supplied
        id?: number;
        article?: string;
        search?: string;
        category?: string;
        system?: string;
    };
    token?: string;
    format: 0 | 1;
    count?: number;
}): Promise<ArticleJSON | null> {
    const payload = { token: args.token };

    let url = null;
    if (args.identifier.id != undefined) {
        url = `${getArticleURL}?id=${args.identifier.id}&format=${args.format}`;
    } else if (args.identifier.article != undefined) {
        url = `${getArticleURL}?article=${args.identifier.article}&format=${args.format}`;
    } else if (args.identifier.category != undefined) {
        url = `${getArticleURL}?category=${args.identifier.category}&format=${args.format}`;
    } else if (args.identifier.search != undefined) {
        url = `${searchArticleURL}?search=${args.identifier.search}&format=${args.format}`;
    } else if (args.identifier.system != undefined) {
        if (args.identifier.system === "recent") {
            url = `${getArticleURL}?system=recent`;
        }
    }

    if (url == null) {
        return null;
    }

    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then<ArticleJSON | null>(async (res) => {
        const resultData = await res.json();

        if (resultData["id"] !== undefined) {
            return resultData;
        } else {
            return null;
        }
    });

    return response;
}

export function useGetArticle() {
    return CreateTrackableAsyncFunction(getArticle);
}

async function getRecentArticleTags(args: {
    token?: string;
    count?: number;
}): Promise<ArticleJSON[]> {
    const payload = { token: args.token };

    const response = await fetch(
        `${recentArticleTagsURL}?count=${args.count ?? 20}`,
        {
            method: "POST",
            body: JSON.stringify(payload),
        }
    ).then<ArticleJSON[]>(async (res) => {
        const resultData = await res.json();

        return resultData;
    });

    return response;
}

export function useGetRecentArticleTags() {
    return CreateTrackableAsyncFunction(getRecentArticleTags);
}

async function createArticle(args: {
    token?: string;
    data: ArticleJSON;
}): Promise<ArticleJSON | null> {
    const payload = { token: args.token, variables: args.data };

    const response = await fetch(`${createArticleURL}`, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then<ArticleJSON | null>(async (res) => {
        const resultData = await res.json();

        if (resultData["id"] !== undefined) {
            return resultData;
        } else {
            return null;
        }
    });

    return response;
}

export function useCreateArticle() {
    return CreateTrackableAsyncFunction(createArticle);
}

async function updateArticle(args: {
    token?: string;
    id: number;
    data: ArticleJSON;
}): Promise<ArticleJSON | null> {
    const payload = { token: args.token, variables: args.data };

    const response = await fetch(`${updateArticleURL}?id=${args.id}`, {
        method: "POST",
        body: JSON.stringify(payload),
    }).then<ArticleJSON | null>(async (res) => {
        const resultData = await res.json();

        if (
            resultData["id"] !== undefined &&
            resultData["id"].toString() === args.id.toString()
        ) {
            return resultData;
        } else {
            return null;
        }
    });

    return response;
}

export function useUpdateArticle() {
    return CreateTrackableAsyncFunction(updateArticle);
}
