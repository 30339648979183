import { Dictionary } from "../../sys/Dictionary";

export default class Attribute {
    private _statKey: string;
    public get statKey(): string {
        return this._statKey;
    }

    private _title: string;
    public get title(): string {
        return this._title;
    }

    private _lowestKey: number = 0;
    private _highestKey: number = 0;

    private _negativeInfinityGrowth = 0;
    private _positiveInfinityGrowth = 0;

    private scoreMap: Dictionary<number | string, number>;

    constructor(statKey: string, title: string) {
        this._statKey = statKey;
        this._title = title;
        this.scoreMap = new Dictionary();
    }

    public static fromJSON(json: {
        key: string;
        title: string;
        scores: { key: string; value: string | number }[];
    }): Attribute {
        const attribute = new Attribute(json.key, json.title);

        if (json.scores != null) {
            json.scores.forEach((score) => {
                const keyInt =
                    typeof score.key === "number"
                        ? score.key
                        : typeof score.key === "string"
                        ? Number.parseInt(score.key)
                        : null;
                const scoreFloat =
                    typeof score.value === "number"
                        ? score.value
                        : typeof score.value === "string"
                        ? Number.parseFloat(score.value)
                        : null;

                if (scoreFloat != null && !Number.isNaN(scoreFloat)) {
                    attribute.addToScoreMap(
                        keyInt == null || Number.isNaN(keyInt)
                            ? score.key
                            : keyInt,
                        scoreFloat == null ? 0 : scoreFloat
                    );
                }
            });
        }

        return attribute;
    }

    public addToScoreMap(scoreKey: number | string, value: number): void {
        this.scoreMap.set(scoreKey, value);
        if (typeof scoreKey === "number") {
            if (scoreKey < this._lowestKey) {
                this._lowestKey = scoreKey;
            }
            if (scoreKey > this._highestKey) {
                this._highestKey = scoreKey;
            }
        } else if (scoreKey === "P") {
            this._positiveInfinityGrowth = value;
        } else if (scoreKey === "N") {
            this._negativeInfinityGrowth = value;
        }
    }

    public removeFromScoreMap(scoreKey: number | string): void {
        this.scoreMap.remove(scoreKey);

        if (typeof scoreKey === "number") {
            let lowest = Number.MAX_SAFE_INTEGER;
            let highest = Number.MIN_SAFE_INTEGER;
            this.scoreMap.forEach((k) => {
                if (typeof k.key === "number") {
                    if (k.key < lowest) {
                        lowest = k.key;
                    }
                    if (k.key > highest) {
                        highest = k.key;
                    }
                } else if (k.key === "P") {
                    this._positiveInfinityGrowth = 0;
                } else if (k.key === "N") {
                    this._negativeInfinityGrowth = 0;
                }
            });
        }
    }

    public hasValueForScore(scoreKey: number | string): boolean {
        return this.scoreMap.hasKey(scoreKey);
    }

    public getValueForScore(scoreKey: number): number {
        if (this.scoreMap.hasKey(scoreKey)) {
            return this.scoreMap.get(scoreKey) ?? 0;
        } else {
            if (scoreKey > this._highestKey) {
                const limit = this.scoreMap.get(this._highestKey);
                if (limit !== undefined) {
                    return (
                        (scoreKey - this._highestKey) *
                            this._positiveInfinityGrowth +
                        limit
                    );
                }
            } else if (scoreKey < this._lowestKey) {
                const limit = this.scoreMap.get(this._lowestKey);
                if (limit !== undefined) {
                    return (
                        (this._lowestKey - scoreKey) *
                            this._negativeInfinityGrowth +
                        limit
                    );
                }
            }
        }
        return 0;
    }
}
