import ClickOutAlertWrapper from "../../wrappers/ClickOutAlertWrapper/ClickOutAlertWrapper";
import FlexColumn from "../../layout/Flexbox/FlexColumn";
import ListMenu from "./ListMenu";

import { useRef, useState } from "react";

type Props = {
    label: string;
    children: Array<{ value: string; label: string; onClick?: () => void }>;
    buttonClassName?: string;
    labelStyle?: React.CSSProperties;
    labelClassName?: string;
};

export default function ListMenuClick(props: Props): JSX.Element {
    const menuRef = useRef(null);
    const [isMenuVisible, setMenuVisibility] = useState<boolean>(false);

    return (
        <FlexColumn
            className={`list-menu-click${
                props.buttonClassName != null ? ` ${props.buttonClassName}` : ``
            }`}
        >
            <div ref={menuRef}>
                <div
                    className={`list-menu-button${
                        props.labelClassName != null
                            ? ` ${props.labelClassName}`
                            : ``
                    }`}
                    style={props.labelStyle}
                    onClick={() => {
                        setMenuVisibility(!isMenuVisible);
                    }}
                >
                    {props.label}
                </div>
                {isMenuVisible && (
                    <ClickOutAlertWrapper
                        forwardRef={menuRef}
                        onClickOut={() => {
                            setMenuVisibility(false);
                        }}
                    >
                        <ListMenu
                            className={isMenuVisible ? "visible" : undefined}
                            openMode="click"
                            onItemClick={(value) => {
                                setMenuVisibility(false);
                                props.children
                                    .find((c) => c.value === value)
                                    ?.onClick?.();
                            }}
                        >
                            {props.children}
                        </ListMenu>
                    </ClickOutAlertWrapper>
                )}
            </div>
        </FlexColumn>
    );
}
