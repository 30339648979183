import ClickOutAlertWrapper from "../../wrappers/ClickOutAlertWrapper/ClickOutAlertWrapper";
import { useRef, useState } from "react";

import "./MultiLineTextInputToggleField_style.css";

type Props = {
    label?: string | null | undefined;
    placeholderText?: string;
    displayFieldStyle?: React.CSSProperties | undefined;
    editorFieldStyle?: React.CSSProperties | undefined;
    isEditable?: boolean;
    onStopEditing?: (val: string) => void;
    children: string;
};

export default function MultiLineTextField(props: Props): JSX.Element {
    const isEditable = props.isEditable != null ? props.isEditable : true;
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [textValue, setTextValue] = useState<string>(props.children);

    const ref = useRef(null);

    const textRef = useRef<string>();
    textRef.current = textValue;

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            // props.onEnterKeyPress?.();
        }
    };

    return (
        <div
            ref={ref}
            className={`multi-line-text-input-toggle-field-container`}
        >
            {props.label != null && <div className="label">{props.label}</div>}
            {isEditing ? (
                <ClickOutAlertWrapper
                    forwardRef={ref}
                    onClickOut={() => {
                        setIsEditing(false);
                        if (textRef.current) {
                            props.onStopEditing?.(textRef.current);
                        }
                    }}
                >
                    <textarea
                        className={`editor-field`}
                        style={props.editorFieldStyle}
                        value={textValue}
                        autoFocus
                        onKeyDown={(event) => {
                            handleKeyDown(event);
                        }}
                        onChange={(e) => {
                            setTextValue(e.target.value);
                        }}
                        onBlur={() => {
                            setIsEditing(false);
                            props.onStopEditing?.(textValue);
                        }}
                    />
                </ClickOutAlertWrapper>
            ) : (
                <div
                    className={`display-field${
                        textValue.length === 0 ? ` placeholder` : ``
                    }${isEditable ? " editable" : ""}`}
                    style={props.displayFieldStyle}
                    onClick={() => {
                        if (isEditable) {
                            setIsEditing(true);
                        }
                    }}
                >
                    {textValue.length === 0 &&
                    props.placeholderText !== undefined
                        ? props.placeholderText
                        : textValue}
                </div>
            )}
        </div>
    );
}
