import TextField from "../../display/TextField/TextField";

import "./List_style.css";

type Props = {
    id: string;
    label?: string;
    children?: React.ReactNode;
    selected?: boolean;
    onClick?: () => void;
};

export default function ListItem({
    id,
    label,
    children,
    selected = false,
    onClick,
}: Props): JSX.Element {
    return (
        <div
            className={`list-item-container${selected ? ` selected` : ``}`}
            onClick={onClick}
        >
            {children === undefined ? <TextField>{label}</TextField> : children}
        </div>
    );
}
