import FlexRow from "../../layout/Flexbox/FlexRow";

type Props = {
    label: string;
    value?: string;
    onSelect?: () => void;
    iconSrc?: string;
};

export default function DropdownSelectorItem(props: Props): JSX.Element {
    return (
        <FlexRow
            className="option-style"
            width="auto"
            gap={16}
            onClick={(e) => {
                props.onSelect?.();
                e?.stopPropagation();
            }}
        >
            {props.iconSrc != null && (
                <img className="icon-style" src={props.iconSrc} />
            )}
            {props.label}
        </FlexRow>
    );
}
