import {
    CharacterData,
    CharacterDataUtils,
} from "../../../../utils/game/character/CharacterData";

import CharacterSchool from "../../../../utils/game/mechanics/character/CharacterSchool";
import CharacterSchoolPanel from "../CharacterSchoolPanel/CharacterSchoolPanel";
import CheckboxInputField from "../../../../common/components/input/CheckboxInputField/CheckboxInputField";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import NumberInputToggleField from "../../../../common/components/input/NumberInputToggleField/NumberInputToggleField";
import TabControl from "../../../../common/components/layout/TabControl/TabControl";
import LoadSchoolModal from "../../modals/LoadSchoolModal/LoadSchoolModal";
import { KeyValuePair } from "../../../../utils/sys/KeyValuePair";
import School from "../../../../utils/game/mechanics/School";
import TextField from "../../../../common/components/display/TextField/TextField";

import { useMemo, useState } from "react";

import pencilIcon from "../../../../assets/images/common/svg/pencil.svg";
import plusIcon from "../../../../assets/images/common/svg/plus.svg";

import "./CharacterSheetSchoolsPanel_style.css";

type Props = {
    characterData: CharacterData;
    isEditable?: boolean;
    isEditing?: boolean;
    onEditingChanged?: (editing: boolean) => void;
    pointSpendEnabled?: boolean;
    onPointSpendEnabledChanged?: (val: boolean) => void;
    pendingSchoolPointSpend?: Array<{ id: number; points: number }>;
    onPendingSchoolPointSpendChanged?: (
        vals: Array<{ id: number; points: number }>
    ) => void;
    onSchoolsAdded?: (schools: Array<School>) => void;
    onCharacterDataValueChanged?: (val: KeyValuePair[]) => void;
};

export default function CharacterSheetSchoolsPanel(props: Props): JSX.Element {
    const pendingSchoolPointSpend = props.pendingSchoolPointSpend ?? [];

    const [isLoadSchoolModalVisible, setLoadSchoolModalVisibility] =
        useState<boolean>(false);
    const [schoolOptions, setSchoolOptions] = useState<
        Array<School> | undefined
    >(undefined);
    const optionFilter = useMemo(() => {
        const schoolIDs = props.characterData.schools.map((s) => s.id);
        return (school: School) => !schoolIDs.includes(school.id);
    }, [props.characterData]);
    const schoolPoints = useMemo(() => {
        return props.characterData.schoolPoints;
    }, [props.characterData.schoolPoints]);

    const specializationLevels = CharacterDataUtils.getSpecializationLevels(
        props.characterData
    );

    return (
        <FlexColumn className="character-sheet-schools-panel">
            <FlexRow className="toolbar" alignment="end">
                <FlexRow>
                    {props.isEditable === true ? (
                        <FlexRow gap={8}>
                            <NumberInputToggleField
                                label={"School Points"}
                                value={schoolPoints}
                                formatValueTextField={(val) => {
                                    if (
                                        props.isEditing === true &&
                                        props.pointSpendEnabled === true
                                    ) {
                                        return (
                                            val -
                                            (props.pendingSchoolPointSpend?.reduce(
                                                (acc, curr) =>
                                                    acc + curr.points,
                                                0
                                            ) ?? 0)
                                        ).toString();
                                    } else {
                                        return val.toString();
                                    }
                                }}
                                onStopEditing={(val) => {
                                    props.onCharacterDataValueChanged?.([
                                        {
                                            key: "available_school_points",
                                            value: val,
                                        },
                                    ]);
                                }}
                            />
                            <CheckboxInputField
                                label={"Enable Point Spend"}
                                checked={props.pointSpendEnabled === true}
                                onChange={() => {
                                    props.onPointSpendEnabledChanged?.(
                                        !props.pointSpendEnabled
                                    );
                                }}
                            />
                        </FlexRow>
                    ) : (
                        <TextField className="header" type="bold">
                            Schools
                        </TextField>
                    )}
                </FlexRow>
                <FlexRow alignment="end" width="auto">
                    {props.isEditable && (
                        <button
                            onClick={() => {
                                const newIsEditing = !props.isEditing;

                                if (!newIsEditing) {
                                    const spentPoints =
                                        pendingSchoolPointSpend.reduce(
                                            (acc, curr) => acc + curr.points,
                                            0
                                        );
                                    if (spentPoints !== 0) {
                                        const updates: Array<{
                                            key: string;
                                            value: string | number;
                                        }> = [];
                                        const newCharacterSchools =
                                            props.characterData.schools.map(
                                                (school) => {
                                                    if (
                                                        pendingSchoolPointSpend.find(
                                                            (entry) =>
                                                                entry.id ===
                                                                school.id
                                                        ) !== undefined
                                                    ) {
                                                        school.score =
                                                            school.score +
                                                            (pendingSchoolPointSpend.find(
                                                                (entry) =>
                                                                    entry.id ===
                                                                    school.id
                                                            )?.points ?? 0);
                                                    }
                                                    return school;
                                                }
                                            );
                                        if (props.pointSpendEnabled === true) {
                                            updates.push({
                                                key: "available_school_points",
                                                value:
                                                    schoolPoints - spentPoints,
                                            });
                                        }
                                        updates.push({
                                            key: "schools",
                                            value: JSON.stringify(
                                                newCharacterSchools.map(
                                                    (school) => school.toJSON()
                                                )
                                            ),
                                        });
                                        props.onCharacterDataValueChanged?.(
                                            updates
                                        );
                                    }
                                    props.onPendingSchoolPointSpendChanged?.(
                                        []
                                    );
                                }
                                props.onEditingChanged?.(newIsEditing);
                            }}
                        >
                            <img src={pencilIcon} alt="..." />
                        </button>
                    )}
                    {props.isEditable && (
                        <button
                            onClick={() => {
                                setLoadSchoolModalVisibility(true);
                            }}
                        >
                            <img src={plusIcon} alt="+" />
                        </button>
                    )}
                </FlexRow>
            </FlexRow>
            {props.characterData.schools.map((school) => (
                <CharacterSchoolPanel
                    key={school.id}
                    school={school}
                    specializationLevel={
                        specializationLevels.find(
                            (entry) => entry.schoolTag === school.tag
                        )?.specializationLevel ?? 0
                    }
                    isEditable={props.isEditable}
                    isEditing={props.isEditing}
                    pendingPointSpend={
                        pendingSchoolPointSpend.find(
                            (entry) => entry.id === school.id
                        )?.points ?? undefined
                    }
                    onPendingPointSpendChanged={(_, newValue) => {
                        const index = pendingSchoolPointSpend.findIndex(
                            (entry) => entry.id === school.id
                        );
                        if (index >= 0) {
                            props.onPendingSchoolPointSpendChanged?.(
                                pendingSchoolPointSpend.map((entry) => {
                                    if (entry.id === school.id) {
                                        return {
                                            id: entry.id,
                                            points: newValue,
                                        };
                                    } else {
                                        return entry;
                                    }
                                })
                            );
                        } else {
                            props.onPendingSchoolPointSpendChanged?.([
                                ...pendingSchoolPointSpend,
                                { id: school.id, points: newValue },
                            ]);
                        }
                    }}
                    onSchoolRemoved={() => {
                        props.onCharacterDataValueChanged?.([
                            {
                                key: "schools",
                                value: JSON.stringify(
                                    props.characterData.schools
                                        .filter((sch) => sch !== school)
                                        .map((sch) => sch.toJSON())
                                ),
                            },
                        ]);
                    }}
                />
            ))}
            {props.isEditable && isLoadSchoolModalVisible && (
                <LoadSchoolModal
                    isVisible={isLoadSchoolModalVisible}
                    schoolOptions={schoolOptions}
                    optionFilter={optionFilter}
                    onSchoolOptionsChanged={(schools) => {
                        setSchoolOptions(schools);
                    }}
                    onClose={() => {
                        setLoadSchoolModalVisibility(false);
                    }}
                    onConfirm={(schools) => {
                        props.onSchoolsAdded?.(schools);
                        setLoadSchoolModalVisibility(false);
                    }}
                />
            )}
        </FlexColumn>
    );
}
