import { KeyValuePair } from "./KeyValuePair";

export default class KeyValueStringParser {
    public static parseKeyValueString(
        input: string,
        trim: boolean = true
    ): KeyValuePair<string, string> {
        return input.includes(":")
            ? {
                  key: trim
                      ? input.substring(0, input.indexOf(":"))
                      : input.substring(0, input.indexOf(":")).trim(),
                  value: trim
                      ? input.substring(input.indexOf(":") + 1)
                      : input.substring(input.indexOf(":") + 1).trim(),
              }
            : { key: trim ? input.trim() : input, value: "" };
    }

    public static parseKeyValueListString(
        input: string,
        trim: boolean = true
    ): Array<KeyValuePair<string, string>> {
        return input.includes(";")
            ? input
                  .split(";")
                  .map((entry) => this.parseKeyValueString(entry, trim))
            : [this.parseKeyValueString(input)];
    }
}
