import AttributePanel from "../AttributePanel/AttributePanel";
import ProficiencyPanel from "../ProficiencyPanel/ProficiencyPanel";
import { CharacterData } from "../../../../utils/game/character/CharacterData";
import StatBoard from "../../../../utils/game/mechanics/StatBoard";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";

type Props = { characterData: CharacterData; statBoard: StatBoard };

export default function AttributesContainer({
    characterData,
    statBoard,
}: Props): JSX.Element {
    return (
        <FlexColumn>
            <FlexRow>
                <AttributePanel statBoard={statBoard} statKey="AWA" />
                <AttributePanel statBoard={statBoard} statKey="WGTALL" />
            </FlexRow>
            <FlexRow>
                <AttributePanel statBoard={statBoard} statKey="REG" />
                <AttributePanel statBoard={statBoard} statKey="SUR" />
            </FlexRow>
            <FlexRow>
                <AttributePanel statBoard={statBoard} statKey="WIL" />
                <AttributePanel statBoard={statBoard} statKey="STLTH" />
            </FlexRow>
        </FlexColumn>
    );
}
