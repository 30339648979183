export type CompoundDiceType = Array<DiceType | Dice | number>;

export type DiceType = {
    /** The number of individual dice represented by this object. */
    count: number;
    /** The number of sides of each individual die represented by this object. */
    sides: number;
};

export type DiceRoll = {
    /** An array of the individual die roll results. */
    rolls: Array<number>;
    /** The total sum of all the die roll results. */
    total: number;
};

/** Class version of a Dice object, giving direct access to die-rolling functionality. */
export class Dice {
    /** The underlying DiceType object around which this object wraps. */
    private primitive: DiceType;

    constructor(count: number, sides: number) {
        this.primitive = { count, sides };
    }

    /** The number of individual dice represented by this object. */
    public get count() {
        return this.primitive.count;
    }
    public set count(val: number) {
        this.primitive.count = val;
    }

    /** The number of sides of each individual die represented by this object. */
    public get sides() {
        return this.primitive.sides;
    }
    public set sides(val: number) {
        this.primitive.sides = val;
    }

    /** Roll the die/dice represented by this object, returning the final total of all rolls. */
    public roll(): number {
        return DiceUtils.rollDice(this.primitive);
    }

    /** Roll the die/dice represented by this object, returning the final total of all rolls, plus the results of each individual die roll. */
    public fullRoll(): DiceRoll {
        return DiceUtils.fullRollDice(this.primitive);
    }

    public clone(): Dice {
        return new Dice(this.count, this.sides);
    }

    public toString(): string {
        return `${this.count}d${this.sides}`;
    }
}

export class DiceUtils {
    /** Private helper function for rolling a single Dice/DiceType instance and returning final numeric total. */
    private static rollDiceSingle(dice: DiceType | Dice): number {
        if (dice.count < 1) {
            return 0;
        }
        if (dice.sides < 1) {
            return 0;
        }

        let total = 0;
        if (dice.sides === 1) {
            total += dice.count;
        } else {
            for (let i = 0; i < dice.count; ++i) {
                total += Math.floor(Math.random() * dice.sides) + 1;
            }
        }

        return total;
    }

    /** Private helper function for rolling a single Dice/DiceType instance and returning broken down results. */
    private static fullRollDiceSingle(dice: DiceType | Dice): DiceRoll {
        if (dice.count < 1) {
            return { rolls: [], total: 0 };
        }
        if (dice.sides < 1) {
            return { rolls: Array<number>(dice.count).fill(0), total: 0 };
        }

        let rolls: Array<number> = [];
        if (dice.sides === 1) {
            rolls = Array<number>(dice.count).fill(1);
        } else {
            for (let i = 0; i < dice.count; ++i) {
                rolls.push(Math.floor(Math.random() * dice.sides) + 1);
            }
        }

        return { rolls, total: rolls.reduce((acc, curr) => acc + curr, 0) };
    }

    /** Roll one or more Dice and/or DiceTypes, returning the final total of all rolls. */
    public static rollDice(...dice: Array<DiceType | Dice | number>): number {
        let total = 0;
        dice.forEach((d) => {
            total += typeof d === "number" ? d : this.rollDiceSingle(d);
        });

        return total;
    }

    /** Roll one or more Dice and/or DiceTypes, returning the final total of all rolls, as well as the results of each individual die roll. */
    public static fullRollDice(
        ...dice: Array<DiceType | Dice | number>
    ): DiceRoll {
        const rolls: Array<number> = [];
        let total: number = 0;

        dice.forEach((d) => {
            if (typeof d === "number") {
                rolls.push(d);
                total += d;
            } else {
                const roll = DiceUtils.fullRollDiceSingle(d);
                rolls.push(...roll.rolls);
                total += roll.total;
            }
        });

        return { rolls, total };
    }

    /** Parse the given string as a Dice instance, and return the total of rolling that instance. */
    public static rollDiceString(val: string): number {
        return DiceUtils.rollDice(DiceUtils.parseDiceString(val));
    }

    /** Parse the given string as a Dice instance, and return the total of rolling that instance, as well as the results of each individual die roll. */
    public static fullRollDiceString(val: string): DiceRoll {
        return DiceUtils.fullRollDice(DiceUtils.parseDiceString(val));
    }

    /** Parse the given string as a Dice instance. */
    public static parseDiceString(input: string): DiceType {
        input = input.replace(/\s/g, "").toLowerCase();
        let matches = input.match(/(\d*)d(\d+)/);
        if (matches) {
            let c = matches[1].length == 0 ? 1 : parseInt(matches[1]);
            let d = parseInt(matches[2]);
            if (!isNaN(c) && !isNaN(d)) {
                return { count: c, sides: d };
            }
        }
        return { count: 1, sides: 0 };
    }

    public static concat(...diceN: CompoundDiceType): CompoundDiceType {
        return [...diceN];
    }

    public static stringify(...dice: CompoundDiceType): string {
        if (dice.length === 0) {
            return "";
        } else if (dice.length === 1) {
            if (typeof dice[0] === "number") {
                return dice[0].toString();
            } else {
                return `${dice[0].count}d${dice[0].sides}`;
            }
        } else {
            let sidesPool = dice.flatMap<number>((d) => {
                return typeof d === "number" ? [] : d.sides;
            });
            sidesPool = sidesPool.filter(
                (d, index) => sidesPool.indexOf(d) === index
            );

            return [
                ...sidesPool.map(
                    (d) =>
                        `${dice
                            .flatMap<DiceType>((d2) => {
                                return typeof d2 === "number"
                                    ? []
                                    : { count: d2.count, sides: d2.sides };
                            })
                            .filter((d2) => d2.sides === d)
                            .reduce((acc, curr) => acc + curr.count, 0)}d${d}`
                ),
                dice
                    .flatMap<number>((d2) => (typeof d2 === "number" ? d2 : []))
                    .reduce((acc, curr) => acc + curr, 0),
            ].join("+");
        }
    }
}
