import { UIInventoryItemContainerProps } from "./InventoryItemContainer";
import { useMemo } from "react";

import InventoryItemIcon from "../InventoryItemIcon/InventoryItemIcon";
import InventoryItemDetails from "./InventoryItemDetails";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import TextField from "../../../../common/components/display/TextField/TextField";
import Tooltip from "../../../../common/components/wrappers/Tooltip/Tooltip";

import pencilIcon from "../../../../assets/images/common/svg/pencil.svg";

import "./style/InventoryItemInfoSlot_style.css";

type Props = UIInventoryItemContainerProps & {
    className?: string;
    tooltipEnabled?: boolean;
    onEditRequested?: () => void;
};

export default function InventoryItemInfoSlot(props: Props): JSX.Element {
    const emptyBGSrc =
        props.emptyBGSrc === undefined ? "back01" : props.emptyBGSrc;

    const tooltipContent = useMemo(() => {
        return <InventoryItemDetails id="tooltip" item={props.item} />;
    }, [props.item]);

    return (
        <Tooltip
            tooltipContent={tooltipContent}
            enabled={props.tooltipEnabled && props.item != null}
            offsetX={0}
            offsetY={0}
            direction="auto-left-right"
        >
            <FlexRow
                className={`inventory-item-info-slot-container${
                    props.className != null ? ` ${props.className}` : ``
                }`}
                onClick={(e) => {
                    if (props.isEditable) {
                        props.onClick?.({
                            event: e,
                            containerProps: props,
                        });
                    }
                }}
            >
                <div className="icon-container">
                    <InventoryItemIcon
                        className="icon"
                        bgSrc={
                            props.item == null ? emptyBGSrc : props.item.bgSrc
                        }
                        bgColor={props.item == null ? null : props.item.bgColor}
                        iconSrc={
                            props.item == null
                                ? props.emptyIconSrc
                                : props.item.iconSrc
                        }
                        iconColor={
                            props.item == null ? null : props.item.iconColor
                        }
                        showHighlight={props.isSelected || props.isValidTarget}
                        highlightColor={
                            props.isSelected
                                ? "active"
                                : props.isValidTarget
                                ? "valid"
                                : undefined
                        }
                    />
                </div>
                <div
                    className={`details-container${
                        props.isSelected ? " active" : ""
                    }${
                        props.isValidTarget && !props.isSelected ? " valid" : ""
                    }`}
                >
                    <FlexColumn>
                        <FlexRow width="full">
                            <TextField className={"title-bar"}>
                                {(props.item?.title.length ?? 0) > 0
                                    ? props.item?.title ?? "<Unnamed Item>"
                                    : "<Unnamed Item>"}
                            </TextField>
                            {props.isEditable === true && (
                                <FlexRow gap={8} width="auto">
                                    <button
                                        onClick={(e) => {
                                            props.onEditRequested?.();
                                            e.stopPropagation();
                                        }}
                                    >
                                        <img
                                            src={pencilIcon}
                                            alt="..."
                                            style={{ width: "12px" }}
                                        />
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            props.onItemRemoved?.();
                                            e.stopPropagation();
                                        }}
                                    >
                                        X
                                    </button>
                                </FlexRow>
                            )}
                        </FlexRow>
                        <TextField>{`${props.item?.typeString}${
                            props.item?.subTypeString == null
                                ? ``
                                : ` - ${props.item.subTypeString}`
                        }`}</TextField>
                        <FlexRow>
                            <TextField>
                                {props.item?.description ?? ""}
                            </TextField>
                        </FlexRow>
                    </FlexColumn>
                </div>
            </FlexRow>
        </Tooltip>
    );
}
