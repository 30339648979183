import InventoryItem from "../game/mechanics/InventoryItem";
import { ReactComponentElement, createContext, useState } from "react";
import { Dispatch, SetStateAction } from "react";

export type InventoryItemTransferRequestResponse = Readonly<{
    requestAccepted: boolean;
    responseItem?: InventoryItem | null;
}>;

export type InventoryItemTransferContextValues = {
    storedItem: InventoryItem | null;
    containerRef: Element | null;
    senderRemoveItemRequestedCallback?: () => InventoryItemTransferRequestResponse;
    senderReceiveItemRequestedCallback?: (
        item: InventoryItem
    ) => InventoryItemTransferRequestResponse;
    changeItemCallback?: (item: InventoryItem | null) => void;
    recipientRemoveItemRequestedCallback?: () => InventoryItemTransferRequestResponse;
    recipientReceiveItemRequestedCallback?: (
        item: InventoryItem
    ) => InventoryItemTransferRequestResponse;
};

export const DefaultInventoryItemTransferContextValue: InventoryItemTransferContextValues =
    {
        storedItem: null,
        containerRef: null,
    };

const InventoryItemTransferContext =
    createContext<InventoryItemTransferContextValues>(
        DefaultInventoryItemTransferContextValue
    );

export default InventoryItemTransferContext;
