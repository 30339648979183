import { InventoryItemCollectionProps } from "./InventoryItemCollection";
import { InventoryItemTransferRequestResponse } from "../../../../utils/sys/InventoryItemTransferContext";
import InventoryItem from "../../../../utils/game/mechanics/InventoryItem";
import InventoryItemIconSlot from "../InventoryItemSlot/InventoryItemIconSlot";
import InventoryItemTransferContext from "../../../../utils/sys/InventoryItemTransferContext";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";

import { useContext, useRef } from "react";

import "./style/InventoryItemGrid_style.css";
import { UIInventoryItemContainerClickParams } from "../InventoryItemSlot/InventoryItemContainer";

type Props = InventoryItemCollectionProps & {
    selectedItem?: InventoryItem | null;
    onItemClick?: (params?: UIInventoryItemContainerClickParams) => void;
    onItemEditRequested?: (itemIndex: number) => void;
};

export default function InventoryItemGrid(props: Props): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <FlexRow
            forwardRef={ref}
            className="inventory-item-grid-container"
            wrap
        >
            {props.items.map((item, index) => {
                return (
                    <InventoryItemIconSlot
                        id={`${props.id}-${index}`}
                        key={index}
                        className="item-slot"
                        item={item}
                        tooltipEnabled={true}
                        isEditable={props.isEditable}
                        onClick={(e) => {
                            if (props.isEditable) {
                                props.onItemClick?.(e);
                            }
                        }}
                        isSelected={props.selectedItem === item}
                        onItemRemoved={() => {
                            props.onItemRemoved?.(item);
                        }}
                        onEditRequested={() => {
                            props.onItemEditRequested?.(index);
                        }}
                        setItemCallback={(item) => {
                            if (item === null) {
                                return props.removeItemAtCallback?.(index);
                            } else {
                                return props.setItemAtCallback?.(index, item);
                            }
                        }}
                    />
                );
            })}
        </FlexRow>
    );
}
