import FlexColumn from "../Flexbox/FlexColumn";
import FlexRow from "../Flexbox/FlexRow";
import React from "react";
import "./Card_style.css";

export type CardProps = {
    className?: string;
    children: React.ReactNode;
    heading?: string | React.ReactNode | null;
    onClick?: () => void;
    disabled?: boolean;
};

type Props = CardProps;

export default function Card({
    className,
    children,
    disabled,
    heading,
    onClick,
}: Props): JSX.Element {
    return (
        <FlexColumn
            className={`card-body${onClick == null ? "" : " clickable"}${
                disabled ? " disabled" : ""
            }${className == null ? "" : ` ${className}`}`}
            onClick={(e) => {
                onClick?.();
            }}
        >
            {heading != null && (
                <FlexRow>
                    {typeof heading === "string" ? <h2>{heading}</h2> : heading}
                </FlexRow>
            )}
            {children}
        </FlexColumn>
    );
}
