import conductLabels from "../../assets/json/alignment/conduct_labels.json";
import moralityLabels from "../../assets/json/alignment/morality_labels.json";
import alignmgnentLabels from "../../assets/json/alignment/alignment_labels.json";

export default class AlignmentLabels {
    public static getConductLabel(val: number) {
        return (
            conductLabels.find((c) => {
                const max = c.max ?? Number.MAX_SAFE_INTEGER;
                const min = c.min ?? Number.MIN_SAFE_INTEGER;

                return val >= min && val <= max;
            })?.label ?? ""
        );
    }

    public static getMoralityLabel(val: number) {
        return (
            moralityLabels.find((c) => {
                const max = c.max ?? Number.MAX_SAFE_INTEGER;
                const min = c.min ?? Number.MIN_SAFE_INTEGER;

                return val >= min && val <= max;
            })?.label ?? ""
        );
    }

    public static getAlignmentLabel(conduct: number, morality: number) {
        return (
            alignmgnentLabels.find((c) => {
                const conductMin = c.con_min ?? Number.MIN_SAFE_INTEGER;
                const conductMax = c.con_max ?? Number.MAX_SAFE_INTEGER;
                const moralityMin = c.mor_min ?? Number.MIN_SAFE_INTEGER;
                const moralityMax = c.mor_max ?? Number.MAX_SAFE_INTEGER;

                return (
                    conduct >= conductMin &&
                    conduct <= conductMax &&
                    morality >= moralityMin &&
                    morality <= moralityMax
                );
            })?.label ?? ""
        );
    }
}
