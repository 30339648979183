import { Color } from "../../../../utils/sys/Color";
import InventoryItemIconLibrary from "../../../../utils/sys/InventoryItemIconLibrary";
import { MutableRefObject } from "react";

import "./InventoryItemIcon_style.css";

type Props = {
    bgSrc?: string | null;
    iconSrc?: string | null;
    bgColor?: Color | null;
    iconColor?: Color | null;
    showHighlight?: boolean;
    highlightColor?: "active" | "valid" | "invalid";
    onClick?: (e: React.MouseEvent | undefined) => void;
    className?: string;
    style?: React.CSSProperties | undefined;
    forwardRef?: MutableRefObject<any>;
};

export default function ItemIcon(props: Props): JSX.Element {
    const bg = InventoryItemIconLibrary.image(props.bgSrc);
    const icon = InventoryItemIconLibrary.image(props.iconSrc);

    return (
        <div
            ref={props.forwardRef}
            className={`item-icon-container${
                props.className != null ? ` ${props.className}` : ``
            }`}
            onClick={props.onClick}
            style={props.style}
        >
            <div className="item-img-stack">
                {bg != null && <img className="item-bg" src={bg} />}
                {bg != null && (
                    <img
                        className="item-bg"
                        src={bg}
                        style={{
                            ...(props.bgColor != null
                                ? {
                                      filter: `opacity(0.5) drop-shadow(0 0 0 ${props.bgColor})`,
                                  }
                                : undefined),
                        }}
                    />
                )}
                {icon != null && (
                    <img
                        className="item-icon"
                        src={icon}
                        style={{
                            ...(props.iconColor != null
                                ? {
                                      filter: `opacity(1)`,
                                  }
                                : undefined),
                        }}
                    />
                )}
                {icon != null && (
                    <img
                        className="item-icon"
                        src={icon}
                        style={{
                            ...(props.iconColor != null
                                ? {
                                      filter: `opacity(0.5) drop-shadow(0 0 0 ${props.iconColor})`,
                                  }
                                : undefined),
                        }}
                    />
                )}
                {props.showHighlight && (
                    <div
                        className={`item-highlight${
                            props.showHighlight
                                ? props.highlightColor !== undefined
                                    ? ` ${props.highlightColor}`
                                    : ""
                                : ""
                        }`}
                    />
                )}
            </div>
        </div>
    );
}
