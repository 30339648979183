export type RGB = `rgb(${number}, ${number}, ${number})`;
export type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX;

export const White: Color = "#FFFFFF";
export const Black: Color = "#000000";
export const LightGray: Color = "#BBBBBB";
export const Gray: Color = "#888888";
export const DarkGray: Color = "#444444";

export const LightRed: Color = "#FF0000";
export const Red: Color = "#BB0000";
export const DarkRed: Color = "#440000";

export const LightYellow: Color = "#FFFF00";
export const Yellow: Color = "#BBBB00";
export const DarkYellow: Color = "#444400";

export const LightGreen: Color = "#00FF00";
export const Green: Color = "#00BB00";
export const DarkGreen: Color = "#004400";

export const LightCyan: Color = "#00FFFF";
export const Cyan: Color = "#00BBBB";
export const DarkCyan: Color = "#004444";

export const LightBlue: Color = "#0000FF";
export const Blue: Color = "#0000BB";
export const DarkBlue: Color = "#000044";

export const LightPurple: Color = "#FF00FF";
export const Purple: Color = "#BB00BB";
export const DarkPurple: Color = "#440044";

export class ColorUtils {
    public static blend(
        c1: Color,
        c2: Color,
        format: "RGB" | "RGBA" | "HEX" = "HEX"
    ): Color {
        const split1 = this.split(c1);
        const split2 = this.split(c2);

        const mix = {
            r: Math.sqrt(split1.r + split2.r),
            g: Math.sqrt(split1.g + split2.g),
            b: Math.sqrt(split1.b + split2.b),
            a: Math.sqrt(split1.a + split2.a),
        };

        return format === "RGB"
            ? `rgb(${mix.r}, ${mix.g}, ${mix.b})`
            : format === "RGBA"
            ? `rgba(${mix.r}, ${mix.g}, ${mix.b}, ${mix.a})`
            : `#${mix.r.toString(16).padStart(2, "0")}${mix.g
                  .toString(16)
                  .padStart(2, "0")}${mix.b
                  .toString(16)
                  .padStart(2, "0")}${mix.a.toString(16).padStart(2, "0")}`;
    }

    public static split(color: Color): {
        r: number;
        g: number;
        b: number;
        a: number;
    } {
        const result = { r: 0, g: 0, b: 0, a: 1 };
        if (color == null) {
            return result;
        }
        if (color.startsWith("rgb(")) {
            const regex: RegExp = /(rgb\()(\d+),(\d+),(\d+)/;
            const matches = color.replace(/\s/g, "").match(regex);
            if (matches != null) {
                if (matches.length > 1) {
                    result.r = Math.min(Number.parseInt(matches[1]), 255);
                    if (matches.length > 2) {
                        result.g = Math.min(Number.parseInt(matches[2]), 255);
                        if (matches.length > 3) {
                            result.b = Math.min(
                                Number.parseInt(matches[3]),
                                255
                            );
                        }
                    }
                }
            }
        } else if (color.startsWith("rgba(")) {
            const regex: RegExp = /(rgba\()(\d+),(\d+),(\d+),(\.\d+)/;
            const matches = color.replace(/\s/g, "").match(regex);
            if (matches != null) {
                if (matches.length > 1) {
                    result.r = Math.min(Number.parseInt(matches[1]), 255);
                    if (matches.length > 2) {
                        result.g = Math.min(Number.parseInt(matches[2]), 255);
                        if (matches.length > 3) {
                            result.b = Math.min(
                                Number.parseInt(matches[3]),
                                255
                            );
                            if (matches.length > 4) {
                                result.b = Math.min(
                                    Number.parseFloat(matches[4]),
                                    1
                                );
                            }
                        }
                    }
                }
            }
        } else {
            const regex: RegExp =
                /^#((?:[0-9a-fA-F]){1,2})((?:[0-9a-fA-F]){1,2})?((?:[0-9a-fA-F]){1,2})?((?:[0-9a-fA-F]){1,2})?/;
            const matches = color.replace(/\s/g, "").match(regex);
            if (matches != null) {
                if (matches.length > 1) {
                    result.r = Math.min(
                        Number.parseInt(
                            `0x${matches[1].padEnd(2, matches[1])}`
                        ),
                        255
                    );
                    if (matches.length > 2) {
                        result.g = Math.min(
                            Number.parseInt(
                                `0x${matches[2].padEnd(2, matches[2])}`
                            ),
                            255
                        );
                        if (matches.length > 3) {
                            result.b = Math.min(
                                Number.parseInt(
                                    `0x${matches[3].padEnd(2, matches[3])}`
                                ),
                                255
                            );
                        }
                    }
                }
            }
        }

        return result;
    }
}
