import ClickOutAlertWrapper from "../../wrappers/ClickOutAlertWrapper/ClickOutAlertWrapper";
import DropdownSelectorItem from "./DropdownSelectorItem";
import FlexColumn from "../../layout/Flexbox/FlexColumn";
import FlexRow from "../../layout/Flexbox/FlexRow";
import Icon from "../../Icon/Icon";
import React, { ReactNode, useCallback, useRef, useState } from "react";
import TextField from "../../display/TextField/TextField";

import DownArrow from "../../../../assets/images/common/svg/arrow_down.svg";
import DownArrowGray from "../../../../assets/images/common/svg/arrow_down_gray.svg";

import "../../../styles/main_style.css";
import "./DropdownSelector_style.css";

const defaultWidth = 250;

type OptionProps = {
    key?: string;
    label: string;
    value: string;
    isDisabled?: boolean;
};

type Props = {
    placeholder: string;
    alternateButton?: ReactNode;
    value: string | null;
    onChange?: (val: string | null) => void;
    options: Array<OptionProps>;
    isDisabled?: boolean;
    buttonClassName?: string;
    buttonStyle?: React.CSSProperties | undefined;
    showChevron?: boolean;
    direction?:
        | "above"
        | "above-right"
        | "right"
        | "below-right"
        | "below"
        | "below-left"
        | "left"
        | "above-left"
        | "auto-above-below"
        | "auto-left-right";
};

export default function DropdownSelector(props: Props): JSX.Element {
    const showChevron =
        props.showChevron === undefined ? true : props.showChevron;
    const ref = useRef<HTMLDivElement>(null);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const isDisabled =
        props.isDisabled !== undefined ? props.isDisabled : false;
    const buttonClassString = `${
        props.buttonClassName !== undefined
            ? props.buttonClassName
            : `button-style`
    } ${isDisabled ? ` disabled` : ` enabled`}`;
    const [menuPos, setMenuPos] = useState<{
        x: number;
        y: number;
    } | null>(null);
    const [menuTranslation, setMenuTranslation] = useState<{
        x: string;
        y: string;
    } | null>(null);

    const positionMenu = useCallback(() => {
        const windowSize = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
        var rect =
            ref.current?.getBoundingClientRect() ?? new DOMRect(0, 0, 0, 0);
        if (ref.current != null) {
            var offsetParent = ref.current.offsetParent;
            if (offsetParent !== null) {
                const parentRect = offsetParent.getBoundingClientRect();
                rect = new DOMRect(
                    rect.x - parentRect.x + offsetParent.scrollLeft,
                    rect.y - parentRect.y + offsetParent.scrollTop,
                    rect.width,
                    rect.height
                );
            }
        }

        const direction = (() => {
            switch (props.direction) {
                case "auto-above-below":
                    if (rect.bottom > windowSize.height / 2) {
                        return "above";
                    } else {
                        return "below";
                    }
                case "auto-left-right":
                    if (rect.right > windowSize.width / 2) {
                        return "left";
                    } else {
                        return "right";
                    }
                default:
                    return props.direction;
            }
        })();

        switch (direction) {
            case "above":
                setMenuPos({
                    x: rect.left,
                    y: rect.top,
                });
                setMenuTranslation({
                    x: "0",
                    y: "-100%",
                });
                break;
            case "above-right":
                setMenuPos({
                    x: rect.right,
                    y: rect.top,
                });
                setMenuTranslation({
                    x: "0",
                    y: "-100%",
                });
                break;
            case "right":
                setMenuPos({
                    x: rect.right,
                    y:
                        rect.bottom > windowSize.height / 2
                            ? rect.bottom ?? 0
                            : rect.top ?? 0,
                });
                setMenuTranslation({
                    x: "0",
                    y: rect.bottom > windowSize.height / 2 ? "-100%" : "0",
                });
                break;
            case "below-right":
                setMenuPos({
                    x: rect.right ?? 0,
                    y: rect.bottom ?? 0,
                });
                setMenuTranslation({
                    x: "0",
                    y: "0",
                });
                break;
            case "below-left":
                setMenuPos({
                    x: rect.left ?? 0,
                    y: rect.bottom ?? 0,
                });
                setMenuTranslation({
                    x: "-100%",
                    y: "0",
                });
                break;
            case "left":
                setMenuPos({
                    x: rect.left ?? 0,
                    y:
                        rect.bottom > windowSize.height / 2
                            ? rect.bottom ?? 0
                            : rect.top ?? 0,
                });
                setMenuTranslation({
                    x: "-100%",
                    y: rect.bottom > windowSize.height / 2 ? "-100%" : "0",
                });
                break;
            case "above-left":
                setMenuPos({
                    x: rect.left ?? 0,
                    y: rect.top ?? 0,
                });
                setMenuTranslation({
                    x: "-100%",
                    y: "-100%",
                });
                break;
            case "below":
            default:
                setMenuPos({
                    x: rect.left ?? 0,
                    y: rect.bottom ?? 0,
                });
                setMenuTranslation({
                    x: "0",
                    y: "0",
                });
        }
    }, [props.direction]);

    const getValueLabel = useCallback(
        (val: string): string => {
            const option = props.options.find((o) => {
                return o.value.toString() === val;
            });
            return option?.label ?? val;
        },
        [props.options]
    );

    return (
        <div
            ref={ref}
            style={
                props.buttonStyle === undefined
                    ? {
                          width: defaultWidth,
                          display: "block",
                      }
                    : {
                          ...props.buttonStyle,
                          width: props.buttonStyle?.width ?? defaultWidth,
                          display: "block",
                      }
            }
        >
            <FlexRow
                className={buttonClassString}
                width="full"
                onClick={(e) => {
                    if (!isDisabled) {
                        positionMenu();
                        setIsExpanded(!isExpanded);
                    }
                    e?.stopPropagation();
                }}
            >
                <FlexRow width="full">
                    {props.alternateButton ? (
                        props.alternateButton
                    ) : (
                        <TextField>
                            {props.value === null
                                ? props.placeholder?.toString()
                                : getValueLabel(props.value.toString())}
                        </TextField>
                    )}
                </FlexRow>
                {showChevron && (
                    <FlexRow alignment="end" width="auto">
                        <Icon
                            src={isDisabled ? DownArrowGray : DownArrow}
                            size={16}
                        />
                    </FlexRow>
                )}
            </FlexRow>
            {isExpanded && (
                <ClickOutAlertWrapper
                    forwardRef={ref}
                    onClickOut={() => {
                        setIsExpanded(false);
                    }}
                >
                    <div
                        className="options-style"
                        style={{
                            minWidth:
                                props.buttonStyle?.width ?? defaultWidth - 20,
                            left: menuPos?.x ?? 0,
                            top: menuPos?.y ?? 0,
                            transform: `translateX(${
                                menuTranslation?.x ?? "0"
                            }) translateY(${menuTranslation?.y ?? "0"})`,
                        }}
                    >
                        <FlexColumn>
                            {props.options.map((option, index) => (
                                <DropdownSelectorItem
                                    key={
                                        option.key ??
                                        `dd-selector-item-${index}`
                                    }
                                    label={option.label}
                                    value={option.value}
                                    onSelect={() => {
                                        props.onChange?.(option.value);
                                        setIsExpanded(false);
                                    }}
                                />
                            ))}
                        </FlexColumn>
                    </div>
                </ClickOutAlertWrapper>
            )}
        </div>
    );
}
