import { useMousePosition } from "../../../hooks/MouseControlHooks";
import { Point2d } from "../../../types/Geometry";

import "./MouseTrackingComponent_style.css";

type Props = {
    children?: React.ReactNode;
    offset?: Point2d;
    enabled: boolean;
};

export default function MouseTrackingComponent(props: Props): JSX.Element {
    const mousePosition = useMousePosition();

    return (
        <>
            {props.enabled && mousePosition != null && (
                <div
                    className="mouse-tracking-component-container"
                    style={{
                        left: `${
                            (mousePosition?.x ?? 0) + (props.offset?.x ?? 0)
                        }px`,
                        top: `${
                            (mousePosition?.y ?? 0) + (props.offset?.y ?? 0)
                        }px`,
                    }}
                >
                    {props.children}
                </div>
            )}
        </>
    );
}
