import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {
    useGetCharacterData,
    useUpdateCharacterData,
} from "../../../../net/CharacterManagement";
import { CharacterDataJSON } from "../../../../utils/game/character/CharacterDataJSON";
import CharacterSheet from "./CharacterSheet";
import { CharacterDataUtils } from "../../../../utils/game/character/CharacterData";
import ActiveUserContext from "../../../../utils/session/ActiveUserContext";
import Cookies from "universal-cookie";
import { Dictionary } from "../../../../utils/sys/Dictionary";
import { WhitelistUtils } from "../../../../utils/sys/Whitelist";

import "./CharacterSheet_style.css";
import { KeyValuePair } from "../../../../utils/sys/KeyValuePair";

type Props = {
    characterID: number;
};

export default function CharacterSheetContainer({
    characterID,
}: Props): JSX.Element {
    const activeUser = useContext(ActiveUserContext);
    const rawCharacterData = useRef<CharacterDataJSON | null>(null);
    const [loadCharacterData, isLoadingCharacterData] = useGetCharacterData();
    const [updateCharacterData, isUpdatingCharacterData] =
        useUpdateCharacterData();
    const cookies = new Cookies();

    useEffect(() => {
        if (
            characterID != null &&
            characterID !== rawCharacterData.current?.id
        ) {
            loadCharacterData({
                variables: {
                    id: characterID,
                    token: cookies.get("session_token"),
                },
                onComplete: (result) => {
                    rawCharacterData.current = result;
                },
                onError: (error) => {
                    rawCharacterData.current = null;
                },
            });
        }
    }, [characterID]);

    const characterData = useMemo(() => {
        return rawCharacterData.current != null
            ? CharacterDataUtils.processRawCharacterData(
                  rawCharacterData.current
              )
            : null;
    }, [rawCharacterData.current]);

    const editable = useMemo(() => {
        const wl = {
            viewers: rawCharacterData.current?.whitelist?.readers ?? [],
            editors: rawCharacterData.current?.whitelist?.writers ?? [],
        };
        const useWL = rawCharacterData.current?.whitelist_protected === true;
        const creator = rawCharacterData.current?.creator ?? -1;

        return activeUser.id <= 0
            ? false
            : characterData == null
            ? true
            : !useWL ||
              characterData.creator === creator ||
              WhitelistUtils.canEdit(activeUser.id, wl);
    }, [
        rawCharacterData,
        activeUser.id,
        characterData,
        characterData?.isWhitelistProtected,
        characterData?.whitelist,
        characterData?.creator,
    ]);

    const handleCharacterDataChange = useCallback(
        (vals: Dictionary | Array<KeyValuePair>) => {
            const newData = CharacterDataUtils.valueArrayToRawCharacterData(
                Array.isArray(vals) ? vals : vals.toArray()
            );
            const keys = Object.keys(newData);
            if (keys.length > 0) {
                updateCharacterData({
                    variables: {
                        id: characterID,
                        token: cookies.get("session_token"),
                        data: newData,
                    },
                    onComplete: (response) => {
                        if (
                            rawCharacterData.current != null &&
                            response != null
                        ) {
                            const merge = CharacterDataUtils.mergeIn(
                                rawCharacterData.current,
                                response
                            );
                            if (merge !== false) {
                                rawCharacterData.current = merge;
                            }
                        }
                    },
                    onError: (error) => {
                        console.log(error);
                    },
                });
            }
        },
        [rawCharacterData, characterData]
    );

    return (
        <div className="character-sheet-container">
            {isLoadingCharacterData() && <div>Loading...</div>}
            {!isLoadingCharacterData() && characterData == null && (
                <div>Failed to Load</div>
            )}
            {!isLoadingCharacterData() &&
                rawCharacterData != null &&
                characterData != null && (
                    <CharacterSheet
                        characterData={characterData}
                        isEditable={editable}
                        onCharacterDataValueChanged={(vals) => {
                            handleCharacterDataChange(vals);
                        }}
                    />
                )}
        </div>
    );
}
