import { ProficiencyStatKey } from "./Proficiency";

export type TalentSubType =
    | "Beneficiary"
    | "Knowledge"
    | "Operative"
    | "Weapon"
    | "Unknown";

export abstract class TalentBase {
    protected _id: number = -1;
    public get id() {
        return this._id;
    }

    protected _title: string = "";
    public get title(): string {
        return this._title;
    }

    protected _description: string = "";
    public get description(): string {
        return this._description;
    }

    protected _subtype: TalentSubType = "Unknown";
    public get subtype(): string {
        return this._subtype;
    }

    protected _proficiency: Array<ProficiencyStatKey> = [];
    public get proficiency(): Array<ProficiencyStatKey> {
        return this._proficiency;
    }

    protected _module: string | null = null;
    public get module(): string | null {
        return this._module;
    }
}
