import StatBoard from "../../../../utils/game/mechanics/StatBoard";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import Tooltip from "../../../../common/components/wrappers/Tooltip/Tooltip";
import { StatKeyMap } from "../../../../utils/game/mechanics/StatKeyMap";
import { useMemo } from "react";

import "./AttributePanel_style.css";

type Props = Readonly<{
    statBoard: StatBoard;
    statKey: string;
}>;

export default function AttributePanel({
    statBoard,
    statKey,
}: Props): JSX.Element {
    const score = Number.parseInt(statBoard.getValueByStatKey(statKey));

    const scoreTips = statBoard.getTipsByStatKey(statKey, null, null, true);

    return (
        <FlexColumn className="attribute-panel-container">
            <div className="title">{StatKeyMap.get(statKey) ?? statKey}</div>
            <Tooltip
                tooltipContent={scoreTips}
                enabled={scoreTips.length > 0}
                direction="auto-above-below"
            >
                <div className="score-container">
                    <div className="score">{score}</div>
                </div>
            </Tooltip>
        </FlexColumn>
    );
}
