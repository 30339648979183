import { CharacterData } from "../../../../utils/game/character/CharacterData";
import { KeyValuePair } from "../../../../utils/sys/KeyValuePair";
import { useMemo, useState } from "react";

import CharacterTrait from "../../../../utils/game/mechanics/character/CharacterTrait";
import CharacterTraitPanel from "../CharacterTraitPanel/CharacterTraitPanel";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import LoadTraitModal from "../../modals/LoadTraitModal/LoadTraitModal";
import StatBoard from "../../../../utils/game/mechanics/StatBoard";
import TextField from "../../../../common/components/display/TextField/TextField";
import Trait from "../../../../utils/game/mechanics/Trait";

import plusIcon from "../../../../assets/images/common/svg/plus.svg";

import "./CharacterSheetTraitsPanel_style.css";

type Props = {
    characterData: CharacterData;
    statBoard: StatBoard;
    isEditable?: boolean;
    onCharacterDataValueChanged?: (val: KeyValuePair[]) => void;
};

export default function CharacterSheetTraitsPanel(props: Props): JSX.Element {
    const [isLoadTraitModalVisible, setLoadTraitModalVisibility] =
        useState<boolean>(false);
    const [traitOptions, setTraitOptions] = useState<Array<Trait> | undefined>(
        undefined
    );
    const optionFilter = useMemo(() => {
        const traitIDs = props.characterData.traits.map((t) => t.id);
        return (trait: Trait) => !traitIDs.includes(trait.id);
    }, [props.characterData]);

    return (
        <FlexColumn className="character-sheet-traits-panel">
            <FlexRow className="toolbar">
                <FlexRow>
                    <TextField className="header" type="bold">
                        Traits
                    </TextField>
                </FlexRow>
                {props.isEditable && (
                    <FlexRow alignment="end">
                        <button
                            onClick={() => {
                                setLoadTraitModalVisibility(true);
                            }}
                        >
                            <img src={plusIcon} alt="+" />
                        </button>
                    </FlexRow>
                )}
            </FlexRow>
            {props.characterData.traits.map((trait) => (
                <CharacterTraitPanel
                    key={trait.id}
                    trait={trait}
                    isEditable={props.isEditable}
                    onTraitRemoved={() => {
                        props.statBoard.removeFeaturesBySource([
                            `trait_${trait.id}`,
                        ]);
                        props.onCharacterDataValueChanged?.([
                            {
                                key: "traits",
                                value: JSON.stringify(
                                    props.characterData.traits
                                        .filter((tr) => tr !== trait)
                                        .map((tr) => tr.toJSON())
                                ),
                            },
                        ]);
                    }}
                    onTraitActivationChanged={(active) => {
                        trait.active = active;
                        props.onCharacterDataValueChanged?.([
                            {
                                key: "traits",
                                value: JSON.stringify(
                                    props.characterData.traits.map((tr) =>
                                        tr.toJSON()
                                    )
                                ),
                            },
                        ]);
                    }}
                />
            ))}
            {props.isEditable && isLoadTraitModalVisible && (
                <LoadTraitModal
                    isVisible={isLoadTraitModalVisible}
                    traitOptions={traitOptions}
                    optionFilter={optionFilter}
                    onTraitOptionsChanged={(traits) => {
                        setTraitOptions(traits);
                    }}
                    onClose={() => {
                        setLoadTraitModalVisibility(false);
                    }}
                    onConfirm={(traits) => {
                        if (props.onCharacterDataValueChanged !== undefined) {
                            const newCharacterTraits = [
                                ...props.characterData.traits,
                                ...traits.map((trait) =>
                                    CharacterTrait.fromTrait(trait, true)
                                ),
                            ];
                            props.onCharacterDataValueChanged([
                                {
                                    key: "traits",
                                    value: JSON.stringify(
                                        newCharacterTraits.map((trait) =>
                                            trait.toJSON()
                                        )
                                    ),
                                },
                            ]);
                        }

                        setLoadTraitModalVisibility(false);
                    }}
                />
            )}
        </FlexColumn>
    );
}
