import CharacterAbilityPanel from "../CharacterAbilityPanel/CharacterAbilityPanel";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import { KeyValuePair } from "../../../../utils/sys/KeyValuePair";
import LoadAbilityModal from "../../modals/LoadAbilityModal/LoadAbilityModal";
import Ability from "../../../../utils/game/mechanics/Ability";

import { useState } from "react";

import plusIcon from "../../../../assets/images/common/svg/plus.svg";

import "./CharacterSheetAbilitiesPanel_style.css";
import TextField from "../../../../common/components/display/TextField/TextField";

type Props = {
    label?: string;
    abilityOptions?: Array<Ability> | undefined;
    onAbilityOptionsChanged?: (abilities: Array<Ability>) => void;
    abilities: Array<Ability>;
    optionFilter?: ((a: Ability) => boolean) | null;
    isEditable?: boolean;
    abilityCountLimit?: number;
    onAbilitiesAdded?: (abilities: Array<Ability>) => void;
    onAbilityRemoved?: (ability: Ability) => void;
};

export default function CharacterSheetAbilitiesPanel(
    props: Props
): JSX.Element {
    const [isLoadAbilityModalVisible, setLoadAbilityModalVisibility] =
        useState<boolean>(false);
    const [abilityOptions, setAbilityOptions] = useState<
        Array<Ability> | undefined
    >(props.abilityOptions);

    return (
        <FlexColumn className="character-sheet-abilities-panel">
            <FlexRow className="toolbar">
                <FlexRow>
                    <TextField className="header" type="bold">
                        {props.label ?? ""}
                    </TextField>
                </FlexRow>
                <FlexRow alignment="end">
                    {props.isEditable && (
                        <button
                            onClick={() => {
                                setLoadAbilityModalVisibility(true);
                            }}
                            disabled={
                                props.abilityCountLimit !== undefined &&
                                props.abilities.length >=
                                    (props.abilityCountLimit ?? 100000)
                            }
                        >
                            <img src={plusIcon} alt="+" />
                        </button>
                    )}
                </FlexRow>
            </FlexRow>
            {props.abilities.map((ability) => (
                <CharacterAbilityPanel
                    key={ability.id}
                    ability={ability}
                    isEditable={props.isEditable}
                    onAbilityRemoved={() => {
                        props.onAbilityRemoved?.(ability);
                    }}
                />
            ))}
            {props.isEditable && isLoadAbilityModalVisible && (
                <LoadAbilityModal
                    isVisible={isLoadAbilityModalVisible}
                    abilityOptions={abilityOptions}
                    optionFilter={(ability) => {
                        if (props.optionFilter != null) {
                            if (!props.optionFilter(ability)) {
                                return false;
                            }
                        }
                        return !props.abilities.find(
                            (a) => a.id === ability.id
                        );
                    }}
                    onAbilityOptionsChanged={(abilities) => {
                        setAbilityOptions(() => {
                            props.onAbilityOptionsChanged?.(abilities);
                            return abilities;
                        });
                    }}
                    onClose={() => {
                        setLoadAbilityModalVisibility(false);
                    }}
                    onConfirm={(abilities) => {
                        setLoadAbilityModalVisibility(false);

                        if (props.abilityCountLimit === undefined) {
                            props.onAbilitiesAdded?.(abilities);
                        } else {
                            if (
                                props.abilities.length + abilities.length <=
                                props.abilityCountLimit
                            ) {
                                props.onAbilitiesAdded?.(abilities);
                            } else {
                                if (
                                    props.abilities.length <
                                    props.abilityCountLimit
                                ) {
                                    props.onAbilitiesAdded?.(
                                        abilities.filter(
                                            (_, index) =>
                                                index <
                                                (props.abilityCountLimit ?? 0) -
                                                    props.abilities.length
                                        )
                                    );
                                }
                            }
                        }
                    }}
                />
            )}
        </FlexColumn>
    );
}
