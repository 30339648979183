import Race from "./Race";

import races from "../../../assets/json/race/races.json";

const Races = races.map((r) => Race.fromJSON(r));

const InferRaceFromName = (val: string): Race | null => {
    return Races.find((r) => r.key === val || r.name === val) ?? null;
};

export { InferRaceFromName, Races };
