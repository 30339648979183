import { CharacterData } from "../../../../utils/game/character/CharacterData";
import { KeyValuePair } from "../../../../utils/sys/KeyValuePair";
import { useMemo, useState } from "react";

import CharacterTalent from "../../../../utils/game/mechanics/character/CharacterTalent";
import CharacterTalentPanel from "../CharacterTalentPanel/CharacterTalentPanel";
import CheckboxInputField from "../../../../common/components/input/CheckboxInputField/CheckboxInputField";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import LoadTalentModal from "../../modals/LoadTalentModal/LoadTalentModal";
import NumberInputToggleField from "../../../../common/components/input/NumberInputToggleField/NumberInputToggleField";
import StatBoard from "../../../../utils/game/mechanics/StatBoard";
import Talent from "../../../../utils/game/mechanics/Talent";
import TextField from "../../../../common/components/display/TextField/TextField";

import pencilIcon from "../../../../assets/images/common/svg/pencil.svg";
import plusIcon from "../../../../assets/images/common/svg/plus.svg";

import "./CharacterSheetTalentsPanel_style.css";

type Props = {
    characterData: CharacterData;
    statBoard: StatBoard;
    isEditable?: boolean;
    isEditing?: boolean;
    onEditingChanged?: (editing: boolean) => void;
    pointSpendEnabled?: boolean;
    onPointSpendEnabledChanged?: (val: boolean) => void;
    pendingTalentPointSpend?: Array<{ id: number; points: number }>;
    onPendingTalentPointSpendChanged?: (
        vals: Array<{ id: number; points: number }>
    ) => void;
    onCharacterDataValueChanged?: (val: KeyValuePair[]) => void;
};

export default function CharacterSheetTalentsPanel(props: Props): JSX.Element {
    const pendingTalentPointSpend = props.pendingTalentPointSpend ?? [];

    const [isLoadTalentModalVisible, setLoadTalentModalVisibility] =
        useState<boolean>(false);
    const [talentOptions, setTalentOptions] = useState<
        Array<Talent> | undefined
    >(undefined);
    const optionFilter = useMemo(() => {
        const talentIDs = props.characterData.talents.map((t) => t.id);
        return (talent: Talent) => !talentIDs.includes(talent.id);
    }, [props.characterData]);
    const talentPoints = useMemo(() => {
        return props.characterData.talentPoints;
    }, [props.characterData.talentPoints]);

    return (
        <FlexColumn className="character-sheet-talents-panel">
            <FlexRow className="toolbar">
                {props.isEditable ? (
                    <FlexRow gap={8}>
                        <NumberInputToggleField
                            label={"Talent Points"}
                            value={talentPoints}
                            formatValueTextField={(val) => {
                                if (
                                    props.isEditing === true &&
                                    props.pointSpendEnabled === true
                                ) {
                                    return (
                                        val -
                                        (props.pendingTalentPointSpend?.reduce(
                                            (acc, curr) => acc + curr.points,
                                            0
                                        ) ?? 0)
                                    ).toString();
                                } else {
                                    return val.toString();
                                }
                            }}
                            onStopEditing={(val) => {
                                props.onCharacterDataValueChanged?.([
                                    {
                                        key: "available_talent_points",
                                        value: val,
                                    },
                                ]);
                            }}
                        />
                        <CheckboxInputField
                            label={"Enable Point Spend"}
                            checked={props.pointSpendEnabled === true}
                            onChange={() => {
                                props.onPointSpendEnabledChanged?.(
                                    !props.pointSpendEnabled
                                );
                            }}
                        />
                    </FlexRow>
                ) : (
                    <TextField type="bold">Talents</TextField>
                )}
                <FlexRow gap={4} alignment="end" width="auto">
                    {props.isEditable && (
                        <button
                            onClick={() => {
                                const newIsEditing = !props.isEditing;

                                if (!newIsEditing) {
                                    const spentPoints =
                                        pendingTalentPointSpend.reduce(
                                            (acc, curr) => acc + curr.points,
                                            0
                                        );
                                    if (spentPoints !== 0) {
                                        const updates: Array<{
                                            key: string;
                                            value: string | number;
                                        }> = [];
                                        const newCharacterTalents =
                                            props.characterData.talents.map(
                                                (talent) => {
                                                    if (
                                                        pendingTalentPointSpend.find(
                                                            (entry) =>
                                                                entry.id ===
                                                                talent.id
                                                        ) !== undefined
                                                    ) {
                                                        talent.score =
                                                            talent.score +
                                                            (pendingTalentPointSpend.find(
                                                                (entry) =>
                                                                    entry.id ===
                                                                    talent.id
                                                            )?.points ?? 0);
                                                    }
                                                    return talent;
                                                }
                                            );
                                        if (props.pointSpendEnabled === true) {
                                            updates.push({
                                                key: "available_talent_points",
                                                value:
                                                    talentPoints - spentPoints,
                                            });
                                        }
                                        updates.push({
                                            key: "talents",
                                            value: JSON.stringify(
                                                newCharacterTalents.map(
                                                    (talent) => talent.toJSON()
                                                )
                                            ),
                                        });
                                        props.onCharacterDataValueChanged?.(
                                            updates
                                        );
                                    }
                                    props.onPendingTalentPointSpendChanged?.(
                                        []
                                    );
                                }
                                props.onEditingChanged?.(newIsEditing);
                            }}
                        >
                            <img src={pencilIcon} alt="..." />
                        </button>
                    )}
                    {props.isEditable && (
                        <button
                            onClick={() => {
                                setLoadTalentModalVisibility(true);
                            }}
                        >
                            <img src={plusIcon} alt="+" />
                        </button>
                    )}
                </FlexRow>
            </FlexRow>
            {props.characterData.talents.map((talent) => (
                <CharacterTalentPanel
                    key={talent.id}
                    talent={talent}
                    isEditable={props.isEditable}
                    isEditing={props.isEditing}
                    pendingPointSpend={
                        pendingTalentPointSpend.find(
                            (entry) => entry.id === talent.id
                        )?.points ?? undefined
                    }
                    onPendingPointSpendChanged={(_, newValue) => {
                        const index = pendingTalentPointSpend.findIndex(
                            (entry) => entry.id === talent.id
                        );
                        if (index >= 0) {
                            props.onPendingTalentPointSpendChanged?.(
                                pendingTalentPointSpend.map((entry) => {
                                    if (entry.id === talent.id) {
                                        return {
                                            id: entry.id,
                                            points: newValue,
                                        };
                                    } else {
                                        return entry;
                                    }
                                })
                            );
                        } else {
                            props.onPendingTalentPointSpendChanged?.([
                                ...pendingTalentPointSpend,
                                { id: talent.id, points: newValue },
                            ]);
                        }
                    }}
                    onTalentRemoved={() => {
                        props.statBoard.removeFeaturesBySource([
                            `talent_${talent.id}`,
                        ]);
                        props.onCharacterDataValueChanged?.([
                            {
                                key: "talents",
                                value: JSON.stringify(
                                    props.characterData.talents
                                        .filter((ta) => ta !== talent)
                                        .map((ta) => ta.toJSON())
                                ),
                            },
                        ]);
                    }}
                />
            ))}
            {props.isEditable && isLoadTalentModalVisible && (
                <LoadTalentModal
                    isVisible={isLoadTalentModalVisible}
                    talentOptions={talentOptions}
                    optionFilter={optionFilter}
                    onTalentOptionsChanged={(talents) => {
                        setTalentOptions(talents);
                    }}
                    onClose={() => {
                        setLoadTalentModalVisibility(false);
                    }}
                    onConfirm={(talents) => {
                        if (props.onCharacterDataValueChanged !== undefined) {
                            const newCharacterTalents = [
                                ...props.characterData.talents,
                                ...talents.map((talent) =>
                                    CharacterTalent.fromTalent(talent, 0)
                                ),
                            ];
                            props.onCharacterDataValueChanged([
                                {
                                    key: "talents",
                                    value: JSON.stringify(
                                        newCharacterTalents.map((talent) =>
                                            talent.toJSON()
                                        )
                                    ),
                                },
                            ]);
                        }

                        setLoadTalentModalVisibility(false);
                    }}
                />
            )}
        </FlexColumn>
    );
}
