import { useEffect, useMemo, useState } from "react";
import { useGetTraitList } from "../../../../net/CharacterFeatureManagement";
import { TableSortKey } from "../../../../common/components/layout/Table/Table";
import Cookies from "universal-cookie";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import Modal from "../../../../common/components/modals/Modal/Modal";
import Table from "../../../../common/components/layout/Table/Table";
import TextField from "../../../../common/components/display/TextField/TextField";
import TextInput from "../../../../common/components/input/TextInput/TextInput";
import Trait from "../../../../utils/game/mechanics/Trait";

import "../style/CommonModalStyle_style.css";

type Props = {
    isVisible?: boolean;
    traitOptions?: Array<Trait>;
    /** Filters trait options based on external properties */
    optionFilter?: ((t: Trait) => boolean) | null;
    onTraitOptionsChanged?: (traits: Array<Trait>) => void;
    onClose?: () => void;
    onConfirm?: (traits: Array<Trait>) => void;
};

export default function LoadTraitModal(props: Props): JSX.Element {
    const [loadTraitList, isLoadingTraitList] = useGetTraitList();
    const [selectedTraitIDs, setSelectedTraitIDs] = useState<Array<number>>([]);
    const [traitList, setTraitList] = useState<Array<Trait>>(
        props.traitOptions ?? []
    );
    const [traitListSortKey, setTraitListSortKey] =
        useState<TableSortKey | null>(null);
    const [searchStr, setSearchStr] = useState<string>("");

    const cookies = new Cookies();

    useEffect(() => {
        if (props.traitOptions === undefined) {
            loadTraitList({
                variables: { token: cookies.get("session_token") },
                onComplete: (result) => {
                    setTraitList(result);
                    props.onTraitOptionsChanged?.(result);
                },
                onError: (error) => {},
            });
        }
    }, []);

    const handleChangeSort = (sortKey: TableSortKey | null) => {
        if (sortKey !== null) {
            setTraitList(
                traitList.sort((a, b) => {
                    switch (sortKey.key) {
                        case "title":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.title.localeCompare(b.title)
                                : b.title.localeCompare(a.title);
                        case "subtype":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.subtype.localeCompare(b.subtype)
                                : b.subtype.localeCompare(a.subtype);
                        case "cost":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.cost - b.cost
                                : b.cost - a.cost;
                        case "description":
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.description.localeCompare(b.description)
                                : b.description.localeCompare(a.description);
                        default:
                            return sortKey.order === undefined ||
                                sortKey.order === "desc"
                                ? a.id - b.id
                                : b.id - a.id;
                    }
                })
            );
        }

        setTraitListSortKey(sortKey);
    };

    const tableFilter = useMemo(() => {
        const searchFilter =
            searchStr.length > 0
                ? (trait: Trait) => {
                      const match = new RegExp(searchStr, "i");
                      const result =
                          trait.title.match(match) ||
                          trait.subtype.match(match) ||
                          trait.description.match(match);

                      return (
                          (props.optionFilter == null ||
                              props.optionFilter(trait)) &&
                          result !== null
                      );
                  }
                : null;

        const filters = [props.optionFilter, searchFilter].flatMap(
            (f) => f ?? []
        );
        return filters.length > 0
            ? (trait: Trait) => filters.every((filter) => filter(trait))
            : null;
    }, [props.optionFilter, searchStr]);

    return (
        <Modal
            contentClassName="character-common-modal"
            header="Add Trait"
            isVisible={props.isVisible}
            width={750}
            footer="standard-submit-cancel"
            onClose={props.onClose}
            onConfirm={() => {
                props.onConfirm?.(
                    traitList.filter((trait) =>
                        selectedTraitIDs.includes(trait.id)
                    )
                );
            }}
        >
            <FlexRow>
                <TextField type="bold" style={{ width: "auto", margin: "8px" }}>
                    Search
                </TextField>
                <TextInput
                    value={searchStr}
                    style={{ width: "240px" }}
                    onChange={setSearchStr}
                />
                <FlexRow alignment="end">
                    <TextField
                        type="bold"
                        style={{ width: "auto", margin: "8px" }}
                    >{`${selectedTraitIDs.length} Selected`}</TextField>
                    <button
                        onClick={() => {
                            setSelectedTraitIDs([]);
                        }}
                    >
                        Clear
                    </button>
                </FlexRow>
            </FlexRow>
            <hr />
            <Table
                className="options-list"
                columns={[
                    {
                        columnKey: "title",
                        columnHeader: "Title",
                        sortable: true,
                        width: 180,
                    },
                    {
                        columnKey: "subtype",
                        columnHeader: "Subtype",
                        sortable: true,
                        width: 120,
                    },
                    {
                        columnKey: "cost",
                        columnHeader: "Cost",
                        sortable: true,
                        width: 90,
                    },
                    {
                        columnKey: "description",
                        columnHeader: "Description",
                        sortable: true,
                    },
                ]}
                rowData={traitList}
                cellParser={[
                    {
                        columnKey: "title",
                        callback: (trait) => {
                            return (
                                <TextField className="option-item">
                                    {trait.title}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "subtype",
                        callback: (trait) => {
                            return (
                                <TextField className="option-item">
                                    {trait.subtype}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "cost",
                        callback: (trait) => {
                            return (
                                <TextField className="option-item">
                                    {trait.cost.toString()}
                                </TextField>
                            );
                        },
                    },
                    {
                        columnKey: "description",
                        callback: (trait) => {
                            return (
                                <TextField className="option-item">
                                    {trait.description}
                                </TextField>
                            );
                        },
                    },
                ]}
                idGetter={(trait) => trait.id.toString()}
                sortKey={traitListSortKey}
                onChangeSortKey={handleChangeSort}
                isRowSelected={(trait) => selectedTraitIDs.includes(trait.id)}
                onRowClick={(trait) => {
                    if (selectedTraitIDs.includes(trait.id)) {
                        setSelectedTraitIDs([
                            ...selectedTraitIDs.filter(
                                (entry) => entry !== trait.id
                            ),
                        ]);
                    } else {
                        setSelectedTraitIDs([...selectedTraitIDs, trait.id]);
                    }
                }}
                rowFilter={tableFilter}
                showHeader={true}
            />
        </Modal>
    );
}
