import { MutableRefObject } from "react";
import * as React from "react";

import "./FlexRow_style.css";

type Props = Readonly<{
    children?: React.ReactNode;
    alignment?: "start" | "center" | "end";
    width?: "full" | "half" | "quarter" | "auto" | string | number;
    style?: React.CSSProperties | undefined;
    className?: string;
    onClick?: (event?: React.MouseEvent) => void;
    onBlur?: () => void;
    gap?: 4 | 8 | 12 | 16;
    wrap?: boolean;
    forwardRef?: MutableRefObject<any>;
}>;

export default function FlexRow(props: Props): JSX.Element {
    const alignment = props.alignment ?? "left";
    const widthClass =
        props.width === "full" || props.width === undefined
            ? "full-width"
            : props.width === "half"
            ? "half-width"
            : props.width === "quarter"
            ? "quarter-width"
            : props.width === "auto"
            ? "auto-width"
            : null;

    return (
        <div
            ref={props.forwardRef}
            className={`flex-row${
                alignment === "end"
                    ? " flex-end"
                    : alignment === "center"
                    ? " flex-center"
                    : ""
            }${props.gap != undefined ? ` gap-${props.gap}` : ``}${
                props.wrap === true ? ` wrap` : ``
            }${widthClass === null ? `` : ` ${widthClass}`}${
                props.className != null ? ` ${props.className}` : ""
            }`}
            style={
                props.style == null
                    ? props.width == null ||
                      props.width === "full" ||
                      props.width === "half" ||
                      props.width === "quarter"
                        ? undefined
                        : { width: props.width }
                    : props.width == null ||
                      props.width === "full" ||
                      props.width === "half" ||
                      props.width === "quarter"
                    ? props.style
                    : { ...props.style, width: props.width }
            }
            onClick={props?.onClick}
            onBlur={props?.onBlur}
        >
            {props.children}
        </div>
    );
}
