import { useEffect, useState } from "react";
import { Point2d } from "../types/Geometry";

const useMousePosition = () => {
    const [mousePos, setMousePos] = useState<Point2d | null>(null);

    useEffect(() => {
        const updateMousePos = (e: MouseEvent) => {
            setMousePos({ x: e.clientX, y: e.clientY });
        };

        window.addEventListener("mousemove", updateMousePos);

        return () => {
            {
                window.removeEventListener("mousemove", updateMousePos);
            }
        };
    }, []);

    return mousePos;
};

export { useMousePosition };
