import { Dictionary } from "../../sys/Dictionary";
import { VitalityRating } from "./VitalityRating";

import hpLowestJSON from "../../../assets/json/vitality/v2/hp_lowest.json";
import hpLowJSON from "../../../assets/json/vitality/v2/hp_low.json";
import hpMediumJSON from "../../../assets/json/vitality/v2/hp_medium.json";
import hpHighJSON from "../../../assets/json/vitality/v2/hp_high.json";
import hpHighestJSON from "../../../assets/json/vitality/v2/hp_highest.json";

import epLowestJSON from "../../../assets/json/vitality/v2/ep_lowest.json";
import epLowJSON from "../../../assets/json/vitality/v2/ep_low.json";
import epMediumJSON from "../../../assets/json/vitality/v2/ep_medium.json";
import epHighJSON from "../../../assets/json/vitality/v2/ep_high.json";
import epHighestJSON from "../../../assets/json/vitality/v2/ep_highest.json";

import mpLowestJSON from "../../../assets/json/vitality/v2/mp_lowest.json";
import mpLowJSON from "../../../assets/json/vitality/v2/mp_low.json";
import mpMediumJSON from "../../../assets/json/vitality/v2/mp_medium.json";
import mpHighJSON from "../../../assets/json/vitality/v2/mp_high.json";
import mpHighestJSON from "../../../assets/json/vitality/v2/mp_highest.json";

const dictFromJSON = (
    json: Array<{ key: string; value: number }>
): Dictionary<string, number> => {
    return new Dictionary<string, number>(json);
};

const hpLowest = dictFromJSON(hpLowestJSON);
const hpLow = dictFromJSON(hpLowJSON);
const hpMedium = dictFromJSON(hpMediumJSON);
const hpHigh = dictFromJSON(hpHighJSON);
const hpHighest = dictFromJSON(hpHighestJSON);

const epLowest = dictFromJSON(epLowestJSON);
const epLow = dictFromJSON(epLowJSON);
const epMedium = dictFromJSON(epMediumJSON);
const epHigh = dictFromJSON(epHighJSON);
const epHighest = dictFromJSON(epHighestJSON);

const mpLowest = dictFromJSON(mpLowestJSON);
const mpLow = dictFromJSON(mpLowJSON);
const mpMedium = dictFromJSON(mpMediumJSON);
const mpHigh = dictFromJSON(mpHighJSON);
const mpHighest = dictFromJSON(mpHighestJSON);

export class Vitality {
    public static getHPValue(rating: VitalityRating, level: number): number {
        let source: Dictionary<string, number> | null = null;
        switch (rating) {
            case VitalityRating.LOWEST:
                source = hpLowest;
                break;
            case VitalityRating.LOW:
                source = hpLow;
                break;
            case VitalityRating.MEDIUM:
                source = hpMedium;
                break;
            case VitalityRating.HIGH:
                source = hpHigh;
                break;
            case VitalityRating.HIGHEST:
                source = hpHighest;
                break;
        }

        return this.getSourceValue(source, level);
    }

    public static getEPValue(rating: VitalityRating, level: number): number {
        let source: Dictionary<string, number> | null = null;
        switch (rating) {
            case VitalityRating.LOWEST:
                source = epLowest;
                break;
            case VitalityRating.LOW:
                source = epLow;
                break;
            case VitalityRating.MEDIUM:
                source = epMedium;
                break;
            case VitalityRating.HIGH:
                source = epHigh;
                break;
            case VitalityRating.HIGHEST:
                source = epHighest;
                break;
        }

        return this.getSourceValue(source, level);
    }

    public static getMPValue(rating: VitalityRating, level: number): number {
        let source: Dictionary<string, number> | null = null;
        switch (rating) {
            case VitalityRating.LOWEST:
                source = mpLowest;
                break;
            case VitalityRating.LOW:
                source = mpLow;
                break;
            case VitalityRating.MEDIUM:
                source = mpMedium;
                break;
            case VitalityRating.HIGH:
                source = mpHigh;
                break;
            case VitalityRating.HIGHEST:
                source = mpHighest;
                break;
        }

        return this.getSourceValue(source, level);
    }

    private static getSourceValue(
        source: Dictionary<string, number>,
        level: number
    ): number {
        let val = source.get(level.toString());
        if (val === undefined) {
            if (level < 0) {
                val = source.get("0") ?? 0;
            } else if (level > 50) {
                val =
                    (source.get("50") ?? 0) +
                    ((source.get("P") ?? 0) * level - 50);
            } else {
                val = 0;
            }
        }

        return val;
    }
}
