import { InventoryItemTransferRequestResponse } from "../../../../utils/sys/InventoryItemTransferContext";
import FlexColumn from "../../../../common/components/layout/Flexbox/FlexColumn";
import FlexRow from "../../../../common/components/layout/Flexbox/FlexRow";
import InventoryItemGrid from "./InventoryItemGrid";
import InventoryItemList from "./InventoryItemList";
import TextField from "../../../../common/components/display/TextField/TextField";
import InventoryItemEditorModal from "../../../../common/components/modals/InventoryItemEditorModal/InventoryItemEditorModal";
import {
    createRef,
    ReactComponentElement,
    ReactElement,
    useCallback,
    useContext,
    useRef,
    useState,
} from "react";
import { InventoryItemCollectionProps } from "./InventoryItemCollection";
import InventoryItem from "../../../../utils/game/mechanics/InventoryItem";
import InventoryItemTransferContext from "../../../../utils/sys/InventoryItemTransferContext";

import listIcon from "../../../../assets/images/common/svg/list.svg";
import gridIcon from "../../../../assets/images/common/svg/grid.svg";
import plusIcon from "../../../../assets/images/common/svg/plus.svg";

import "./style/InventoryItemDynamicCollection_style.css";
import { UIInventoryItemContainerClickParams } from "../InventoryItemSlot/InventoryItemContainer";

export type DynamicInventoryDisplayMode = "list" | "grid";

type Props = InventoryItemCollectionProps & {
    label?: string;
    selectedItem?: InventoryItem | null;
    displayMode?: DynamicInventoryDisplayMode;
    onDisplayModeChanged?: (mode: DynamicInventoryDisplayMode) => void;
    onItemClick?: (params?: UIInventoryItemContainerClickParams) => void;
    expanded?: boolean;
    onExpandedChanged?: (val: boolean) => void;
};

export default function InventoryItemDynamicCollection(
    props: Props
): JSX.Element {
    const [displayMode, setDisplayMode] = useState<DynamicInventoryDisplayMode>(
        props.displayMode ?? "list"
    );
    const [isItemEditorVisible, setItemEditorVisibility] =
        useState<boolean>(false);
    const [itemEditorIndex, setItemEditorIndex] = useState<number | null>(null);
    const [itemEditorInitialData, setItemEditorInitialData] = useState<
        InventoryItem | undefined
    >(undefined);

    const ref = useRef<HTMLDivElement>(null);

    const onItemEditRequested = useCallback(
        (itemIndex: number) => {
            if (itemIndex >= 0 && itemIndex < props.items.length) {
                setItemEditorIndex(itemIndex);
                setItemEditorInitialData(props.items[itemIndex]);
                setItemEditorVisibility(true);
            }
        },
        [props.items, setItemEditorIndex, setItemEditorVisibility]
    );

    return (
        <div ref={ref} className="inventory-item-dynamic-collection-container">
            <FlexColumn>
                <FlexRow
                    className="header"
                    onClick={(e) => {
                        props.onExpandedChanged?.(!props.expanded === true);
                        e?.stopPropagation();
                    }}
                >
                    <TextField type="bold" className="header-text">
                        {props.label ?? ""}
                    </TextField>
                    <FlexRow className="toolbar" alignment="end">
                        <button
                            className="toolbar-button"
                            onClick={(e) => {
                                setDisplayMode(() => {
                                    props.onDisplayModeChanged?.("list");
                                    return "list";
                                });
                                props.onExpandedChanged?.(true);
                                e.stopPropagation();
                            }}
                        >
                            <img src={listIcon} alt="List" />
                        </button>
                        <button
                            className="toolbar-button"
                            onClick={(e) => {
                                setDisplayMode(() => {
                                    props.onDisplayModeChanged?.("grid");
                                    return "grid";
                                });
                                props.onExpandedChanged?.(true);
                                e.stopPropagation();
                            }}
                        >
                            <img src={gridIcon} alt="Grid" />
                        </button>
                        {props.isEditable && (
                            <>
                                <div className="button-spacer" />
                                <button
                                    className="toolbar-button"
                                    onClick={(e) => {
                                        setItemEditorVisibility(true);
                                        e.stopPropagation();
                                    }}
                                >
                                    <img src={plusIcon} alt="+" />
                                </button>
                            </>
                        )}
                    </FlexRow>
                </FlexRow>
                <div
                    className={`contents-wrapper${
                        props.expanded === true ? `` : ` hidden`
                    }`}
                >
                    {displayMode === "grid" && (
                        <InventoryItemGrid
                            id={props.id}
                            items={props.items}
                            selectedItem={props.selectedItem}
                            onItemClick={props.onItemClick}
                            isEditable={props.isEditable}
                            onItemAdded={props.onItemAdded}
                            onItemRemoved={props.onItemRemoved}
                            onItemRemovedAt={props.onItemRemovedAt}
                            onItemChanged={props.onItemChanged}
                            addItemCallback={props.addItemCallback}
                            removeItemCallback={props.removeItemCallback}
                            removeItemAtCallback={props.removeItemAtCallback}
                            setItemAtCallback={props.setItemAtCallback}
                            onItemEditRequested={onItemEditRequested}
                        />
                    )}
                    {displayMode === "list" && (
                        <InventoryItemList
                            id={props.id}
                            items={props.items}
                            selectedItem={props.selectedItem}
                            onItemClick={props.onItemClick}
                            isEditable={props.isEditable}
                            onItemAdded={props.onItemAdded}
                            onItemRemoved={props.onItemRemoved}
                            onItemRemovedAt={props.onItemRemovedAt}
                            onItemChanged={props.onItemChanged}
                            addItemCallback={props.addItemCallback}
                            removeItemCallback={props.removeItemCallback}
                            removeItemAtCallback={props.removeItemAtCallback}
                            setItemAtCallback={props.setItemAtCallback}
                            onItemEditRequested={onItemEditRequested}
                        />
                    )}
                </div>
            </FlexColumn>
            {isItemEditorVisible && (
                <InventoryItemEditorModal
                    isVisible={isItemEditorVisible}
                    onClose={() => setItemEditorVisibility(false)}
                    onConfirm={(itemData) => {
                        if (itemEditorIndex == null) {
                            props.onItemAdded?.(itemData);
                        } else {
                            props.onItemChanged?.(itemEditorIndex, itemData);
                        }
                        setItemEditorVisibility(false);
                    }}
                    initialItemData={itemEditorInitialData}
                />
            )}
        </div>
    );
}
