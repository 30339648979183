export type Coin = Readonly<{
    id: string;
    name: string;
    unitValue: number;
    standardizedValue: number;
}>;

export type CoinCount = {
    coin: Coin;
    count: number;
};

export type Currency = Readonly<{
    id: string;
    name: string;
    modules: string[];
    denominations: Coin[];
}>;

export class CurrencyUtils {
    public static fromJSON(json: {
        id: string;
        name: string;
        modules?: string[];
        denominations: {
            id: string;
            name: string;
            unit_value: number;
            standardized_unit_value: number;
        }[];
    }): Currency {
        return {
            id: json.id,
            name: json.name,
            modules: json.modules ?? [],
            denominations: json.denominations.map((d) => {
                return {
                    id: d.id,
                    name: d.name,
                    unitValue: d.unit_value,
                    standardizedValue: d.standardized_unit_value,
                };
            }),
        };
    }
}
