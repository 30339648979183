export abstract class AbilityBase {
    protected _id: number = -1;
    public get id() {
        return this._id;
    }

    protected _title: string = "";
    public get title() {
        return this._title;
    }

    protected _description: string = "";
    public get description() {
        return this._description;
    }

    protected _subtype: string = "";
    public get subtype() {
        return this._subtype;
    }

    protected _school: string = "";
    public get school() {
        return this._school;
    }

    protected _vocation: string = "";
    public get vocation() {
        return this._vocation;
    }

    protected _level: number = 1;
    public get level() {
        return this._level;
    }

    protected _cost: string = "";
    public get cost() {
        return this._cost;
    }

    protected _range: string = "";
    public get range() {
        return this._range;
    }

    protected _duration: string = "";
    public get duration() {
        return this._duration;
    }

    protected _accuracyCheck: boolean = false;
    public get accuracyCheck() {
        return this._accuracyCheck;
    }

    protected _exhaustion: number = 0;
    public get exhaustion() {
        return this._exhaustion;
    }

    protected _icon: string = "";
    public get icon() {
        return this._icon;
    }

    protected _module: string | null = null;
    public get module(): string | null {
        return this._module;
    }
}
