import "./TagList_style.css";

export type TagEntryProps = Readonly<{
    id: string;
    value: string;
    isEditable?: boolean;
    onRemove?: () => void;
}>;

export default function TagEntry(props: TagEntryProps): JSX.Element {
    return (
        <div className="tag-container">
            {props.value}
            {props.isEditable === true && (
                <div
                    className="remove-button"
                    onClick={() => {
                        props.onRemove?.();
                    }}
                >
                    X
                </div>
            )}
        </div>
    );
}
