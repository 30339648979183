import ImageRenderer from "../../../../common/components/display/ImageRenderer/ImageRenderer";
import ImageSlicer from "../../../../common/utilities/ImageSlicer";
import { useMemo } from "react";

import abilityIcons01Sheet from "../../../../assets/images/common/jpg/abilities/ability_icons_01.jpg";

import "./AbilityIcon_style.css";

const ability01ImageSlicer = new ImageSlicer(abilityIcons01Sheet, 16, 16);

type Props = {
    src: string;
    index: number;
    className?: string;
};

export default function AbilityIcon(props: Props): JSX.Element {
    const isAbilityImageLoaded = useMemo(() => {
        return ability01ImageSlicer.isLoaded;
    }, [ability01ImageSlicer.isLoaded]);

    const image = useMemo(() => {
        return ability01ImageSlicer.image;
    }, [ability01ImageSlicer.image]);

    const subCoords = useMemo(() => {
        return ability01ImageSlicer.getPixelCoordsFromIndex(props.index);
    }, [props.index]);

    return (
        <>
            {isAbilityImageLoaded && props.src === "ability_icons_01" ? (
                <ImageRenderer
                    className={`ability-icon${
                        props.className === undefined
                            ? ``
                            : ` ${props.className}`
                    }`}
                    image={image}
                    imageSubCoords={subCoords}
                />
            ) : (
                <div
                    className={`ability-icon-placeholder${
                        props.className === undefined
                            ? ``
                            : ` ${props.className}`
                    }`}
                />
            )}
        </>
    );
}
