import { Color } from "../../../../utils/sys/Color";
import ClickOutAlertWrapper from "../../wrappers/ClickOutAlertWrapper/ClickOutAlertWrapper";
import Icon from "../../Icon/Icon";
import { useCallback, useMemo, useRef, useState } from "react";

import greenCheck from "../../../../assets/images/common/svg/check_green.svg";
import redX from "../../../../assets/images/common/svg/x_red.svg";

import "./Meter_style.css";

type Props = {
    /** Baseline color with which to fill the meter */
    fillColor: Color;
    /** The text to show as the current value for the meter */
    currentValue?: number;
    /** The text to show as the max value for the meter */
    maxValue?: number;
    meterClassName?: string;
    isEditable?: boolean;
    isCurrentEditable?: boolean;
    isMaxEditable?: boolean;
    showNumberLabels?: boolean;
    onValuesChanged?: (vals: { current: number; max: number }) => void;
};

export default function Meter(props: Props): JSX.Element {
    const [editorCurrentValue, setEditorCurrentValue] = useState<number>(
        props.currentValue ?? 0
    );
    const [editorMaxValue, setEditorMaxValue] = useState<number>(
        props.maxValue ?? 0
    );
    const [isEditingCurrent, setEditingCurrent] = useState<boolean>(false);
    const [isEditingMax, setEditingMax] = useState<boolean>(false);
    const showNumberLabels = useMemo((): boolean => {
        return props.showNumberLabels === undefined
            ? true
            : props.showNumberLabels;
    }, [props.showNumberLabels]);

    const currentElementRef = useRef(null);
    const maxElementRef = useRef(null);

    const currentValueRef = useRef<number>();
    currentValueRef.current = editorCurrentValue;
    const maxValueRef = useRef<number>(editorMaxValue);
    maxValueRef.current = editorMaxValue;

    const fillAmount = useMemo(() => {
        const curr = props.currentValue ?? 0;
        const max = props.maxValue ?? 0;

        if (max <= 0) {
            return 0;
        } else {
            if (curr <= 0) {
                return 0;
            } else if (curr < max) {
                return curr / max;
            } else {
                return 1;
            }
        }
    }, [props.currentValue, props.maxValue]);

    return (
        <div
            className={`meter-container${
                props.meterClassName != null ? ` ${props.meterClassName}` : ""
            }`}
        >
            <div className="top-left-border"></div>
            <div className="top-border"></div>
            <div className="top-right-border"></div>
            <div className="left-border"></div>
            <div className="meter-backing">
                <MeterFill
                    fillAmount={fillAmount}
                    fillColor={props.fillColor}
                />
            </div>
            <div className="meter-overlay">
                {isEditingCurrent && props.isCurrentEditable === true ? (
                    <div
                        ref={currentElementRef}
                        className="current-value-editor-container"
                    >
                        <ClickOutAlertWrapper
                            forwardRef={currentElementRef}
                            onClickOut={() => {
                                setEditingCurrent(false);

                                props.onValuesChanged?.({
                                    current:
                                        currentValueRef.current ??
                                        props.currentValue ??
                                        0,
                                    max: props.maxValue ?? 0,
                                });
                            }}
                        >
                            <input
                                type="number"
                                value={editorCurrentValue}
                                onChange={(e) => {
                                    setEditorCurrentValue(
                                        Number.parseInt(e.target.value)
                                    );
                                }}
                            />
                        </ClickOutAlertWrapper>
                    </div>
                ) : (
                    <div
                        className={`current-value-field${
                            props.isEditable && props.isCurrentEditable
                                ? ` editable`
                                : ``
                        }`}
                        onClick={() => {
                            if (props.isEditable && props.isCurrentEditable) {
                                setEditorCurrentValue(props.currentValue ?? 0);
                                setEditingCurrent(true);
                            }
                        }}
                    >
                        {showNumberLabels ? props.currentValue ?? 0 : null}
                    </div>
                )}
                {isEditingMax && props.isMaxEditable === true ? (
                    <div
                        ref={maxElementRef}
                        className="max-value-editor-container"
                    >
                        <ClickOutAlertWrapper
                            forwardRef={maxElementRef}
                            onClickOut={() => {
                                setEditingMax(false);

                                props.onValuesChanged?.({
                                    current: props.currentValue ?? 0,
                                    max:
                                        maxValueRef.current ??
                                        props.maxValue ??
                                        0,
                                });
                            }}
                        >
                            <input
                                type="number"
                                value={editorMaxValue}
                                onChange={(e) => {
                                    setEditorMaxValue(
                                        Number.parseInt(e.target.value)
                                    );
                                }}
                            />
                        </ClickOutAlertWrapper>
                    </div>
                ) : (
                    <div
                        className={`max-value-field${
                            props.isEditable && props.isMaxEditable
                                ? ` editable`
                                : ``
                        }`}
                        onClick={() => {
                            if (props.isEditable && props.isMaxEditable) {
                                setEditorMaxValue(props.maxValue ?? 0);
                                setEditingMax(true);
                            }
                        }}
                    >
                        {showNumberLabels ? props.maxValue ?? 0 : null}
                    </div>
                )}
            </div>
            <div className="right-border"></div>
            <div className="bottom-left-border"></div>
            <div className="bottom-border"></div>
            <div className="bottom-right-border"></div>
        </div>
    );
}

type MeterFillProps = {
    /** Amount (0.0 - 1.0) to fill the meter */
    fillAmount: number;
    /** Baseline color with which to fill the meter */
    fillColor: Color;
};

function MeterFill(props: MeterFillProps): JSX.Element {
    return (
        <div
            className="meter-fill-bar"
            style={{
                width: `${Math.min(Math.max(props.fillAmount, 0), 1) * 100}%`,
                backgroundColor: props.fillColor,
            }}
        ></div>
    );
}
